import React, { useState } from "react";
import Navbarside from "./Navbarside";
import Footer from "./footer";
import { getfitcenterDetails } from "../store/slices/fitness";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const FitnessCenterDetails = () => {
  const [fcName, setfcName] = useState("");
  const [fcEmail, setfcEmail] = useState("");
  const [fcMobileNo, setfcMobileNo] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [amenities, setAmenities] = useState([]);
  const [services, setServices] = useState([]);

  const dispatch = useDispatch();
  const { id } = useParams();
  localStorage.setItem("fitnesscenterId", id);

  React.useEffect(() => {
    dispatch(getfitcenterDetails())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id === id) {
          setfcName(data.user.data[0].fcName);
          setfcEmail(data.user.data[0].fcEmail);
          setfcMobileNo(data.user.data[0].fcMobileNo);
          setCity(data.user.data[0].fcAddress.city);
          setState(data.user.data[0].fcAddress.state);
          setPincode(data.user.data[0].fcAddress.pincode);
          setAddress(data.user.data[0].fcAddress.doorNoAndStreetName);
          setAmenities(data.user.data[0].amenities);
          setServices(data.user.data[0].fitnessServices);
        }
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);
  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-3">
                <div className="row">
                  <div className="col-lg-12 col-md-10 col-sm-12">
                    <div className="card-details">
                      <h4 className="text-left pb-3">Fitness Center Details</h4>

                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-gap-bet">
                          <div className="d-flex justify-content-between">
                            <div className="info-head">
                              <p>Fitness Center Name: {fcName}</p>
                              <p>Email: {fcEmail}</p>
                              <p>Contact Number: {fcMobileNo}</p>
                              <p>
                                Address: {address}
                                <br />
                                {city}, State:{state}
                                ,Pincode:{pincode}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-details mt-2 mb-4">
                      <div className="card-title-wrap bar-success">
                        <h4 className="card-title ">Amenities</h4>
                        <div className="row">
                          {amenities.map((amenityVal, index) => (
                            <div className="col-md-2" align="center">
                              <p
                                style={{
                                  padding: "10px",
                                  background: "blue",
                                  color: "white",
                                  borderRadius: "10px",
                                  fontWeight: 400,
                                }}
                              >
                                {amenityVal.name}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="card-details mt-2 mb-4">
                      <div className="card-title-wrap bar-success">
                        <h4 className="card-title ">Services</h4>
                        <div className="row">
                          {services.map((serviceVal, index) => (
                            <div className="col-md-2" align="center">
                              <p
                                style={{
                                  padding: "10px",
                                  borderRadius: "10px",
                                  background: "blue",
                                  fontWeight: 400,
                                  color: "white",
                                }}
                              >
                                {serviceVal.name}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
};

export default FitnessCenterDetails;
