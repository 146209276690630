import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "./Navbarside";
import { Link } from "react-router-dom";
import Footer from "./footer";
import { Clients } from "../store/slices/client";
import { userDetails } from "./../store/slices/auth";
function MyPatient() {
  const [MyPatients, setMyPatients] = useState([]);
  const [details, setDetails] = useState("");
  const [topExpertise, settopExpertise] = useState("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  let expertId = JSON.parse(localStorage.getItem("user")).data.id;

  React.useEffect(() => {
    dispatch(userDetails())
      .unwrap()
      .then((data) => {
        setDetails(data.user.data[0]);
        settopExpertise(data.user.data[0].topExpertise.name);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  let flow = "consultation";
  let type = "healthCare";
  React.useEffect(() => {
    let item = {
      flow,
      type,
      expertId,
    };
    localStorage.setItem("item", JSON.stringify(item));
    dispatch(Clients())
      .unwrap()
      .then((data) => {
        setMyPatients(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">
                        My Patient
                        {/* <Link
                              to="/UploadPackage"
                              className="btn btn-primary"
                            >
                              <i className="fa fa-plus"></i> Add
                            </Link> */}
                      </h4>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Patient Name</th>

                              <th>Consultation</th>
                            </tr>
                          </thead>
                          <tbody>
                            {MyPatients.map((consultationVal, index) => (
                              <tr key={index}>
                                <td>
                                  <Link
                                    to={`/ClientDetails/${consultationVal._id}`}
                                  >
                                    {consultationVal.firstName}{" "}
                                    {consultationVal.lastName}
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={`/PatientConsultation/${
                                      consultationVal._id
                                        ? consultationVal._id
                                        : null
                                    }`}
                                  >
                                    Consultations
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
}

export default MyPatient;
