import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "./../Navbarside";
import { Link, useLocation, useParams } from "react-router-dom";
import Footer from "./../footer";
import { API_BASE_URL } from "./../../utils/constants/api.constants";
import axios, { get } from 'axios'
import VideoPlayer from '../videoPlayer/VideoPlayer';
import VideoFilePlayer from "../videoPlayer/videoFilePlayer";

function ViewHowTo() {
  const params = useParams();
  const { id } = params;
  const module = 'howTo'

  const [data, setData] = useState({});
  const dispatch = useDispatch();
  React.useEffect(() => {
    get(API_BASE_URL + '/get/' + module + '?id=' + id).then(res => {
      if (res.data?.data) {
        setData(res.data.data[0])
      }
    }).catch(err => alert(err.message))
  }, [dispatch]);

  const deleteData = (id) => {
    axios.delete(`${API_BASE_URL}/delete/${module}?id=${id}`).then(res => {
      if (res.data?.status === 1) alert('deleted successfully')
    }).catch(err => alert(err.message))
  }

  function isVideoLink(url) {
    var videoExtensions = ['mp4', 'avi', 'wmv', 'mov', 'flv'];
    var fileExtension = url.split('.').pop();

    return {
      status: videoExtensions.includes(fileExtension),
      format: fileExtension
    }
  }

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">
                        How To Details
                      </h4>
                      <br></br>
                      <p>Name : {data?.name}</p>
                      <p>Description : {data?.description}</p>
                      <p>Sequence : {data?.sequence}</p>
                      <p>Thumbnail :</p>
                      <img
                        src={data?.thumbnail}
                        alt=""
                        style={{
                          width: "150px",
                          height: "100px",
                        }}
                      />
                      <p>Content : </p>
                      {data?.mediaLink?.includes('s3')
                        ? isVideoLink(data.mediaLink).status
                          ? <VideoFilePlayer s3Link={data.mediaLink} format={isVideoLink(data.mediaLink).format} />
                          : <img
                            src={data.mediaLink}
                            alt=""
                            style={{
                              width: "150px",
                              height: "100px",
                            }}
                          />
                        : <VideoPlayer url={data.mediaLink} />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body >
  );
}

export default ViewHowTo;
