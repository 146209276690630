import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import Navbarside from "../component/Navbarside";
import Footer from "./footer";
import Moment from "moment";
import { HcRevenue } from "../store/slices/revenue";

function Revenue() {
  const [revenuehc, setRevenueHc] = useState([]);
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(HcRevenue())
      .unwrap()
      .then((data) => {
        setRevenueHc(data.user.data.revenuesData);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      {/* <div className="card-title-wrap bar-success">
                        <h4 className="card-title">Zero configuration</h4>
                    </div> */}
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Consultation Date</th>
                              <th>Consultation Id</th>
                              <th>Client</th>
                              <th>Session Cost</th>
                              <th>Payout</th>
                              <th>Service Tax</th>
                            </tr>
                          </thead>
                          <tbody>
                            {revenuehc.map((RevenueVal, index) => (
                              <tr key={index}>
                                <td>
                                  {Moment(
                                    RevenueVal.hcConsultationId.startDate
                                  ).format("DD-MM-YYYY")}
                                </td>
                                <td>{RevenueVal.hcConsultationId._id}</td>
                                <td>
                                  {
                                    RevenueVal.hcConsultationId.userId[0]
                                      .firstName
                                  }{" "}
                                  {
                                    RevenueVal.hcConsultationId.userId[0]
                                      .lastName
                                  }
                                </td>
                                <td>Rs. {RevenueVal.sessionCost}</td>
                                <td>Rs. {RevenueVal.payout}</td>
                                <td>Rs. {RevenueVal.serviceTax}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default Revenue;
