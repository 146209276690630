import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Navbarside from "./Navbarside";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "./footer";
import { userDetails, editprofile } from "../store/slices/auth";
import { API_PATHS } from "../utils/constants/api.constants";
const EditProfile = () => {
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const [certificates, setCertificates] = useState([]);
  const [firstname, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [about, setAbout] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [state, setState] = useState("");
  const [topExpertise, settopExpertise] = useState("");
  const [registrationNumber, setregistrationNumber] = useState("");

  React.useEffect(() => {
    dispatch(userDetails())
      .unwrap()
      .then((data) => {
        setFirstName(data.user.data[0].firstName);
        setLastName(data.user.data[0].lastName);
        setAbout(data.user.data[0].about);
        setCity(data.user.data[0].city);
        setCountry(data.user.data[0].country);
        setEmail(data.user.data[0].email);
        setMobileNo(data.user.data[0].mobileNo);
        setPinCode(data.user.data[0].pinCode);
        setState(data.user.data[0].state);
        settopExpertise(data.user.data[0].topExpertise._id);
        setregistrationNumber(data.user.data[0].registrationNumber);
        setCertificates(data.user.data[0].certificates);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  async function imageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    const files = [];
    files.push(...e.target.files);
    files.forEach((file) => formdata.append("file", file));
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.multiUploadImage, requestOptions);
    let data = await response.json();
    let imgurl = [];
    for (let i = 0; i < data.data.length; i++) {
      imgurl.push(data.data[i]);
    }
    setCertificates(imgurl);
    //setMessage(data.message);
  }
  async function upload(e) {
    e.preventDefault();

    let item = {
      set: {
        firstname,
        lastName,
        about,
        city,
        country,
        email,
        mobileNo,
        pinCode,
        state,
        registrationNumber,
        certificates,
      },
    };

    dispatch(editprofile(item))
      .unwrap()
      .then(() => {
        alert("Updated succesfully");
        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">Edit Profile</h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <form onSubmit={upload}>
                        <div className="row">
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              First Name
                            </p>
                            <input
                              type="text"
                              placeholder="Enter your first name"
                              value={firstname}
                              onChange={(e) => setFirstName(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Lastname
                            </p>
                            <input
                              type="text"
                              placeholder="Enter your last name"
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              About
                            </p>
                            <input
                              type="text"
                              placeholder="Enter your about"
                              value={about}
                              onChange={(e) => setAbout(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              City
                            </p>
                            <input
                              type="text"
                              placeholder="Enter your city name"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Country
                            </p>
                            <input
                              type="text"
                              placeholder="Enter your country name"
                              value={country}
                              onChange={(e) => setCountry(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Email
                            </p>
                            <input
                              type="text"
                              placeholder="Enter your email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Mobile No
                            </p>
                            <input
                              type="text"
                              placeholder="Enter your mobile number"
                              value={mobileNo}
                              onChange={(e) => setMobileNo(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Pincode
                            </p>
                            <input
                              type="number"
                              placeholder="Enter your pincode"
                              value={pinCode}
                              onChange={(e) => setPinCode(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              State
                            </p>
                            <input
                              type="text"
                              placeholder="Enter your state"
                              value={state}
                              onChange={(e) => setState(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{}</p>
                          </div>

                          {topExpertise === "6343acb2f427d20b635ec853" && (
                            <div className="col-md-6 col-sm-12 pt-2">
                              <p
                                className="col-black"
                                style={{ marginBottom: "2px" }}
                              >
                                Regd No.
                              </p>
                              <input
                                type="text"
                                placeholder="Enter your registration number"
                                value={registrationNumber}
                                onChange={(e) =>
                                  setregistrationNumber(e.target.value)
                                }
                                className="form-control"
                              />
                              <p className="alert-message">{}</p>
                            </div>
                          )}
                          <div className="col-md-6 col-sm-12">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Certificates
                            </p>
                            <br />
                            {/* <div className="row">
                            {certificates !='' && (
                              {certificates.map((image, index) => (
                                <div className="col-md-2">
                                  <a
                                    href={image}
                                    target="_blank"
                                    className="btn btn-primary"
                                    style={{
                                      height: "25px",
                                      padding: "0 10px",
                                    }}
                                  >
                                    View
                                  </a>
                                </div>
                              ))}
                              )}
                            </div> */}

                            <input
                              type="File"
                              name="imageUrl"
                              onChange={(e) => imageUpload(e)}
                              id="inputimageUrl"
                              className="form-control-file"
                              multiple
                            />
                            <p className="alert-message">{}</p>
                          </div>
                        </div>

                        <div className="d-flex justify-content-center pt-3">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            // onClick={save}
                          >
                            Save
                          </button>
                          &nbsp; &nbsp; &nbsp;
                          <button
                            type="reset"
                            className="btn btn-warning"
                            // onClick={cancel}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default EditProfile;
