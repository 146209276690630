import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Navbarside from "./Navbarside";
import Loader from "./Loader";
import { useDispatch, useSelector } from "react-redux";
import Footer from "./footer";
import { getViewLabs } from "../store/slices/lab";
import { orange } from "@mui/material/colors";

const ViewLabs = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  localStorage.setItem("vendorId", id);
  const [isLoading, setIsLoading] = useState(false);
  const [labDetail, setlabDetail] = useState([]);

  React.useEffect(() => {
    setIsLoading(true);
    dispatch(getViewLabs())
      .unwrap()
      .then((data) => {
        setIsLoading(false);
        if (data?.user?.data) {
          setlabDetail(data.user.data);
        }
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />
      {isLoading && <Loader />}

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header  ">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Labs</h4>
                        &nbsp; &nbsp; &nbsp;
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th style={{ width: "150px" }}>Lab Name</th>

                              <th>Email</th>
                              <th>Mobile Number</th>
                              <th>Service Type</th>
                            </tr>
                          </thead>
                          <tbody>
                            {labDetail.map((LabVal, index) => (
                              <tr>
                                <td>{LabVal.name}</td>

                                <td>{LabVal.labEmail}</td>
                                <td>{LabVal.labMobileNo}</td>

                                <td >{LabVal.serviceType}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ViewLabs;
