import React, { useState,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import Navbarside from "../component/Navbarside";
import { Wenquiry, EditWmenquiry } from "../store/slices/enquiry";
import Moment from "moment";
import Footer from "./footer";

import { useDownloadExcel } from "react-export-table-to-excel";
import { API_PATHS } from "../utils/constants/api.constants";
import axios from "axios";
import { excelDownload } from "../utils/appUtils";

const WmEnquiry = () => {
  const [expertises, setexpertise] = useState([]);
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const tableRef = useRef(null);

  const [data, setData] = useState([]);
  const fileName = "WMEnqiury";

  React.useEffect(() => {
    const fetchData = () => {
      axios.get(API_PATHS.getWmEnquiry).then((postData) => {
        const customHeadings = postData.data.data.map((item) => ({
          Name: item.name,
          Email: item.email,
          "Mobile No.": item.contactNumber,
          Profession: item.profession,
          "Health Conditions": item.healthConditions,
        }));

        setData(customHeadings);
      });
    };
    fetchData();
    fetchDataWEnquiry()
  }, []);

  React.useEffect(() => {

  }, [dispatch]);

  const fetchDataWEnquiry =(page =0)=>{
    dispatch(Wenquiry())
    .unwrap()
    .then((data) => {
      setexpertise(data.user.data);
    })
    .catch(({ message }) => {
      alert(message);
    });
  }

  async function enableApprove(id) {
    let status = "expertAssigned";
    let item = { set: { status } };
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("id", id);

    dispatch(EditWmenquiry())
      .unwrap()
      .then(() => {
        // alert("Uploaded succesfully");
        // window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
    console.log(item);
    console.log(id);
  }
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Weight Management Enquiry",
    sheet: "Sheet1",
  });
  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">
                          Weight Management Enquiry
                        </h4>{" "}
                        &nbsp; &nbsp; &nbsp;
                        {/* <ExportToExcel apiData={data} fileName={fileName} /> */}
                        <div className="col-md-2 col-sm-12 pt-2 mt-3">
                          <button
                            onClick={excelDownload()}
                            className="btn btn-primary"
                          >
                            Export to Excel
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration"
                          ref={tableRef}
                        >
                          <thead>
                            <tr>
                              <th>User Details</th>
                              {/* <th>Enquiry For</th> */}
                              <th>Message</th>
                              <th>Preferred Language</th>
                              <th>Date of Enquiry</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          {loading ? (
                            <Loader active inline />
                          ) : (
                            <>
                              <tbody>
                                {expertises.map((enqVal, index) => (
                                  <tr key={index}>
                                    <td>
                                      Name: {enqVal.name}
                                      <br />
                                      Email: {enqVal.email}
                                      <br />
                                      Mobile Number: {enqVal.contactNumber}
                                      <br />
                                      Profession: {enqVal.profession}
                                      <br />
                                      Health Condition:{" "}
                                      {enqVal.healthConditions}
                                    </td>
                                    {/* <td>{enqVal.enquiryFor}</td> */}

                                    <td>{enqVal.message}</td>
                                    <td>{enqVal.prefferedLanguage}</td>
                                    <td>
                                      {Moment(enqVal.created_at).format(
                                        "DD-MM-YYYY"
                                      )}
                                      /{" "}
                                      {Moment(enqVal.created_at).format(
                                        "hh:mm A"
                                      )}
                                    </td>

                                    <td>
                                      {enqVal.userId &&
                                        enqVal.status === "initiated" && (
                                          <Link
                                            to={`/ProcessEnquiry/:id'${enqVal.userId
                                              ? enqVal.userId
                                              : null
                                              }`}
                                            onClick={() =>
                                              enableApprove(enqVal._id)
                                            }
                                            className="btn btn-warning"
                                            style={{
                                              height: "25px",
                                              padding: "0 10px",
                                            }}
                                          >
                                            Assign Expert
                                          </Link>
                                        )}

                                      {enqVal.userId &&
                                        enqVal.status === "expertAssigned" && (
                                          <button
                                            className="btn btn-success"
                                            style={{
                                              height: "25px",
                                              padding: "0 10px",
                                            }}
                                          >
                                            Expert Assigned
                                          </button>
                                        )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default WmEnquiry;