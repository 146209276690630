import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import formsService from "../../services/forms.service";
import AuthService from "../../services/auth.service";



export const fitnessFormData = createAsyncThunk(
    "get/fitnessform",
    async(thunkAPI) => {
        try {
            const data = await formsService.fitnessForms();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const medicalhistoryData = createAsyncThunk(
    "get/medicalhistory",
    async(thunkAPI) => {
        try {
            const data = await formsService.medicalHistory();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const measurementData = createAsyncThunk(
    "get/medicalhistory",
    async(thunkAPI) => {
        try {
            const data = await formsService.measurements();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const weightLogData = createAsyncThunk(
    "get/weightLog",
    async(thunkAPI) => {
        try {
            const data = await formsService.weightLog();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const lifestyleData = createAsyncThunk(
    "get/weightLog",
    async(thunkAPI) => {
        try {
            const data = await formsService.lifeStyleForm();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const LabReports = createAsyncThunk(
    "get/weightLog",
    async(thunkAPI) => {
        try {
            const data = await formsService.Labreports();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const socialHabits = createAsyncThunk(
    "get/weightLog",
    async(thunkAPI) => {
        try {
            const data = await formsService.socialHabits();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const majorIllness = createAsyncThunk(
    "get/weightLog",
    async(thunkAPI) => {
        try {
            const data = await formsService.majorIllness();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const familyIllness = createAsyncThunk(
    "get/weightLog",
    async(thunkAPI) => {
        try {
            const data = await formsService.familyIllness();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const surgeryHistory = createAsyncThunk(
    "get/weightLog",
    async(thunkAPI) => {
        try {
            const data = await formsService.surgeryHistory();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const allergyHistory = createAsyncThunk(
    "get/medicalhistory",
    async(thunkAPI) => {
        try {
            const data = await formsService.allergyHistory();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const userhealthLocker = createAsyncThunk(
    "get/medicalhistory",
    async(thunkAPI) => {
        try {
            const data = await formsService.HealthLocker();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const userBp = createAsyncThunk(
    "get/medicalhistory",
    async(thunkAPI) => {
        try {
            const data = await formsService.userBp();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const bloodGlucose = createAsyncThunk(
    "get/medicalhistory",
    async(thunkAPI) => {
        try {
            const data = await formsService.bloodGlucose();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const hba1c = createAsyncThunk(
    "get/medicalhistory",
    async(thunkAPI) => {
        try {
            const data = await formsService.hba1c();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const dietPlan = createAsyncThunk(
    "get/medicalhistory",
    async(thunkAPI) => {
        try {
            const data = await formsService.dietPlan();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const WorkoutPlan = createAsyncThunk(
    "get/medicalhistory",
    async(thunkAPI) => {
        try {
            const data = await formsService.WorkoutPlan();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
    loading: false,
    error: "",
    user: AuthService.getUserDetails() || null,
    isLoggedIn: false,
};

const toolSlice = createSlice({
    name: "forms",
    initialState,
    extraReducers: {
      
        [fitnessFormData.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [fitnessFormData.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [fitnessFormData.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },

       
        // [logout.fulfilled]: (state, action) => {
        //   state.isLoggedIn = false;
        //   state.user = null;
        // },
    },
});

const { reducer } = toolSlice;
export default reducer;