import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class ClientService {
    static getClient() {
      let item = JSON.parse(localStorage.getItem("item"));
      return axios
      .post(API_PATHS.getClient,
          item
      )
      .then((response) => {
          if (response.data) {
              // localStorage.setItem("user", JSON.stringify(response.data));
          }
          return response.data;
      });
      }

      
      static bloodGlucose() {
        let api = '';      
        let userId = localStorage.getItem("userId"); 
        api = API_PATHS.bloodGlucose+ '?userId=' + userId + '&dateType=monthly';
          return axios
            .get(api)
          .then((response) => {
            if (response.data) {
             
            }
            return response.data;
          });
      }
    
      static expiredSubscription() {
        let api = ''; 
        let expertId = JSON.parse(localStorage.getItem("user")).data.id;
        api=API_PATHS.expiredSubscription+ '?expertId=' + expertId;
        return axios
        .get(api)
        .then((response) => {
            if (response.data) {
                // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
        }

        static addComment() {
            let api = ''; 
            let expertId = JSON.parse(localStorage.getItem("user")).data.id;
            api=API_PATHS.expiredSubscription+ '?expertId=' + expertId;
            return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
            }
      
    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default ClientService;