import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import exercseService from "../../services/exercise.service";
import AuthService from "../../services/auth.service";




export const getexercises = createAsyncThunk(
    "exercise/store/exercise",
    async( thunkAPI) => {
        try {
            const data = await exercseService.getexercise();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) || 
                error.message ||  
                error.toString(); 
            return thunkAPI.rejectWithValue({ message });  
        }
    }
);


export const getdetialsexercise = createAsyncThunk(
    "exercise/store/exercise",
    async( thunkAPI) => {
        try {
            const data = await exercseService.getexercisedetials();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) || 
                error.message ||  
                error.toString(); 
            return thunkAPI.rejectWithValue({ message });  
        }
    }
);

export const postexercise = createAsyncThunk(
    "exercise/post/exercise",
    async(item, thunkAPI) => {
        try {
            const data = await exercseService.postexercises(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) || 
                error.message ||  
                error.toString(); 
            return thunkAPI.rejectWithValue({ message });  
        }
    }
);

export const putexercise = createAsyncThunk(
    "exercise/post/exercise",
    async(item, thunkAPI) => {
        try {
            const data = await exercseService.editexercise(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) || 
                error.message ||  
                error.toString(); 
            return thunkAPI.rejectWithValue({ message });  
        }
    }
);


// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
    loading: false,
    error: "",
    user: AuthService.getUserDetails() || null,
    isLoggedIn: false,
};

const TravelSlice = createSlice({
    name: "addtravels",
    initialState,
    extraReducers: {
    
        [getexercises.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getexercises.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getexercises.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },

    
        [getdetialsexercise.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getdetialsexercise.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getdetialsexercise.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },

        [postexercise.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [postexercise.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [postexercise.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },

        [putexercise.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [putexercise.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [putexercise.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },

        // [logout.fulfilled]: (state, action) => {
        //   state.isLoggedIn = false;
        //   state.user = null;
        // },
    },
});

const { reducer } = TravelSlice;
export default reducer;