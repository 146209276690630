import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class specialistService {
  
    static getspecialistdetails() {
      let api = '';
     
      api = API_PATHS.getspecialist;
      
      localStorage.removeItem("id");
      return axios
          .get(api)
          .then((response) => {
              if (response.data) {
                  // localStorage.setItem("user", JSON.stringify(response.data));
              }
              return response.data;
          });
  }
  static getspecialist() {
    let api = '';
    let id = localStorage.getItem("expertise");
    api = API_PATHS.getspecialist + '?expertise=' + id;
    
    localStorage.removeItem("id");
    return axios
        .get(api)
        .then((response) => {
            if (response.data) {
                // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
}


    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default specialistService