import React from "react";
import Navbarside from "./Navbarside";
import { useDispatch } from "react-redux";
import { getdishfood } from "../store/slices/food";
import { postdishfood } from "../store/slices/food";
import { useState } from "react";
import Footer from "./footer";
import { useNavigate, useParams, Link } from "react-router-dom";

import { ExportToExcel } from "./ExportToExcel";
import { API_PATHS } from "../utils/constants/api.constants";
import axios from "axios";
import Table from "./common/Table";
import Loader from "./Loader";
import Pagination from "./common/Pagination";
const Dish = () => {
  const [dishfood, setDishfood] = useState([]);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [column, setColumn] = useState([
  
    {"isHtml":false, "name":'#'},
    {"isHtml":false, "name":'Dish Name'},
    {"isAction":true, "name":'Image'},
    {"isAction":false, "name":'Nutrition'},
    {"isAction":false, 'name': 'Per Unit'},

  ]);
  const [page, setpage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [row, setRow] = useState([]);
  const fileName = "WMDish";

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setIsLoading(true)
    axios.get(API_PATHS.getdish).then((postData) => {
    setIsLoading(false)
      const customHeadings = postData.data.data.map((item) => ({
        Name: item.name,
        Unit: item.Unit,
        Calories: item.perUnit.calories,
        Quantity: item.perUnit.quantity,
        Weight: item.perUnit.weight,
      }));

      setData(customHeadings);
    });
  };

  React.useEffect(() => {
 getDishHandler()
  }, [dispatch]);

  const getDishHandler = (page = 0)=>{
    setIsLoading(true)
    dispatch(getdishfood({ limit: 10, page: page }))
    .unwrap()
    .then((data) => {
        setIsLoading(false)
        setpage(page);
        setTotalCount(data?.user?.totalCount);
        const modifiedArray = data?.user?.data?.map((obj, key) => {
          let newObj = {
            // Modify key names
            "#": parseInt(page)*10 +1+key,
            "Dish Name":  obj?.name,
            "Image": [<img
              src={obj?.mediaLink}
              alt=""
              style={{
                borderRadius: "5px",
                height: "60px",
                width: "60px",
              }}
            />],
            'Nutrition':[    
              <>
              <div className="d-flex justify-content-between">
            <p> Carbs Quantity :</p>
            <span>
              ${obj?.nutrition[0]?.carbs?.quantity}
            </span>
          </div>

          <div className="d-flex justify-content-between">
            <p> Fats Quantity :</p>
            <span>
              ${obj?.nutrition[0]?.fats?.quantity}
            </span>
          </div>

          <div className="d-flex justify-content-between">
            <p>Fiber Quantity :</p>
            <span>
              ${obj?.nutrition[0]?.fiber?.quantity}
            </span>
          </div>

          <div className="d-flex justify-content-between">
            <p>Proteins Quantity :</p>
            <span>
              ${obj?.nutrition[0]?.proteins?.quantity}
            </span>
          </div>  </> ],


          'Per Unit':  [
            <>
          <div className="d-flex justify-content-between">
          <p>Calories :</p>
          <span>${obj?.perUnit?.calories}</span>
        </div>

        <div className="d-flex justify-content-between">
          <p>Quantity :</p>
          <span>${obj?.perUnit?.quantity}</span>
        </div>

        <div className="d-flex justify-content-between">
          <p>Weight :</p>
          <span>${obj?.perUnit?.weight}</span>
        </div>
        </>
        ],


          };
  
          return newObj;
        });
        setRow(modifiedArray)
        setDishfood(modifiedArray);
      })
      .catch(({ message }) => {
        setIsLoading(false)
        alert(message);
      });
  }

  return (
    <div>
      <Navbarside />
      {isLoading && <Loader/>}

      <div className="container-fluid response-cover pb-5">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Food Library</h4>
                        &nbsp; &nbsp; &nbsp;
                        <Link
                          to="AddDish"
                          className="btn btn-success col-white"
                        >
                          Add
                        </Link>
                        &nbsp; &nbsp; &nbsp;
                        {/* <ExportToExcel apiData={data} fileName={fileName} /> */}
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        {row.length>0 &&
                        <Table columns={column} data={row} isFooter={true} />
                        }
                          <div className="PaginationContainer">
                            <span className="total-elements">
                              Showing {parseInt(page)*10 +1 } to {parseInt(page)*10 +10 } of {totalCount} entries
                            </span>
                            <Pagination
                              currentPage={(parseInt(page)+1)}
                              totalCount={totalCount}
                              itemsPerPage={10}
                              onPageChange={(page)=>{getDishHandler(parseInt(page)-1)}}
                            />
                          </div>
                        {/* <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Dish Name</th>
                              <th>Image</th>
                              <th>Nutrition</th>
                              <th>Per Unit</th>

                            </tr>
                          </thead>

                          <tbody>
                            {dishfood.map((dishVal, index) => (
                              <tr key={index}>
                                <td>{dishVal.name}</td>
                                <td>
                                  <img
                                    src={dishVal.mediaLink}
                                    alt=""
                                    style={{
                                      borderRadius: "5px",
                                      height: "60px",
                                      width: "60px",
                                    }}
                                  />
                                </td>

                                <td>
                                  <div className="d-flex justify-content-between">
                                    <p> Carbs Quantity :</p>
                                    <span>
                                      {dishVal.nutrition[0].carbs.quantity}
                                    </span>
                                  </div>

                                  <div className="d-flex justify-content-between">
                                    <p> Fats Quantity :</p>
                                    <span>
                                      {dishVal.nutrition[0].fats.quantity}
                                    </span>
                                  </div>

                                  <div className="d-flex justify-content-between">
                                    <p>Fiber Quantity :</p>
                                    <span>
                                      {dishVal.nutrition[0].fiber.quantity}
                                    </span>
                                  </div>

                                  <div className="d-flex justify-content-between">
                                    <p>Proteins Quantity :</p>
                                    <span>
                                      {dishVal.nutrition[0].proteins.quantity}
                                    </span>
                                  </div>
                                </td>

                                <td>
                                  <div className="d-flex justify-content-between">
                                    <p>Calories :</p>
                                    <span>{dishVal.perUnit.calories}</span>
                                  </div>

                                  <div className="d-flex justify-content-between">
                                    <p>Quantity :</p>
                                    <span>{dishVal.perUnit.quantity}</span>
                                  </div>

                                  <div className="d-flex justify-content-between">
                                    <p>Weight :</p>
                                    <span>{dishVal.perUnit.weight}</span>
                                  </div>
                                </td>

                                <td>
                                  <Link
                                    to=""
                                    className="btn btn-primary col-white"
                                  >
                                    Edit
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Dish;
