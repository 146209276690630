import React from "react";
import Navbarside from "./Navbarside";
import { useDispatch } from "react-redux";
import { getcategoryfoods } from "../store/slices/food";
import { useState } from "react";
import Footer from "./footer";
import { useNavigate, useParams, Link } from "react-router-dom";

import { ExportToExcel } from "./ExportToExcel";
import { API_PATHS } from "../utils/constants/api.constants";
import axios from "axios";
import Table from "./common/Table";
import { param } from "jquery";
import Pagination from "./common/Pagination";
import Loader from "./Loader";

const Foodcategory = () => {
  const [foodrecipescategory, setFoodRecipescategory] = useState([]);

  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setpage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [column, setColumn] = useState([
    { isHtml: false, name: "#" },
    { isHtml: false, name: "Food Name" },
    { isAction: true, name: "Action" },
  ]);
  const [row, setRow] = useState([]);

  const fileName = "Food Category";

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = (page = 0) => {
    const api = `${API_PATHS.getfoodcategory}?page=${page}&limit=10`;
    setIsLoading(true);
    axios.get(api, { limit: 10, page: page }).then((postData) => {
      setIsLoading(false);
      setpage(page);
      setTotalCount(postData.data?.totalCount);
      const customHeadings = postData?.data?.data?.map((obj, key) => {
        console.log("newImp", obj);
        // "Name": item.name,
        let newObj = {
          // Modify key names
          "#": parseInt(page) * 10 + 1 + key,
          "Food Name": obj?.name,
          Action: [
            <button
              key="edit"
              className="btn btn-warning"
              style={{ height: "25px", padding: "0 10px" }}
            >
              Edit
            </button>,
          ],
        };

        return newObj;
      });
      setRow(customHeadings);
      // setData(customHeadings);
    });
  };

  // React.useEffect(() => {
  //   dispatch(getcategoryfoods())
  //     .unwrap()
  //     .then((data) => {
  //       setFoodRecipescategory(data.user.data);
  //     })
  //     .catch(({ message }) => {
  //       alert(message);
  //     });
  // }, [dispatch]);

  return (
    <div>
      <Navbarside />
      {isLoading && <Loader />}
      <div className="container-fluid response-cover pb-5">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Food Category </h4>
                        &nbsp; &nbsp; &nbsp;
                        <Link
                          to="/Weightmanagement/Foodcategory/AddFoodcategory"
                          className="btn btn-success col-white"
                        >
                          Add
                        </Link>
                        &nbsp; &nbsp; &nbsp;
                        <ExportToExcel apiData={data} fileName={fileName} />
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <Table data={row} columns={column} isFooter={true} />
                        <div className="PaginationContainer">
                          <span className="total-elements">
                            Showing {parseInt(page) * 10 + 1} to{" "}
                            {parseInt(page) * 10 + 10} of {totalCount} entries
                          </span>
                          <Pagination
                            currentPage={parseInt(page) + 1}
                            totalCount={totalCount}
                            itemsPerPage={10}
                            onPageChange={(page) => {
                              fetchData(parseInt(page) - 1);
                            }}
                          />
                        </div>
                        {/* <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Food Name</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {foodrecipescategory.map(
                              (foodcategoryVal, index) => (
                                <tr key={index}>
                                  <td>{foodcategoryVal.name}</td>

                                  <td>
                                    <Link
                                      to=""
                                      className="btn btn-primary col-white"
                                    >
                                      Edit
                                    </Link>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Foodcategory;
