import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Navbarside from "./Navbarside";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "./footer";
import { editlab, getlabtestcategory } from "../store/slices/Labcategory";

const EditLabTestCategory = () => {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [alertname, setAlertName] = useState("");
  const [alertprice, setAlertPrice] = useState("");

  const navigate = useNavigate("");
  const dispatch = useDispatch();

  const save = (e) => {
    const regname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (regname.test(name)) {
      setAlertName("");
    } else if (!regname.test(name) && name === "") {
      setAlertName("Please enter the category name");
      e.preventDefault();
    } else {
      setAlertName("Single and double quotes are not-valid");
      e.preventDefault();
    }

    const regnumber = /^[0-9][0-9]?$|^100$/;
    if (regnumber.test(price)) {
      setAlertPrice("");
    } else if (!regnumber.test(price) && price === "") {
      setAlertPrice("Please enter the lab price");
      e.preventDefault();
    } else {
      setAlertPrice("");
    }
  };

  const cancel = () => {
    setName("");
    setPrice("");
    setAlertName("");
    setAlertPrice("");
  };

  const { id } = useParams();
  localStorage.setItem("id", id);
  React.useEffect(() => {
    dispatch(getlabtestcategory())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id == id) {
          setName(data.user.data[0].name);
          setPrice(data.user.data[0].price);
        }
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  async function upload(e) {
    e.preventDefault();

    let item = { set: { name, price } };

    dispatch(editlab(item))
      .unwrap()
      .then(() => {
        alert("Updated succesfully");
        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">Edit Lab Test Category</h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <form onSubmit={upload}>
                        <div className="row">
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Name
                            </p>
                            <input
                              type="text"
                              placeholder="Enter your name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertname}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Price
                            </p>
                            <input
                              type="text"
                              placeholder="Enter the price"
                              value={price}
                              onChange={(e) => setPrice(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertprice}</p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center pt-3">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={save}
                          >
                            Submit
                          </button>
                          &nbsp; &nbsp; &nbsp;
                          <button
                            type="reset"
                            className="btn btn-warning"
                            onClick={cancel}
                          >
                            Reset
                          </button>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default EditLabTestCategory;
