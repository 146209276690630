import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader, MessageHeader } from "semantic-ui-react";
import Navbarside from "./Navbarside";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "./footer";
// import { postlabrequesttest } from "../store/slices/labtestrequest";

const UpdateLabtestrequest = () => {
  const navigate = useNavigate("");
  const dispatch = useDispatch();

  // async function upload(e) {
  //   e.preventDefault();
  //   let id = "635ba44ed85614d2ca2755c8";
  //   let status = "rejected";

  //   let item = {
  //     id,
  //     status,
  //   };

  //   dispatch(postlabrequesttest(item))
  //     .unwrap()
  //     .then(() => {
  //       alert("Uploaded succesfully");
  //       // navigate(-1);
  //     })
  //     .catch(({ message }) => {
  //       alert(message);
  //     });
  // }

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">Add Lab Test Request</h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <form>
                        <div className="d-flex justify-content-center pt-3">
                          <button type="submit" className="btn btn-success">
                            Submit
                          </button>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default UpdateLabtestrequest;
