import React from "react";
import Navbarside from "../Navbarside";
import { useDispatch } from "react-redux";
import { Weightmanagementsession } from "../../store/slices/Wmconsultation";
import { useState } from "react";
import Footer from "../footer";
import Moment from "moment";

import { ExportToExcel } from "../ExportToExcel";
import { API_PATHS } from "../../utils/constants/api.constants";
import axios from "axios";
const LiveWellSession = () => {
  const [consultation, setConsultation] = useState([]);

  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const fileName = "LiveWellSession";

  React.useEffect(() => {
    const fetchData = () => {
      axios.get(API_PATHS.WMSessions).then((postData) => {
        const customHeadings = postData.data.data.map((item) => ({
          Name: item.name,
          "Start Date": item.startDate,
          "Start Time": item.startTime,
          Status: item.status,
        }));

        setData(customHeadings);
      });
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    dispatch(Weightmanagementsession())
      .unwrap()
      .then((data) => {
        setConsultation(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid response-cover pb-5">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">
                          LiveWell Session                        </h4>{" "}
                        &nbsp; &nbsp; &nbsp;
                        <ExportToExcel apiData={data} fileName={fileName} />
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Client Name</th>
                              <th>Expert Name</th>
                              <th>Session Details</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {consultation.map((wmSessionVal, index) => (
                              <tr key={index}>
                                <td>
                                  {wmSessionVal.subscriptionId.userId.firstName}
                                  &nbsp;
                                  {wmSessionVal.subscriptionId.userId.lastName}
                                </td>
                                <td>
                                  {
                                    wmSessionVal.subscriptionId.expertId
                                      .firstName
                                  }
                                  {
                                    wmSessionVal.subscriptionId.expertId
                                      .lastName
                                  }
                                </td>
                                <td>
                                  Session Name:
                                  {wmSessionVal.name}
                                  <br />
                                  Session Date:
                                  {Moment(wmSessionVal.startDate).format(
                                    "DD-MM-YYYY"
                                  )}
                                  <br />
                                  Session Time:
                                  {wmSessionVal.startTime}
                                </td>
                                <td>
                                  {wmSessionVal.status === "stored" && (
                                    <span
                                      className="btn btn-primary"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Scheduled
                                    </span>
                                  )}

                                  {wmSessionVal.status === "sessionEnded" && (
                                    <span
                                      className="btn btn-success"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Completed
                                    </span>
                                  )}
                                </td>{" "}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default LiveWellSession;
