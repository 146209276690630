import React, { useState } from "react";
import Footer from "./footer";
import { fitnessFormData } from "../store/slices/forms";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "../component/Navbarside";
import { useParams } from "react-router-dom";

const FitnessForm = () => {
  const { id } = useParams();
  localStorage.setItem("userId", id);
  const [fitform, setFirform] = useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fitnessFormData())
      .unwrap()
      .then((data) => {
        setFirform(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid response-cover pb-5">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Fitness Form</h4>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th className="wd-15p">Question</th>
                              <th>Answer</th>
                            </tr>
                          </thead>
                          {fitform.map((formfitVal, index) => (
                            <tbody>
                              {fitform.map((formfitVal, index) => (
                                <tr key={index}>
                                  <td>{formfitVal.questionId.question}</td>
                                  <td>{formfitVal.answer}</td>
                                </tr>
                              ))}
                            </tbody>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default FitnessForm;
