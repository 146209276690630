import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class wmPackage {
  static getWmPackage() {
    return axios.get(API_PATHS.getWmPackage).then((response) => {
      if (response.data) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
  }

  static postWmPackage(item) {
    return axios.post(API_PATHS.postWmPackage, item).then((response) => {
      if (response.data) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
  }

  static editWmPackage(item) {
    let api = "";
    let id = localStorage.getItem("id");
    api = API_PATHS.editWmPackage + "?id=" + id;
    localStorage.removeItem("id");
    return axios.put(api, item).then((response) => {
      if (response.data) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
  }

  static deleteWmPackage() {
    let api = "";
    let id = localStorage.getItem("id");
    api = API_PATHS.deleteWmPackage + "?id=" + id;
    localStorage.removeItem("id");
    return axios.delete(api).then((response) => {
      if (response.data) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
  }

  static removeUserDetails() {
    localStorage.removeItem("user");
  }

  static getUserDetails() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default wmPackage;
