import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Navbarside from "../Navbarside";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../footer";
import { editEnquiry, fetchConsultation } from "../../store/slices/enquiry";
import { Card } from '@mui/material';
import moment from 'moment';
import './style.css';

const ViewDetails = () => {
  const [consultations, setConsultations] = useState("");
  const navigate = useNavigate("");
  const dispatch = useDispatch();

  const { ticketNumber, flow, } = useParams();

  localStorage.setItem("ticketNumber", ticketNumber);
  localStorage.setItem("flow", flow);

  React.useEffect(() => {
    dispatch(fetchConsultation()).unwrap().then((data) => {
      setConsultations(data.data.consultationData[0]);
    }).catch(({ message }) => {
      // alert(message);
    });
  }, [dispatch]);


  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">Consultation details</h4>
                      </div>
                    </div>

                    <section className="form-section">
                      {consultations
                        ? Object.keys(consultations).map((x, i) => {
                          if (
                            x != "_id"
                            && x != "__v"
                            && x != "source"
                            && x != "flow"
                            && x != "updated_at"
                            && x != "expertId"
                            && x != "expertiseId"
                            && x != "subExpertiseId"
                            && x != "userId"
                            && x != "comments"
                            && x != "paymentLink"
                          ) {
                            return <p> {x} : {consultations[x]}</p>
                          }
                        })
                        : ''
                      }
                    </section>

                  </div>

                  <h3>Comments</h3>
                  {consultations
                    ? Object.keys(consultations).map((x, i) => {
                      if (x === "comments" && Array.isArray(consultations[x])) {
                        let comments = consultations[x]
                        return comments.map(comment => {
                          return <Card style={{ "padding": "10px" }}>
                            <div className="row">

                              <div className="col-md-10">{comment.message}</div>
                              <div className="col-md-2">{moment(comment.time).format('MM/DD/YYYY hh:mm')}</div>
                            </div>
                          </Card>
                        })

                      }
                    })
                    : ''
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default ViewDetails;
