import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbarside from "./Navbarside";
import { useDispatch } from "react-redux";
import { getVendorLabs } from "../store/slices/lab";
import Footer from "./footer";

const MyLabs = () => {
  const [labDetail, setlabDetail] = useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getVendorLabs())
      .unwrap()
      .then((data) => {
        setlabDetail(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Lab List</h4>
                        &nbsp; &nbsp; &nbsp;
                        <Link
                          to="/MyLabs/AddLabdetails"
                          className="btn btn-primary col-white mt-2"
                          style={{
                            height: "25px",
                            padding: "0 10px",
                          }}
                        >
                          Add New Lab
                        </Link>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th style={{ width: "150px" }}>Lab Name</th>
                              <th>Address</th>
                              <th>Email</th>
                              <th>Mobile Number</th>
                              <th>Status</th>
                              <th>Revenue</th>
                            </tr>
                          </thead>
                          <tbody>
                            {labDetail.map((LabVal, index) => (
                              <tr>
                                <td>
                                  <Link
                                    to={`/MyLabs/LabDetails/${
                                      LabVal._id ? LabVal._id : null
                                    }`}
                                  >
                                    {LabVal.name}
                                  </Link>
                                </td>
                                <td>{LabVal.address}</td>
                                <td>{LabVal.labEmail}</td>
                                <td>{LabVal.labMobileNo}</td>

                                <td>
                                  {LabVal.status === "approvalPending" && (
                                    <button
                                      className="btn btn-warning"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Approval Pending
                                    </button>
                                  )}
                                  {LabVal.status === "rejected" && (
                                    <button
                                      className="btn btn-danger"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Rejected
                                    </button>
                                  )}
                                  {LabVal.status === "approved" && (
                                    <button
                                      className="btn btn-success"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Approved
                                    </button>
                                  )}
                                </td>
                                <td>
                                  {LabVal.status === "approved" && (
                                    <Link
                                      className="btn btn-primary"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                      to={`/LabRevenue/${
                                        LabVal._id ? LabVal._id : null
                                      }`}
                                    >
                                      View
                                    </Link>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default MyLabs;
