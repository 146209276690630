import React from "react";
import Navbarside from "./Navbarside";
import { useDispatch } from "react-redux";
import { getFitnessPlans } from "../store/slices/fitnessplans";
import { editfitnesscenterPlan } from "../store/slices/fitness";
import { useState } from "react";
import Footer from "./footer";
import { useNavigate, useParams, Link } from "react-router-dom";
import { API_PATHS } from "../utils/constants/api.constants";
import axios from "axios";

const FitnessPlans = () => {
  const [fitnessenquire, setFitnessenquire] = useState([]);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  React.useEffect(() => {
    const fetchData = () => {};
    fetchData();
  }, []);

  React.useEffect(() => {
    dispatch(getFitnessPlans())
      .unwrap()
      .then((data) => {
        setFitnessenquire(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  async function enableApprove(ids) {
    let isActive = true;
    let id = ids;
    let item = { set: { isActive } };
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("id", id);
    dispatch(editfitnesscenterPlan())
      .unwrap()
      .then(() => {
        alert("Plan enabled succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  async function disableApprove(ids) {
    let isActive = false;
    let id = ids;
    let item = { set: { isActive } };
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("id", id);
    dispatch(editfitnesscenterPlan())
      .unwrap()
      .then(() => {
        alert("Request disabled succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  return (
    <div>
      <Navbarside />

      <div className="container-fluid response-cover pb-5">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Fitness Center Plans</h4>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Plan Name</th>
                              <th>Plan Validity</th>
                              <th>Price</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {fitnessenquire.map((fitnessVal, index) => (
                              <tr key={index}>
                                <td>{fitnessVal.name}</td>
                                <td>{fitnessVal.memberShipDurationInDays}</td>
                                <td>{fitnessVal.price}</td>
                                <td>
                                  {" "}
                                  {fitnessVal.isActive === true && (
                                    <button
                                      className="btn btn-warning"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                      onClick={() =>
                                        disableApprove(fitnessVal._id)
                                      }
                                    >
                                      Disable
                                    </button>
                                  )}
                                  &nbsp;
                                  {fitnessVal.isActive === false && (
                                    <button
                                      className="btn btn-success"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                      onClick={() =>
                                        enableApprove(fitnessVal._id)
                                      }
                                    >
                                      Enable
                                    </button>
                                  )}{" "}
                                  &nbsp;
                                  <Link
                                    to={`/EditFitnessPlan/${
                                      fitnessVal._id ? fitnessVal._id : null
                                    }`}
                                    className="btn btn-primary"
                                    style={{
                                      height: "25px",
                                      padding: "0 10px",
                                    }}
                                  >
                                    Edit
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default FitnessPlans;
