import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Navbarside from "./Navbarside";
import Footer from "./footer";
import { enableExpert } from "../store/slices/auth";
import { Link } from "react-router-dom";
import FilterComponent from "../component/FilterComponent";
import { ExportToExcel } from "./ExportToExcel";
import { API_BASE_URL } from "../utils/constants/api.constants";
import axios from "axios";
import { get } from "jquery";
import Loader from "./Loader";
const Experts = () => {
  const [Expertsdetils, setExpertsdetils] = useState([]);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [expertise, setExpertise] = useState("");
  const [subExpertise, setSubExpertise] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const fileName = "Experts";

  useEffect(() => {
    getExperts({});
  }, []);

  const handleExpertiseChange = (expertise, subExpertise) => {
    setExpertise(expertise);
    setSubExpertise(subExpertise);
  };

  const applyFilter = (param) => {
    console.log(param, "param");
    let queryParam = {};
    if (param.selectedExpertise)
      queryParam.topExpertise = param.selectedExpertise;
    if (param.selectedSubExpertise)
      queryParam.expertise = param.selectedSubExpertise;
    getExperts(queryParam);
  };

  function getExperts(params) {
    setIsLoading(true);
    axios.get(API_BASE_URL + "fetch/experts", { params }).then((res) => {
      setIsLoading(false);
      const customHeadings = res.data.data[0].map((item) => ({
        "First Name": item.firstName,
        "Last Name": item.lastName,
        "Mobile No.": item.mobileNo,
        Email: item.email,
        Expertise: item.expertise,
      }));
      let experts = res.data.data[0].filter((x) => x.disabled != true);
      setExpertsdetils(experts);
      setData(customHeadings);
    });
  }

  async function disableApprove(ids) {
    let isAdminApproved = false;
    let id = ids;
    let item = { set: { isAdminApproved } };
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("expertId", id);
    setIsLoading(true);
    dispatch(enableExpert(item))
      .unwrap()
      .then(() => {
        setIsLoading(false);
        alert("Expert disabled succesfully");
        getExperts();
        // window.location.reload(false);
      })
      .catch(({ message }) => {
        setIsLoading(false);
        alert(message);
      });
    // console.log(item);
    // console.log(id);
  }

  const deleteData = (id) => {
    let text = "Are you sure you want to delete?";
    if (window.confirm(text) === true) {
      setIsLoading(true);
      axios
        .delete(`${API_BASE_URL}/delete/user?id=${id}`)
        .then((res) => {
          setIsLoading(false);
          if (res.data?.status === 1) alert("deleted successfully");
          getExperts();
        })
        .catch((err) => {
          setIsLoading(false);
          alert(err.message);
        });
    }
  };

  async function enableApprove(ids) {
    let isAdminApproved = true;
    let id = ids;
    let item = { set: { isAdminApproved } };
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("expertId", id);
    setIsLoading(true);
    dispatch(enableExpert(item))
      .unwrap()
      .then(() => {
        setIsLoading(false);
        alert("Expert approved succesfully");
        getExperts();
        // window.location.reload(false);
      })
      .catch(({ message }) => {
        setIsLoading(false);
        alert(message);
      });
    // console.log(item);
    // console.log(id);
  }
  const styles = (expertise) => {
    let styles = {};
    if (expertise) {
      styles = {
        height: "25px",
        padding: "0 10px",
      };
    } else {
      styles = {
        height: "25px",
        padding: "0 10px",
      };
    }
    return styles;
  };

  return (
    <div>
      <Navbarside />
      {isLoading && <Loader />}

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Experts</h4> &nbsp; &nbsp;
                        &nbsp;
                        <ExportToExcel
                          apiData={data}
                          fileName={fileName}
                          style={{
                            height: "25px",
                            padding: "0 10px",
                            marginBottom: "0%",
                          }}
                        />
                        &nbsp; &nbsp; &nbsp;
                        <Link to="/AddExpert" className="btn btn-success">
                          Add Expert
                        </Link>
                      </div>
                    </div>
                    <FilterComponent
                      onCategoryChange={handleExpertiseChange}
                      onApply={applyFilter}
                    />
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Mobile Number</th>
                              <th>Is Verified</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {Expertsdetils.map((ExpertsVal, index) => (
                              <tr key={index}>
                                <td>
                                  <Link
                                    to={`/Expertdetails/${
                                      ExpertsVal._id ? ExpertsVal._id : null
                                    }`}
                                  >
                                    {ExpertsVal.firstName} {ExpertsVal.lastName}
                                  </Link>
                                </td>
                                <td>{ExpertsVal.email}</td>
                                <td>{ExpertsVal.mobileNo}</td>
                                <td>
                                  {/* {ExpertsVal.isAdminApproved === false && ( */}
                                  <button
                                    // onClick={() =>
                                    //   enableApprove(ExpertsVal._id)
                                    // }
                                    className={
                                      ExpertsVal.isAdminApproved === false
                                        ? "btn btn-warning"
                                        : "btn btn-success"
                                    }
                                    style={{
                                      height: "25px",
                                      padding: "0 10px",
                                      cursor:"default"
                                    }}
                                  >
                                    {ExpertsVal.isAdminApproved === false
                                      ? "Disable"
                                      : "Verified"}
                                  </button>
                                </td>
                                <td>
                                  <Link
                                    to={`/EditExpert/${
                                      ExpertsVal._id ? ExpertsVal._id : null
                                    }`}
                                    className="btn btn-primary"
                                    style={{
                                      height: "25px",
                                      padding: "0 10px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    Edit Profile
                                  </Link>
                                  <button
                                    className="btn btn-danger"
                                    onClick={() => deleteData(ExpertsVal._id)}
                                    style={{
                                      height: "25px",
                                      padding: "0 10px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    Delete
                                  </button>
                                  &nbsp;
                                  {/* {ExpertsVal.isAdminApproved === true && ( */}
                                  <button
                                    onClick={() =>
                                      ExpertsVal.isAdminApproved === true
                                        ? disableApprove(ExpertsVal._id)
                                        : enableApprove(ExpertsVal._id)
                                    }
                                    className={ExpertsVal.isAdminApproved === true ?"btn btn-warning" :"btn btn-success"}
                                    style={{
                                      height: "25px",
                                      padding: "0 10px",
                                      marginRight: "10px",
                                      marginTop:'-15px'
                                    }}
                                  >
                                    {ExpertsVal.isAdminApproved === true
                                      ? "Disable"
                                      : "Approve"}
                                  </button>
                                  {/* )} */}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Experts;
