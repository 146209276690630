import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "../../Navbarside";
import { Link, useLocation, useParams } from "react-router-dom";
import Footer from "../../footer";
import { API_BASE_URL_HC } from "../../../utils/constants/api.constants";
import axios, { get } from 'axios'
import VideoPlayer from '../../videoPlayer/VideoPlayer';


function PlaylistContent() {
  const params = useParams();
  const { id } = params;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mastCatName = searchParams.get('mastCatName');

  const [playlistDetails, setPlaylistDetails] = useState({});
  const dispatch = useDispatch();
  console.log(playlistDetails, 'playlistDetails')
  React.useEffect(() => {
    get(API_BASE_URL_HC + '/fetch/playlist?id=' + id).then(res => {
      if (res.data?.data) {
        setPlaylistDetails(res.data.data)
      }
    }).catch(err => alert(err.message))
  }, [dispatch]);

  const deleteData = (id) => {
    axios.delete(`${API_BASE_URL_HC}/delete/content?id=${id}`).then(res => {
      if (res.data?.status === 1) alert('deleted successfully')
    }).catch(err => alert(err.message))
  }

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">
                        Playlist Details
                        <Link
                          to={`/LiveWell/Contents/AddContent?playlistId=${id}&playlistName=${playlistDetails?.playlistData?.name}`}
                          className="btn btn-primary"
                        >
                          <i className="fa fa-plus"></i> Add new video
                        </Link>
                      </h4>
                      <p>Name : {playlistDetails?.playlistData?.name}</p>
                      <p>Thumbnail : {playlistDetails?.playlistData?.mediaLink}</p>
                      <p>Benefits : {playlistDetails?.playlistData?.benefits}</p>
                      <p>Playlist Description : {playlistDetails?.playlistData?.description}</p>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Video Name</th>
                              <th>Video Description</th>
                              <th>video</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {playlistDetails?.contents?.map((content, index) => (
                              <tr key={index}>
                                <td>{content.title}</td>
                                <td>{content.description}</td>
                                <td>
                                  {/* <img
                                    src={content.mediaLink}
                                    alt="content"
                                    style={{
                                      height: "60px",
                                      width: "60px",
                                      borderRadius: "10px",
                                    }}
                                  /> */}
                                  {content?.mediaLink?.includes('s3')
                                    ? `${content.mediaLink}`
                                    : <VideoPlayer url={content.mediaLink} />
                                  }
                                </td>
                                <td>
                                  <button
                                    className="btn btn-danger"
                                    onClick={() => deleteData(content._id)}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body >
  );
}

export default PlaylistContent;
