import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "../../component/Navbarside";
import { getblog, DeleteBlog } from "../../store/slices/blog";
import Footer from "../footer";
import { confirmAlert } from "react-confirm-alert";

import { Link, useNavigate } from "react-router-dom";
import VideoFilePlayer from "../videoPlayer/videoFilePlayer";

const Blog = () => {
  const dispatch = useDispatch();
  const [blogs, setBlogs] = useState([]);

  React.useEffect(() => {
    dispatch(getblog())
      .unwrap()
      .then((data) => {
        setBlogs(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  const Delete = async function deletePlaylist(id) {
    localStorage.setItem("id", id);
    dispatch(DeleteBlog())
      .unwrap()
      .then(() => {
        // alert("Uploaded succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });

    console.log(id);
  };
  const deleteData = (id) => {
    confirmAlert({
      title: "Want to delete?",
      message: "Are you sure you want to delete this post?",
      buttons: [
        {
          label: "Yes",
          onClick: () => Delete(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  function isVideoLink(url) {
    var videoExtensions = ['mp4', 'avi', 'wmv', 'mov', 'flv'];
    var fileExtension = url.split('.').pop();

    return {
      status: videoExtensions.includes(fileExtension),
      format: fileExtension
    }
  }
  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-wrap bar-success">
                          <h4 className="card-title">
                            Blog &nbsp; &nbsp;
                            <Link to="/Addblog" className="btn btn-primary">
                              <i className="fa fa-plus"></i> Add
                            </Link>
                          </h4>
                        </div>
                      </div>
                      <div className="card-body collapse show">
                        <div className="card-block card-dashboard table-responsive">
                          <table className="table table-striped table-bordered zero-configuration">
                            <thead>
                              <tr>
                                <th style={{ width: "30%" }}>Blog Title</th>
                                {/* <th>Description</th> */}
                                <th>Image</th>
                                <th>Action</th>
                              </tr>
                            </thead>

                            <tbody>
                              {blogs.map((BlogVal, index) => (
                                <tr>
                                  <td>
                                    {" "}
                                    <Link
                                      to={`/BlogDetails/${BlogVal._id ? BlogVal._id : null
                                        }`}
                                    >
                                      {BlogVal.blogTitle}
                                    </Link>
                                  </td>
                                  {/* <td>
                                    {BlogVal.description.substring(0, 150)}...
                                  </td> */}

                                  <td>
                                    {BlogVal?.mediaLink?.includes('s3')
                                      ? isVideoLink(BlogVal.mediaLink).status ? <VideoFilePlayer s3Link={BlogVal.mediaLink} format={isVideoLink(BlogVal.mediaLink).format} /> : <img
                                        src={BlogVal.mediaLink}
                                        alt=""
                                        style={{
                                          width: "150px",
                                          height: "100px",
                                        }}
                                      />
                                      : ''
                                    }

                                  </td>
                                  <td>
                                    <Link
                                      to={`/EditBlog/${BlogVal._id ? BlogVal._id : null
                                        }`}
                                      className="btn btn-primary"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Edit
                                    </Link>{" "}
                                    &nbsp;
                                    <button
                                      onClick={() => deleteData(BlogVal._id)}
                                      className="btn btn-warning"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
};

export default Blog;
