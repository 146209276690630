import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postPackage } from "../store/slices/package";
import Footer from "./footer";
import Navbarside from "../component/Navbarside";
import ExpertiseService from "./../services/expertise.service";

function AddFitnessPackage(e) {
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const [expertiseId, setexpertiseId] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [benefits, setbenefits] = useState("");
  const [description, setDescription] = useState("");
  const [price, setprice] = useState("");
  const [name, setname] = useState("");
  const [sessionCount, setsessionCount] = useState("");
  const [durationInDays, setdurationInDays] = useState("");
  const [expertises, setExpertises] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [services, setServices] = useState([
    {
      serviceName: "",
      count: "",
      mode: "",
    },
  ]);
  //   Alert-sessionCount

  const handleAddForms1 = () => {
    let hotel = [...services];
    hotel.push({
      destination: "",
      HotelName: "",
      address: "",
      capacityPerRoom: "",
    });
    setServices(hotel);
  };

  const handleDeleteForms1 = (e) => {
    if (services.length > 1) {
      let hotel = [...services];
      hotel.pop();
      setServices(hotel);
    }
  };
  const handleRowChange1 = (e, index) => {
    const hotel = [...services];
    hotel[index][e.currentTarget.name] = e.currentTarget.value;
    setServices(hotel);
    // console.log(hotelDescription);
  };

  const [alertbenefits, setAlertbenefits] = useState("");
  const [alertdescription, setAlertdescription] = useState("");
  const [alertprice, setAlertprice] = useState("");
  const [alertname, setAlertname] = useState("");
  const [alertsession, setAlertsession] = useState("");
  const [alertvalidity, setAlertvalidity] = useState("");
  const [alertexpertise, setAlertexpertise] = useState("");
  async function upload(e) {
    e.preventDefault();
    let flow = "fitness";
    let isActive = true;
    let item = {
      name,
      benefits,
      description,
      price,
      sessionCount,
      expertiseId,
      subCategoryId,
      isActive,
      flow,
      durationInDays,
      services,
    };

    dispatch(postPackage(item))
      .unwrap()
      .then(() => {
        alert("Uploaded successfully");
        navigate("/package");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }
  const Expertises = createAsyncThunk(
    "expertise/get/expertise",
    async (thunkAPI) => {
      try {
        // console.log("consultation")
        const data = await ExpertiseService.getExpertise();
        return { user: data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );


  React.useEffect(() => {
    dispatch(Expertises())
      .unwrap()
      .then((data) => {
        setExpertises(data.user.data);
        setexpertiseId(data.user.data[0]._id);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  const handleCategoryChange = (e) => {
    var value = expertises.filter(function (Expertises) {
      return Expertises.name == e.target.value;
    });
    setexpertiseId(value[0]._id);
  };

  React.useEffect(() => {
    dispatch(Expertises())
      .unwrap()
      .then((data) => {
        let expertises = data.user.data?.filter(
          (e) => e?.parentExpertiseId == null
        );
        let subCategories = data.user.data?.filter(
          (e) => e?.parentExpertiseId != null
        );
        setExpertises(expertises);
        setexpertiseId(expertises[0]._id);
        setSubCategories(subCategories);
      })
      .catch(({ sessionCount }) => {
        alert(sessionCount);
      });
  }, [dispatch]);

  const save = (e) => {
    const regtext = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regtext.test(name)) {
      setAlertname("");
    } else if (!regtext.test(name) && name === "") {
      setAlertname("Please enter package name");
      e.preventDefault();
    } else {
      setAlertname("");
    }

    const regtext2 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regtext2.test(description)) {
      setAlertdescription("");
    } else if (!regtext2.test(description) && description === "") {
      setAlertdescription("Please enter description");
      e.preventDefault();
    } else {
      setAlertdescription("");
    }

    const regprice =
      /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[-]([0]?[1-9]|[1][0-2])[-]([0-9]{4}|[0-9]{2})$/;
    if (regprice.test(price)) {
      setAlertprice("");
    } else if (!regprice.test(price) && price === "") {
      setAlertprice("Please enter package price");
      e.preventDefault();
    } else {
      setAlertprice("");
    }

    const regsession =
      /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[-]([0]?[1-9]|[1][0-2])[-]([0-9]{4}|[0-9]{2})$/;
    if (regsession.test(sessionCount)) {
      setAlertsession("");
    } else if (!regsession.test(price) && price === "") {
      setAlertsession("Please enter no.of sessions");
      e.preventDefault();
    } else {
      setAlertsession("");
    }

    if (expertiseId === "") {
      setAlertexpertise("Please select expertise");
      e.preventDefault();
    } else {
      setAlertexpertise("");
    }

    const regvalidity =
      /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[-]([0]?[1-9]|[1][0-2])[-]([0-9]{4}|[0-9]{2})$/;
    if (regvalidity.test(durationInDays)) {
      setAlertvalidity("");
    } else if (!regsession.test(durationInDays) && durationInDays === "") {
      setAlertvalidity("Please enter validity");
      e.preventDefault();
    } else {
      setAlertvalidity("");
    }
  };

  const cancel = () => {
    setbenefits("");
    setDescription("");
    setprice("");
    setname("");
    setAlertbenefits("");
    setAlertdescription("");
    setAlertprice("");
    setAlertsession("");
    setAlertname("");
    setsessionCount("");
  };
  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Upload Package
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form className="form" onSubmit={upload}>
                          <div className="row justify-content-md-center">
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Package Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Package Name"
                                    value={name}
                                    onChange={(e) => setname(e.target.value)}
                                  />
                                  <p className="alert-message">{alertname}</p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">price</label>
                                  <input
                                    type="price"
                                    className="form-control"
                                    Placeholder=""
                                    value={price}
                                    onChange={(e) => setprice(e.target.value)}
                                  />
                                  <p className="alert-message">{alertprice}</p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    No.of Sessions
                                  </label>
                                  <input
                                    type="number"
                                    min="0"
                                    className="form-control-file"
                                    Placeholder=""
                                    name="imageUrl"
                                    value={sessionCount}
                                    onChange={(e) =>
                                      setsessionCount(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">
                                    {alertsession}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Category</label>
                                  <select
                                    className="form-control"
                                    onChange={(e) => {
                                      setexpertiseId(e.target.value);
                                    }}
                                    required
                                  >
                                    <option value="">Select</option>
                                    {expertises.map((option) => (
                                      <option value={option._id}>
                                        {option.name}
                                      </option>
                                    ))}
                                  </select>
                                  <p className="alert-message">
                                    {alertexpertise}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Sub Category
                                  </label>
                                  <select
                                    className="form-control"
                                    onChange={(e) => {
                                      setSubCategoryId(e.target.value);
                                      console.log(e.target.value);
                                    }}
                                    required
                                  >
                                    <option value="">Select</option>
                                    {subCategories
                                      ?.filter(
                                        (e) =>
                                          e.parentExpertiseId == expertiseId
                                      )
                                      .map((option) => (
                                        <option value={option._id}>
                                          {option._id}
                                          {option.name}
                                        </option>
                                      ))}
                                  </select>
                                  {/* 
                                  <select
                                    className="form-control"
                                    onChange={handleCategoryChange}
                                    required
                                  >
                                    <option value="">Select</option>
                                    {expertises.map((option) => (
                                      <option value={option.expertiseId}>
                                        {option._id}
                                        {option.name}
                                      </option>
                                    ))}
                                  </select> */}

                                  <p className="alert-message">
                                    {alertexpertise}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Validity</label>
                                  <input
                                    type="number"
                                    min="0"
                                    className="form-control-file"
                                    Placeholder=""
                                    value={durationInDays}
                                    onChange={(e) =>
                                      setdurationInDays(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">
                                    {alertvalidity}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Description</label>
                                  <textarea
                                    rows="5"
                                    className="form-control"
                                    Placeholder="Enter Description"
                                    value={description}
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">
                                    {alertdescription}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <h2>Services</h2>
                            </div>
                            <br />

                            <div className="col-md-4"></div>
                            <div className="col-md-4" align="center">
                              <button
                                type="button"
                                className="btn btn-success"
                                onClick={(e) => handleAddForms1(e)}
                              >
                                <i className="fa fa-plus-circle" />
                              </button>
                              &nbsp; &nbsp;
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={(e) => handleDeleteForms1(e)}
                              >
                                <i className="fa fa-minus-circle" />
                              </button>
                            </div>
                            <div className="col-md-4"></div>
                            {services.map((hotel, index) => (
                              <div key={index} className="form-group row">
                                <div className="col-md-4">
                                  <label for="eventInput5">Service Name</label>
                                  <input
                                    onChange={(e) => handleRowChange1(e, index)}
                                    className="form-control"
                                    placeholder="Service Name"
                                    name="serviceName"
                                    type="text"
                                    value={hotel.serviceName}
                                    required
                                  />
                                </div>
                                <div className="col-md-4">
                                  <label for="eventInput5">
                                    No.of Consultations
                                  </label>
                                  <input
                                    onChange={(e) => handleRowChange1(e, index)}
                                    className="form-control"
                                    placeholder="No.of Consultations"
                                    name="count"
                                    type="text"
                                    value={hotel.count}
                                    required
                                  />
                                </div>
                                <div className="col-md-4">
                                  <label for="eventInput5">
                                    Mode of Consultation
                                  </label>
                                  <input
                                    onChange={(e) => handleRowChange1(e, index)}
                                    className="form-control"
                                    placeholder="Mode of Consultation"
                                    name="mode"
                                    type="text"
                                    required
                                    value={hotel.mode}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>

                          <div className="form-actions center">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={save}
                            >
                              <i className="icon-note"></i> Save
                            </button>{" "}
                            &nbsp;
                            <button
                              type="reset"
                              className="btn btn-warning"
                              onClick={cancel}
                            >
                              <i className="icon-trash"></i> Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default AddFitnessPackage;
