import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./footer";
import Navbarside from "../component/Navbarside";
import { Frequently } from "../store/slices/faq";
import Loader from "./Loader";
import { API_PATHS } from "../utils/constants/api.constants";
import axios from "axios";

function Faq() {
  const [faqs, setFaqs] = useState([]);
  const navigate = useNavigate()
  const [faqsDefault, setFaqsDefault] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [categ, setCateg] = useState("");
  const dispatch = useDispatch();

  const categories = [
    { name: "app", label: "App" },
    { name: "website", label: "Website" },
  ];
  React.useEffect(() => {
    fetchData()
  }, [dispatch]);

  const fetchData= ()=>{
    setIsLoading(true);
    dispatch(Frequently())
      .unwrap()
      .then((data) => {
        setIsLoading(false);
        setFaqs(data.user.data);
        setFaqsDefault(data.user.data);
      })
      .catch(({ message }) => {
        setIsLoading(false);
        alert(message);
      });
  }

  const filterHandler = (value) => {
    if (value?.length > 0) {
      const faqsData = faqs?.filter((data) => data?.category === value);
      setFaqs(faqsData);
    } else {
      setFaqs(faqsDefault);
    }
    setCateg(value);
  };

  const deleteData = async(id)=>{
    try{
      setIsLoading(true)
      const getData = await axios.delete(`${API_PATHS.deleteFreq}?id=${id}`,)
      setIsLoading(false)
      if(getData){
        fetchData()
        alert("Deleted succesfully")
          // setFaqData(getData?.data?.data?.[0])
      }
  }catch(e){console.log("e",e)}
  }

  return (
    <div>
      <Navbarside />

      {isLoading && <Loader />}

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <div className="d-flex align-items-center">
                          <h4
                            className="card-title"
                            id="basic-layout-form-center"
                          >
                            FAQ
                          </h4>
                          &nbsp; &nbsp; &nbsp;
                          <Link to="/AddFaq" className="btn btn-success">
                            Add
                          </Link>
                          <div
                            className="col-md-3 col-sm-12 pt-2"
                            style={{
                              position: "relative",
                              left: "65%",
                              transform: "translateY(-10px)",
                            }}
                          >
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Category
                            </p>
                            <select
                              className="form-control"
                              onChange={(e) => filterHandler(e?.target?.value)}
                              value={categ}
                              //  onChange={handleExpertiseChange}
                            >
                              <option value="">Select Category</option>
                              {categories?.map((option) => (
                                <option value={option.name}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Questions</th>
                              <th>Answer</th>
                              <th>Category</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {faqs.map((faqVal, index) => (
                              <tr key={index}>
                                <td>{faqVal?.question}</td>
                                <td>{faqVal?.answer}</td>
                                <td>{faqVal?.category ?? ""}</td>

                                <td>
                                  <button
                                    key="edit"
                                    onClick={() => {
                                      navigate(
                                        `/editFaq/${faqVal?._id}`
                                      );
                                    }}
                                    className="btn btn-primary"
                                    style={{
                                      height: "25px",
                                      padding: "0 10px",
                                      marginRight: "3%",
                                    }}
                                  >
                                    Edit
                                  </button>
                                  
                                  <button
                                    key="delete"
                                    onClick={() => {
                                      deleteData(faqVal?._id);
                                    }}
                                    className="btn btn-warning"
                                    style={{
                                      height: "25px",
                                      padding: "0 10px",
                                    }}
                                  >
                                    Delete
                                  </button>
                                  
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default Faq;
