import React, { useState } from "react";
import Navbarside from "./Navbarside";
import { useNavigate } from "react-router-dom";
import Footer from "./footer";
import {
  getlabtestresultdetails,
  AddReport,
  UpdateTestRequest,
} from "../store/slices/lab";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const AddTestReport = () => {
  const [labTestCategoryId, setLabTestCategoryId] = useState([]);
  const [referredBy, setreferredBy] = useState("");
  const [tests, setTests] = useState([
    {
      name: "",
      units: "",
      value: "",
      reference: "",
    },
  ]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  localStorage.setItem("labId", id);

  React.useEffect(() => {
    dispatch(getlabtestresultdetails())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id === id) {
          setLabTestCategoryId(data.user.data[0].labTestCategoryId);
        }
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  const handleRowChange1 = (e, index) => {
    const LabVal = [...labTestCategoryId];
    LabVal[index][e.currentTarget.name] = e.currentTarget.value;
    setTests(LabVal);
    // console.log(hotelDescription);
  };

  async function Upload(e) {
    let labTestId = id;
    let reportDate = new Date().toISOString().slice(0, 10);

    var today = new Date();
    let reportTime =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    e.preventDefault();
    let item = { labTestId, reportDate, reportTime, tests, referredBy };

    dispatch(AddReport(item))
      .unwrap()
      .then(() => {
        alert("Report uploaded successfully");
        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
      });
    completeApprove();
  }

  async function completeApprove() {
    let status = "completed";
    let item = { id, status };
    localStorage.setItem("item", JSON.stringify(item));
    dispatch(UpdateTestRequest(item))
      .unwrap()
      .then(() => {
        // alert("Request completed succesfully");
        // window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }
  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-lg-12 col-md-10 col-sm-12">
                    <div className="card-details mt-2 mb-4">
                      <div className="card-title-wrap bar-success">
                        <h4 className="card-title">Test Result</h4>
                      </div>
                      <form onSubmit={Upload}>
                        <div className="row mt-3">
                          {labTestCategoryId.map((LabVal, index) => (
                            <div key={index} className="form-group row">
                              <div className="col-md-3">
                                <p>Test Name</p>
                                <input
                                  onChange={(e) => handleRowChange1(e, index)}
                                  className="form-control"
                                  placeholder="Test name"
                                  name="name"
                                  type="text"
                                  value={LabVal.name}
                                  readonly
                                />
                              </div>
                              <div className="col-md-3">
                                <p>Units</p>
                                <input
                                  onChange={(e) => handleRowChange1(e, index)}
                                  className="form-control"
                                  placeholder="Units"
                                  name="units"
                                  type="text"
                                  value={LabVal.units}
                                  required
                                />
                              </div>
                              <div className="col-md-3">
                                <p>Value</p>
                                <input
                                  onChange={(e) => handleRowChange1(e, index)}
                                  className="form-control"
                                  placeholder="Value"
                                  name="value"
                                  type="number"
                                  min="0"
                                  value={LabVal.value}
                                  required
                                />
                              </div>
                              <div className="col-md-3">
                                <p>Reference</p>
                                <input
                                  onChange={(e) => handleRowChange1(e, index)}
                                  className="form-control"
                                  placeholder="Reference"
                                  name="reference"
                                  type="number"
                                  min="0"
                                  value={LabVal.reference}
                                  required
                                />
                              </div>
                            </div>
                          ))}
                          <div className="col-md-4">
                            <p>Referred By</p>
                            <input
                              type="text"
                              placeholder="Referred By"
                              className="form-control"
                              required
                              onChange={(e) => setreferredBy(e.target.value)}
                              value={referredBy}
                            />
                          </div>
                        </div>
                        <div className="form-actions center">
                          <button
                            type="submit"
                            className="btn btn-primary wd-100"
                          >
                            <i className="icon-note"></i> Save
                          </button>
                          &nbsp;
                          <button
                            type="reset"
                            className="btn btn-warning wd-100"
                            // onClick={clear}
                          >
                            <i className="icon-trash"></i> Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
};

export default AddTestReport;
