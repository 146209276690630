import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import axios from "axios";
import { API_PATHS } from "../../utils/constants/api.constants";
import Navbarside from "../Navbarside";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../footer";
import { postayurdedaplans } from "../../store/slices/ayurvedaplan";

const AddAyurvedaPlan = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [mediaLink, setMediaLink] = useState("");
  const [conditionId, setConditionId] = useState("");
  const [price, setPrice] = useState(0);
  const [validityInDays, setValidityInDays] = useState(0);
  const [sessionsCount, setSessionsCount] = useState(0);

  const [message, setMessage] = useState("");
  const [alertname, setAlertName] = useState("");
  const [conditions, setConditions] = useState([]);

  const navigate = useNavigate("");
  const dispatch = useDispatch();

  React.useEffect(() => {
    axios.get(API_PATHS.getayurvedaconditions).then((response) => {
      setConditions(response.data.data);
    });
  }, [dispatch]);

  const save = (e) => {
    const regname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (regname.test(name)) {
      setAlertName("");
    } else if (!regname.test(name) && name === "") {
      setAlertName("Please enter the body part name");
      e.preventDefault();
    } else {
      setAlertName("Single and double quotes are not-valid");
      e.preventDefault();
    }
  };

  const cancel = () => {
    setName("");
    setDescription("");
    setMediaLink("");
    setConditionId("");
    setPrice("");
    setValidityInDays("");
    setSessionsCount("");
    setAlertName("");
  };

  async function addData(e) {
    e.preventDefault();
    let item = {
      name,
      description,
      conditionId,
      mediaLink,
      price,
      validityInDays,
      sessionsCount,
    };
    dispatch(postayurdedaplans(item))
      .unwrap()
      .then(() => {
        alert("Uploaded succesfully");
        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  async function imageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setMediaLink(data.data.location);
    setMessage(data.message);
  }

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Add Ayurveda Plan
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form className="form" onSubmit={addData}>
                          <div className="row justify-content-md-center">
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Plan Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter plan name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                  <p className="alert-message">{alertname}</p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Description</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter description"
                                    value={description}
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Image</label>
                                  <input
                                    type="file"
                                    className="form-control-file"
                                    Placeholder=""
                                    name="imageUrl"
                                    onChange={(e) => imageUpload(e)}
                                  />
                                  <p
                                    style={{
                                      color: "green",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {message}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Condition</label>
                                  <select
                                    className="form-control"
                                    value={conditionId}
                                    onChange={(e) =>
                                      setConditionId(e.target.value)
                                    }
                                  >
                                    <option value="">Select condition</option>
                                    {conditions.map((e) => (
                                      <option value={e._id}>{e.name}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Price</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter price"
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Validity In Days
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter validity"
                                    value={validityInDays}
                                    onChange={(e) =>
                                      setValidityInDays(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Session Count
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter session count"
                                    value={sessionsCount}
                                    onChange={(e) =>
                                      setSessionsCount(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-actions center">
                            <button
                              type="submit"
                              className="btn btn-primary wd-100"
                              onClick={save}
                            // disabled={!message}
                            >
                              <i className="icon-note"></i> Save
                            </button>{" "}
                            &nbsp;
                            <button
                              type="reset"
                              className="btn btn-warning"
                              onClick={cancel}
                            >
                              <i className="icon-trash"></i> Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default AddAyurvedaPlan;
