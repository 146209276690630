import { useLocation, useNavigate, useParams } from "react-router-dom";
import Navbarside from "../Navbarside";
import Footer from "../footer";
import { useEffect, useState } from "react";
import { fitnessCenterPlansById } from "../../store/slices/fitnessplans";
import { useDispatch } from "react-redux";
import axios from "axios";
import { API_BASE_URL, API_PATHS } from "../../utils/constants/api.constants";
import Loader from "../Loader";

const FitnessCenterPlanAddEdit = () => {
  const navigate = useNavigate();
  const { flow, id } = useParams();
  const location = useLocation();
  const { state } = location;
  const { fcAdminId, fitnessCenterId } = state || {};
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [fCat, setFCat] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [memberShipDurationInDays, setMemberShipDurationInDays] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState([]);
  const [fitnessCat, setFitnessCat] = useState([]);
  const [mediaLink, setMediaLink] = useState("");
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    getFitnessCarhandler();
    if (id?.length > 0) {
      setIsLoading(true);
      dispatch(fitnessCenterPlansById(id))
        .unwrap()
        .then((data) => {
          const fetchedData = data?.user?.data?.[0]
          setName(fetchedData?.name)
          setDescription(fetchedData?.description)
          setIsActive(fetchedData?.isActive)
          setIsDisable(fetchedData?.isDisable)
          setMediaLink(fetchedData?.mediaLink)
          setPrice(fetchedData?.price)
          setMemberShipDurationInDays(fetchedData?.memberShipDurationInDays)
          setTermsAndConditions(fetchedData?.termsAndConditions)
          // setFCat(fitId?.label)
          // setFitnessCat(fetchedData?.fitnessPlanCategoryId)
          setIsLoading(false);
        })
        .catch(({ message }) => {
          setIsLoading(false);
          alert(message);
        });
    }
  }, []);

  const getFitnessCarhandler = async () => {
    try {
      const fitnessCat = await axios.get(API_PATHS.fcPlanCat);
      if (fitnessCat) {
        const dataSelect = fitnessCat?.data?.data?.map((data) => {
          const obj = { label: data?.name, value: data?._id };
          return obj;
        });
        setFitnessCat(dataSelect);
        console.log("fitnessCat", dataSelect);
      }
    } catch (e) {
      console.log("e");
    }
  };

  const addPlanHandler = async () => {
    try {
      const payload = {
        name: name,
        description: description,
        memberShipDurationInDays: memberShipDurationInDays,
        fcAdminId: fcAdminId,
        fitnessCenterId: fitnessCenterId,
        termsAndConditions: termsAndConditions,
        isActive: isActive,
        isDisable: isDisable,
        price: price,
        imageUpload: mediaLink,
      };
      const planAdd = await axios.post(API_PATHS.fcCreatePlan, payload);
      if (planAdd) {
        navigate(`/FitnessCenterPlan/${fcAdminId}`);
        console.log("plan", planAdd);
      }
    } catch (e) {
      console.log("e");
      alert("Something Failed contact with admin");
    }
  };

  const editHandler = async() =>{
    try {
      const payload = {
        name: name,
        description: description,
        memberShipDurationInDays: memberShipDurationInDays,
        fcAdminId: fcAdminId,
        fitnessCenterId: fitnessCenterId,
        termsAndConditions: termsAndConditions,
        isActive: isActive,
        isDisable: isDisable,
        price: price,
        imageUpload: mediaLink,
      };
      const editPaylod = {}
      editPaylod['set'] = payload
      const planAdd = await axios.put(`${API_PATHS.fcEditPlans}?id=${id}`, editPaylod);
      if (planAdd) {
        alert("Details update sussesfully")
        // navigate(`/FitnessCenterPlan/${fcAdminId}`,);
        console.log("plan", planAdd);
      }
    } catch (e) {
      console.log("e");
      alert("Something Failed contact with admin");
    }
  }

  async function imageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    console.log("imageUpload", data.data.location);
    setMediaLink(data.data.location);
    setMessage(data.message);
  }

  const handleTermsChange = (e, index) => {
    const newTerms = [...termsAndConditions];
    newTerms[index] = e.target.value;
    setTermsAndConditions(newTerms);
  };

  const addTerm = () => {
    setTermsAndConditions([...termsAndConditions, ""]);
  };

  const removeTerm = (index) => {
    const newTerms = termsAndConditions.filter((_, i) => i !== index);
    setTermsAndConditions(newTerms);
  };
  return (
    <>
      <div></div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />
        {isLoading && <Loader/>}

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-wrap bar-danger d-flex align-items-center">
                          <h4 className="card-title">
                            {flow == "edit" ? "Edit" : "Add"} Fitness Centre
                            Plan
                          </h4>
                        </div>
                      </div>
                      {/* <h1>Updated soon</h1> */}

                      <section className="form-section">
                        <div className="row">
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Name:
                            </p>
                            <input
                              type="text"
                              className="form-control"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              // disabled={disabled}
                            />
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Description:
                            </p>
                            <input
                              type="text"
                              className="form-control"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              // disabled={disabled}
                            />
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Membership Duration (in days):
                            </p>
                            <input
                              type="number"
                              className="form-control"
                              value={memberShipDurationInDays}
                              onChange={(e) =>
                                setMemberShipDurationInDays(e.target.value)
                              }
                              // disabled={disabled}
                            />
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "10px" }}
                            >
                              Fitness Category:
                            </p>
                            <select
                              style={{ width: "100%" }}
                              value={fCat}
                              onChange={(e) => setFCat(e.target.value)}
                              // disabled={disabled}
                            >
                              <option value="">Select Type</option>
                              {fitnessCat?.map((option, index) => (
                                <option key={index} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Price:
                            </p>
                            <input
                              type="number"
                              className="form-control"
                              value={price}
                              onChange={(e) => setPrice(e.target.value)}
                              // disabled={disabled}
                            />
                          </div>
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Media:
                            </p>
                            <input
                              type="file"
                              className="form-control-file"
                              Placeholder=""
                              name="imageUrl"
                              onChange={(e) => imageUpload(e)}
                            />
                          {id?.length>0 && <img src={mediaLink} style={{width:"100px", height:"100px"}}/>}
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Terms and Conditions:
                            </p>
                            {termsAndConditions?.map((term, index) => (
                              <div key={index}>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={term}
                                  onChange={(e) => handleTermsChange(e, index)}
                                  // disabled={disabled}
                                />
                                <span
                                  key={index}
                                  className="clear-icon"
                                  onClick={() => removeTerm(index)}
                                >
                                  &times;
                                </span>
                              </div>
                            ))}
                            <button onClick={addTerm} disabled={false}>
                              Add Term
                            </button>
                          </div>

                          <div
                            className="col-md-6 col-sm-12 pt-2"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <p
                              className="col-black"
                              style={{
                                marginBottom: "0px",
                                marginRight: "10px",
                              }}
                            >
                              Is Active:
                            </p>
                            <input
                              type="checkbox"
                              style={{ marginRight: "50px" }}
                              checked={isActive}
                              onChange={(e) => setIsActive(e.target.checked)}
                              // disabled={disabled}
                            />
                            <p
                              className="col-black"
                              style={{
                                marginBottom: "0px",
                                marginRight: "10px",
                              }}
                            >
                              Disabled:
                            </p>
                            <input
                              type="checkbox"
                              style={{ marginRight: "50px" }}
                              checked={isDisable}
                              onChange={(e) => setIsDisable(e.target.checked)}
                              // disabled={disabled}
                            />
                          </div>

                          {/* <div className="col-md-6 col-sm-12 pt-2" style={{ display:"flex",alignItems:"center"}}>
                           
                          </div> */}
                        </div>

                        <div className="col-md-12" align="center">
                          <br />
                          <button
                            onClick={id?.length > 0 ? editHandler: addPlanHandler  }
                            className="btn btn-primary"
                            style={{ width: "150px", marginRight: "30px" }}
                          >
                            {id?.length > 0 ? "Save" : "Submit"}
                          </button>
                          &nbsp;
                          <button
                            // type="reset"
                            onClick={()=>{navigate(`/FitnessCenterPlan/${fcAdminId}`)}}
                            className="btn btn-danger"
                            style={{ width: "150px", marginRight: "30px" }}
                          >
                            Back
                          </button>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </body>
    </>
  );
};

export default FitnessCenterPlanAddEdit;
