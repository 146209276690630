import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Navbarside from "./Navbarside";
import { useNavigate } from "react-router-dom";
import Footer from "./footer";
import { postWomenSpecialSetting } from "../store/slices/womenSpecialSetting";

const AddWomenSpecialSetting = () => {
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [alertType, setAlertType] = useState("");
  const [alertDescription, setAlertDescription] = useState("");
  const [settings, setSettings] = useState([
    {
      type: "",
      data: "",
      value: [],
    },
  ]);
  const navigate = useNavigate("");
  const dispatch = useDispatch();

  const save = (e) => {
    const regname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (regname.test(type)) {
      setAlertType("");
    } else if (!regname.test(type) && type === "") {
      setAlertType("Please enter the type");
      e.preventDefault();
    } else {
      setAlertType("Single and double quotes are not-valid");
      e.preventDefault();
    }
  };

  const cancel = () => {
    setType("");
    setAlertType("");
    setDescription("");
    setAlertDescription("");
    setSettings([
      {
        type: "",
        data: "",
        value: [],
      },
    ]);
  };

  async function addData(e) {
    e.preventDefault();
    let item = {
      type,
      description,
      settings,
    };
    dispatch(postWomenSpecialSetting(item))
      .unwrap()
      .then(() => {
        alert("Uploaded succesfully");
        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const handleAddForms = () => {
    let setting = [...settings];
    setting.push({
      type: "",
      data: "",
      value: [],
    });
    setSettings(setting);
  };

  const handleDeleteForms = (e) => {
    if (settings.length > 1) {
      let setting = [...settings];
      setting.pop();
      setSettings(setting);
    }
  };

  const handleRowChange = (e, index) => {
    const setting = [...settings];
    setting[index][e.currentTarget.name] =
      e.currentTarget.name == "value"
        ? e.currentTarget.value.split(",")
        : e.currentTarget.value;
    setSettings(setting);
  };

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Add Women Special Setting
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form className="form" onSubmit={addData}>
                          <div className="row justify-content-md-center">
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Type</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter your body part"
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                  />
                                  <p className="alert-message">{alertType}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Description</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter your body part"
                                    value={description}
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">
                                    {alertDescription}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <h2>Services</h2>
                            </div>
                            <div className="col-md-12" align="center">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={(e) => handleAddForms(e)}
                              >
                                <i className="fa fa-plus-circle" />
                              </button>
                              &nbsp; &nbsp;
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={(e) => handleDeleteForms(e)}
                              >
                                <i className="fa fa-minus-circle" />
                              </button>
                            </div>
                            {settings.map((setting, index) => (
                              <div key={index} className="form-group row">
                                <div className="col-md-4">
                                  <label for="eventInput5">Type</label>
                                  <input
                                    onChange={(e) => handleRowChange(e, index)}
                                    className="form-control"
                                    placeholder="Type"
                                    name="type"
                                    type="text"
                                    value={setting.type}
                                    required
                                  />
                                </div>
                                <div className="col-md-4">
                                  <label for="eventInput5">Data</label>
                                  <input
                                    onChange={(e) => handleRowChange(e, index)}
                                    className="form-control"
                                    placeholder="Data"
                                    name="data"
                                    type="text"
                                    value={setting.data}
                                    required
                                  />
                                </div>
                                <div className="col-md-4">
                                  <label for="eventInput5">value</label>
                                  <input
                                    onChange={(e) => handleRowChange(e, index)}
                                    className="form-control"
                                    placeholder="value"
                                    name="value"
                                    type="text"
                                    required
                                    value={setting.value.join(",")}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>

                          <div className="form-actions center">
                            <button
                              type="submit"
                              className="btn btn-primary wd-100"
                              onClick={save}
                            >
                              <i className="icon-note"></i> Save
                            </button>{" "}
                            &nbsp;
                            <button
                              type="reset"
                              className="btn btn-warning"
                              onClick={cancel}
                            >
                              <i className="icon-trash"></i> Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default AddWomenSpecialSetting;
