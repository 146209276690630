import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import Navbarside from "./Navbarside";
import { getbodypartgroup, DeleteBodypartgroup } from "../store/slices/body";
import Footer from "./footer";

import { Link, useNavigate } from "react-router-dom";

const ConditionBodypartgroup = () => {
  const dispatch = useDispatch();
  const [bodypartgroup, setBodypartgroup] = useState([]);

  React.useEffect(() => {
    dispatch(getbodypartgroup())
      .unwrap()
      .then((data) => {
        setBodypartgroup(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  const Delete = async function (id) {
    localStorage.setItem("id", id);
    dispatch(DeleteBodypartgroup())
      .unwrap()
      .then(() => {
        // alert("Uploaded succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const deleteData = (id) => {
    confirmAlert({
      title: "Want to delete?",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => Delete(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-wrap bar-success">
                          <h4 className="card-title">
                            Body Part Group &nbsp; &nbsp;
                            <Link
                              to="/Conditions/Bodypartgroup/Addbodypartgroup"
                              className="btn btn-primary"
                            >
                              <i className="fa fa-plus"></i> Add
                            </Link>
                          </h4>
                        </div>
                      </div>
                      <div className="card-body collapse show">
                        <div className="card-block card-dashboard table-responsive">
                          <table className="table table-striped table-bordered zero-configuration">
                            <thead>
                              <tr>
                                <th>Body</th>
                                <th>Image</th>
                                <th>Actions</th>
                              </tr>
                            </thead>

                            <tbody>
                              {bodypartgroup.map((partVal, index) => (
                                <tr>
                                  <td>{partVal.name}</td>
                                  <td>
                                    <img
                                      src={partVal.mediaLink}
                                      alt="bodypart"
                                      style={{
                                        borderRadius: "10px",
                                        height: "60px",
                                        width: "60px",
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Link
                                      to={`/Conditions/Bodypartgroup/Editbodypartgroup/${
                                        partVal._id ? partVal._id : null
                                      }`}
                                      className="btn btn-primary"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Edit
                                    </Link>{" "}
                                    <button
                                      onClick={() => deleteData(partVal._id)}
                                      className="btn btn-warning"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
};

export default ConditionBodypartgroup;
