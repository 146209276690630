import React, { useState } from "react";
import Footer from "./footer";
import {
  socialHabits,
  majorIllness,
  familyIllness,
  surgeryHistory,
  allergyHistory,
} from "../store/slices/forms";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "../component/Navbarside";
import { useParams } from "react-router-dom";
import Moment from "moment";
const UserMedicalHistory = () => {
  const [usocialHabits, setusocialHabits] = useState([]);
  const [umajorIllness, setumajorIllness] = useState([]);
  const [ufamilyIllness, setufamilyIllness] = useState([]);
  const [usurgeryHistory, setusurgeryHistory] = useState([]);
  const [uallergyHistory, setuallergyHistory] = useState([]);
  const { id } = useParams();
  localStorage.setItem("userId", id);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(socialHabits())
      .unwrap()
      .then((data) => {
        setusocialHabits(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(majorIllness())
      .unwrap()
      .then((data) => {
        setumajorIllness(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(familyIllness())
      .unwrap()
      .then((data) => {
        setufamilyIllness(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(surgeryHistory())
      .unwrap()
      .then((data) => {
        setusurgeryHistory(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(allergyHistory())
      .unwrap()
      .then((data) => {
        setuallergyHistory(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid response-cover pb-5">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">User Medical History</h4>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="container">
                        <h3>Social Habits</h3>
                        <div className="card-block card-dashboard table-responsive">
                          <table className="table table-striped table-bordered zero-configuration">
                            <thead>
                              <tr>
                                <th>Habit</th>
                                <th>From</th>
                                <th>Frequency</th>
                                <th>Comments</th>
                              </tr>
                            </thead>
                            <tbody>
                              {usocialHabits.map((socialHabitsVal, index) => (
                                <tr>
                                  <td> {socialHabitsVal.socialHabit}</td>

                                  <td>{socialHabitsVal.havingFrom}</td>
                                  <td> {socialHabitsVal.frequency}</td>
                                  <td>{socialHabitsVal.comments}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="container">
                        <h4>Major Illness</h4>
                        <div className="card-block card-dashboard table-responsive">
                          <table className="table table-striped table-bordered zero-configuration">
                            <thead>
                              <tr>
                                <th>Condition</th>
                                <th>From</th>
                                <th>On Medication</th>
                                <th>Comments</th>
                              </tr>
                            </thead>
                            <tbody>
                              {umajorIllness.map((umajorIllnessVal, index) => (
                                <tr>
                                  <td> {umajorIllnessVal.condition}</td>

                                  <td>{umajorIllnessVal.sinceWhen}</td>
                                  <td>
                                    {" "}
                                    {umajorIllnessVal.onMedication === true && (
                                      <p>Yes</p>
                                    )}
                                    {umajorIllnessVal.onMedication ===
                                      false && <p>No</p>}
                                  </td>
                                  <td> {umajorIllnessVal.comments}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="container">
                        <h4>Family Illness</h4>
                        <div className="card-block card-dashboard table-responsive">
                          <table className="table table-striped table-bordered zero-configuration">
                            <thead>
                              <tr>
                                <th>Condition</th>
                                <th>From</th>
                                <th>Relationn</th>
                                <th>Comments</th>
                              </tr>
                            </thead>
                            <tbody>
                              {ufamilyIllness.map(
                                (ufamilyIllnessVal, index) => (
                                  <tr>
                                    <td> {ufamilyIllnessVal.condition}</td>

                                    <td>{ufamilyIllnessVal.sinceWhen}</td>
                                    <td> {ufamilyIllnessVal.relation}</td>
                                    <td> {ufamilyIllnessVal.comments}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="container">
                        <h4>Surgery History</h4>
                        <div className="card-block card-dashboard table-responsive">
                          <table className="table table-striped table-bordered zero-configuration">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Hospital Name/ Doctor Name</th>

                                <th>Comments</th>
                              </tr>
                            </thead>
                            <tbody>
                              {usurgeryHistory.map(
                                (usurgeryHistoryVal, index) => (
                                  <tr>
                                    <td> {usurgeryHistoryVal.name}</td>

                                    <td>
                                      {
                                        usurgeryHistoryVal.hospitalNameOrDoctorName
                                      }
                                    </td>

                                    <td> {usurgeryHistoryVal.comments}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="container">
                        <h4>Allergy History</h4>
                        <div className="card-block card-dashboard table-responsive">
                          <table className="table table-striped table-bordered zero-configuration">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>From</th>
                                <th>Type</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              {uallergyHistory.map(
                                (uallergyHistoryVal, index) => (
                                  <tr>
                                    <td> {uallergyHistoryVal.name}</td>

                                    <td>{uallergyHistoryVal.sinceFrom}</td>
                                    <td> {uallergyHistoryVal.type}</td>
                                    <td> {uallergyHistoryVal.description}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default UserMedicalHistory;
