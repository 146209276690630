import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "../component/Navbarside";
import Footer from "./footer";
import Moment from "moment";
import { SessionnalysisDetails } from "../store/slices/analysis";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useSearchParams } from "react-router-dom";
import Loader from "./Loader";
import { formatDate } from "../utils/appUtils";

const SessionAnalysis = () => {
  const [URLSearchParams, SetURLSearchParams] =useSearchParams()
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const fileName = "Appointment Analysis Report";
  const [data, setData] = useState([]);
  const [errorStart, setErrorStart] = useState("");
  const [errorEnd, setErrorEnd] = useState("");
  const [responseCode, setResponseCode] = useState();
  const [isLoading, setIsLoading] = useState(false)
  const [categ, setCateg] = useState('')

  useEffect(()=>{
    const sDate =URLSearchParams.get('startDate') || dateForQuery().startDate
    const eDate =URLSearchParams.get('endDate') || dateForQuery().endDate
    const cat =URLSearchParams.get('category') || ""
    analysisData(sDate,eDate,cat)
    setstartDate(sDate)
    setendDate(eDate)
    const catDataRender =categories?.filter((data)=>data?.name===cat)[0]
    setCateg(catDataRender?.name)
},[])

    const categories = [{name:"physioTherapy", label :"Physio Therapy"},
    {name:"liveWell", label :"Live Well"},{name:"healthCare", label :"Health Care"},{name:"fitness", label :"Fitness"},{name:"manageWeight", label :"Manage Weight"}
    ]

  const analysisData = (sDate, eDate, cat="") => {

    let item = { startDate:sDate, endDate:eDate };
    if (sDate?.length < 1 && eDate?.length < 1) {
      setErrorStart("Please select start date");
      setErrorEnd("Please select end date");
      return;
    }else if (sDate?.length < 1) {
      setErrorStart("Please select start date");
      return;
    } else if (eDate?.length < 1) {
      setErrorEnd("Please select end date");
      return;
    }else {
      setErrorEnd('')
      setErrorStart('')
    }
    cat?.length && (item['category'] = cat)
    setIsLoading(true)
    dispatch(SessionnalysisDetails(item))
      .unwrap()
      .then((data) => {
        console.log("dataUSer",data?.user?.data?.data)
        setIsLoading(false)
        setResponseCode(data?.user?.status);
        setData(data?.user?.data?.data);
      })
      .catch(({ message }) => {
        setIsLoading(false)
        console.log("message", message);
        // alert(message);
      });
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Appointment Analysis Report",
    sheet: "Sheet1",
  });

  const dateForQuery = ()=>{
    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    const formattedToday = formatDate(today);
    const formattedOneMonthAgo = formatDate(oneMonthAgo);
    return {startDate:formattedToday, endDate:formattedOneMonthAgo}
  }

  return (
    <div>
      <Navbarside />
      {isLoading && <Loader/>}

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Session Analysis Report</h4>
                      </div>
                    </div>
                    <section className="form-section">
                      <form onSubmit={(e)=>{e.preventDefault();analysisData(startDate,endDate,categ||"")}}>
                        <div className="row">
                          <div className="col-md-3 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              From Date
                            </p>
                            <input
                              type="date"
                              placeholder="Enter the recipe name"
                              value={startDate}
                              onChange={(e) => setstartDate(e.target.value)}
                              className="form-control"
                            />
                            {errorStart?.length > 0 && (
                              <div className="text-danger">{errorStart}</div>
                            )}
                            <p className="alert-message"></p>
                          </div>
                          <div className="col-md-3 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              To Date
                            </p>
                            <input
                              type="date"
                              placeholder="Enter the recipe name"
                              value={endDate}
                              onChange={(e) => setendDate(e.target.value)}
                              className="form-control"
                            />
                            {errorEnd?.length > 0 && (
                              <div className="text-danger">{errorEnd}</div>
                            )}
                            <p className="alert-message"></p>
                          </div>
                          <div className="col-md-3 col-sm-12 pt-2">
                          <p className="col-black" style={{ marginBottom: "2px" }}>Category</p>
                                <select className="form-control"
                                onChange={(e)=>setCateg(e.target.value)}
                                value={categ}
                                //  onChange={handleExpertiseChange} 
                                 >
                                  <option value="">Select Category</option>
                                  { categories?.map(option =>   (
                                          <option value={option?.name}>
                                            {option?.label}
                                          </option>
                                        ))
                                  }
                                </select>
                          </div>
                          <div className="col-md-3 col-sm-12 pt-2 mt-3">
                            <button type="submit" className="btn btn-primary">
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </section>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        {data !== "" && (
                          <div>
                            <button
                            disabled = {!data?.length}
                              onClick={onDownload}
                              className="btn btn-primary"
                            >
                              Export to Excel
                            </button>
                            {
                              data?.length > 0 && (
                                <table
                                  className="table table-striped table-bordered zero-configuration"
                                  ref={tableRef}
                                >
                                  <thead>
                                    <tr>
                                      <th>Session Date</th>
                                      <th>Session Time</th>
                                      <th>Category</th>
                                      <th>Session Expert</th>
                                      <th>Client / User Name</th>
                                      <th>Membership Plan Name</th>
                                      <th>Session Status</th>
                                      <th>Client Login Time</th>
                                    </tr>
                                  </thead>

                              <tbody>
                                {data?.map((dataVal, index) => (
                                  <tr>
                                    <td>
                                      {" "}
                                      {Moment(dataVal?.created_at).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </td>
                                    <td>
                                      {Moment(dataVal?.created_at).format(
                                        "hh:mm A"
                                      )}
                                    </td>

                                    <td>{dataVal?.category}</td>

                                    <td>
                                      <p>
                                        {dataVal?.expert?.firstName
                                          ? dataVal?.expert?.firstName
                                          : null}
                                        &nbsp;
                                        {dataVal?.expert?.lastName
                                          ? dataVal?.expert?.lastName
                                          : null}
                                      </p>
                                    </td>
                                    <td>
                                      {dataVal?.client?.firstName
                                        ? dataVal?.client?.firstName
                                        : null}
                                      &nbsp;
                                      {dataVal?.client?.lastName
                                        ? dataVal?.client?.lastName
                                        : null}
                                    </td>
                                    <td>
                                      {dataVal?.subscriptionId?.packageId
                                        ? dataVal?.subscriptionId?.packageId?.name
                                        : null}
                                    </td>
                                    <td>{dataVal?.status}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>)}
                            {responseCode ==204 && (<p>No session data found in this Date Range</p>)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};


export default SessionAnalysis;
