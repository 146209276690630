import React from "react";
import Navbarside from "./Navbarside";
import { useDispatch } from "react-redux";
import { Weightmanagementconsultation } from "../store/slices/Wmconsultation";
import { useState,useRef } from "react";
import Footer from "./footer";
import Moment from "moment";
import { useDownloadExcel } from "react-export-table-to-excel";

import { ExportToExcel } from "./ExportToExcel";
import { API_PATHS } from "../utils/constants/api.constants";
import Pagination from "./common/Pagination";
import axios from "axios";
import Table from "./common/Table";
import Loader from "./Loader";

const WmConsultation = () => {
  const [consultation, setConsultation] = useState([]);
  const tableRef = useRef(null);
  const [column, setColumn] = useState([
    {"isHtml":false, "name":'#'},
    {"isHtml":false, "name":'Client Name'},
    {"isHtml":false, "name":'Expert Name'},
    {"isHtml":true, "name":'Consultation Details'},
    {"isHtml":false, "name":'Status'},

  ]);
  const [row, setRow] = useState([]);
  const [page, setpage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);


  const dispatch = useDispatch();


  const [data, setData] = useState([]);
  const fileName = "WMConsutation";

  // React.useEffect(() => {
  //   const fetchData = () => {
  //     axios.get(API_PATHS.WMConsultations).then((postData) => {
  //       const customHeadings = postData.data.data.map((item) => ({
  //         "First Name": item.expertId[0].firstName,
  //         "Last Name": item.expertId[0].lastName,
  //         "Start Date": item.startDate,
  //         "Start Time": item.startTime,
  //         "Status": item.status,
  //       }));

  //       setData(customHeadings);
  //     });
  //   };
  //   fetchData();
  // }, []);

  React.useEffect(() => {
    getWeightManagement()
  }, [dispatch]);

  const getWeightManagement = (page=0)=>{
    setIsLoading(true)
    dispatch(Weightmanagementconsultation({ limit: 10, page: page }))
    .unwrap()
    .then((data) => {
      setpage(page);
    setIsLoading(false)
      setTotalCount(data?.user?.totalCount);
      const modifiedArray = data?.user?.data?.map((obj, key) => {
        let newObj = {
          // Modify key names
          "#": parseInt(page)*10 +1+key,
          "Client Name":  `${obj.userId[0].firstName}  ${obj.userId[0].lastName}`,
          "Expert Name": `${obj.expertId[0].firstName}  ${obj.expertId[0].lastName}`,
          "Consultation Details": ` Consultation Date:
          ${Moment(obj.startDate).format(
            "DD-MM-YYYY"
          )}
          <br />
          Consultation Time:
          ${obj.startTime}
          <br />
          Consultation Type: ${obj?.type}`,
          Status: obj.status,
        };

        return newObj;
      });

      setRow(modifiedArray);
      setConsultation(data.user.data);
    })
    .catch(({ message }) => {
    setIsLoading(false)
      alert(message);
    });
  }

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Weight Management Consultation",
    sheet: "Sheet1",
  });
  return (
    <div>
      <Navbarside />

      {isLoading && <Loader/>}

      <div className="container-fluid response-cover pb-5">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">
                          Weight Management Consultation
                        </h4>{" "}
                        &nbsp; &nbsp; &nbsp;
                        {/* <ExportToExcel apiData={data} fileName={fileName} /> */}
                        <div className="col-md-2 col-sm-12 pt-2 mt-3">
                          <button
                            onClick={onDownload}
                            className="btn btn-primary"
                          >
                            Export to Excel
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <Table columns={column} data={row} isFooter={true} tableRef={tableRef}/>
                      <div className="PaginationContainer">
                            <span className="total-elements">
                              Showing {parseInt(page)*10 +1 } to {parseInt(page)*10 +10 } of {totalCount} entries
                            </span>
                            <Pagination
                              currentPage={(parseInt(page)+1)}
                              totalCount={totalCount}
                              itemsPerPage={10}
                              onPageChange={(page)=>{getWeightManagement(parseInt(page)-1)}}
                            />
                          </div>
                      {/* <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration"
                          ref={tableRef}
                        >
                          <thead>
                            <tr>
                              <th>Client Name</th>
                              <th>Expert Name</th>
                              <th>Consultation Details</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {consultation.map((wmconsultationVal, index) => (
                              <tr key={index}>
                                <td>
                                  {wmconsultationVal.userId[0].firstName}
                                  &nbsp;
                                  {wmconsultationVal.userId[0].lastName}
                                </td>

                                <td>
                                  {wmconsultationVal.expertId[0].firstName}
                                  {wmconsultationVal.expertId[0].lastName}
                                </td>
                                <td>
                                  Consultation Date:{" "}
                                  {Moment(wmconsultationVal.startDate).format(
                                    "DD-MM-YYYY"
                                  )}
                                  <br />
                                  Consultation Time:
                                  {wmconsultationVal.startTime}
                                  <br />
                                  Consultation Type:{wmconsultationVal.type}
                                </td>
                                <td>{wmconsultationVal.status}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default WmConsultation;
