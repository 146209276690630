import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import Navbarside from "../component/Navbarside";
import Footer from "./footer";
import { useParams } from "react-router-dom";
import { fitnessCenterRevenue } from "../store/slices/fitnessplans";
import Moment from "moment";
import axios from "axios";
import { ExportToExcel } from "./ExportToExcel";
import { API_PATHS } from "../utils/constants/api.constants";
function FitnessRevenue() {
  let id = JSON.parse(localStorage.getItem("user")).data.id;
  localStorage.setItem("fitnesscenterId", id);
  const fileName = "Lab Vendor Revenue";
  const [data, setData] = useState([]);
  const [revenueVendor, setrevenueVendor] = useState([]);
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // React.useEffect(() => {
  //   const fetchData = () => {
  //     axios.get(API_PATHS.fitnessCenterRevenue + "?labId=" + id).then((postData) => {
  //       const customHeadings = postData.data.data.map((item) => ({
  //         Patient_Name: item.labTestId.userId.firstName,
  //         Test_Type: item.labTestId.testType,
  //         Lab_Name: item.labId.name,
  //         Date: item.updated_at,
  //         NetAmount: item.netAmount,
  //         Commission: item.commission,
  //         Payout: item.payout,
  //         Service_Tax: item.serviceTax,
  //       }));

  //       setData(customHeadings);
  //     });
  //   };
  //   fetchData();
  // }, []);

  React.useEffect(() => {
    dispatch(fitnessCenterRevenue())
      .unwrap()
      .then((data) => {
        setrevenueVendor(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Revenue</h4> &nbsp; &nbsp;
                        &nbsp;
                        {/* <ExportToExcel
                          apiData={data}
                          fileName={fileName}
                          style={{
                            height: "25px",
                            padding: "0 10px",
                            marginBottom: "0%",
                          }}
                        /> */}
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Plan Details</th>
                              <th>Date</th>
                              <th>Net Amount</th>
                              <th>Commission</th>
                              <th>Payout</th>
                              <th>Service Tax</th>
                            </tr>
                          </thead>
                          <tbody>
                            {revenueVendor.map((RevenueVal, index) => (
                              <tr key={index}>
                                <td>
                                  Name-{" "}
                                  {
                                    RevenueVal.fitnessPlanSubscriptionId.userId
                                      .firstName
                                  }
                                  <br />
                                  Plan Type- {RevenueVal.fitnessPlanId.name}
                                </td>
                                <td>
                                  {" "}
                                  {Moment(RevenueVal.updated_at).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                                <td>Rs. {RevenueVal.netAmount}</td>
                                <td>Rs. {RevenueVal.commission}</td>
                                <td>Rs. {RevenueVal.payout}</td>
                                <td>Rs. {RevenueVal.serviceTax}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default FitnessRevenue;
