import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { postWmPackage } from "../store/slices/wmPackage";
import Footer from "./footer";
import Navbarside from "../component/Navbarside";
import ExpertiseService from "./../services/expertise.service";
import { API_PATHS } from "../utils/constants/api.constants";
import axios from "axios";

function WmAddPackage(e) {
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const [expertiseId, setexpertiseId] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [benefits, setBenefits] = useState("");
  const [description, setDescription] = useState("");
  const [price, setprice] = useState("");
  const [name, setname] = useState("");
  const [sessionCount, setSessionCount] = useState("");
  const [doctorSessionCount, setDoctorSessionCount] = useState("");
  const [dietSessionCount, setDietSessionCount] = useState("");
  const [fitnessGroupSessionCount, setFitnessGroupSessionCount] = useState("");
  const [immunityBoosterCounselling, setImmunityBoosterCounselling] =
    useState("");
  const [customizedDietPlan, setCustomizedDietPlan] = useState("");
  const [fitnessPlan, setFitnessPlan] = useState("");
  const [freeGroupSessionAccess, setFreeGroupSessionAccess] = useState(false);
  const [durationInDays, setDurationInDays] = useState("");
  const [frequency, setFrequency] = useState("");
  const [expertises, setExpertises] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [packageTypeId, setPackageTypeId] = useState([]);
  const [packageTypes, setPackageTypes] = useState([]);

  const [alertbenefits, setAlertbenefits] = useState("");
  const [alertdescription, setAlertdescription] = useState("");
  const [alertprice, setAlertprice] = useState("");
  const [alertname, setAlertname] = useState("");
  const [alertsession, setAlertsession] = useState("");
  const [alertvalidity, setAlertvalidity] = useState("");
  const [alertexpertise, setAlertexpertise] = useState("");
  async function upload(e) {
    e.preventDefault();
    let isActive = true;
    let item = {
      name,
      benefits,
      description,
      price,
      sessionCount,
      doctorSessionCount,
      dietSessionCount,
      fitnessGroupSessionCount,
      immunityBoosterCounselling,
      customizedDietPlan,
      fitnessPlan,
      freeGroupSessionAccess,
      packageTypeId,
      expertiseId,
      subCategoryId,
      isActive,
      durationInDays,
    };

    dispatch(postWmPackage(item))
      .unwrap()
      .then(() => {
        alert("Uploaded successfully");
        navigate("/Weightmanagement/Package");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const Expertises = createAsyncThunk(
    "expertise/get/expertise",
    async (thunkAPI) => {
      try {
        // console.log("consultation")
        const data = await ExpertiseService.getExpertise();
        return { user: data };
      } catch (error) {
        const sessionCount =
          (error.response &&
            error.response.data &&
            error.response.data.sessionCount) ||
          error.sessionCount ||
          error.toString();
        return thunkAPI.rejectWithValue({ sessionCount });
      }
    }
  );

  React.useEffect(() => {
    axios.get(API_PATHS.getPackageType).then((response) => {
      setPackageTypes(response.data.data);
    });
    dispatch(Expertises())
      .unwrap()
      .then((data) => {
        let expertises = data.user.data?.filter(
          (e) => e?.parentExpertiseId == null
        );
        let subCategories = data.user.data?.filter(
          (e) => e?.parentExpertiseId != null
        );
        setExpertises(expertises);
        setexpertiseId(expertises[0]._id);
        setSubCategories(subCategories);
      })
      .catch(({ sessionCount }) => {
        alert(sessionCount);
      });
  }, [dispatch]);

  const save = (e) => {
    const regtext = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regtext.test(name)) {
      setAlertname("");
    } else if (!regtext.test(name) && name === "") {
      setAlertname("Please enter package name");
      e.preventDefault();
    } else {
      setAlertname("");
    }

    const regtext2 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regtext2.test(description)) {
      setAlertdescription("");
    } else if (!regtext2.test(description) && description === "") {
      setAlertdescription("Please enter description");
      e.preventDefault();
    } else {
      setAlertdescription("");
    }

    const regprice =
      /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[-]([0]?[1-9]|[1][0-2])[-]([0-9]{4}|[0-9]{2})$/;
    if (regprice.test(price)) {
      setAlertprice("");
    } else if (!regprice.test(price) && price === "") {
      setAlertprice("Please enter package price");
      e.preventDefault();
    } else {
      setAlertprice("");
    }

    const regsession =
      /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[-]([0]?[1-9]|[1][0-2])[-]([0-9]{4}|[0-9]{2})$/;
    if (regsession.test(sessionCount)) {
      setAlertsession("");
    } else if (!regsession.test(price) && price === "") {
      setAlertsession("Please enter no.of sessions");
      e.preventDefault();
    } else {
      setAlertsession("");
    }

    if (expertiseId === "") {
      setAlertexpertise("Please select expertise");
      e.preventDefault();
    } else {
      setAlertexpertise("");
    }

    const regvalidity =
      /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[-]([0]?[1-9]|[1][0-2])[-]([0-9]{4}|[0-9]{2})$/;
    if (regvalidity.test(durationInDays)) {
      setAlertvalidity("");
    } else if (!regsession.test(durationInDays) && durationInDays === "") {
      setAlertvalidity("Please enter validity");
      e.preventDefault();
    } else {
      setAlertvalidity("");
    }
  };

  const cancel = () => {
    setBenefits("");
    setDescription("");
    setprice("");
    setname("");
    setAlertbenefits("");
    setAlertdescription("");
    setAlertprice("");
    setAlertsession("");
    setAlertname("");
    setSessionCount("");
  };
  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Add Package
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form className="form" onSubmit={upload}>
                          <div className="row justify-content-md-center">
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Package Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Package Name"
                                    value={name}
                                    onChange={(e) => setname(e.target.value)}
                                  />
                                  <p className="alert-message">{alertname}</p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">price</label>
                                  <input
                                    type="price"
                                    className="form-control"
                                    Placeholder=""
                                    value={price}
                                    onChange={(e) => setprice(e.target.value)}
                                  />
                                  <p className="alert-message">{alertprice}</p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    No.of Sessions
                                  </label>
                                  <input
                                    type="number"
                                    min="0"
                                    className="form-control"
                                    Placeholder=""
                                    name="imageUrl"
                                    value={sessionCount}
                                    onChange={(e) =>
                                      setSessionCount(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">
                                    {alertsession}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Doctor No.of Sessions
                                  </label>
                                  <input
                                    type="number"
                                    min="0"
                                    className="form-control"
                                    Placeholder=""
                                    value={doctorSessionCount}
                                    onChange={(e) =>
                                      setDoctorSessionCount(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">
                                    {alertsession}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Diet No.of Sessions
                                  </label>
                                  <input
                                    type="number"
                                    min="0"
                                    className="form-control"
                                    Placeholder=""
                                    value={dietSessionCount}
                                    onChange={(e) =>
                                      setDietSessionCount(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">
                                    {alertsession}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Fitness No.of Sessions
                                  </label>
                                  <input
                                    type="number"
                                    min="0"
                                    className="form-control"
                                    Placeholder=""
                                    value={fitnessGroupSessionCount}
                                    onChange={(e) =>
                                      setFitnessGroupSessionCount(
                                        e.target.value
                                      )
                                    }
                                  />
                                  <p className="alert-message">
                                    {alertsession}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Immunity Booster Counselling
                                  </label>
                                  <input
                                    type="number"
                                    min="0"
                                    className="form-control"
                                    Placeholder=""
                                    value={immunityBoosterCounselling}
                                    onChange={(e) =>
                                      setImmunityBoosterCounselling(
                                        e.target.value
                                      )
                                    }
                                  />
                                  <p className="alert-message">
                                    {alertsession}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Customized DietPlan
                                  </label>
                                  <input
                                    type="number"
                                    min="0"
                                    className="form-control"
                                    Placeholder=""
                                    value={customizedDietPlan}
                                    onChange={(e) =>
                                      setCustomizedDietPlan(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">
                                    {alertsession}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Fitness Plan
                                  </label>
                                  <input
                                    type="number"
                                    min="0"
                                    className="form-control"
                                    Placeholder=""
                                    value={fitnessPlan}
                                    onChange={(e) =>
                                      setFitnessPlan(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">
                                    {alertsession}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Fitness Plan
                                  </label>
                                  <select
                                    className="form-control"
                                    value={freeGroupSessionAccess}
                                    onChange={(e) =>
                                      setFreeGroupSessionAccess(
                                        e.target.value == 1
                                      )
                                    }
                                  >
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                  </select>
                                  <p className="alert-message">
                                    {alertsession}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Frequency</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder=""
                                    value={frequency}
                                    onChange={(e) =>
                                      setFrequency(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">
                                    {alertsession}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Benefits</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder=""
                                    value={benefits}
                                    onChange={(e) =>
                                      setBenefits(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">
                                    {alertsession}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Package Type</label>
                                  <select
                                    className="form-control"
                                    onChange={(e) => {
                                      setPackageTypeId(e.target.value);
                                    }}
                                    required
                                  >
                                    <option value="">Select</option>
                                    {packageTypes.map((option) => (
                                      <option value={option._id}>
                                        {option.name}
                                      </option>
                                    ))}
                                  </select>
                                  <p className="alert-message">
                                    {alertexpertise}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Category</label>
                                  <select
                                    className="form-control"
                                    onChange={(e) => {
                                      setexpertiseId(e.target.value);
                                    }}
                                    required
                                  >
                                    <option value="">Select</option>
                                    {expertises.map((option) => (
                                      <option value={option._id}>
                                        {option.name}
                                      </option>
                                    ))}
                                  </select>
                                  <p className="alert-message">
                                    {alertexpertise}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Sub Category
                                  </label>
                                  <select
                                    className="form-control"
                                    onChange={(e) => {
                                      setSubCategoryId(e.target.value);
                                    }}
                                    required
                                  >
                                    <option value="">Select</option>
                                    {subCategories
                                      ?.filter(
                                        (e) =>
                                          e.parentExpertiseId == expertiseId
                                      )
                                      .map((option) => (
                                        <option value={option._id}>
                                          {option.name}
                                        </option>
                                      ))}
                                  </select>
                                  <p className="alert-message">
                                    {alertexpertise}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Validity</label>
                                  <input
                                    type="number"
                                    min="0"
                                    className="form-control"
                                    Placeholder=""
                                    value={durationInDays}
                                    onChange={(e) =>
                                      setDurationInDays(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">
                                    {alertvalidity}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Description</label>
                                  <textarea
                                    rows="5"
                                    className="form-control"
                                    Placeholder="Enter Description"
                                    value={description}
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">
                                    {alertdescription}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-actions center">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={save}
                            >
                              <i className="icon-note"></i> Save
                            </button>{" "}
                            &nbsp;
                            <button
                              type="reset"
                              className="btn btn-warning"
                              onClick={cancel}
                            >
                              <i className="icon-trash"></i> Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default WmAddPackage;
