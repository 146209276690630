import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import Navbarside from "../component/Navbarside";
import { HcConsultations } from "../store/slices/HcConsultation";
import Moment from "moment";
import Footer from "./footer";

const HcEnquiry = () => {
  const [expertises, setexpertise] = useState([]);
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  localStorage.setItem('flow', 'healthCare')

  React.useEffect(() => {
    dispatch(HcConsultations()).unwrap().then((data) => {

      setexpertise(data.user.data);
    }).catch(({ message }) => {
      alert(message);
    });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success">
                        <h4 className="card-title">Health Care Consultation</h4>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>User Details</th>
                              <th>Health Issue</th>
                              <th>Booking For</th>

                              <th>Booking Date/ Booking Time</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          {loading ? (
                            <Loader active inline />
                          ) : (
                            <>
                              <tbody>
                                {expertises.map((enqVal, index) => (
                                  <tr key={index}>
                                    <td>
                                      Name: {enqVal.userId[0]?.firstName}{" "}
                                      {enqVal.userId[0]?.lastName}
                                      <br />
                                      Email: {enqVal.userId[0]?.email}
                                      <br />
                                      Mobile Number: {enqVal.userId[0]?.mobileNo}
                                    </td>
                                    <td>{enqVal.description}</td>

                                    <td>{enqVal.expertiseId[0]?.name}</td>

                                    <td>
                                      {Moment(enqVal.startDate).format(
                                        "DD-MM-YYYY"
                                      )}
                                      / {enqVal.startTime}
                                    </td>

                                    <td>
                                      {enqVal.status === "initiated" && (
                                        <Link
                                          to={`/ProcessEnquiry/:id'${enqVal._id ? enqVal._id : null
                                            }`}
                                          className="btn btn-warning"
                                          style={{
                                            height: "25px",
                                            padding: "0 10px",
                                          }}
                                        >
                                          Assign Expert
                                        </Link>
                                      )}
                                      {enqVal.status === "scheduled" && (
                                        <button
                                          className="btn btn-success"
                                          style={{
                                            height: "25px",
                                            padding: "0 10px",
                                          }}
                                        >
                                          Appointment Scheduled
                                        </button>
                                      )}
                                      {enqVal.status === "paymentRequested" && (
                                        <button
                                          className="btn btn-danger"
                                          style={{
                                            height: "25px",
                                            padding: "0 10px",
                                          }}
                                        >
                                          Payment Pending
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default HcEnquiry;
