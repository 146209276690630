import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "./Navbarside";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "./footer";
import { postapprovelab } from "../store/slices/labapprove";

const AddLabapprove = () => {
  const [platformCostPercent, setPlatformCostPercent] = useState("");
  const [hplCommission, setHplCommission] = useState("");
  const [homeServicePercent, setHomeServicePercent] = useState("");
  const [gstPercent, setGstPercent] = useState("");

  const [alertplatformCostPercent, setAlertPlatformCostPercent] = useState("");
  const [alerthplCommission, setAlertHplCommission] = useState("");
  const [alerthomeServicePercent, setAlertHomeServicePercent] = useState("");
  const [alertgstPercent, setAlertGstPercent] = useState("");

  const navigate = useNavigate("");
  const dispatch = useDispatch();

  const save = (e) => {
    const regnumber = /^[0-9][0-9]?$|^100$/;
    if (regnumber.test(platformCostPercent)) {
      setAlertPlatformCostPercent("");
    } else if (
      !regnumber.test(platformCostPercent) &&
      platformCostPercent === ""
    ) {
      setAlertPlatformCostPercent("Please enter the lab price");
      e.preventDefault();
    } else {
      setAlertPlatformCostPercent("");
    }

    const regnumber1 = /^[0-9][0-9]?$|^100$/;
    if (regnumber1.test(hplCommission)) {
      setAlertHplCommission("");
    } else if (!regnumber1.test(hplCommission) && hplCommission === "") {
      setAlertHplCommission("Please enter the HPL commission");
      e.preventDefault();
    } else {
      setAlertHplCommission("");
    }

    const regnumber3 = /^[0-9][0-9]?$|^100$/;
    if (regnumber3.test(homeServicePercent)) {
      setAlertHomeServicePercent("");
    } else if (
      !regnumber3.test(homeServicePercent) &&
      homeServicePercent === ""
    ) {
      setAlertHomeServicePercent("Please enter the home service percent");
      e.preventDefault();
    } else {
      setAlertHomeServicePercent("");
    }

    const regnumber4 = /^[0-9][0-9]?$|^100$/;
    if (regnumber4.test(gstPercent)) {
      setAlertGstPercent("");
    } else if (!regnumber4.test(gstPercent) && gstPercent === "") {
      setAlertGstPercent("Please enter the GST percent");
      e.preventDefault();
    } else {
      setAlertGstPercent("");
    }
  };

  const cancel = () => {
    setPlatformCostPercent("");
    setHplCommission("");
    setHomeServicePercent("");
    setGstPercent("");
    setAlertPlatformCostPercent("");
    setAlertHplCommission("");
    setAlertHomeServicePercent("");
    setAlertGstPercent("");
  };

  const { id } = useParams();
  localStorage.setItem("id", id);
  async function upload(e) {
    e.preventDefault();
    let isApproved = true;
    let status = "approved";

    let item = {
      id,
      isApproved,
      status,
      platformCostPercent,
      hplCommission,
      homeServicePercent,
      gstPercent,
    };

    dispatch(postapprovelab(item))
      .unwrap()
      .then(() => {
        alert("Approved succesfully");
        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">Lab Approve</h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <form onSubmit={upload}>
                        <div className="row">
                          <div className="col-md-6 col-sm-12 pt-2">
                            <input
                              type="number"
                              placeholder="Enter the platform cost percent"
                              value={platformCostPercent}
                              onChange={(e) =>
                                setPlatformCostPercent(e.target.value)
                              }
                              className="form-control"
                            />
                            <p className="alert-message">
                              {alertplatformCostPercent}
                            </p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <input
                              type="number"
                              placeholder="Enter the HPL commission"
                              value={hplCommission}
                              onChange={(e) => setHplCommission(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">
                              {alerthplCommission}
                            </p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <input
                              type="number"
                              placeholder="Enter the home service percent"
                              value={homeServicePercent}
                              onChange={(e) =>
                                setHomeServicePercent(e.target.value)
                              }
                              className="form-control"
                            />
                            <p className="alert-message">
                              {alerthomeServicePercent}
                            </p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <input
                              type="number"
                              placeholder="Enter the GST percent"
                              value={gstPercent}
                              onChange={(e) => setGstPercent(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertgstPercent}</p>
                          </div>
                        </div>

                        <div className="d-flex justify-content-center pt-3">
                          <button
                            type="submit"
                            className="btn btn-success"
                            onClick={save}
                          >
                            Submit
                          </button>
                          &nbsp; &nbsp; &nbsp;
                          <button
                            type="reset"
                            className="btn btn-warning"
                            onClick={cancel}
                          >
                            Reset
                          </button>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default AddLabapprove;
