import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Navbarside from "../component/Navbarside";
import { getWmPackage, deleteWmPackage } from "../store/slices/wmPackage";
import Footer from "./footer";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import $ from "jquery";

const WmPackage = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getWmPackage())
      .unwrap()
      .then((data) => {
        $("#example").DataTable({
          data: data.user.data.map((e, index) => {
            return {
              index,
              ...e,
              category: e.expertiseId?.name,
              subCategory: e.subCategoryId?.name,
              session: e.sessionCount,
              duration: e.durationInDays,
            };
          }),
          columns: [
            {
              title: "#",
              render: (data, type, row) => {
                return ++row.index;
              },
            },
            {
              title: "Package Name",
              data: "name",
            },
            {
              title: "Category",
              data: "category",
              render: (data, type, row) => {
                return row.category ?? "";
              },
            },
            {
              title: "Sub Category",
              data: "subCategory",
              render: (data, type, row) => {
                return row.subCategory ?? "";
              },
            },
            {
              title: "Session",
              data: "session",
              render: (data, type, row) => {
                return row.session ?? "";
              },
            },
            {
              title: "Duration",
              data: "duration",
              render: (data, type, row) => {
                return row.duration ?? "";
              },
            },
            {
              title: "Status",
              render: (data, type, row) => {
                if (row.isActive === true) {
                  return `<button class="btn btn-success" style="height: 25px; padding: 0 10px">Active</button>`;
                } else {
                  return `<button class="btn btn-danger" style="height: 25px; padding: 0 10px">Inactive</button>`;
                }
              },
            },
            {
              title: "Action",
              render: (data, type, row) => {
                return `<button
                data-id="${row._id}"
                class="btn btn-warning delete"
                style="height: 25px; padding: 0 10px"
              >
                Delete
              </button>`;
              },
            },
          ],
          destroy: true,
        });
      })
      .catch(({ message }) => {
        // alert(message);
      });

    $("#example").on("click", ".delete", function (e) {
      e.stopPropagation();
      deleteData($(this).data("id"));
    });
  }, [dispatch]);

  const Delete = async function deletePlaylist(id) {
    localStorage.setItem("id", id);
    dispatch(deleteWmPackage())
      .unwrap()
      .then(() => {
        // alert("Uploaded succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });

    console.log(id);
  };
  const deleteData = (id) => {
    confirmAlert({
      title: "Want to delete?",
      message: "Are you sure you want to delete this package?",
      buttons: [
        {
          label: "Yes",
          onClick: () => Delete(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };
  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-wrap bar-success">
                          <h4 className="card-title">
                            Weight Management Package &nbsp; &nbsp;
                            <Link
                              to="/Weightmanagement/AddPackage"
                              className="btn btn-primary"
                            >
                              <i className="fa fa-plus"></i> Add
                            </Link>
                          </h4>
                        </div>
                      </div>
                      <div className="card-body collapse show">
                        <div className="card-block card-dashboard table-responsive">
                          <table id="example" className="display"></table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
};

export default WmPackage;
