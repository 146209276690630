import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import Navbarside from "./Navbarside";
import {
  getWomenSpecialSetting,
  deleteWomenSpecialSetting,
  editWomenSpecialSetting,
} from "../store/slices/womenSpecialSetting";
import Footer from "./footer";
import { Link } from "react-router-dom";

const WomenSpecialSetting = () => {
  const dispatch = useDispatch();
  const [settings, setSettings] = useState([]);

  React.useEffect(() => {
    dispatch(getWomenSpecialSetting())
      .unwrap()
      .then((data) => {
        setSettings(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  const Delete = async function (id) {
    localStorage.setItem("id", id);
    dispatch(deleteWomenSpecialSetting())
      .unwrap()
      .then(() => {
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  async function Update(id, isActive) {
    localStorage.setItem("id", id);
    let item = {
      set: {
        isActive,
      },
    };
    dispatch(editWomenSpecialSetting(item))
      .unwrap()
      .then(() => {
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const deleteData = (id) => {
    confirmAlert({
      title: "Want to delete?",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => Delete(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const updateStatus = (id, isActive) => {
    confirmAlert({
      title: "Want to update?",
      message: "Are you sure you want to update status?",
      buttons: [
        {
          label: "Yes",
          onClick: () => Update(id, isActive),
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-wrap bar-success">
                          <h4 className="card-title">
                            Women Special Setting &nbsp; &nbsp;
                            <Link
                              to="/AddWomenSpecialSetting"
                              className="btn btn-primary"
                            >
                              <i className="fa fa-plus"></i> Add
                            </Link>
                          </h4>
                        </div>
                      </div>
                      <div className="card-body collapse show">
                        <div className="card-block card-dashboard table-responsive">
                          <table className="table table-striped table-bordered zero-configuration">
                            <thead>
                              <tr>
                                <th>Type</th>
                                <th>Description</th>
                                <th>Status</th>
                                <th>Actions</th>
                              </tr>
                            </thead>

                            <tbody>
                              {settings.map((e, index) => (
                                <tr>
                                  <td>{e.type}</td>
                                  <td>{e.description}</td>
                                  <td>
                                    {e.isActive === true ? (
                                      <button
                                        className="btn btn-success"
                                        style={{
                                          height: "25px",
                                          padding: "0 10px",
                                        }}
                                        onClick={() =>
                                          updateStatus(e._id, !e.isActive)
                                        }
                                      >
                                        Active
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-warning"
                                        style={{
                                          height: "25px",
                                          padding: "0 10px",
                                        }}
                                        onClick={() =>
                                          updateStatus(e._id, !e.isActive)
                                        }
                                      >
                                        Deactive
                                      </button>
                                    )}
                                  </td>
                                  <td>
                                    <Link
                                      to={`/EditWomenSpecialSetting/${
                                        e._id ? e._id : null
                                      }`}
                                      className="btn btn-primary"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Edit
                                    </Link>{" "}
                                    <button
                                      onClick={() => deleteData(e._id)}
                                      className="btn btn-warning"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
};

export default WomenSpecialSetting;
