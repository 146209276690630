import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Navbarside from "./Navbarside";
import Footer from "./footer";
import { enableExpert } from "../store/slices/auth";
import { Link } from "react-router-dom";
import FilterComponent from "../component/FilterComponent";
import { ExportToExcel } from "./ExportToExcel";
import { API_BASE_URL } from "../utils/constants/api.constants";
import axios from "axios";
import { get } from "jquery";
import Loader from "./Loader";
import Pagination from "./common/Pagination";
const LifeSpanUserList = () => {
  const [Expertsdetils, setExpertsdetils] = useState([]);
  const dispatch = useDispatch();

  const [subExpertise, setSubExpertise] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setpage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);


  useEffect(() => {
    getLifeSpanUser();
  }, []);




  function getLifeSpanUser(page = 1) {
    const params = {
      lifespan: page
    };
    setIsLoading(true);
    axios.get(API_BASE_URL + "fetch/users", { params }).then((res) => {
      setIsLoading(false);

      setExpertsdetils(res?.data.data);

    });
  }

  return (
    <div>
      <Navbarside />
      {isLoading && <Loader />}

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Users</h4> &nbsp; &nbsp;
                        &nbsp;
                        {/* <ExportToExcel
                          apiData={data}
                          fileName={fileName}
                          style={{
                            height: "25px",
                            padding: "0 10px",
                            marginBottom: "0%",
                          }}
                        /> */}
                        &nbsp; &nbsp; &nbsp;
                        {/* <Link to="/AddExpert" className="btn btn-success">
                          Add Expert
                        </Link> */}
                      </div>
                    </div>
                    {/* <FilterComponent
                      onCategoryChange={handleExpertiseChange}
                      onApply={applyFilter}
                    /> */}
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Mobile Number</th>
                              <th>LifeSpan Patient Id</th>
                              {/* <th>Action</th> */}
                            </tr>
                          </thead>

                          <tbody>
                            {Expertsdetils.map((ExpertsVal, index) => (
                              <tr key={index}>
                                <td>
                                    {ExpertsVal.firstName} {ExpertsVal.lastName}       
                                </td>
                                <td>{ExpertsVal.email}</td>
                                <td>{ExpertsVal.mobileNo}</td>
                                <td>
                                  {ExpertsVal.lifeSpanPatientId}
                                  {/* {ExpertsVal.isAdminApproved === false && ( */}
                                  {/* <button */}
                                    {/* // onClick={() => */}
                                    {/* //   enableApprove(ExpertsVal._id)
                                    // }
                                    // className={ */}
                                    {/* //   ExpertsVal.isAdminApproved === false
                                    //     ? "btn btn-warning"
                                    //     : "btn btn-success"
                                    // }
                                    // style={{ */}
                                    {/* //   height: "25px",
                                    //   padding: "0 10px",
                                    //   cursor:"default"
                                    // }} */}
                                  {/* // >
                                  //   {ExpertsVal.isAdminApproved === false */}
                                  {/* //     ? "Disable"
                                  //     : "Verified"}
                                  // </button> */}
                                </td>
                                {/* <td>
                                  <Link
                                    to={`/EditExpert/${
                                      ExpertsVal._id ? ExpertsVal._id : null
                                    }`}
                                    className="btn btn-primary"
                                    style={{
                                      height: "25px",
                                      padding: "0 10px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    Edit Profile
                                  </Link>
                                  <button
                                    className="btn btn-danger"
                                    onClick={() => deleteData(ExpertsVal._id)}
                                    style={{
                                      height: "25px",
                                      padding: "0 10px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    Delete
                                  </button> */}
                                  &nbsp;
                                  {/* {ExpertsVal.isAdminApproved === true && ( */}
                                  {/* <button
                                    onClick={() =>
                                      ExpertsVal.isAdminApproved === true
                                        ? disableApprove(ExpertsVal._id)
                                        : enableApprove(ExpertsVal._id)
                                    }
                                    className={ExpertsVal.isAdminApproved === true ?"btn btn-warning" :"btn btn-success"}
                                    style={{
                                      height: "25px",
                                      padding: "0 10px",
                                      marginRight: "10px",
                                      marginTop:'-15px'
                                    }}
                                  >
                                    {ExpertsVal.isAdminApproved === true
                                      ? "Disable"
                                      : "Approve"}
                                  </button> */}
                           
                                {/* </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div className="PaginationContainer">
                          <span className="total-elements">
                            Showing {parseInt(page) * 10 + 1} to{" "}
                            {parseInt(page) * 10 + 10} of {totalCount} entries
                          </span>
                          <Pagination
                            currentPage={parseInt(page) + 1}
                            totalCount={totalCount}
                            itemsPerPage={10}
                            onPageChange={(page) => {
                              getLifeSpanUser(parseInt(page) - 1);
                            }}
                          />
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default LifeSpanUserList;
