import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import Navbarside from "../Navbarside";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../footer";
import { FaVideo } from "react-icons/fa";
import { API_PATHS, API_BASE_URL } from "../../utils/constants/api.constants";
import { post, get, put } from 'axios';
import { error } from "jquery";
import CircularProgress from '@mui/material/CircularProgress';
import VideoPlayer from '../videoPlayer/VideoPlayer';
import VideoFilePlayer from "../videoPlayer/videoFilePlayer";

const AddCategory = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const [id, setId] = useState("");
  const [data, setData] = useState('');

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [mediaLink, setmediaLink] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [contentType, setContentType] = useState('fileUpload');
  const [format, setFormat] = useState('videoLink');
  const [sequence, setSequence] = useState(1);
  const module = 'howTo'

  const [showLoader, setLoader] = useState(false);
  const navigate = useNavigate("");
  const goBack = () => navigate(-1);

  useEffect(() => {
    const { id } = params;

    if (id && !data) {
      setId(id)
      get(API_BASE_URL + '/get/' + module + '?id=' + id).then(res => {
        if (res.data?.data) {
          setData(res.data.data[0])

          setName(res.data.data[0].name)
          setDescription(res.data.data[0].description)
          setThumbnail(res.data.data[0].thumbnail)
          setSequence(res.data.data[0].sequence)
          setmediaLink(res.data.data[0].mediaLink)
          setFormat(res.data.data[0].format)
        }
      }).catch(err => alert(err.message))
    }
  }, [dispatch]);

  console.log({
    name,
    description,
    mediaLink,
    format,
    sequence,
    thumbnail
  })
  async function addData(e) {
    e.preventDefault();
    let data = JSON.parse(localStorage.getItem("user"));
    let userId = data.data.id;
    let userType = "user";
    let isActive = false;
    let item = {
      name,
      description,
      mediaLink,
      isActive,
      format,
      sequence,
      thumbnail
    };

    post(API_BASE_URL + 'post/' + module, item).then(res => {
      if (res.data.status === 1) alert(res.data.message)
      else throw res.data
    }).catch(ex => {
      alert(ex.message)
    })
  }
  async function updateData(e) {
    e.preventDefault();
    let item = {
      name,
      description,
      mediaLink,
      format,
      sequence,
      thumbnail
    };

    put(API_BASE_URL + 'put/' + module + '?id=' + id, { set: item }).then(res => {
      if (res.data.status === 1) alert(res.data.message)
      else throw res.data
    }).catch(ex => {
      alert(ex.message)
    })
  }
  const handleSelectChange = (e, type) => {
    if (type === 'format') {
      setFormat(e.target.value)
      if (e.target.value == 'externalLink') setContentType('videoLink')
      else setContentType('fileUpload')
    } else if (type == 'sequence') {
      setSequence(e.target.value)
    } else {
      setContentType(e.target.value)
    }
  }
  async function imageUpload(e, type) {
    setLoader(true)
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setLoader(false)
    if (type == 'mediaLink') setmediaLink(data.data.location);
    else setThumbnail(data.data.location)
  }
  function isVideoLink(url) {
    var videoExtensions = ['mp4', 'avi', 'wmv', 'mov', 'flv'];
    var fileExtension = url.split('.').pop();

    return {
      status: videoExtensions.includes(fileExtension),
      format: fileExtension
    }
  }

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        {showLoader ? <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </div> : ''}
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">
                          {id
                            ? `Edit 'How to' Video` : `Add 'How to' Video`}
                        </h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <form>
                        <div className="row">
                          {id
                            ? <><div className="col-md-12 col-sm-12">
                              <p>Selected Sequence : {sequence}</p>
                              <p>Existing Thumbnail : </p>
                              <img
                                src={thumbnail}
                                alt=""
                                style={{
                                  width: "150px",
                                  height: "100px",
                                }}
                              />
                              <p className="pt2">Existing content : </p>
                              {mediaLink?.includes('s3')
                                ? isVideoLink(mediaLink).status
                                  ? <VideoFilePlayer s3Link={mediaLink} format={isVideoLink(mediaLink).format} />
                                  : <img
                                    src={mediaLink}
                                    alt=""
                                    style={{
                                      width: "150px",
                                      height: "100px",
                                    }}
                                  />
                                : <VideoPlayer url={mediaLink} />
                              }
                            </div>

                            </>
                            : ''
                          }

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Name
                            </p>
                            <input
                              type="text"
                              placeholder="Enter content name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              className="form-control"
                            />
                          </div>

                          <div className="col-md-4 pt-2 pb-3">
                            <span className="label">Sequence</span>
                            <select
                              className="form-control"
                              onChange={e => handleSelectChange(e, 'sequence')}
                              required
                            >
                              <option value="1" selected disabled>Select / Update</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                          </div>

                          <div className="col-md-12 col-lg-12 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Description
                            </p>
                            <textarea
                              placeholder="Enter Description"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-12 pt-2">
                            <span className="label">Thumbnail </span>
                            <input
                              type="file"
                              className="form-control"
                              Placeholder="upload file"
                              name="thumbnail"
                              onChange={(e) => imageUpload(e)}
                            />
                          </div>

                          <div className="col-md-4 pt-2 pb-3">
                            <span className="label">Content Format</span>
                            <select
                              className="form-control"
                              onChange={e => handleSelectChange(e, 'format')}
                              required
                            >
                              <option value="video" selected>Video</option>
                              <option value="image">Image</option>
                              <option value="externalLink">External Link</option>
                            </select>
                          </div>


                          <div className="col-md-8 pt-2 pb-3">
                            <span className="label">Content </span>
                            {contentType == 'fileUpload' ?
                              <input
                                type="file"
                                className="form-control"
                                Placeholder="upload file"
                                name="mediaLink"
                                onChange={(e) => imageUpload(e, 'mediaLink')}
                              />
                              : <input
                                type="text"
                                className="form-control"
                                Placeholder="Enter content link"
                                name="format"
                                onChange={(e) => setmediaLink(e.target.value)}
                              />
                            }
                          </div>
                        </div>
                        <div className="d-flex justify-content-center pt-3">
                          <button
                            type="submit"
                            className="btn btn-primary wd-100"
                            onClick={id ? updateData : addData}
                            disabled={!mediaLink}
                          >
                            Submit
                          </button>
                          &nbsp; &nbsp; &nbsp;
                          <button
                            type="reset"
                            className="btn btn-warning wd-100"
                            onClick={goBack}
                          >
                            Back
                          </button>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default AddCategory;
