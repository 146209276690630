import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class fitnessplansService {
    
    
    static getFitnessCenterPlans() {
        let api = '';
        let id = JSON.parse(localStorage.getItem("user")).data.id;
          api = API_PATHS.getFitnessPlan + '?fcAdminId=' + id;
     
        localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static getFitnessCenterRevenue() {
        let api = '';
        let id = localStorage.getItem("fitnesscenterId");
          api = API_PATHS.fitnesscenterRevenue + '?fcAdminId=' + id;
     
        localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }


    static fitnessCenterPlans() {
        let api = '';
        let id = localStorage.getItem("fitnesscenterId");
          api = API_PATHS.getFitnessPlan + '?fcAdminId=' + id;
     
        localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static fitnessCenterPlansById(id) {
        let api = '';
          api = API_PATHS.getFitnessPlan + '?id=' + id;
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static putfitnesscenterPlan(item) {
        let api='';
        let id = localStorage.getItem("planid");
        api = API_PATHS.putFitnessPlan+  '?id=' + id ;
        return axios
          .put(api,item)
          .then((response) => {
            if (response.data) {
            
            }
            return response.data;
          });
      }

    static getFitnessCenterPlanDetails() {
        let api = '';
        let id = localStorage.getItem("planid");
          api = API_PATHS.getFitnessPlan + '?id=' + id;
     
        localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }
        
    static getFitnessPlanCategory() {
        let api = '';
     
          api = API_PATHS.fcPlannsCategory;
     
        localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }


   
  

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default fitnessplansService