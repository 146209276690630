import React from "react";
import Navbarside from "./Navbarside";
import Footer from "./footer";

const Appointment = () => {
  return (
    <div>
      <Navbarside />
      <div className="container-fluid response-cover pb-5">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <section
                className="app container pt-5 pb-5"
                style={{ alignItems: "center" }}
              >
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
                    <div className="col-lg-12 col-md-12 col-sm-12 white-shadow bg-color-blue">
                      <p className="a_text">Today's Appointment</p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
                    <div className="col-lg-12 col-md-12 col-sm-12 white-shadow bg-color-blue">
                      <p className="a_text">My Appointment</p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
                    <div className="col-lg-12 col-md-12 col-sm-12 white-shadow bg-color-blue">
                      <p className="a_text">
                        Today's Batchess <br />/ Upcomming Batches{" "}
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
                    <div className="col-lg-12 col-md-12 col-sm-12 white-shadow bg-color-blue">
                      <p className="a_text">Today's Session's</p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
                    <div className="col-lg-12 col-md-12 col-sm-12 white-shadow bg-color-blue">
                      <p className="a_text">Today's Payout Commission</p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
                    <div className="col-lg-12 col-md-12 col-sm-12 white-shadow bg-color-blue">
                      <p className="a_text"> Month till-date Payout</p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
                    <div className="col-lg-12 col-md-12 col-sm-12 white-shadow bg-color-blue">
                      <p className="a_text">Year till-date Payout</p>
                    </div>
                  </div>

                  {/* <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
    <div className='col-lg-12 col-md-12 col-sm-12 white-shadow' style={{backgroundColor:"#00bcd4"}}>
        <p className='a_text'>Amount Paid till date</p>
    </div>
</div> */}

                  <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
                    <div className="col-lg-12 col-md-12 col-sm-12 white-shadow bg-color-blue">
                      <p className="a_text">Balance Amount</p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
                    <div className="col-lg-12 col-md-12 col-sm-12 white-shadow bg-color-blue">
                      <p className="a_text">Active Subscription</p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
                    <div className="col-lg-12 col-md-12 col-sm-12 white-shadow bg-color-blue">
                      <p className="a_text">Expired Subscription</p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
                    <div className="col-lg-12 col-md-12 col-sm-12 white-shadow bg-color-blue">
                      <p className="a_text">
                        {" "}
                        Above to Expire <br /> Subscription{" "}
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
                    <div className="col-lg-12 col-md-12 col-sm-12 white-shadow bg-color-blue">
                      <p className="a_text">Total Live Members</p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
                    <div className="col-lg-12 col-md-12 col-sm-12 white-shadow bg-color-blue">
                      <p className="a_text">Today's Check-in's</p>
                    </div>
                  </div>

                  {/* <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
    <div className='col-lg-12 col-md-12 col-sm-12 white-shadow' style={{backgroundColor:"#ffeb3b"}}>
        <p className='a_text'>Active PT Subscription</p>
    </div>
</div> */}

                  <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
                    <div className="col-lg-12 col-md-12 col-sm-12 white-shadow bg-color-blue">
                      <p className="a_text">Diet Diary Template</p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
                    <div className="col-lg-12 col-md-12 col-sm-12 white-shadow bg-color-blue">
                      <p className="a_text">My Client's </p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 pt-2 pb-2">
                    <div className="col-lg-12 col-md-12 col-sm-12 white-shadow bg-color-blue">
                      <p className="a_text">Start Live Session's</p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Appointment;
