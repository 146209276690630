import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class ayurvedaplansService {
 
    static getayurvedaplans() {
        return axios
            .get(API_PATHS.getayurvedaplans)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }


    static postayurdedaplans(item) {
        return axios
            .post(API_PATHS.postayurvedaplans,
                item
            )
            .then((response) => {
                if (response.data) {
                     // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static editayurvedaplans(item) {
        let api = '';
        let id = localStorage.getItem("id");
          api = API_PATHS.putayurvedaplans + '?id=' + id ;
        localStorage.removeItem("id");
        return axios
            .put(api,item)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static deleteayurvedaplans() {
       let api = '';
       let id = localStorage.getItem("id");
         api = API_PATHS.deleteayurvedaplans + '?id=' + id ;
       localStorage.removeItem("id");
       return axios
           .delete(api)
           .then((response) => {
               if (response.data) {
                   // localStorage.setItem("user", JSON.stringify(response.data));
               }
               return response.data;
           });
   }

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default ayurvedaplansService;