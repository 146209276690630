import React from "react";
import Navbarside from "./Navbarside";
import { useDispatch } from "react-redux";
import { recipe, DeleteRecipe } from "../store/slices/recipe";
import { useState } from "react";
import Footer from "./footer";
import { useNavigate, useParams, Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

import { ExportToExcel } from "./ExportToExcel";
import { API_PATHS } from "../utils/constants/api.constants";
import axios from "axios";
const ExpertRecipes = () => {
  const [foodrecipes, setFoodRecipes] = useState([]);

  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const fileName = "WMRecipes";

  React.useEffect(() => {
    const fetchData = () => {
      axios.get(API_PATHS.getrecipes).then((postData) => {
        const customHeadings = postData.data.data.map((item) => ({
          Name: item.name,
          Status: item.description,
          Ingredients: item.description.ingredients,
          Method: item.description.method,
        }));

        setData(customHeadings);
      });
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    dispatch(recipe())
      .unwrap()
      .then((data) => {
        setFoodRecipes(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid response-cover pb-5">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Recipes </h4>
                        &nbsp; &nbsp; &nbsp;
                        <Link
                          to="/Weightmanagement/Recipes/AddRecipes"
                          className="btn btn-success col-white"
                        >
                          Add
                        </Link>
                        &nbsp; &nbsp; &nbsp;
                        <ExportToExcel apiData={data} fileName={fileName} />
                      </div>
                    </div>
                  </div>
                  <div className="card-body collapse show">
                    <div className="card-block card-dashboard table-responsive">
                      <table className="table table-striped table-bordered zero-configuration">
                        <thead>
                          <tr>
                            <th style={{ width: "30%" }}>Recipe Name</th>
                            {/* <th>Description</th> */}
                            <th>Image</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {foodrecipes.map((recipesVal, index) => (
                            <tr>
                              <td> {recipesVal.name}</td>
                              {/* <td>
                                    {recipesVal.description.substring(0, 150)}...
                                  </td> */}

                              <td>
                                <img
                                  src={recipesVal.mediaLink}
                                  alt=""
                                  style={{ height: "100px", width: "100px" }}
                                />
                              </td>
                              <td>
                                <Link
                                  to={`/Recipes/EditRecipes/${
                                    recipesVal._id ? recipesVal._id : null
                                  }`}
                                  className="btn btn-primary"
                                  style={{
                                    height: "25px",
                                    padding: "0 10px",
                                  }}
                                >
                                  Edit
                                </Link>{" "}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ExpertRecipes;
