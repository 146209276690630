import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import Navbarside from "../Navbarside";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../footer";
import { FaVideo } from "react-icons/fa";
import { API_PATHS, API_BASE_URL } from "../../utils/constants/api.constants";
import { post } from 'axios';
import { error } from "jquery";
import CircularProgress from '@mui/material/CircularProgress';

const AddCategory = () => {
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");
  const [mediaLink, setmediaLink] = useState("");
  const [contentType, setContentType] = useState('videoLink');
  const [format, setFormat] = useState('videoLink');


  const [showLoader, setLoader] = useState(false);

  const navigate = useNavigate("");
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();

  const goBack = () => navigate(-1);

  async function addData(e) {
    e.preventDefault();
    let data = JSON.parse(localStorage.getItem("user"));
    let userId = data.data.id;
    let userType = "user";
    let isActive = false;
    let item = {
      date,
      description,
      mediaLink,
      userId,
      userType,
      isActive,
      format
    };
    post(API_BASE_URL + 'post/communityPost', item).then(res => {
      if (res.data.status == 1) alert(res.data.message)
      else throw new error(res.data.error)
    }).catch(ex => {
      alert(ex.message)
    })
  }
  const handleSelectChange = (e, type) => {
    if (type == 'format') {
      setFormat(e.target.value)
    } else {
      setContentType(e.target.value)
    }
  }


  async function imageUpload(e) {
    setLoader(true)
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setLoader(false)
    setmediaLink(data.data.location);
    setMessage(data.message);
  }

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        {showLoader ? <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </div> : ''}
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">
                          Add Community post
                          {/* <Link
                              to="/UploadPackage"
                              className="btn btn-primary"
                            >
                              <i className="fa fa-plus"></i> Add
                            </Link> */}
                        </h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <form>
                        <div className="row">
                          <div className="col-md-12 col-lg-12 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Description
                            </p>
                            <textarea
                              placeholder="Enter the Description"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              className="form-control"
                            />
                          </div>

                          <div className="col-md-4 pt-2 pb-3">
                            <span className="label">Format</span>
                            <select
                              className="form-control"
                              onChange={e => handleSelectChange(e, 'format')}
                              required
                            >
                              <option value="video" selected>Video</option>
                              <option value="image">Image</option>
                              <option value="externalLink">External Link</option>
                            </select>
                          </div>


                          <div className="col-md-4 pt-2 pb-3">
                            <span className="label">Content type</span>
                            <select
                              className="form-control"
                              onChange={handleSelectChange}
                              required
                            >
                              <option value="videoLink" selected>Video Link</option>
                              <option value="fileUpload">File Upload</option>
                            </select>
                          </div>

                          <div className="col-md-6 col-lg-4 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Date
                            </p>
                            <input
                              type="date"
                              placeholder="Enter the date"
                              value={date}
                              onChange={(e) => setDate(e.target.value)}
                              className="form-control"
                            />
                          </div>

                          <div className="col-md-8 pt-2 pb-3">
                            <span className="label">Content </span>
                            {contentType == 'fileUpload' ?
                              <input
                                type="file"
                                className="form-control"
                                Placeholder="upload file"
                                name="mediaLink"
                                onChange={(e) => imageUpload(e)}
                              />
                              : <input
                                type="text"
                                className="form-control"
                                Placeholder="Enter content link"
                                name="format"
                                onChange={(e) => setmediaLink(e.target.value)}
                              />
                            }
                          </div>
                        </div>
                        <div className="d-flex justify-content-center pt-3">
                          <button
                            type="submit"
                            className="btn btn-primary wd-100"
                            onClick={addData}
                            disabled={!mediaLink}
                          >
                            Submit
                          </button>
                          &nbsp; &nbsp; &nbsp;
                          <button
                            type="reset"
                            className="btn btn-warning wd-100"
                            onClick={goBack}
                          >
                            Back
                          </button>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default AddCategory;
