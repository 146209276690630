import React, { useState } from "react";

import Navbarside from "./Navbarside";
import Footer from "./footer";
import { getworkoutplanDetails } from "../store/slices/workoutplan";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

const Workoutdetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  localStorage.setItem("workoutId", id);
  const [wokroutname, setWorkoutname] = useState("");
  const [workoutgoal, setWorkoutgoal] = useState("");
  const [workoutlevel, setWorkoutlevel] = useState("");
  const [daysInweek, setDaysInweek] = useState("");
  const [workdescription, setWorkdescription] = useState("");
  const [worktype, setWorktype] = useState("");
  const [workvalidityInDays, setWorkvalidityInDays] = useState("");

  // Schedule
  const [scheduleday, setScheduleday] = useState("");
  const [schedulenote, setSchedulenote] = useState("");
  const [scheduleorder, setScheduleorder] = useState("");

  // Exercise
  const [exercise, setExercise] = useState([]);

  React.useEffect(() => {
    dispatch(getworkoutplanDetails())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id === id) {
          setExercise(data.user.data[0].schedule);
          setWorkoutname(data.user.data[0].name);
          setWorkoutgoal(data.user.data[0].goal);
          setWorkoutlevel(data.user.data[0].level);
          setDaysInweek(data.user.data[0].daysInweek);
          setWorkdescription(data.user.data[0].description);
          setWorktype(data.user.data[0].type);
          setWorkvalidityInDays(data.user.data[0].validityInDays);
          setScheduleday(data.user.data[0].schedule[0].day);
          setSchedulenote(data.user.data[0].schedule[0].note);
          setScheduleorder(data.user.data[0].schedule[0].order);
        }
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row align-items-center">
                  <div className="col-lg-10 col-md-12 col-sm-12 pt-1 pb-1">
                    <div className="card-workout">
                      <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 text-para-p">
                          <p>
                            Workout plan name : &nbsp; &nbsp;
                            <span>{wokroutname}</span>
                          </p>
                          <p>
                            Goal : &nbsp; &nbsp;<span>{workoutgoal}</span>
                          </p>
                          <p>
                            Level : &nbsp; &nbsp;<span>{workoutlevel}</span>
                          </p>
                          <p>
                            Days in week : &nbsp; &nbsp;
                            <span>{daysInweek}</span>
                          </p>
                        </div>

                        <div className="col-lg-6 col-md-12 col-sm-12 text-para-p">
                          <p>
                            Description : &nbsp; &nbsp;
                            <span>{workdescription}</span>
                          </p>
                          <p>
                            Type : &nbsp; &nbsp;<span>{worktype}</span>
                          </p>
                          <p>
                            Validity in days : &nbsp; &nbsp;
                            <span>{workvalidityInDays}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container pt-4">
                  <h3 className="head-text">Schedule</h3>

                  {exercise.map((exerciseVal, index) => (
                    <div className="col-lg-10 col-md-12 col-sm-12 pt-1 pb-1">
                      <div className="card-workout">
                        <div className="d-flex align-items-center">
                          <p className="text-center bold-text">
                            {exerciseVal.day} Exercises
                          </p>
                        </div>
                        <br />
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Workout Name</th>
                              <th>Group</th>
                              <th>Round</th>
                            </tr>
                          </thead>

                          <tbody>
                            {exerciseVal.exercises.map((workVal, index) => (
                              <tr>
                                <td>{workVal.exerciseId.name}</td>
                                <td>{workVal.group}</td>
                                <td>{workVal.round}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
};

export default Workoutdetails;
