import React from "react";
import { Link } from "react-router-dom";
import Navbarside from "./Navbarside";
import Footer from "./footer";

const Payout = () => {
  return (
    <div>
      <Navbarside />

      <div className="container-fluid response-cover pb-5">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success">
                        <h4 className="card-title">
                          Month till-date Payout &nbsp; &nbsp;
                        </h4>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Client Name</th>
                              <th>Appointment Timing</th>
                              <th>Status</th>
                              <th>Problem Description</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td>test</td>
                              <td>test</td>
                              <td>test</td>
                              <td>test</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Payout;
