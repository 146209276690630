import React, { useState, useRef, useEffect } from 'react';
// import './MultiSelectDropdown.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faClose } from '@fortawesome/free-solid-svg-icons';

// const options = [
//   'Oliver Hansen',
//   'Van Henry',
//   'April Tucker',
//   'Ralph Hubbard',
//   'Omar Alexander',
//   'Carlos Abbott',
//   'Miriam Wagner',
//   'Bradley Wilkerson',
//   'Virginia Andrews',
//   'Kelly Snyder',
// ];

const MultiSelectDropdown = ({options,setSelected,selected}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(()=>{
  const a = options?.filter((data)=>selected?.includes(data?.id ))?.map((data)=>data?.name)
  setSelectedOptions(a)
  },[selected])

  // const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    if (selectedOptions.includes(option?.name)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option?.name));
      setSelected(selected.filter((item) => item !== option?.id))
    } else {
      setSelectedOptions([...selectedOptions, option?.name]);
      setSelected([...selected, option?.id])

    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const clearSelection = () => {
    setSelectedOptions([]);
    setSelected([])
  };

  const allSelected = options.length === selectedOptions.length;

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="custom-dropdown-container" ref={dropdownRef}>
      <div className="dropdown-header" onClick={()=>setIsOpen(!isOpen)}>
        {selectedOptions.length > 0
          ? selectedOptions?.join(', ')
          : 'Select options'}
        {isOpen ? (
          <FontAwesomeIcon icon={faChevronUp} className="dropdown-icon" />
        ) : (
          <FontAwesomeIcon icon={faChevronDown} className="dropdown-icon" />
        )}
      </div>
      {isOpen && (
        <div className="dropdown-list-new" >
          <div className="dropdown-option"
           onClick={clearSelection}
            >
            {selectedOptions?.length ? 'Clear All' : 'Select All'}
          </div>
          {options.map((option,key) => (
            <div
              key={key}
              className={`dropdown-option ${
                selectedOptions.includes(option?.name) ? 'selected' : ''
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {option?.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
