import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import wmPackageService from "../../services/wmPackage.service";
import AuthService from "../../services/auth.service";

export const getWmPackage = createAsyncThunk(
  "wmPackage/get",
  async (thunkAPI) => {
    try {
      const data = await wmPackageService.getWmPackage();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const postWmPackage = createAsyncThunk(
  "wmPackage/post",
  async (item, thunkAPI) => {
    try {
      const data = await wmPackageService.postWmPackage(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const editWmPackage = createAsyncThunk(
  "wmPackage/put",
  async (item, thunkAPI) => {
    try {
      const data = await wmPackageService.editWmPackage(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const deleteWmPackage = createAsyncThunk(
  "wmPackage/delete",
  async (thunkAPI) => {
    try {
      const data = await wmPackageService.deleteWmPackage();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

const initialState = {
  loading: false,
  error: "",
  user: AuthService.getUserDetails() || null,
  isLoggedIn: false,
};

const wmPackage = createSlice({
  name: "wmPackage",
  initialState,
  extraReducers: {
    [getWmPackage.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [getWmPackage.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [getWmPackage.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },

    [postWmPackage.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [postWmPackage.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [postWmPackage.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },

    [editWmPackage.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [editWmPackage.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [editWmPackage.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },

    [deleteWmPackage.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [deleteWmPackage.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [deleteWmPackage.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});

const { reducer } = wmPackage;
export default reducer;
