import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Navbarside from "../Navbarside";
import { Link, useLocation } from "react-router-dom";
import Footer from "../footer";
import { getMasterCategory } from "../../store/slices/livewell";
import { ExportToExcel } from "../ExportToExcel";
import { API_BASE_URL } from "../../utils/constants/api.constants";
import axios from "axios";
import sampleImage from '../../img/livewell.png'

function LiveWellCategory() {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const module = 'howTo'

  useEffect(() => {
    if (!data.length) {
      axios.get(`${API_BASE_URL}get/${module}`).then(res => {
        if (res.data?.data?.length) {
          setData(res.data.data)
        }
      }).catch(err => alert(err.message))
    }
  }, [dispatch]);

  const deleteData = (id) => {
    axios.delete(`${API_BASE_URL}/delete/${module}?id=${id}`).then(res => {
      if (res.data?.status === 1) alert('deleted successfully')
    }).catch(err => alert(err.message))
  }

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">
                          How To Videos
                        </h4>
                        &nbsp; &nbsp;
                        <Link
                          to={`/educate/add`}
                          className="btn btn-primary"
                          style={{ marginBottom: "0%" }}
                        >
                          <i className="fa fa-plus"></i> Add
                        </Link>{" "}
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Description</th>
                              <th>Sequence</th>
                              <th>Video</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((howTo, index) => (
                              <tr key={index}>
                                <td>{howTo.name}</td>

                                <td>{howTo.description}</td>
                                <td>{howTo.sequence}</td>

                                <td>
                                  <Link to={`/educate/view/${howTo._id}`} className="btn btn-warning">
                                    View
                                  </Link>
                                </td>

                                <td>
                                  <Link to={`/educate/edit/${howTo._id}`} className="btn btn-warning">
                                    Edit
                                  </Link>
                                  <button
                                    className="btn btn-danger"
                                    onClick={() => deleteData(howTo._id)}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
}

export default LiveWellCategory;