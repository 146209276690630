import React, { useState,useEffect } from "react";
import Navbarside from "../component/Navbarside";
import { useNavigate } from "react-router-dom";
import Footer from "./footer";
import { useDispatch } from "react-redux";
import { userDetails } from "./../store/slices/auth";
import { expiredSubscription } from "./../store/slices/client";
import { getlabtestresultDatewise } from "../store/slices/lab";
import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";
import Loader from "./Loader";
function Home() {
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const [enquiryCount, setEnquiryCount] = useState(0);
  let data = JSON.parse(localStorage.getItem("user"));
  let role = data.data.roles[0];
  let fname = data.data.firstName;
  let lname = data.data.lastName;

  const [fdate, setfDate] = useState("");
  const [tdate, settDate] = useState("");
  const [totlab, setTotLab] = useState("");
  const [dasData, setDasData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState("");
  const [userCount, setuserCount] = useState("");
  const [topExpertise, settopExpertise] = useState("");

  const myDatas = {
    fromDate: fdate,
    toDate: tdate,
  };

  // Current Date and Three Months Ago in ISO format
  let currentDate = new Date();
  let threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

  // Set initial values to current date in DD-MM-YYYY format
  useEffect(() => {
    setfDate(formatDate(currentDate));
    settDate(formatDate(currentDate));
    fetchDetails({
      startDate: formatDate(currentDate),
      toDate: formatDate(currentDate),
    });
    dispatch(getlabtestresultDatewise())
      .unwrap()
      .then((data) => {
        setTotLab(data.user.dataCount);
      })
      .catch(({ message }) => {
        // handle error
      });
  }, [dispatch]);

  const fetchDetails = async ({ startDate, toDate }) => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${API_PATHS.dashboard}?startDate=${startDate}&toDate=${toDate}`);
      setIsLoading(false);
      if (response) {
        setDasData(response?.data?.data);
        console.log("dasData", response);
      }
    } catch (err) {
      console.log("Error fetching data:", err);
    }
  };

  // Function to format date as DD-MM-YYYY
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so add 1
    const year = date.getFullYear();
    return `${month}-${day}-${year}`; // Format as DD-MM-YYYY
  };
 
  // Function to validate if the selected date is within 3 months range
  const validateDate = (selectedDate) => {
    const selected = new Date(selectedDate);
    if (selected < threeMonthsAgo || selected > currentDate) {
      alert("Date should be within the last 3 months.");
      return false;
    }
    return true;
  };

  const handleFromDateChange = (e) => {
    const newFromDate = e.target.value;
    if (validateDate(newFromDate)) {
      setfDate(formatDate(new Date(newFromDate)));
    }
  };

  const handleToDateChange = (e) => {
    const newToDate = e.target.value;
    if (validateDate(newToDate)) {
      settDate(formatDate(new Date(newToDate)));
    }
  };

  const getoDateEnquiry = (e) => {
    e.preventDefault();
    fetchDetails({ startDate: fdate, toDate: tdate });
    navigate("/Enquiry/", { state: myDatas });
  };




  React.useEffect(() => {
    dispatch(userDetails())
      .unwrap()
      .then((data) => {
        setDetails(data.user.data[0]);
        settopExpertise(data.user.data[0].topExpertise.name);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(expiredSubscription())
      .unwrap()
      .then((data) => {
        setuserCount(data.user.data.clientsCount);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);
  return (
    <div>
      <Navbarside />
      {isLoading && <Loader />}
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-6 col-12">
                  <div className="card bg-white">
                    <div className="card-body">
                      <div className="card-block pt-2 pb-0">
                        <div className="media">
                          <div className="media-body white text-left">
                            <h4 className="font-medium-5 card-title mb-0">
                              Welcome {fname} {lname}!
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div
                        id="Widget-line-chart"
                        className="height-70 lineChartWidget WidgetlineChart mb-2"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              {role === "ROLE_SUPERADMIN" && (
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.enquiries} Enquiry
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <form onSubmit={getoDateEnquiry}>
                            <div className="row pb-2">
                              <div className="col-md-4">
                                <p>From</p>
                                <input
                                  className="form-control"
                                  type="date"
                                  value={fdate}
                                  onChange={handleFromDateChange}
                                  max={currentDate.toISOString().slice(0, 10)}
                                  min={threeMonthsAgo.toISOString().slice(0, 10)}
                                 
                                />
                              </div>
                              <div className="col-md-4">
                                <p>To</p>
                                <input
                                  className="form-control"
                                  type="date"
                                  value={tdate}
                                  onChange={handleToDateChange}
                                  max={currentDate.toISOString().slice(0, 10)}
                                  min={threeMonthsAgo.toISOString().slice(0, 10)}
                               
                                />
                              </div>
                              <div className="col-md-4">
                                <p>&nbsp;</p>
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.openEnquiries} Open Enquiry
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  {dasData?.appointments} Appointments
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  {dasData?.sessions} Sessions
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.todaySales}
                              </h4>
                              <span className="grey darken-1">
                                Today's Sales
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-poll fa-edit-icon warning"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart1"
                          className="height-70 lineChartWidget WidgetlineChart1 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.thisMonthSales}
                              </h4>
                              <span className="grey darken-1">
                                This Month Sales
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-wallet fa-edit-icon info"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.totalPaid}
                              </h4>
                              <span className="grey darken-1">Total Paid</span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.activeSubscription}
                              </h4>
                              <span className="grey darken-1">
                                Active Subscription
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.expiredSubscription}
                              </h4>
                              <span className="grey darken-1">
                                Expired Subscription
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.todaysBirthday}
                              </h4>
                              <span className="grey darken-1">
                                Today's Birthday
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {/* {dasData?.appInstalls} */}
                                <a href={dasData?.androidInstalls} target="_blank" class="btn btn-primary">Android installs</a>
                                <a href={dasData?.iosInstalls} target="_blank" class="btn btn-primary">IOS installs</a>
                              </h4>
                              <span className="grey darken-1">

                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {topExpertise === "Health Care" && (
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <form>
                            <div className="row pb-2">
                              <div className="col-md-3">
                                <div className="media-body white text-left">
                                  <h4 className="font-medium-5 card-title mb-0">
                                    {dasData?.appointments} Appointments
                                  </h4>{" "}
                                  <span className="grey darken-1">Today</span>
                                </div>
                              </div>

                              <div className="col-md-3">
                                <p>From</p>
                                <input className="form-control" type="date" />
                              </div>
                              <div className="col-md-3">
                                <p>To</p>
                                <input className="form-control" type="date" />
                              </div>
                              <div className="col-md-3">
                                <p>&nbsp;</p>
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.todaySales}
                              </h4>
                              <span className="grey darken-1">
                                This Month Sales
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-wallet fa-edit-icon info"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.totalPaid}
                              </h4>
                              <span className="grey darken-1">Total Paid</span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.activeSubscription}
                              </h4>
                              <span className="grey darken-1">
                                Active Subscription
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.expiredSubscription}
                              </h4>
                              <span className="grey darken-1">
                                Expired Subscription
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {topExpertise === "Dietitian" && (
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <form>
                            <div className="row pb-2">
                              <div className="col-md-3">
                                <div className="media-body white text-left">
                                  <h4 className="font-medium-5 card-title mb-0">
                                    {dasData?.appointments} Appointments
                                  </h4>{" "}
                                  <span className="grey darken-1">Today</span>
                                </div>
                              </div>

                              <div className="col-md-3">
                                <p>From</p>
                                <input className="form-control" type="date" />
                              </div>
                              <div className="col-md-3">
                                <p>To</p>
                                <input className="form-control" type="date" />
                              </div>
                              <div className="col-md-3">
                                <p>&nbsp;</p>
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  15 Consultations
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  {dasData?.sessions} Sessions
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  15 Active Clients
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  15 Inactive Clients
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.thisMonthSales}

                              </h4>
                              <span className="grey darken-1">
                                This Month Sales
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-wallet fa-edit-icon info"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.totalPaid}
                              </h4>
                              <span className="grey darken-1">Total Paid</span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.activeSubscription}
                              </h4>
                              <span className="grey darken-1">
                                Active Subscription
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.expiredSubscription}
                              </h4>
                              <span className="grey darken-1">
                                Expired Subscription
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {topExpertise === "Physiotherapy" && (
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <form>
                            <div className="row pb-2">
                              <div className="col-md-3">
                                <div className="media-body white text-left">
                                  <h4 className="font-medium-5 card-title mb-0">
                                    {dasData?.appointments} Appointments
                                  </h4>{" "}
                                  <span className="grey darken-1">Today</span>
                                </div>
                              </div>

                              <div className="col-md-3">
                                <p>From</p>
                                <input className="form-control" type="date" />
                              </div>
                              <div className="col-md-3">
                                <p>To</p>
                                <input className="form-control" type="date" />
                              </div>
                              <div className="col-md-3">
                                <p>&nbsp;</p>
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  15 Consultations
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  {dasData?.sessions} Sessions
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  15 Active Clients
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  15 Inactive Clients
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.thisMonthSales}

                              </h4>
                              <span className="grey darken-1">
                                This Month Sales
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-wallet fa-edit-icon info"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.totalPaid}
                              </h4>
                              <span className="grey darken-1">Total Paid</span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.activeSubscription}
                              </h4>
                              <span className="grey darken-1">
                                Active Subscription
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.expiredSubscription}
                              </h4>
                              <span className="grey darken-1">
                                Expired Subscription
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {topExpertise === "Fitness" && (
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <form>
                            <div className="row pb-2">
                              <div className="col-md-3">
                                <div className="media-body white text-left">
                                  <h4 className="font-medium-5 card-title mb-0">
                                    {dasData?.appointments} Appointments
                                  </h4>{" "}
                                  <span className="grey darken-1">Today</span>
                                </div>
                              </div>

                              <div className="col-md-3">
                                <p>From</p>
                                <input className="form-control" type="date" />
                              </div>
                              <div className="col-md-3">
                                <p>To</p>
                                <input className="form-control" type="date" />
                              </div>
                              <div className="col-md-3">
                                <p>&nbsp;</p>
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  15 Consultations
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  {dasData?.sessions} Sessions
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  15 Active Clients
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  15 Inactive Clients
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.thisMonthSales}

                              </h4>
                              <span className="grey darken-1">
                                This Month Sales
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-wallet fa-edit-icon info"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.totalPaid}
                              </h4>
                              <span className="grey darken-1">Total Paid</span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.activeSubscription}
                              </h4>
                              <span className="grey darken-1">
                                Active Subscription
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.expiredSubscription}
                              </h4>
                              <span className="grey darken-1">
                                Expired Subscription
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {role === "ROLE_LABVENDOR" && (
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <form>
                            <div className="row pb-2">
                              <div className="col-md-3">
                                <div className="media-body white text-left">
                                  <h4 className="font-medium-5 card-title mb-0">
                                    {dasData?.appointments} Appointments
                                  </h4>{" "}
                                  <span className="grey darken-1">Today</span>
                                </div>
                              </div>

                              <div className="col-md-3">
                                <p>From</p>
                                <input className="form-control" type="date" />
                              </div>
                              <div className="col-md-3">
                                <p>To</p>
                                <input className="form-control" type="date" />
                              </div>
                              <div className="col-md-3">
                                <p>&nbsp;</p>
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.thisMonthSales}

                              </h4>
                              <span className="grey darken-1">
                                This Month Sales
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-wallet fa-edit-icon info"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.totalPaid}
                              </h4>
                              <span className="grey darken-1">Total Paid</span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                Total Bookings
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {role === "ROLE_FITNESSCENTERADMIN" && (
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.thisMonthSales}

                              </h4>
                              <span className="grey darken-1">
                                This Month Sales
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-wallet fa-edit-icon info"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dasData?.totalPaid}
                              </h4>
                              <span className="grey darken-1">Total Paid</span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                Total Bookings
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Home;
