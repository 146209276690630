import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./slices/auth";
import analysisReducer from "./slices/analysis";
import blogReducer from "./slices/blog";
import bodypartReducer from "./slices/body";
import challengeReducer from "./slices/challenges";
import clientReducer from "./slices/client";
import communityReducer from "./slices/Community";

const reducer = {
  auth: authReducer,
  analysis:analysisReducer,
  blog:blogReducer,
  bodyparts:bodypartReducer,
  challenge:challengeReducer,
  client:clientReducer,
  community:communityReducer,
}




const store = configureStore({
    reducer: reducer,
    devTools: true,
})

export default store;