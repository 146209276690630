import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import fitnesscenterService from "../../services/fitnesscenter.service";
import AuthService from "../../services/auth.service";


export const getfitnessServices = createAsyncThunk(
    "fitness/get/fitness",
    async(thunkAPI) => {
        try {
            const data = await fitnesscenterService.getfitnessService();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)



export const getfitnesscenterEnquiry = createAsyncThunk(
    "fitness/get/fitness",
    async(thunkAPI) => {
        try {
            const data = await fitnesscenterService.getfitnesscenterRegister(thunkAPI);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)

export const getfitcenter = createAsyncThunk(
    "fitness/get/fitness",
    async(thunkAPI) => {
        try {
            const data = await fitnesscenterService.getfitnesscenters();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)

export const getfitcenterDetails = createAsyncThunk(
    "fitness/get/fitness",
    async(thunkAPI) => {
        try {
            const data = await fitnesscenterService.getfitnesscenterDetails();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)

export const getVendorfitcenter = createAsyncThunk(
    "fitness/get/fitness",
    async(thunkAPI) => {
        try {
            const data = await fitnesscenterService.getVendorfitnesscenters();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)

export const getfitnessrequest = createAsyncThunk(
    "fitness/get/fitness",
    async(thunkAPI) => {
        try {
            const data = await fitnesscenterService.getfitnesscenterrequest();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)

export const ApprovefitnessEnquiry = createAsyncThunk(
    "fitness/post/fitness",
    async(item, thunkAPI) => {
        try {
            const data = await fitnesscenterService.ApprovefitnessEnquiry(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
  );

  export const addfitnesscenter = createAsyncThunk(
    "fitness/put/fitness",
    async(item, thunkAPI) => {
        try {
            const data = await fitnesscenterService.postfitnesscenters(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
  );

  export const addfitnesscenterPlan = createAsyncThunk(
    "fitness/put/fitness",
    async(item, thunkAPI) => {
        try {
            const data = await fitnesscenterService.postfitnesscenterPlan(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
  );

  export const editfitnesscenterPlan = createAsyncThunk(
    "fitness/put/fitness",
    async(item, thunkAPI) => {
        try {
            const data = await fitnesscenterService.putfitnesscenterPlan(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
  );

  export const ApproveRequestFitnesss = createAsyncThunk(
    "fitness/put/fitness",
    async(item, thunkAPI) => {
        try {
            const data = await fitnesscenterService.ApprovefitnessRequest(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
  );

  export const getAmenities = createAsyncThunk(
    "fitness/get/fitness",
    async(thunkAPI) => {
        try {
            const data = await fitnesscenterService.getAmenties();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)

export const addAmenities = createAsyncThunk(
    "fitness/put/fitness",
    async(item, thunkAPI) => {
        try {
            const data = await fitnesscenterService.postAmenities(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
  );

// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
    loading: false,
    error: "",
    user: AuthService.getUserDetails() || null,
    isLoggedIn: false,
};

const fitnessSlice = createSlice({
    name: "fitness",
    initialState,
    extraReducers: {
        [getfitnesscenterEnquiry.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getfitnesscenterEnquiry.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getfitnesscenterEnquiry.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },

     


        [ApprovefitnessEnquiry.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [ApprovefitnessEnquiry.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [ApprovefitnessEnquiry.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },

        [getfitnessrequest.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getfitnessrequest.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getfitnessrequest.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },

        [ApproveRequestFitnesss.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [ApproveRequestFitnesss.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [ApproveRequestFitnesss.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },

        [getfitcenter.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getfitcenter.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getfitcenter.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },

        // [logout.fulfilled]: (state, action) => {
        //   state.isLoggedIn = false;
        //   state.user = null;
        // },
    },
});

const { reducer } = fitnessSlice;
export default reducer;