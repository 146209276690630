import axios from "axios";
import { API_BASE_URL, API_PATHS } from "../utils/constants/api.constants";

class AnalysisService {

  static getSubscription(params) {
    // let url = 'http://localhost:3310/fetch/subscription'
    let url = `${API_BASE_URL}fetch/subscription`
    return axios.get(
      url,
      { params }
    ).then(response => response.data);
  }

  static getSessions(params) {
    // let url = 'http://localhost:3310/fetch/subscription'
    let url = `${API_BASE_URL}fetch/session`
    return axios.get(
      url,
      { params }
    ).then(response => response.data);
  }

  static salesAnalysis(params){
    let api = '';
    api = API_PATHS.salesAnalysisDetail
    return axios
      .get(api,{params})
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  } 

  static GSTRecon(params){
    let api = '';
    api = API_PATHS.GSTRecon
    return axios
      .get(api,{params})
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static salesReport(params){
    let api = '';
    api = API_PATHS.salesReport
    return axios
      .get(api,{params})
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  } 

  static exportRecon(params){
    let api = '';
    api = API_PATHS.exportRecon
    return axios
      .get(api,{params})
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  } 


  static ConversionAnalysis(params){
    let api = '';
    api = API_PATHS.ConversionAnalysisDetails
    return axios
      .get(api,{params})
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }
  
  static enquiryAnalysis(params){
    let api = '';
    api = API_PATHS.enquiryAnalysisDetails
    return axios
      .get(api,{params})
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  } 
  static appointmentAnalysis(params) {
    let api = '';
    api = API_PATHS.appointmentAnalysisDetail;
    return axios
      .get(api,{params})
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static sessionAnalysis(params) {
    let api = '';
    let item = JSON.parse(localStorage.getItem("item"));
    api = API_PATHS.sessionAnalysisDetail;
    return axios
      .get(api,{params})
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response;
      });
  }

  static gstAnalysis() {
    let api = '';
    let item = JSON.parse(localStorage.getItem("item"));
    api = API_PATHS.accountReport + '?startDate=' + item.startDate + '&endDate=' + item.endDate;
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }





  static removeUserDetails() {
    localStorage.removeItem("user");
  }

  static getUserDetails() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default AnalysisService;
