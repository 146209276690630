import React, { useState } from "react";

import Navbarside from "./Navbarside";
import Footer from "./footer";
import { getdietDetails } from "../store/slices/diet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

const DietPlanDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  localStorage.setItem("dietPlanId", id);
  const [wokroutname, setDietname] = useState("");
  const [workoutgoal, setWorkoutgoal] = useState("");
  const [workoutlevel, setWorkoutlevel] = useState("");
  const [daysInweek, setDaysInweek] = useState("");
  const [workdescription, setNote] = useState("");
  const [worktype, setWorktype] = useState("");
  const [workvalidityInDays, setWorkvalidityInDays] = useState("");

  // Exercise
  const [exercise, setExercise] = useState([]);

  React.useEffect(() => {
    dispatch(getdietDetails())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id === id) {
          setExercise(data.user.data[0].weeklyDetails);
          setDietname(data.user.data[0].name);
          setWorkoutgoal(data.user.data[0].goal);
          setWorkoutlevel(data.user.data[0].level);
          setDaysInweek(data.user.data[0].daysInweek);
          setNote(data.user.data[0].note);
          setWorktype(data.user.data[0].type);
          setWorkvalidityInDays(data.user.data[0].validity);
        }
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row align-items-center">
                  <div className="col-lg-10 col-md-12 col-sm-12 pt-1 pb-1">
                    <div className="card-workout">
                      <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 text-para-p">
                          <p>
                            Diet plan name : &nbsp; &nbsp;
                            <span>{wokroutname}</span>
                          </p>
                          <p>
                            Goal : &nbsp; &nbsp;<span>{workoutgoal}</span>
                          </p>
                          <p>
                            Level : &nbsp; &nbsp;<span>{workoutlevel}</span>
                          </p>
                        </div>

                        <div className="col-lg-6 col-md-12 col-sm-12 text-para-p">
                          <p>
                            Note : &nbsp; &nbsp;
                            <span>{workdescription}</span>
                          </p>
                          <p>
                            Type : &nbsp; &nbsp;<span>{worktype}</span>
                          </p>
                          <p>
                            Validity in days : &nbsp; &nbsp;
                            <span>{workvalidityInDays} Days</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container pt-2">
                  <h3 className="head-text">Schedule</h3>

                  {exercise.map((exerciseVal, index) => (
                    <div className="col-lg-10 col-md-12 col-sm-12">
                      <div className="card-workout">
                        <div className="d-flex align-items-center">
                          <p className="text-center bold-text">
                            {exerciseVal.day}{" "}
                          </p>
                        </div>
                        <hr />
                        <div className="row">
                          {exerciseVal.meals.map((workVal, index) => (
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-4">
                                  <p> Meal: {workVal.mealName}</p>
                                </div>
                                <div className="col-md-4">
                                  <p> Time: {workVal.mealTime}</p>
                                </div>
                              </div>
                            </div>

                            // <table className="table table-striped table-bordered zero-configuration">
                            //   <thead>
                            //     <tr>
                            //       <th>Dish Name</th>
                            //       <th>Quantity</th>
                            //     </tr>
                            //   </thead>

                            //   <tbody>
                            //     {workVal.dishes.map((dishVal, index) => (
                            //       <tr>
                            //         <td>{dishVal.dishId.name}</td>
                            //         <td>{dishVal.quantity}</td>
                            //       </tr>
                            //     ))}
                            //   </tbody>
                            // </table>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
};

export default DietPlanDetails;
