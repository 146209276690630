import React from "react";
import Navbarside from "./Navbarside";
import { useDispatch } from "react-redux";
import {
    getfitnessrequest,
    ApproveRequestFitnesss,
} from "../store/slices/fitness";
import { useState } from "react";
import Footer from "./footer";
import { useNavigate, useParams, Link, } from "react-router-dom";


const FitnessCenterRequest = () => {
  const [fitnessrequest, setFitnessrequest] = useState([]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getfitnessrequest())
      .unwrap()
      .then((data) => {
        setFitnessrequest(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  async function disableApprove(ids) {
        let status = "rejected";
        let id = ids;
        let item = { id, status };
        localStorage.setItem("item", JSON.stringify(item));
        dispatch(ApproveRequestFitnesss(item))
          .unwrap()
          .then(() => {
            alert("Request rejected succesfully");
            // window.location.reload(false);
          })
          .catch(({ message }) => {
            alert(message);
          });
        console.log(item);
        console.log(id);
      }

//   async function enableApprove(ids) {
//     let status = "approved";
//     let id = ids;
//     let item = { id, status };
//     dispatch(ApprovefitnessEnquiry(item))
//       .unwrap()
//       .then(() => {
//         alert("Approved succesfully");
//         // window.location.reload(false);
//       })
//       .catch(({ message }) => {
//         alert(message);
//       });
//     console.log(item);
//     console.log(id);
//   }

//   async function disableApprove(ids) {
//     let status = "rejected";
//     let id = ids;
//     let item = { id, status };
//     localStorage.setItem("item", JSON.stringify(item));
//     dispatch(ApprovefitnessEnquiry(item))
//       .unwrap()
//       .then(() => {
//         alert("Request rejected succesfully");
//         // window.location.reload(false);
//       })
//       .catch(({ message }) => {
//         alert(message);
//       });
//     console.log(item);
//     console.log(id);
//   }

  return (
    <div>
      <Navbarside />

      <div className="container-fluid response-cover pb-5">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Fitness Center Request</h4>
                        &nbsp; &nbsp; &nbsp;
                        {/* <Link to="/AddLabvendors" className="btn btn-success col-white">Add</Link>*/}
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Fitness Center Name</th>
                              <th>Email</th>

                              <th>Mobile No.</th>
                              <th>About</th>
                              <th>Fitness Center Address</th>
                              <th>Status</th>
                              <th>Action</th>
                              
  {/* <th>Status</th>*/}

                            </tr>
                          </thead>

                          <tbody>
                            {fitnessrequest.map((fitnessVal, index) => (
                              <tr key={index}>
                                
                                <td>{fitnessVal.fcName}</td>
                                <td>{fitnessVal.fcEmail}</td>
                                <td>{fitnessVal.fcMobileNo}</td>
                                <td>{fitnessVal.about.substring(0, 60)}</td>
                                <td>
                                {fitnessVal.fcAddress.doorNoAndStreetName},<br/> 
                                {fitnessVal.fcAddress.city},<br/> 
                                {fitnessVal.fcAddress.state}, {fitnessVal.fcAddress.pincode}.</td>

                                <td className="d-flex justify-content-center">
                                <Link   to={`/FitnessrequestApprove/${
                                    fitnessVal._id ? fitnessVal._id : null
                                  }`} className="btn btn-warning">Approve</Link>
                                &nbsp;
                                &nbsp;
                                
                                {fitnessVal.status ===
                                    "fitnessCenterRegistrationRequest" && (
                                    <button
                                      className="btn btn-warning"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                      onClick={() => disableApprove(fitnessVal._id)}
                                    >
                                      Reject
                                    </button>
                                  )}
                                </td>

                                
                                 <td>
                                <Link to="" className="btn btn-success">Yes</Link>
                                &nbsp;
                                &nbsp;
                                </td>
                              
                        {  /*  <td>
                                  {fitnessVal.status ===
                                    "fitnessCenterRegistrationRequest" && (
                                    <button
                                      className="btn btn-primary"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                      onClick={() => enableApprove(fitnessVal._id)}
                                    >
                                      Approve
                                    </button>
                                  )}
                                  &nbsp;                                  &nbsp;                                  &nbsp;
                                  {fitnessVal.status ===
                                    "fitnessCenterRegistrationRequest" && (
                                    <button
                                      className="btn btn-warning"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                      onClick={() => disableApprove(fitnessVal._id)}
                                    >
                                      Reject
                                    </button>
                                  )}
                                  &nbsp;                                  &nbsp;                                  &nbsp;
                                  {fitnessVal.status === "rejected" && (
                                    <button
                                      className="btn btn-warning"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Rejected
                                    </button>
                                  )}
                                  {fitnessVal.status === "approved" && (
                                    <button
                                      className="btn btn-success"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Approved
                                    </button>
                                  )}
                                    </td>*/}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default FitnessCenterRequest;
