import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Navbarside from "../Navbarside";
import { useParams, useLocation } from "react-router-dom";
import Footer from "../footer";
import { Card } from "@mui/material";
import { get, post } from "axios";
import { API_BASE_URL, API_PATHS } from "../../utils/constants/api.constants";
import { Link } from "react-router-dom";
import Loader from "../Loader";

const ViewSubscription = () => {
  const [subscription, setSubscription] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const { ticketNumber } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const flow = searchParams.get("flow");

  useEffect(() => {
    if (!Object.keys(subscription).length) {
      setIsLoading(true);
      getSubscription(flow)
        .then((subscription) => {
          setIsLoading(false);
          subscription = subscription.data.data;
          // setSubscription(subscription)
          subscription.serviceDetails.forEach(async (service, i) => {
            let experts = await get(
              `${API_BASE_URL}get/user?topExpertise=${service.serviceId}`
            );
            subscription.serviceDetails[i].experts = experts.data.data;
            if (i === subscription.serviceDetails.length - 1) {
              setSubscription(subscription);
            }
          });
        })
        .catch((err) => {
          setIsLoading(false);
          alert(err.message);
        });
    }
  }, [dispatch]);

  const getSubscription = async (flow) => {
    return await get(`${API_BASE_URL}fetch/subscription`, {
      params: {
        ticketNumber,
        flow,
      },
    });
  };

  const assignExpert = (e) => {
    let { value } = e.target;
    console.log(JSON.parse(value));
    let { serviceName, id, firstName, lastName, email } = JSON.parse(value);
    subscription.serviceDetails = subscription.serviceDetails.map((service) => {
      if (!service.expertId && service.serviceName === serviceName) {
        service.expertId = {
          _id: id,
          firstName,
          lastName,
        };
      }
      return service;
    });
    // console.log(subscription)

    setSubscription(subscription);
    setIsLoading(true)
    post(`${API_BASE_URL}service/assignExpert`, {
      ticketNumber,
      flow: serviceName,
      expertId: id,
      email,
    })
      .then((resp) => {
      setIsLoading(false)
        if (resp.data?.data) alert(resp.data.message);
      })
      .catch((err) => {
      setIsLoading(false)
        alert(err.message)});

    //   setSubscription(subscription)
    // }).catch(err => alert(err.message))
  };

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />
      {isLoading && <Loader/>}
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">Subscription details</h4>
                      </div>
                    </div>

                    <section className="form-section">
                      {subscription && Object.keys(subscription).length
                        ? Object.keys(subscription).map((x, i) => {
                            if (
                              x != "_id" &&
                              x != "__v" &&
                              x != "userId" &&
                              x != "expertId" &&
                              x != "packageId" &&
                              x != "serviceDetails" &&
                              x != "updated_at" &&
                              x != "subExpertiseId" &&
                              x != "userId" &&
                              x != "isActive" &&
                              x != "availableConsultations" &&
                              x != "paymentId" &&
                              x != "paymentLink" &&
                              x != "startDate"
                            ) {
                              return (
                                <p>
                                  {" "}
                                  {x} : {subscription[x]}
                                </p>
                              );
                            }
                          })
                        : ""}
                    </section>
                  </div>

                  <h3>Session</h3>
                  {subscription && Object.keys(subscription).length
                    ? Object.keys(subscription).map((x, i) => {
                        if (
                          x === "serviceDetails" &&
                          Array.isArray(subscription[x])
                        ) {
                          let serviceDetails = subscription[x];
                          return serviceDetails.map((service, j) => {
                            return (
                              <Card
                                key={service.serviceName}
                                style={{ padding: "10px" }}
                              >
                                <div className="row">
                                  <div className="col-md-3">
                                    {service.serviceName}
                                  </div>
                                  <div className="col-md-2">
                                    {service.count} sessions
                                  </div>
                                  <div className="col-md-2">
                                    <select
                                      key={service.serviceName}
                                      name={service.serviceName}
                                      onChange={assignExpert}
                                    >
                                      <option key="0" disabled selected>
                                        Select/change Expert
                                      </option>
                                      {service?.experts?.map((expert) => {
                                        let {
                                          _id: id,
                                          firstName,
                                          lastName,
                                          email,
                                        } = expert;
                                        let { serviceName, serviceId } =
                                          service;
                                        return (
                                          <option
                                            id={lastName}
                                            key={firstName}
                                            value={JSON.stringify({
                                              id,
                                              firstName,
                                              lastName,
                                              email,
                                              serviceName,
                                              serviceId,
                                            })}
                                          >
                                            {expert.firstName} {expert.lastName}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  <div className="col-md-2">
                                    <p>
                                      Selected Expert :{" "}
                                      {service.expertId
                                        ? `${service.expertId.firstName} ${service.expertId.lastName}`
                                        : "None selected"}
                                    </p>
                                  </div>
                                  <div>
                                    <Link
                                      to={`/ListSessions?ticketNumber=${subscription.ticketNumber}&flow=${service.serviceName}`}
                                    >
                                      <button class="btn btn-success">
                                        View Sessions
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </Card>
                            );
                          });
                        }
                      })
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default ViewSubscription;
