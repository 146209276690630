import React from "react";
import Navbarside from "./Navbarside";
import { useDispatch } from "react-redux";
import { weightLogData } from "../store/slices/forms";
import { useState } from "react";
import Footer from "./footer";
import Moment from "moment";
import { MdVideoCameraFront } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";

const UserWeightLog = () => {
  const { id } = useParams();
  localStorage.setItem("clientId", id);
  const [WeightLog, setWeightLog] = useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(weightLogData())
      .unwrap()
      .then((data) => {
        setWeightLog(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid response-cover pb-5">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">User Weight Log</h4>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Weight (In Kg)</th>
                            </tr>
                          </thead>

                          <tbody>
                            {WeightLog.map((DoctorAppointmentVal, index) => (
                              <tr key={index}>
                                <td>
                                  {Moment(DoctorAppointmentVal.date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                                <td>{DoctorAppointmentVal.weight} Kg</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default UserWeightLog;
