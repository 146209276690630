import React from "react";
import Navbarside from "./Navbarside";
import { useDispatch } from "react-redux";
import { getPatientConsultation } from "../store/slices/HcConsultation";
import { useState } from "react";
import Footer from "./footer";
import Moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";

const PatientConsultation = () => {
  const [consultation, setConsultation] = useState([]);

  const dispatch = useDispatch();
  const { id } = useParams();
  localStorage.setItem("patientId", id);

  React.useEffect(() => {
    dispatch(getPatientConsultation())
      .unwrap()
      .then((data) => {
        setConsultation(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid response-cover pb-5">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Appointments</h4>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Client Name</th>
                              <th>Issue</th>
                              <th>Consultation Details</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {consultation.map(
                              (PatientConsultationVal, index) => (
                                <tr key={index}>
                                  <td>
                                    <Link
                                      to={`/ClientDetails/${PatientConsultationVal.userId[0]._id}`}
                                    >
                                      {
                                        PatientConsultationVal.userId[0]
                                          .firstName
                                      }
                                      &nbsp;
                                      {
                                        PatientConsultationVal.userId[0]
                                          .lastName
                                      }
                                    </Link>
                                  </td>

                                  <td>{PatientConsultationVal.description}</td>
                                  <td>
                                    Consultation Date:{" "}
                                    {Moment(
                                      PatientConsultationVal.startDate
                                    ).format("DD-MM-YYYY")}
                                    <br />
                                    Consultation Time:
                                    {PatientConsultationVal.startTime}
                                  </td>
                                  <td>
                                    {PatientConsultationVal.status ===
                                      "scheduled" && (
                                      <a
                                        href={`https://meet.jit.si/${
                                          PatientConsultationVal._id
                                            ? PatientConsultationVal._id
                                            : null
                                        }`}
                                        target="_blank"
                                        className="btn btn-success"
                                      >
                                        Join
                                      </a>
                                    )}
                                    &nbsp;{" "}
                                    {PatientConsultationVal.status !=
                                      "paymentRequested" && (
                                      <Link
                                        to={`/AddPrescription/${
                                          PatientConsultationVal._id
                                            ? PatientConsultationVal._id
                                            : null
                                        }`}
                                        className="btn btn-primary mt-2"
                                      >
                                        Add Prescription
                                      </Link>
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PatientConsultation;
