import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Navbarside from "./Navbarside";
import { useDispatch, useSelector } from "react-redux";
import { getHcConsultation } from "../store/slices/HcConsultation";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "./footer";
import { useDownloadExcel } from "react-export-table-to-excel";
import { excelDownload, limitForPagination } from "../utils/appUtils";
import Loader from "./Loader";
import Pagination from "./common/Pagination";

const HealthCareConsultation = () => {
  const [hconsultation, setHconsultation] = useState([]);
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setpage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [row, setRow] = useState([]);

  React.useEffect(() => {
    fetchData();
  }, [dispatch]);

  const fetchData = (page = 0) => {
    setIsLoading(true);
    dispatch(getHcConsultation({ page: page, limit: limitForPagination() }))
      .unwrap()
      .then((data) => {
        setIsLoading(false)
        setpage(page);
        setTotalCount(data?.user?.totalCount);
        const customRow = data?.user?.data?.map((value) => {
          let newObj = {
            User: value.userId[0]?.firstName + " " + value?.userId[0]?.lastName,
            Expert: Array?.isArray(value.expertId)
              ? value.expertId[0]?.firstName + "" + value?.expertId[0]?.lastName
              : "",
            Expertise: value?.expertiseId[0]?.name,
            Description: value?.description,
            Date: value?.startDate,
            Time: value?.startTime,
          };
          return newObj
        });
        setRow(customRow)
        setHconsultation(data.user.data);
      })
      .catch(({ message }) => {
        setIsLoading(false)
        // alert(message);
      });
  };
  // const { onDownload } = useDownloadExcel({
  //   currentTableRef: tableRef.current,
  //   filename: "Ayurveda Enquiries",
  //   sheet: "Sheet1",
  // });
  return (
    <div>
      <Navbarside />
      {isLoading && <Loader/>}

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Ayurveda Consultation</h4>
                      </div>
                      <div className="col-md-2 col-sm-12 pt-2 mt-3">
                        <button
                          onClick={()=>{excelDownload(row, "Ayurveda Enquiries.xlsx")}}
                          className="btn btn-primary"
                        >
                          Export
                        </button>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>User</th>
                              <th>Expert</th>
                              <th>Expertise</th>
                              <th>Description</th>
                              <th>Date</th>
                              <th>Time</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {hconsultation.map((hcConsultationVal, index) => (
                              <tr key={index}>
                                <td>
                                  {hcConsultationVal.userId[0]?.firstName}{" "}
                                  {hcConsultationVal.userId[0]?.lastName}
                                </td>

                                <td>
                                  {Array.isArray(hcConsultationVal.expertId)
                                    ? hcConsultationVal.expertId[0]?.firstName
                                    : ""}{" "}
                                  {Array.isArray(hcConsultationVal.expertId)
                                    ? hcConsultationVal.expertId[0]?.lastName
                                    : ""}
                                </td>

                                <td>
                                  {hcConsultationVal.expertiseId[0]?.name}
                                </td>

                                <td>{hcConsultationVal.description}</td>

                                <td>{hcConsultationVal.startDate}</td>

                                <td>{hcConsultationVal.startTime}</td>
                                <td>
                                  {hcConsultationVal.status === "initiated" && (
                                    <Link
                                      to={`/HealthCare/AssignHealthCareExpert/${
                                        hcConsultationVal._id
                                          ? hcConsultationVal._id
                                          : null
                                      }`}
                                      className="btn btn-warning"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Assign Expert
                                    </Link>
                                  )}
                                  {hcConsultationVal.status === "scheduled" && (
                                    <button
                                      className="btn btn-success"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Appointment Scheduled
                                    </button>
                                  )}
                                  {hcConsultationVal.status ===
                                    "paymentRequested" && (
                                    <button
                                      className="btn btn-danger"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Payment Pending
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div className="PaginationContainer">
                            <span className="total-elements">
                              Showing {parseInt(page)*limitForPagination() +1 } to {parseInt(page)*limitForPagination() +limitForPagination() >totalCount ? totalCount : parseInt(page)*limitForPagination() +limitForPagination()} of {totalCount} entries
                            </span>
                            <Pagination
                              currentPage={(parseInt(page)+1)}
                              totalCount={totalCount}
                              itemsPerPage={limitForPagination()}
                              onPageChange={(page)=>{fetchData(parseInt(page)-1)}}
                            />
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default HealthCareConsultation;
