import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class RevenueService {
  
    static getHCRevenue() {
    let api = '';
    let user = JSON.parse(localStorage.getItem("user"));
    let id = user.data.id;
    api = API_PATHS.revenue+ '?specialExpertId=' + id;
    localStorage.removeItem("id");
      return axios
      .get(api)
      .then((response) => {
          if (response.data) {
              // localStorage.setItem("user", JSON.stringify(response.data));
          }
          return response.data;
      });
      }


    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default RevenueService;