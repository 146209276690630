import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import { postexpert } from "../store/slices/auth";
import Navbarside from "./Navbarside";
import ExpertiseService from "./../services/expertise.service";
import { useParams, useNavigate } from "react-router-dom";
import Footer from "./footer";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllExpertise } from "../store/slices/hcexpert";


function AddExpert(e) {
  const [passwordShown, setPasswordShown] = useState(false);
  const { id } = useParams();
  const [firstName, setfirstName] = useState("");
  const [email, setEmail] = useState("");
  const [lastName, setlastName] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [password, setPassword] = useState("");


  //Alert Message Start
  const [alertfirstname, setAlertfirstname] = useState("");
  const [alertemail, setAlertemail] = useState("");
  const [alertlastName, setAlertlastName] = useState("");
  const [alertmobileNo, setAlertmobileNo] = useState("");
  const [alertpassword, setAlertpassword] = useState("");

  //Alert Message End
  const navigate = useNavigate("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  // const [expertiseId, setexpertiseId] = useState("");
  // const [expertises, setExpertises] = useState([]);
  const [selectedExpertise, setSelectedExpertise] = useState(null)
  const [allExpertise, setExpertise] = useState(null);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const [alertfn, setAlertfn] = useState("");
  const [alertln, setAlertln] = useState("");
  const [alertmes, setAlertmes] = useState("");
  const [alertmail, setAlertmail] = useState("");
  // const [alertrole, setAlertrole] = useState("");
  const [alertExpertise, setalertExpertise] = useState("");
  const [alertpass, setAlertpass] = useState("");
  let roles = "expert";

  async function signUp(e) {
    e.preventDefault();
    let topExpertise = selectedExpertise;
    let item = {
      firstName,
      email,
      mobileNo,
      password,
      lastName,
      roles,
      topExpertise,
      // expertiseId,
      
    };

    dispatch(postexpert(item))
      .unwrap()
      .then(() => {
        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
      });

    const fName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (fName.test(firstName)) {
      setAlertfn("");
    } else if (!fName.test(firstName) && firstName === "") {
      setAlertfn("Please enter your first name");
    } else {
      setAlertfn("Please enter a valid first name");
    }

    // const passReg = /^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,10}$/;
    // if (passReg.test(password)) {
    //   setAlertpass("");
    // } else if (!passReg.test(password) && password === "") {
    //   setAlertpass("Please enter your password");
    // } else {
    //   setAlertpass(
    //     "Password must be min 6 characters, should contain upper case,lower case, numeric values"
    //   );
    // }

    const lName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (lName.test(lastName)) {
      setAlertln("");
    } else if (!lName.test(lastName) && lastName === "") {
      setAlertln("Please enter your last name");
    } else {
      setAlertln("Please enter a valid last name");
    }

    const mobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (mobile.test(mobileNo)) {
      setAlertmes("");
    } else if (!mobile.test(mobileNo) && mobileNo === "") {
      setAlertmes("Please fill your mobile number");
    } else {
      setAlertmes("Please enter a valid mobile number");
    }

    const textvil = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (textvil.test(email)) {
      setAlertmail("");
    } else if (!textvil.test(email) && email === "") {
      setAlertmail("Please enter your email");
    } else {
      setAlertmail("Please enter a valid email");
    }

    // if (expertiseId == "Select") {
    //   setalertExpertise("Please select expertise");
    //   e.preventDefault();
    // } else {
    //   setalertExpertise("");
    // }
  }

  const clear = () => {
    setAlertfirstname("");
    setAlertemail("");
    setAlertlastName("");
    setAlertmobileNo("");
    setAlertpassword("");
    setAlertfn("");
    setAlertln("");
    setAlertmes("");
    setAlertmail("");
    setAlertpass("");
    setfirstName("");
    setEmail("");
    setlastName("");
    setmobileNo("");

    setPassword("");
  };
  // const Expertises = createAsyncThunk(
  //   "expertise/get/expertise",
  //   async (thunkAPI) => {
  //     try {
  //       // console.log("consultation")
  //       const data = await ExpertiseService.getExpertise();
  //       return { user: data };
  //     } catch (error) {
  //       const message =
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //         error.message ||
  //         error.toString();
  //       return thunkAPI.rejectWithValue({ message });
  //     }
  //   }
  // );

  // const handleCategoryChange = (e) => {
  //   var value = expertises.filter(function (Expertises) {
  //     return Expertises.name == e.target.value;
  //   });
  //   setexpertiseId(value[0]._id);
  // };

 
  const handleExpertiseChange = e => setSelectedExpertise(e.target.value)
  React.useEffect(() => {
    dispatch(getAllExpertise()).unwrap().then((data) =>{
        setExpertise(data.allExpertise);
        // setexpertiseId(data.user.data[0]._id);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">Add Expert</h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <form onSubmit={signUp}>
                        <div
                          className="row"
                          style={{ paddingLeft: "6%", paddingRight: "6%" }}
                        >
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              First Name
                            </p>
                            <input
                              type="text"
                              className="form-control"
                              name="firstName"
                              onChange={(e) => setfirstName(e.target.value)}
                              id="inputFirstname"
                              value={firstName}
                              placeholder="First Name"
                            />
                            <p className="alert-message">{alertfn}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Last Name
                            </p>
                            <input
                              type="text"
                              className="form-control"
                              name="lastName"
                              onChange={(e) => setlastName(e.target.value)}
                              id="inputLastname"
                              value={lastName}
                              placeholder="Last Name"
                            />
                            <p className="alert-message">{alertln}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Mobile No
                            </p>
                            <input
                              maxlength="10"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              type="text"
                              className="form-control "
                              name="inputmobileNo"
                              id="inputmobileNo"
                              placeholder="Mobile No"
                              value={mobileNo}
                              onChange={(e) => setmobileNo(e.target.value)}
                            />
                            <p className="alert-message">{alertmes}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Email
                            </p>
                            <input
                              type="text"
                              className="form-control "
                              name="inputEmail"
                              id="inputEmail"
                              value={email}
                              placeholder="Email Address"
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            <p className="alert-message">{alertmail}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Password
                            </p>
                            <input
                              className="form-control"
                              name="inputPass"
                              id="inputPass"
                              type={passwordShown ? "text" : "password"}
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              placeholder="Password"
                            />
                            <p className="alert-message">{alertpass}</p>
                          </div>

                                    <div className="col-md-6 col-sm-12 pt-2">
          <p
              className="col-black"
              style={{ marginBottom: "2px" }}
            >
              Category
            </p>            <select
              className="form-control"
              onChange={handleExpertiseChange}
            >
              <option value="">Select Expertise</option>
              {allExpertise && allExpertise.data && allExpertise.data.length
                ? allExpertise.data.map(option => {
                  if (!option.parentExpertiseId || option.parentExpertiseId == null) {
                    return (
                      <option value={option._id}>
                        {option.name}
                      </option>
                    )
                  }
                })
                : ''
              }
            </select>
          </div>
                        </div>

                        {loading ? (
                          <Loader active inline />
                        ) : (
                          <>
                            {/* <div className="row"> */}
                            <div className=" d-flex justify-content-center text-center col-md-12">
                              <button
                                type="submit"
                                className="btn btn-primary px-4 py-2  white font-small-4 box-shadow-2 border-0"
                              >
                                Register
                              </button>{" "}
                              &nbsp; &nbsp;
                              <button
                                type="reset"
                                onClick={clear}
                                className="btn btn-danger px-4 py-2 text-uppercase white font-small-4 box-shadow-2 border-0"
                              >
                                Cancel
                              </button>
                            </div>
                            {/* </div> */}
                          </>
                        )}
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
}

export default AddExpert;
