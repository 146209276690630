import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../services/auth.service";
import RecipeService from "../../services/recipe.service";

export const recipe = createAsyncThunk(
  "recipe/get/getRecipe",
  async ( thunkAPI) => {
    try {
      const data = await RecipeService.getRecipe(thunkAPI);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const DeleteRecipe = createAsyncThunk(
  "blog/delete/blog",
  async(thunkAPI) => {
      try {
          const data = await RecipeService.deleteRecipes();
          return { user: data };
      } catch (error) {
          const message =
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();
          return thunkAPI.rejectWithValue({ message });
      }
  }
);

export const recipeDetails = createAsyncThunk(
  "recipe/get/getRecipe",
  async ( thunkAPI) => {
    try {
      const data = await RecipeService.getRecipeDetails();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const uploadRecipe = createAsyncThunk(
    "recipe/post/postrecipe",
    async(item, thunkAPI) => {
        try {
            const data = await RecipeService.uploadRecipe(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const putRecipe = createAsyncThunk(
  "recipe/put/editRecipe",
  async(item, thunkAPI) => {
      try {
          const data = await RecipeService.editrecipe(item);
          return { user: data };
      } catch (error) {
          const message =
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();
          return thunkAPI.rejectWithValue({ message });
      }
  }
);


// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
  loading: false,
  error: "",
  user: AuthService.getUserDetails() || null,
  isLoggedIn: false,
};

const recipeSlice = createSlice({
  name: "recipe",
  initialState,
  extraReducers: {
    [recipe.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [recipe.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [recipe.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },
    [uploadRecipe.pending]: (state) => {
        state.loading = true;
        state.error = "";
        state.isLoggedIn = false;
        state.user = null;
      },
      [uploadRecipe.fulfilled]: (state, action) => {
        state.loading = false;
        state.error = "";
        state.isLoggedIn = true;
        state.user = action.payload.user;
      },
      [uploadRecipe.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
        state.isLoggedIn = false;
        state.user = null;
      },
      [putRecipe.pending]: (state) => {
        state.loading = true;
        state.error = "";
        state.isLoggedIn = false;
        state.user = null;
      },
      [putRecipe.fulfilled]: (state, action) => {
        state.loading = false;
        state.error = "";
        state.isLoggedIn = true;
        state.user = action.payload.user;
      },
      [putRecipe.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
        state.isLoggedIn = false;
        state.user = null;
      },

      [recipeDetails.pending]: (state) => {
        state.loading = true;
        state.error = "";
        state.isLoggedIn = false;
        state.user = null;
      },
      [recipeDetails.fulfilled]: (state, action) => {
        state.loading = false;
        state.error = "";
        state.isLoggedIn = true;
        state.user = action.payload.user;
      },
      [recipeDetails.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
        state.isLoggedIn = false;
        state.user = null;
      },
    // [logout.fulfilled]: (state, action) => {
    //   state.isLoggedIn = false;
    //   state.user = null;
    // },
  },
});

const { reducer } = recipeSlice;
export default reducer;