import React, { useState, useRef } from "react";

import { EditBlog, getsingleblogs } from "../../store/slices/blog";
import { useDispatch } from "react-redux";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { API_PATHS } from "../../utils/constants/api.constants";
import { useParams, Link } from "react-router-dom";
import ExpertiseService from "../../services/expertise.service";
import JoditEditor from "jodit-react";
import Navbarside from "../Navbarside";

const EditBlogs = (e) => {
  const editor = useRef(null);

  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const [blogTitle, setblogTitle] = useState("");
  const [description, setDescription] = useState("");
  const [mediaLink, setmediaLink] = useState("");
  const [date, setDate] = useState("");

  // alert-message
  const [alertblogTitle, setAlertblogTitle] = useState("");
  const [alertdescription, setAlertdescription] = useState("");
  const [alertmedia, setAlertmeda] = useState("");
  const [message, setMessage] = useState("");

  const save = (e) => {
    const regtitle = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (regtitle.test(blogTitle)) {
      setAlertblogTitle("");
    } else if (!regtitle.test(blogTitle) && blogTitle === "") {
      setAlertblogTitle("Please enter blog title");
      e.preventDefault();
    } else {
      setAlertblogTitle("Single and double quotes are not-valid");
      e.preventDefault();
    }

    const regdes = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (regdes.test(description)) {
      setAlertdescription("");
    } else if (!regdes.test(description) && description === "") {
      setAlertdescription("Please enter blog description");
      e.preventDefault();
    } else {
      setAlertdescription("");
    }

    const regmedia = /(gif|jpe?g|tiff?|png|webp|bmp)$/i;
    if (regmedia.test(mediaLink)) {
      setAlertmeda("");
    } else if (!regmedia.test(mediaLink) && mediaLink === "") {
      setAlertmeda("Please upload image");
      e.preventDefault();
    } else {
      setAlertmeda("");
    }
  };

  const clear = () => {
    navigate(-1);
  };

  // let date = new Date();

  const { id } = useParams();
  localStorage.setItem("blogId", id);

  React.useEffect(() => {
    dispatch(getsingleblogs())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id === id) {
          setblogTitle(data.user.data[0].blogTitle);
          setmediaLink(data.user.data[0].mediaLink);
          setDescription(data.user.data[0].description);
          setDate(data.user.data[0].date);
        }
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  async function imageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setmediaLink(data.data.location);
    setMessage(data.message);
  }

  async function upload(e) {
    e.preventDefault();
    let item = { set: { blogTitle, description, mediaLink, date } };
    dispatch(EditBlog(item))
      .unwrap()
      .then(() => {
        alert("Edited succesfully");
        navigate("/Blog");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }
  return (
    <div>
      <Navbarside />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Edit Blog
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form className="form" onSubmit={upload}>
                          <div className="row justify-content-md-center">
                            <div className="col-md-12">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Blog Title</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Blog Title"
                                    value={blogTitle}
                                    onChange={(e) =>
                                      setblogTitle(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">
                                    {alertblogTitle}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Blog Image</label>
                                  <br />
                                  <img
                                    src={mediaLink}
                                    alt=""
                                    style={{ width: "150px", height: "100px" }}
                                  />
                                  <br />
                                  <input
                                    type="file"
                                    className="form-control-file mt-2"
                                    Placeholder=""
                                    name="imageUrl"
                                    onChange={(e) => imageUpload(e)}
                                  />
                                  {/* <p className="alert-message">
                                    {alertmediaLink}
                                  </p> */}
                                  <p
                                    style={{
                                      color: "green",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {message}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6" />

                            <div className="col-md-12">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Description</label>
                                  <JoditEditor
                                    ref={editor}
                                    value={description}
                                    // config={config}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={(newContent) =>
                                      setDescription(newContent)
                                    } // preferred to use only this option to update the content for performance reasons
                                    onChange={(newContent) => { }}
                                  />
                                  <p className="alert-message">
                                    {alertdescription}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-actions center">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={save}
                            //  disabled={!message}
                            >
                              <i className="icon-note"></i> Save
                            </button>{" "}
                            &nbsp;
                            <button
                              type="reset"
                              className="btn btn-warning"
                            //   onClick={cancel}
                            >
                              <i className="icon-trash"></i> Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default EditBlogs;
