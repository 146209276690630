import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Navbarside from "./Navbarside";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "./footer";
import { expertDetail, editExpert } from "../store/slices/auth";
import { API_PATHS } from "../utils/constants/api.constants";
import axios, { Axios } from "axios";
import MultipleSelect from "./common/MultipleSelect";
import Loader from "./Loader";
const { imageUpload: commonImageUpload } = require("../utils/appUtils");

const EditExpert = () => {
  const { id } = useParams();
  localStorage.setItem("expertId", id);
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const [firstname, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [about, setAbout] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [state, setState] = useState("");
  const [certificates, setCertificates] = useState([]);
  const [expertList, setExpertList] = useState([]);
  const [selecetdExpert, setSelecetdExpert] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  React.useEffect(() => {
    fetchExpert();
    getExpertList();
  }, [dispatch]);

  // useEffec(()=>{})

  const fetchExpert = () => {
    setIsLoading(true);
    dispatch(expertDetail())
      .unwrap()
      .then((data) => {
        setIsLoading(false);
        setFirstName(data.user.data[0].firstName);
        setLastName(data.user.data[0].lastName);
        setAbout(data.user.data[0].about);
        setCity(data.user.data[0].city);
        setCountry(data.user.data[0].country);
        setEmail(data.user.data[0].email);
        setMobileNo(data.user.data[0].mobileNo);
        setPinCode(data.user.data[0].pinCode);
        setState(data.user.data[0].state);
        setCertificates(data.user.data[0].certificates);
        setImageUrl(data.user.data[0].imageUrl);
        const exp = data.user.data[0]?.expertise?.map((data) => data?._id);
        setSelecetdExpert(exp);
      })
      .catch(({ message }) => {
        setIsLoading(false);
        alert(message);
      });
  };

  /** Experties function gives all Expert list  */
  const getExpertList = async () => {
    try {
      setIsLoading(true);
      const expertDetils = await axios.get(API_PATHS.ExperList);
      setIsLoading(false);
      if (expertDetils && expertDetils.status == 200) {
        const d = expertDetils?.data?.data?.map((data) => {
          const rData = { id: data?._id, name: data?.name };
          return rData;
        });
        setExpertList(d);
      }
    } catch (error) {
      console.log("From expert page", error);
    }
  };

  async function imageUpload(e, mod) {
    e.preventDefault();
    var formdata = new FormData();
    const files = [];
    files.push(...e.target.files);
    files.forEach((file) => formdata.append("file", file));
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.multiUploadImage, requestOptions);
    let data = await response.json();
    let imgurl = [];
    for (let i = 0; i < data.data.length; i++) {
      imgurl.push(data.data[i]);
    }
    if (mod == "imageUrl") setImageUrl(data.data[0]);
    else setCertificates(imgurl);
    setMessage(data.message);
  }
  // async function imgUpload2(e, mod) {
  //   let link = await commonImageUpload(e)
  // }

  async function upload(e) {
    e.preventDefault();

    let item = {
      set: {
        firstname,
        lastName,
        about,
        city,
        country,
        email,
        mobileNo,
        pinCode,
        state,
        certificates,
        imageUrl,
        expertise: selecetdExpert,
      },
    };
    setIsLoading(true);
    dispatch(editExpert(item))
      .unwrap()
      .then(() => {
        setIsLoading(false);

        alert("Updated succesfully");
        navigate("/Experts");
        // navigate(-1);
      })
      .catch(({ message }) => {
        setIsLoading(false);
        alert(message);
      });
  }

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };

  return (
    <div>
      <Navbarside />
      {isLoading && <Loader />}

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">Edit Expert</h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <form onSubmit={upload}>
                        <div className="row">
                          {/* <div className="">
                            {imageUrl ? <img src={imageUrl} alt="text" style={{
                              width: "80px",
                              height: "150px",
                            }} /> : ''}
                          </div> */}

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              First Name
                            </p>
                            <input
                              type="text"
                              placeholder="Enter your name"
                              value={firstname}
                              onChange={(e) => setFirstName(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Lastname
                            </p>
                            <input
                              type="text"
                              placeholder="Enter your name"
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{}</p>
                          </div>
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Email
                            </p>
                            <input
                              type="text"
                              placeholder="Enter your name"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Mobile No
                            </p>
                            <input
                              type="text"
                              placeholder="Enter your name"
                              value={mobileNo}
                              onChange={(e) => setMobileNo(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              City
                            </p>
                            <input
                              type="text"
                              placeholder="Enter city name"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{}</p>
                          </div>
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              State
                            </p>
                            <input
                              type="text"
                              placeholder="Enter state name"
                              value={state}
                              onChange={(e) => setState(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{}</p>
                          </div>

                          <div className="col-md-6 col-sm-12">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Country
                            </p>
                            <input
                              type="text"
                              placeholder="Enter country name"
                              value={country}
                              onChange={(e) => setCountry(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{}</p>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Experties
                            </p>
                            <MultipleSelect
                              options={expertList}
                              setSelected={setSelecetdExpert}
                              selected={selecetdExpert}
                            />
                          </div>
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Certificates
                            </p>
                            <br />
                            <input
                              type="File"
                              name="imageUrl"
                              onChange={(e) => imageUpload(e)}
                              id="inputimageUrl"
                              className="form-control-file"
                              multiple
                            />
                            {certificates?.length > 0 && (
                              <img
                                src={certificates}
                                style={{
                                  width: "17%",
                                  height: "25%",
                                  margin: "10px",
                                }}
                              />
                            )}
                            <p className="alert-message">{}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Profile Pic
                            </p>
                            <br />
                            <input
                              type="file"
                              className="form-control-file"
                              Placeholder="upload file"
                              name="imageUrl"
                              onChange={(e) => imageUpload(e, "imageUrl")}
                            />
                            <p className="alert-message">{}</p>
                          </div>

                          <div className="col-md-12 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              About
                            </p>
                            <textarea
                              placeholder="Enter your about"
                              value={about}
                              onChange={(e) => setAbout(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{}</p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center pt-3">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            // onClick={save}
                          >
                            Submit
                          </button>
                          &nbsp; &nbsp; &nbsp;
                          <button
                            type="reset"
                            className="btn btn-warning"
                            // onClick={cancel}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default EditExpert;
