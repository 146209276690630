import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "../../Navbarside";
import { Link, useLocation, useNavigation } from "react-router-dom";
import Footer from "../../footer";
import { API_BASE_URL_HC, API_PATHS } from "../../../utils/constants/api.constants";
import axios from "axios";
import sampleImage from '../../../img/livewell.png'

function ViewPlaylist() {
  const location = useLocation();
  const [playlists, setPlaylists] = useState([]);
  const [reload, setReload] = useState()
  const dispatch = useDispatch();

  const fileName = "Live Well";
  const searchParams = new URLSearchParams(location.search);
  const masterCat = searchParams.get('masterCat');
  const mastCatName = searchParams.get('mastCatName');
  const cat = searchParams.get('cat')
  const catName = searchParams.get('catName')

  useEffect(() => {
    if (!playlists.length) {
      if (masterCat) {
        axios.get(`${API_BASE_URL_HC}/get/playlist`, {
          params: {
            masterCategoryId: masterCat,
            categoryId: cat
          }
        }).then(res => {
          if (res.data?.data?.length) {
            setPlaylists(res.data.data)
          }
        }).catch(err => alert(err.message))
      }
    }
  }, [dispatch]);

  const deleteData = (id) => {
    axios.delete(`${API_BASE_URL_HC}/delete/playlist?id=${id}`).then(res => {
      if (res.data?.status === 1) alert('deleted successfully')
      // setPlaylists([]);
      window.location.reload()
    }).catch(err => alert(err.message))
  }

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">
                          {mastCatName} / {catName} / Playlists
                        </h4>
                        &nbsp; &nbsp;
                        <Link
                          to={`/LiveWell/Playlist/Add?masterCat=${masterCat}&mastCatName=${mastCatName}&cat=${cat}&catName=${catName}`}
                          className="btn btn-primary"
                          style={{ marginBottom: "0%" }}
                        >
                          <i className="fa fa-plus"></i> Add Playlist
                        </Link>{" "}
                        &nbsp; &nbsp;
                        {/* <ExportToExcel
                          apiData={data}
                          fileName={fileName}
                          style={{
                            height: "25px",
                            padding: "0 10px",
                            marginBottom: "0%",
                          }}
                        /> */}
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Description</th>
                              <th>Image</th>
                              <th>View Details</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {playlists.map((playlist, index) => (
                              <tr key={index}>
                                <td>{playlist.name}</td>

                                <td>{playlist.description}</td>

                                <td>
                                  <img src={playlist.mediaLink || sampleImage} height="100" alt='livewllVal.mediaLink' />
                                </td>

                                <td>
                                  <Link to={`/Playlist/view/${playlist._id}?playlistName=${playlist.name}`} className="btn btn-info">
                                    View Videos
                                  </Link>

                                </td>

                                <td>
                                  <Link to={`/LiveWell/Playlist/Edit?id=${playlist._id}&masterCat=${masterCat}&mastCatName=${mastCatName}&cat=${cat}&catName=${catName}&playlist=${playlist.name}`} className="btn btn-warning">
                                    Edit
                                  </Link>
                                  <button
                                    className="btn btn-danger"
                                    onClick={() => deleteData(playlist._id)}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
}

export default ViewPlaylist;