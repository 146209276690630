import React, { useState } from "react";

import Navbarside from "../Navbarside";
import Footer from "../footer";
import { getsingleblogs } from "../../store/slices/blog";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import VideoFilePlayer from "../videoPlayer/videoFilePlayer";


import { FaUserCircle } from "react-icons/fa";

const BlogDetails = () => {
  const dispatch = useDispatch();
  const [blogTitle, setblogTitle] = useState("");
  const [mediaLink, setmediaLink] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const { id } = useParams();
  localStorage.setItem("blogId", id);

  React.useEffect(() => {
    dispatch(getsingleblogs())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id === id) {
          setblogTitle(data.user.data[0].blogTitle);
          setmediaLink(data.user.data[0].mediaLink);
          setDescription(data.user.data[0].description);
          setDate(data.user.data[0].date);
        }
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  function isVideoLink(url) {
    var videoExtensions = ['mp4', 'avi', 'wmv', 'mov', 'flv'];
    var fileExtension = url.split('.').pop();

    return {
      status: videoExtensions.includes(fileExtension),
      format: fileExtension
    }
  }
  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-lg-12 col-sm-12 col-md-12">
                    <div className="post-card mt-2 mb-2">


                      {mediaLink?.includes('s3')
                        ? isVideoLink(mediaLink).status ? <VideoFilePlayer s3Link={mediaLink} format={isVideoLink(mediaLink).format} /> : <img
                          src={mediaLink}
                          alt=""
                          style={{
                            width: "150px",
                            height: "100px",
                          }}
                        />
                        : ''
                      }
                      <br />
                      <br />

                      <h3>Title</h3>
                      <div dangerouslySetInnerHTML={{ __html: blogTitle }}></div>
                      <br />
                      <h3>Description</h3>
                      <div dangerouslySetInnerHTML={{ __html: description }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
};

export default BlogDetails;
