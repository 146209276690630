import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";

import { API_PATHS } from "../utils/constants/api.constants";
import { ApproveRequestFitnesss } from "../store/slices/fitness";
// import { Loader } from "semantic-ui-react";
import Footer from "./footer";
import Navbarside from "../component/Navbarside";

const FitnessrequestApprove = (e) => {
  const navigate = useNavigate("");
  const dispatch = useDispatch();

  const [platformCostPercent, setPlatformCostPercent] = useState("");
  const [hplCommission, setHplCommission] = useState("");
  const [gstPercent, setGstPercent] = useState("");

  //   Alert-message

  const [alertplatformCostPercent, setAlertplatformCostPercent] = useState("");
  const [alerthplCommission, setAlerthplCommission] = useState("");
  const [alertgstPercent, setAlertgstPercent] = useState("");

  const { id } = useParams();
  localStorage.setItem("id", id);

  async function upload(e) {
    e.preventDefault();
    let status = "approved";
    let isApproved = true;
    let item = {
      id,
      status,
      isApproved,
      platformCostPercent,
      hplCommission,
      gstPercent,
    };

    dispatch(ApproveRequestFitnesss(item))
      .unwrap()
      .then(() => {
        alert("Uploaded successfully");
        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const save = (e) => {
    const num1 = /^[0-9][0-9]?$|^100$/;
    if (num1.test(platformCostPercent)) {
      setAlertplatformCostPercent("");
    } else if (!num1.test(platformCostPercent) && platformCostPercent === "") {
      setAlertplatformCostPercent("Please enter the pincode");
      e.preventDefault();
    } else {
      setAlertplatformCostPercent("");
    }

    const num2 = /^[0-9][0-9]?$|^100$/;
    if (num2.test(hplCommission)) {
      setAlerthplCommission("");
    } else if (!num2.test(hplCommission) && hplCommission === "") {
      setAlerthplCommission("Please enter the pincode");
      e.preventDefault();
    } else {
      setAlerthplCommission("");
    }

    const num3 = /^[0-9][0-9]?$|^100$/;
    if (num3.test(gstPercent)) {
      setAlertgstPercent("");
    } else if (!num3.test(gstPercent) && gstPercent === "") {
      setAlertgstPercent("Please enter the pincode");
      e.preventDefault();
    } else {
      setAlertgstPercent("");
    }
  };

  const cancel = () => {
    setPlatformCostPercent("");
    setHplCommission("");
    setGstPercent("");
    setAlertplatformCostPercent("");
    setAlerthplCommission("");
    setAlertgstPercent("");
  };

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Update Fitness Center Details
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form className="form" onSubmit={upload}>
                          <div className="row justify-content-md-center">
                            <div className="col-md-4 col-sm-12">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    {" "}
                                    Platform Cost Percent
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    Placeholder="Enter the platform cost percent"
                                    value={platformCostPercent}
                                    onChange={(e) =>
                                      setPlatformCostPercent(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">
                                    {alertplatformCostPercent}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    HCL Commission
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    Placeholder="Enter the HCL commission"
                                    value={hplCommission}
                                    onChange={(e) =>
                                      setHplCommission(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">
                                    {alerthplCommission}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">GST Percent</label>
                                  <input
                                    type="test"
                                    className="form-control"
                                    Placeholder="Enter the GST percent"
                                    value={gstPercent}
                                    onChange={(e) =>
                                      setGstPercent(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">
                                    {alertgstPercent}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-actions center">
                            <button
                              type="submit"
                              className="btn btn-success"
                              onClick={save}
                            >
                              Save
                            </button>{" "}
                            &nbsp;
                            <button
                              type="reset"
                              className="btn btn-warning mt-2"
                              onClick={cancel}
                            >
                              Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default FitnessrequestApprove;
