import React, { useState } from "react";
import Footer from "./footer";
import { measurementData } from "../store/slices/forms";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "../component/Navbarside";
import { useParams } from "react-router-dom";
import Moment from "moment";
const UserMeasurement = () => {
  const [measurement, setMeasurement] = useState([]);
  const { id } = useParams();
  localStorage.setItem("userId", id);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(measurementData())
      .unwrap()
      .then((data) => {
        setMeasurement(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid response-cover pb-5">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">User Measurement</h4>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="container">
                        <div className="row">
                          {measurement.map((measurementVal, index) => (
                            <div className="col-md-6" key={index}>
                              <b>Date:</b>
                              {Moment(measurementVal.date).format("DD-MM-YYYY")}
                              <br />
                              <b>BP:</b>
                              {measurementVal.bloodPressure}
                              <br />
                              <b>BMI:</b> {measurementVal.bmi}
                              <br />
                              <b>BMR:</b> {measurementVal.bmr}
                              <br />
                              <b>Body Fat:</b> {measurementVal.bodyFat}
                              <br />
                              <b>Body Metabolic Age:</b>{" "}
                              {measurementVal.bodyMetabolicAge}
                              <br />
                              <b>Heart Rate:</b> {measurementVal.heartRate}
                              <br />
                              <b>Muscle Mass:</b> {measurementVal.muscleMass}
                              <br />
                              <b>Sub Cutaneous:</b>{" "}
                              {measurementVal.subCutaneous}
                              <br />
                              <b>Height (in cm):</b>{" "}
                              {measurementVal.totalheight}
                              <br />
                              <b>Visceral Fat:</b> {measurementVal.visceralFat}
                              <br />
                              <b>Weight (in KG):</b> {measurementVal.weightInKg}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default UserMeasurement;
