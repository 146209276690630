import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class frequentlyQuestion {
    static frequently() {
        return axios
            .get(API_PATHS.faq)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static addfrequently(item) {
        return axios
        .post(API_PATHS.postfaq,
            item
        )
        .then((response) => {
            if (response.data) {
                //  localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
    }

    static editFreq(item) {
        const api = API_PATHS.editFreq + "?id=" + item?.id;
        return axios
        .put(api,
            item?.item
        )
        .then((response) => {
            if (response.data) {
                //  localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
    }

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default frequentlyQuestion;