import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import bodyService from "../../services/body.service";
import AuthService from "../../services/auth.service";



export const getbodypartgroup = createAsyncThunk(
    "bodypart/get/bodypartgroup",
    async( thunkAPI) => {
        try {
            const data = await bodyService.getbodygroup();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) || 
                error.message ||  
                error.toString(); 
            return thunkAPI.rejectWithValue({ message });  
        }
    }
);

export const getbodyspart = createAsyncThunk(
    "bodypart/get/bodypart",
    async( thunkAPI) => {
        try {
            const data = await bodyService.getbodyparts(thunkAPI);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) || 
                error.message ||  
                error.toString(); 
            return thunkAPI.rejectWithValue({ message });  
        }
    }
);

export const postbodygroups = createAsyncThunk(
    "bodypart/post/bodypartgroup",
    async(item, thunkAPI) => {
        try {
            const data = await bodyService.postbodygroup(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) || 
                error.message ||  
                error.toString(); 
            return thunkAPI.rejectWithValue({ message });  
        }
    }
);

export const editbodygroups = createAsyncThunk(
    "bodypart/put/bodypartgroup",
    async(item, thunkAPI) => {
        try {
            const data = await bodyService.editbodygroup(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) || 
                error.message ||  
                error.toString(); 
            return thunkAPI.rejectWithValue({ message });  
        }
    }
);

export const DeleteBodypartgroup = createAsyncThunk(
    "bodypart/delete/bodypartgroup",
    async(thunkAPI) => {
        try {
            const data = await bodyService.deleteBodygroup();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const postbodyparts = createAsyncThunk(
    "bodypart/post/bodyparts",
    async(item, thunkAPI) => {
        try {
            const data = await bodyService.postbodypart(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) || 
                error.message ||  
                error.toString(); 
            return thunkAPI.rejectWithValue({ message });  
        }
    }
);

export const editbodyparts = createAsyncThunk(
    "bodypart/put/bodyparts",
    async(item, thunkAPI) => {
        try {
            const data = await bodyService.editbodypart(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) || 
                error.message ||  
                error.toString(); 
            return thunkAPI.rejectWithValue({ message });  
        }
    }
);

export const DeleteBodypart = createAsyncThunk(
    "bodypart/delete/bodyparts",
    async(thunkAPI) => {
        try {
            const data = await bodyService.deleteBodyparts();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);



// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
    loading: false,
    error: "",
    user: AuthService.getUserDetails() || null,
    isLoggedIn: false,
};

const bodypartsSlice = createSlice({
    name: "bodyparts",
    initialState,
    extraReducers: {
    
        [getbodypartgroup.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getbodypartgroup.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getbodypartgroup.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },

        [getbodyspart.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getbodyspart.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getbodyspart.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },

        [postbodygroups.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [postbodygroups.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [postbodygroups.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },

        [postbodyparts.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [postbodyparts.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [postbodyparts.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },

        [editbodygroups.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [editbodygroups.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [editbodygroups.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },

        [editbodyparts.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [editbodyparts.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [editbodyparts.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },

        [DeleteBodypartgroup.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [DeleteBodypartgroup.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [DeleteBodypartgroup.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },

        [DeleteBodypart.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [DeleteBodypart.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [DeleteBodypart.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },

        // [logout.fulfilled]: (state, action) => {
        //   state.isLoggedIn = false;
        //   state.user = null;
        // },
    },
});

const { reducer } = bodypartsSlice;
export default reducer;