import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "./Navbarside";
import { Link, useParams } from "react-router-dom";
import Footer from "./footer";
import { medicalhistoryData } from "../store/slices/forms";

const MedicalForm = () => {
  const { id } = useParams();
  localStorage.setItem("userId", id);
  const [medical, setMedical] = useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(medicalhistoryData())
      .unwrap()
      .then((data) => {
        setMedical(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid response-cover pb-5">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Medical Form</h4>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th className="wd-15p">Question</th>
                              <th>Answer</th>
                            </tr>
                          </thead>
                          {medical.map((medicalVal, index) => (
                            <tbody>
                              {medicalVal.conditions.map(
                                (conditationVal, index) => (
                                  <tr key={index}>
                                    <td>
                                      {conditationVal.questions[0].question}{" "}
                                    </td>
                                    <td>
                                      {conditationVal.questions[0].answer}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MedicalForm;
