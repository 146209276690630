import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "./Navbarside";
import { getcompany } from "../store/slices/corporate";
import Footer from "./footer";
import { Link, useNavigate } from "react-router-dom";

const Company = () => {
  const dispatch = useDispatch();
  const [allcompany, setAllcompany] = useState([]);

  React.useEffect(() => {
    dispatch(getcompany())
      .unwrap()
      .then((data) => {
        setAllcompany(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-wrap bar-success">
                          <h4 className="card-title">
                            Companies &nbsp; &nbsp;
                            <Link
                              to="/Corporate/Company/OnBoardCompany"
                              className="btn btn-primary"
                            >
                              <i className="fa fa-plus"></i> Add
                            </Link>
                          </h4>
                        </div>
                      </div>
                      <div className="card-body collapse show">
                        <div className="card-block card-dashboard table-responsive">
                          <table className="table table-striped table-bordered zero-configuration">
                            <thead>
                              <tr>
                                <th>Admin Name</th>
                                <th>Company Name</th>
                                <th>Email</th>
                                <th>Employee Limit</th>
                                <th>Contact Number</th>
                                <th>whitelistDomain</th>
                              </tr>
                            </thead>

                            <tbody>
                              {allcompany.map((companyVal, index) => (
                                <tr>
                                  <td>{companyVal.adminName}</td>
                                  <td>{companyVal.companyName}</td>

                                  <td>{companyVal.email}</td>
                                  <td>{companyVal.employeeLimit}</td>
                                  <td>{companyVal.mobileNo}</td>
                                  <td>{companyVal.whitelistDomain}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
};

export default Company;
