import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "./Navbarside";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./footer";
import { postlabdetails } from "../store/slices/lab";
import { API_PATHS } from "../utils/constants/api.constants";
import { getlabtestcategory } from "../store/slices/Labcategory";
import { getStates, getCitys } from "../store/slices/lab";
import { useGeolocated } from "react-geolocated";

const AddLabdetails = () => {
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [category, setCategory] = useState([]);
  const [name, setName] = useState("");
  const [labEmail, setLabEmail] = useState("");
  const [labMobileNo, setLabMobileNo] = useState("");
  const [address, setAddress] = useState("");
  const [agreement, setAgreement] = useState("");
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const [checked, setChecked] = useState([]);
  const [servicechecked, setServiceChecked] = useState([]);
  const [long, setLong] = useState("");
  const [lat, setLat] = useState("");
  const [cityId, setcityId] = useState("");
  const [city, setCity] = useState("");
  const [stateId, setstateId] = useState("");
  const [state, setState] = useState("");

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  const handleServiceCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setServiceChecked(updatedList);
  };

  React.useEffect(() => {
    dispatch(getCitys())
      .unwrap()
      .then((data) => {
        setCities(data.user.data);
        setcityId(data.user.data[0]._id);
        setCity(data.user.data[0].name);
        // console.log("data", data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getStates())
      .unwrap()
      .then((data) => {
        setStates(data.user.data);
        setstateId(data.user.data[0]._id);
        setState(data.user.data[0].name);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  let labTestCategoryId = checked;
  let serviceType = servicechecked;
  let latitude = lat;
  let longitude = long;
  async function upload(e) {
    e.preventDefault();
    let location = {
      type: "Point",
      coordinates: [13.00345, 77.662795],
    };

    let vendorId = JSON.parse(localStorage.getItem("user")).data.id;

    let item = {
      name,
      labEmail,
      labMobileNo,
      vendorId,
      labTestCategoryId,
      address,
      location,
      serviceType,
      agreement,
      cityId,
      stateId,
    };

    dispatch(postlabdetails(item))
      .unwrap()
      .then(() => {
        alert("Uploaded succesfully");
        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  async function imageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setAgreement(data.data.location);
  }

  const receivedData = [{ value: "Home Service" }, { value: "Lab Visit" }];
  React.useEffect(() => {
    dispatch(getlabtestcategory())
      .unwrap()
      .then((data) => {
        setCategory(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  // alert movile number]

  const [alertname, setAlertName] = useState("");
  const [alertlabEmail, setAlertLabEmail] = useState("");
  const [alertlabMobileNo, setAlertLabMobileNo] = useState("");
  const [alertaddress, setAlertAddress] = useState("");
  const [alertagreement, setAlertAgreement] = useState("");

  const save = (e) => {
    const fName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (fName.test(name)) {
      setAlertName("");
    } else if (!fName.test(name) && name === "") {
      setAlertName("Please enter lab name");
    } else {
      setAlertName("Please enter a valid cname");
    }

    const regimage = /(gif|jpe?g|tiff?|png|webp|bmp|pdf|doc|docx)$/i;
    if (regimage.test(agreement)) {
      setAlertAgreement("");
    } else if (!regimage.test(agreement) && agreement === "") {
      setAlertAgreement("Please upload lab document");
      e.preventDefault();
    } else {
      setAlertAgreement("Invalid file");
      e.preventDefault();
    }

    const regallchar = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (regallchar.test(address)) {
      setAlertAddress("");
    } else if (!regallchar.test(address) && address === "") {
      setAlertAddress("Please enter lab address");
      e.preventDefault();
    } else {
      setAlertAddress("");
    }

    const emailrgex =
      /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (emailrgex.test(labEmail)) {
      setAlertLabEmail("");
    } else if (!emailrgex.test(labEmail) && labEmail === "") {
      setAlertLabEmail("Please enter the lab email");
      e.preventDefault();
    } else {
      setAlertLabEmail("Please enter a valid email");
    }

    const mobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (mobile.test(labMobileNo)) {
      setAlertLabMobileNo("");
    } else if (!mobile.test(labMobileNo) && labMobileNo === "") {
      setAlertLabMobileNo("Please enter the lab mobile number");
      e.preventDefault();
    } else {
      setAlertLabMobileNo("Please enter a valid mobile number");
    }
  };

  const cancel = (e) => {
    setName("");
    setLabEmail("");
    setLabMobileNo("");
    setAddress("");
    setAgreement("");
    setAlertName("");
    setAlertLabEmail("");
    setAlertLabMobileNo("");
    setAlertAddress("");
    setAlertAgreement("");
  };

  const handleCityChange = (e) => {
    var value = cities.filter(function (citys) {
      return citys.name == e.target.value;
    });
    setcityId(value[0]._id);
    setCity(value[0].name);
  };

  const handleStateValChange = (e) => {
    var value = states.filter(function (states) {
      return states.name == e.target.value;
    });
    setstateId(value[0]._id);
    setState(value[0].name);
  };

  return !isGeolocationAvailable ? (
    <div>Your browser does not support Geolocation</div>
  ) : !isGeolocationEnabled ? (
    <div>Geolocation is not enabled</div>
  ) : coords ? (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">Add Lab</h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <form onSubmit={upload}>
                        <div className="row">
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Lab Name
                            </p>
                            <input
                              type="text"
                              placeholder="Lab name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertname}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Lab Email
                            </p>
                            <input
                              type="text"
                              placeholder="Enter email"
                              value={labEmail}
                              onChange={(e) => setLabEmail(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertlabEmail}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Mobile Number
                            </p>
                            <input
                              type="text"
                              placeholder="Mobile number"
                              value={labMobileNo}
                              onChange={(e) => setLabMobileNo(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertlabMobileNo}</p>
                          </div>
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Lab Certificate
                            </p>
                            <input
                              type="file"
                              className="form-control-file"
                              Placeholder=""
                              name="pdf"
                              onChange={(e) => imageUpload(e)}
                              // required
                            />
                            <p className="alert-message">{alertagreement}</p>
                          </div>

                          <div className="col-md-12 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Address
                            </p>
                            <textarea
                              placeholder="Address"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertaddress}</p>
                          </div>
                        </div>

                        <div className="row pt-3">
                          <div className="col-md-6">
                            <div className="form-group">
                              <p className="col-black">City</p>
                              <select
                                className="form-control"
                                onChange={handleCityChange}
                                required
                              >
                                <option value="">Select</option>
                                {cities.map((option) => (
                                  <option value={option.cityId}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <p className="col-black">State</p>
                              <select
                                className="form-control"
                                onChange={handleStateValChange}
                                required
                              >
                                <option value="">Select</option>
                                {states.map((option) => (
                                  <option value={option.stateId}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row pt-3">
                          <div className="col-12">
                            <p className="col-black">Service Type</p>
                          </div>
                          {receivedData?.map((data, index) => (
                            <div className=" col-lg-6 col-md-6 col-sm-12">
                              <input
                                type="checkbox"
                                value={data.value}
                                name="services"
                                onChange={handleServiceCheck}
                              />{" "}
                              {data.value}
                            </div>
                          ))}

                          <div className="col-12 mt-3">
                            <p className="col-black">Tests</p>
                          </div>

                          {category.map((LabVal, index) => (
                            <div className=" col-lg-6 col-md-6 col-sm-12">
                              <input
                                type="checkbox"
                                name="option"
                                value={LabVal._id}
                                onChange={handleCheck}
                              />
                              &nbsp;
                              {LabVal.name}
                            </div>
                          ))}
                        </div>
                        <input
                          type="text"
                          value={coords.longitude}
                          style={{ display: "none" }}
                          onChange={(e) => setLong(e.target.value)}
                        />
                        <input
                          type="text"
                          style={{ display: "none" }}
                          value={coords.latitude}
                          onChange={(e) => setLat(e.target.value)}
                        />

                        <div className="form-actions center">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={save}
                          >
                            Submit
                          </button>
                          &nbsp; &nbsp; &nbsp;
                          <button
                            type="reset"
                            className="btn btn-warning"
                            onClick={cancel}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  ) : (
    <div>Getting the location data&hellip; </div>
  );
};

export default AddLabdetails;
