import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class labService {
    static getlabEnquiry() {
        let api = '';
        api =  API_PATHS.getLabVendorEnquiry;
        localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                   
                }
                return response.data;
            });
    }

    static ApprovelabEnquiry(item) {
        let api='';
        api = API_PATHS.approveLabVendorEnquiry;
        return axios
          .post(api,item)
          .then((response) => {
            if (response.data) {
            
            }
            return response.data;
          });
      }


      static ListLab(item) {
        return axios
            .post(API_PATHS.listLab,
                item
            )
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
         }

 //  LabTestOrders
         static LabTestOrder(item) {
            return axios
                .get(API_PATHS.LabTestOrders,
                    item
                )
                .then((response) => {
                    if (response.data) {
                        // localStorage.setItem("user", JSON.stringify(response.data));
                    }
                    return response.data;
                });
             }


    static getLabs() {
        let api = '';
        let user = JSON.parse(localStorage.getItem("user"));
        let id = JSON.parse(localStorage.getItem("user")).data.id;
        let role = user.data.roles[0];
        if(role === 'ROLE_HEALTHADMIN'){
        api = API_PATHS.getlabs;
        }
        else 
        if(role === 'ROLE_LABVENDOR'){
          api = API_PATHS.getlabs + '?vendorId=' + id;
        }
        localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static getViewLabs() {
        let api = '';
        // let user = JSON.parse(localStorage.getItem("user"));
        let id = localStorage.getItem("vendorId");;
        
      
          api = API_PATHS.getViewlabs + '?vendorId=' + id;
        
        localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static getVendorLabs() {
        let api = '';
       
        let id = JSON.parse(localStorage.getItem("user")).data.id;
       
          api = API_PATHS.getlabs + '?vendorId=' + id;
        
        localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }


    // getLabVendors

    static getVendors() {
        let api = '';
        let role="labVendor"
       
        // let id = JSON.parse(localStorage.getItem("user")).data.id;
       
          api = API_PATHS.getLabVendors + '?role=' + role;
        
        // localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }
    
    static getVendorLabsDetails() {
        let api = '';
       
        let id = localStorage.getItem("LabVal");
       console.log(id)
          api = API_PATHS.getLabVendorsDetails + '?id=' + id;
        
        localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }



    static getApprovedLabs() {
        let api = '';
        let user = JSON.parse(localStorage.getItem("user"));
        let id = JSON.parse(localStorage.getItem("user")).data.id;
        let role = user.data.roles[0];
        if(role === 'ROLE_HEALTHADMIN'){
        api = API_PATHS.getlabs;
        }
        else 
        if(role === 'ROLE_LABVENDOR'){
          api = API_PATHS.getlabs + '?vendorId=' + id + '&status=' + "approved";
        }
        localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }
  

  static getLabDetail() {
    let api = '';
    let id = localStorage.getItem("labId");
    api = API_PATHS.getlabs + '?id=' + id ;
    localStorage.removeItem("id");
    return axios
        .get(api)
        .then((response) => {
            if (response.data) {
                // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
}

static getCity() {
    let api = '';
    api = API_PATHS.getCity;
    return axios
        .get(api)
        .then((response) => {
            if (response.data) {
                // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
}

static getStates() {
    let api = '';
    api = API_PATHS.getStates;
    return axios
        .get(api)
        .then((response) => {
            if (response.data) {
                // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
}

 
static getlabtest() {
    let api = '';
      let id = localStorage.getItem("labId");
          api = API_PATHS.getlabtests +  '?labId=' + id;
   
    return axios
        .get(api)
        .then((response) => {
            if (response.data) {
                // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
}

static getlabtestDatewise() {
    let api = '';
  
    let id = JSON.parse(localStorage.getItem("user")).data.id;
      let fdate= localStorage.getItem("fromDate");
      let tdate = localStorage.getItem("toDate");
          api = API_PATHS.getlabtests +  '?vendorId=' + id +  '&fromDate=' + fdate + '&toDate=' + tdate;
   
    return axios
        .get(api)
        .then((response) => {
            if (response.data) {
                // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
}

static getRevenueVendor() {
    let api = '';
    let id = JSON.parse(localStorage.getItem("user")).data.id;
          api = API_PATHS.getLabRevenue +  '?vendorId=' + id ;
   
    return axios
        .get(api)
        .then((response) => {
            if (response.data) {
                // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
}

static getRevenueLab() {
    let api = '';
    let id = localStorage.getItem("labId");
          api = API_PATHS.getLabRevenue +  '?labId=' + id ;
   
    return axios
        .get(api)
        .then((response) => {
            if (response.data) {
                // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
}

static getlabtestdetail() {

    let api = '';
    let id = localStorage.getItem("labId");
    api = API_PATHS.getlabtests + '?_id=' + id ;
    return axios
        .get(api)
        .then((response) => {
            if (response.data) {
                // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
}

static UpdatelabTest(item) {
    let api='';
    api = API_PATHS.updatelabtestrequest;
    return axios
      .post(api,item)
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      });
  }

  static AddReports(item) {
    let api='';
    api = API_PATHS.addTestReport;
    return axios
      .post(api,item)
      .then((response) => {
        if (response.data) {
        }
        return response.data;
      });
  }

   

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default labService