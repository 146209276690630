import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import Navbarside from "./Navbarside";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./footer";
import { addcategoryfood } from "../store/slices/food";

const AddFoodcategory = () => {
  const [name, setName] = useState("");

  const [alertname, setAlertName] = useState("");

  const navigate = useNavigate("");
  const dispatch = useDispatch();

  const save = (e) => {
    const regname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (regname.test(name)) {
      setAlertName("");
    } else if (!regname.test(name) && name === "") {
      setAlertName("Please enter the food category");
      e.preventDefault();
    } else {
      setAlertName("Single and double quotes are not-valid");
      e.preventDefault();
    }
  };

  const cancel = () => {
    setName("");
    setAlertName("");
  };

  async function addData(e) {
    e.preventDefault();
    let item = { name };
    dispatch(addcategoryfood(item))
      .unwrap()
      .then(() => {
        alert("Uploaded succesfully");
        // navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">Add Food Category</h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <form onSubmit={addData}>
                        <div className="d-flex justify-content-center">
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                            Food Category Name
                            </p>
                            <input
                              type="text"
                              placeholder="Enter the food category"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertname}</p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center pt-3">
                          <button
                            type="submit"
                            className="btn btn-success"
                            onClick={save}
                          >
                            Submit
                          </button>
                          &nbsp; &nbsp; &nbsp;
                          <button
                            type="reset"
                            className="btn btn-warning"
                            onClick={cancel}
                          >
                            Reset
                          </button>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default AddFoodcategory;
