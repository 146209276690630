import React, { useState } from "react";
import Footer from "./footer";
import { clientDetail } from "../store/slices/auth";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "../component/Navbarside";
import { useParams } from "react-router-dom";
import Moment from "moment";
const UserGoals = () => {
  const [details, setDetails] = useState("");
  const [mealGoal, setmealGoal] = useState("");
  const { id } = useParams();
  localStorage.setItem("userId", id);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(clientDetail())
      .unwrap()
      .then((data) => {
        setDetails(data.user.data[0]);
        setmealGoal(data.user.data[0].mealCaloriesGoal);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid response-cover pb-5">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">User Goals</h4>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="container">
                        <div className="card-block card-dashboard table-responsive">
                          <table className="table table-striped table-bordered zero-configuration">
                            <tbody>
                              <tr>
                                <td>
                                  <p>Daily Steps:</p>
                                  {details.dailyStepCountGoal}
                                </td>
                                <td>
                                  <p>Daily Water:</p>
                                  {details.dailyWaterGoal}
                                </td>
                                <td>
                                  <p>Daily Sleep Time:</p>
                                  {details.sleepTime}
                                </td>
                                <td>
                                  <p>Calorie Intake:</p>
                                  {details.calorieIntake} KCal
                                </td>
                                <td>
                                  <p>Calorie Burnt:</p>
                                  {details.caloriesBurntGoal} KCal
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p>Breakfast:</p>
                                  {mealGoal.breakfastGoal} KCal
                                </td>
                                <td>
                                  <p>Morning Snacks:</p>
                                  {mealGoal.morningSnacksGoal} KCal
                                </td>
                                <td>
                                  <p>Lunch:</p>
                                  {mealGoal.lunchGoal} KCal
                                </td>
                                <td>
                                  <p>Afternoon Snacks:</p>
                                  {mealGoal.afternoonSnacksGoal} KCal
                                </td>
                                <td>
                                  <p>Dinner:</p>
                                  {mealGoal.dinnerGoal} KCal
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default UserGoals;
