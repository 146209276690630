import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbarside from "./Navbarside";
import { useDispatch, useSelector } from "react-redux";
import { getApprovedLabs } from "../store/slices/lab";
import Footer from "./footer";

const Labtest = () => {
  const [detailslab, setDetailslab] = useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getApprovedLabs())
      .unwrap()
      .then((data) => {
        setDetailslab(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Lab Test Booking</h4>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Lab Name</th>
                              <th>Address</th>
                              <th>Bookings</th>
                            </tr>
                          </thead>
                          <tbody>
                            {detailslab.map((LabVal, index) => (
                              <tr key={index}>
                                <td>{LabVal.name}</td>
                                <td>{LabVal.address}</td>
                                <td>
                                  <Link
                                    className="btn btn-primary"
                                    style={{
                                      height: "25px",
                                      padding: "0 10px",
                                    }}
                                    to={`/Labtest/LabTestRequest/${
                                      LabVal._id ? LabVal._id : null
                                    }`}
                                  >
                                    View Bookings
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Labtest;
