import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "./Navbarside";
import { editFaq, postfaq } from "../store/slices/faq";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "./footer";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ExpertiseService from "./../services/expertise.service";
import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";
function EditFaq() {
    const {id} = useParams()
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate("");
  const [question, setQuestion] = useState("");
  const [expertiseId, setexpertiseId] = useState("");
  const [answer, setAnswer] = useState("");
  const [alertAnswer, setalertAnswer] = useState("");
  const [alertQuestion, setAlertQuestion] = useState("");
  const [alertexpertiseId, setalertexpertiseId] = useState("");
  const [categ, setCateg] = useState('')
  const [expName, setExpName] = useState('')

  const [expertises, setExpertises] = useState([]);

  useEffect(()=>{getFaq()},[])

  const categories = [{name:"app", label :"App"},
    {name:"website", label :"Website"}
    ]

  async function upload(e) {
    e.preventDefault();
    let item = {set:{

        question:question,
        expertiseId:expertiseId,
        answer:answer,
        category:categ
    } };
    dispatch(editFaq({item,id}))
      .unwrap()
      .then(() => {
        alert("FAQ added successfully");
        navigate("/Faq");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const getFaq = async()=>{
    try{
        const getData = await axios.get(`${API_PATHS.faq}?id=${id}`,)
        if(getData){
            const data = getData?.data?.data?.[0]
            setQuestion(data?.question)
            setAnswer(data?.answer)
            setCateg(data?.category)
            const id = data?.expertiseId?.[0]
           const exp = expertises?.filter((data)=>data?._id == id)?.[0]
            setExpName(exp?.name)
            // setFaqData(getData?.data?.data?.[0])
        }
    }catch(e){console.log("e",e)}
  }

  const save = (e) => {
    if (question === "") {
      setAlertQuestion("Please enter the question");
      e.preventDefault("");
    } else {
      setAlertQuestion("");
    }

    if (expertiseId === "") {
      setalertexpertiseId("Please select");
      e.preventDefault("");
    } else {
      setalertexpertiseId("");
    }

    if (answer === "") {
      setalertAnswer("Please enter the answer");
      e.preventDefault("");
    } else {
      setAlertQuestion("");
    }
  };

  const clear = () => {
    setQuestion("");
    setAnswer("");
    setalertAnswer("");
    setAlertQuestion("");
  };

  const Expertises = createAsyncThunk(
    "expertise/get/expertise",
    async (thunkAPI) => {
      try {
        const data = await ExpertiseService.getExpertise();
        return { user: data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  const handleCategoryChange = (e) => {
    var value = expertises.filter(function (Expertises) {
      return Expertises.name == e.target.value;
    });
    setexpertiseId(value[0]._id);
  };

  React.useEffect(() => {
    dispatch(Expertises())
      .unwrap()
      .then((data) => {
        setExpertises(data.user.data);
        setexpertiseId(data.user.data[0]._id);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Edit FAQ
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form onSubmit={upload}>
                          <div className="row">
                            <div className="col-md-4 col-12">
                              <p>Expertise</p>
                              <select
                                className="form-control"
                                onChange={handleCategoryChange}
                                value={expName}
                                required
                              >
                                <option value="">Select</option>
                                {expertises.map((option) => (
                                  <option value={option.expertiseId}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                              <p className="alert-message">
                                {alertexpertiseId}
                              </p>
                            </div>
                            <div className="col-md-3 col-sm-12 pt-2" style={{position:"relative",left:"40%", transform:'translateY(-10px)'}}>
                          <p className="col-black" style={{ marginBottom: "2px" }}>Category</p>
                                <select className="form-control"
                                onChange={(e)=>setCateg(e.target.value)}
                                value={categ}
                                //  onChange={handleExpertiseChange} 
                                 >
                                  <option value="">Select Category</option>
                                  { categories?.map(option =>   (
                                          <option value={option.name}>
                                            {option.label}
                                          </option>
                                        ))
                                  }
                                </select>
                          </div>
                            <div className="col-md-12 col-sm-12">
                              <p>Question</p>
                              <textarea
                                className="form-control"
                                placeholder="Enter your question"
                                onChange={(e) => setQuestion(e.target.value)}
                                value={question}
                              />
                              <p className="alert-message">{alertQuestion}</p>
                            </div>

                            <div className="col-md-12 col-sm-12">
                              <p>Answer</p>
                              <textarea
                                className="form-control"
                                row="5"
                                placeholder="Enter answer"
                                onChange={(e) => setAnswer(e.target.value)}
                                value={answer}
                              />
                              <p className="alert-message">{alertAnswer}</p>
                            </div>
                          </div>

                          <div className="col-12 d-flex justify-content-center mt-3">
                            <button
                              type="submit"
                              className="btn btn-primary col-white"
                              onClick={save}
                            >
                              Save
                            </button>
                            &nbsp; &nbsp;
                            <button
                              type="reset"
                              className="btn btn-danger col-white"
                              onClick={()=>{navigate('/faq')}}
                            >
                              Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default EditFaq;
