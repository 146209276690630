import React from "react";
import Navbarside from "./Navbarside";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  getlabvendorEnquiry,
  ApprovelabsvendorEnquiry,
  getVendors,
} from "../store/slices/lab";
import { useState } from "react";
import Footer from "./footer";
import { ExportToExcel } from "./ExportToExcel";
import Loader from "./Loader";
import { API_PATHS } from "../utils/constants/api.constants";

const LabVendorEnquiry = () => {
  const [vendor, setVendor] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const fetchData = () => {
    setIsLoading(true);
    dispatch(getVendors())
      .unwrap()
      .then((postData) => {
        setIsLoading(false);
        console.log("postitem", postData?.user.data);
        const customHeadings = postData?.user?.data?.map((item) => ({
          Name: item.firstName,
          Mobile: item.mobileNo,
          Email: item.email,
          isAdminApproved: item.isAdminApproved,
        }));
        setVendor(postData.user.data);
        setData(customHeadings);
      })
      .catch(({ message }) => {
        setIsLoading(false);
        console.log("message", message);
        // alert(message);
      });
  };

  React.useEffect(() => {
    fetchData();
  }, [dispatch]);

  async function enableApprove(ids) {
    let status = "approved";
    let id = ids;
    let item = { id, status };
    dispatch(ApprovelabsvendorEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Approved succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
    console.log(item);
    console.log(id);
  }

  async function disableApprove(ids) {
    let status = "rejected";
    let id = ids;
    let item = { id, status };
    localStorage.setItem("item", JSON.stringify(item));
    dispatch(ApprovelabsvendorEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Request rejected succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
    console.log(item);
    console.log(id);
  }

  return (
    <div>
      <Navbarside />
      {isLoading && <Loader />}

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Lab Vendors</h4> &nbsp; &nbsp;
                        &nbsp;
                        {/* <ExportToExcel
                          apiData={data}
                          fileName={fileName}
                          style={{
                            height: "25px",
                            padding: "0 10px",
                            marginBottom: "0%",
                          }}
                        /> */}
                        &nbsp; &nbsp; &nbsp;
                        {/* <Link to="/AddExpert" className="btn btn-success">
                          Add Expert
                        </Link> */}
                      </div>
                    </div>
                    {/* <FilterComponent
                    onCategoryChange={handleExpertiseChange}
                    onApply={applyFilter}
                  /> */}
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Mobile Number</th>
                              <th>Is Verified</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {vendor?.map((LabVal, index) => (
                              <tr key={index}>
                                <td>
                                  <Link
                                    to={`/MyLabs/LabVendorDetails/${
                                      LabVal._id ? LabVal._id : null
                                    }`}
                                  >
                                    {LabVal.firstName} {LabVal.lastName}
                                  </Link>
                                </td>
                                <td>{LabVal.email}</td>
                                <td>{LabVal.mobileNo}</td>
                                <td>
                                  {/* {LabVal.isAdminApproved === false && ( */}
                                  <button
                                    // onClick={() =>
                                    //   enableApprove(LabVal._id)
                                    // }
                                    className={
                                      LabVal.isAdminApproved === false
                                        ? "btn btn-warning"
                                        : "btn btn-success"
                                    }
                                    style={{
                                      height: "25px",
                                      padding: "0 10px",
                                      cursor: "default",
                                    }}
                                  >
                                    {LabVal.isAdminApproved === false
                                      ? "Disable"
                                      : "Verified"}
                                  </button>
                                </td>
                                <td>
                                  <Link
                                    to={`/MyLabs/LabVendorDetails/${
                                      LabVal._id ? LabVal._id : null
                                    }`}
                                    className="btn btn-primary"
                                    style={{
                                      height: "25px",
                                      padding: "0 10px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    View Details
                                  </Link>
                                  {/* <Link
                                  to={`/EditExpert/${
                                    LabVal._id ? LabVal._id : null
                                  }`}
                                  className="btn btn-success"
                                  style={{
                                    height: "25px",
                                    padding: "0 10px",
                                    marginRight: "10px",
                                  }}
                                >
                                 View Revenue
                                </Link> */}
                                  {/* <button
                                  className="btn btn-danger"
                                  onClick={() => deleteData(LabVal._id)}
                                  style={{
                                    height: "25px",
                                    padding: "0 10px",
                                    marginRight: "10px",
                                  }}
                                >
                                  Delete
                                </button>
                                &nbsp; */}
                                  {/* {LabVal.isAdminApproved === true && ( */}
                                  {/* <Link
                                    to={`/EditExpert/${
                                      LabVal._id ? LabVal._id : null
                                    }`}
                                  >
                                    <button
                                      className="btn btn-success"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                        marginRight: "10px",
                                        marginTop: "-15px",
                                      }}
                                    >
                                      View Revenue
                                    </button>
                                  </Link> */}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default LabVendorEnquiry;
