import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "../../component/Navbarside";
import {
  getAllcommunity,
  approvecommunity,
  DeletePost,
} from "../../store/slices/Community";
import Footer from "../footer";
import { confirmAlert } from "react-confirm-alert";
import { Link, useNavigate } from "react-router-dom";
import VideoPlayer from '../videoPlayer/VideoPlayer';
import VideoFilePlayer from "../videoPlayer/videoFilePlayer";


const Community = () => {
  const dispatch = useDispatch();
  const [communitys, setCommunitys] = useState([]);

  React.useEffect(() => {
    dispatch(getAllcommunity())
      .unwrap()
      .then((data) => {
        setCommunitys(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  async function disableApprove(ids) {
    let isApproved = false;
    let id = ids;
    let item = { set: { isApproved } };
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("postsId", id);
    dispatch(approvecommunity(item))
      .unwrap()
      .then(() => {
        alert("Post rejected succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
    // console.log(item);
    // console.log(id);
  }

  async function enableApprove(ids) {
    let isApproved = true;
    let id = ids;
    let item = { set: { isApproved } };
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("postsId", id);
    dispatch(approvecommunity(item))
      .unwrap()
      .then(() => {
        alert("Post approved succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
    // console.log(item);
    // console.log(id);
  }

  const Delete = async function deletePlaylist(id) {
    localStorage.setItem("id", id);
    dispatch(DeletePost())
      .unwrap()
      .then(() => {
        // alert("Uploaded succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });

    console.log(id);
  };
  const deleteData = (id) => {
    confirmAlert({
      title: "Want to delete?",
      message: "Are you sure you want to delete this post?",
      buttons: [
        {
          label: "Yes",
          onClick: () => Delete(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  function isVideoLink(url) {
    var videoExtensions = ['mp4', 'avi', 'wmv', 'mov', 'flv'];
    var fileExtension = url.split('.').pop();

    return {
      status: videoExtensions.includes(fileExtension),
      format: fileExtension
    }
  }
  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-wrap bar-success">
                          <h4 className="card-title">
                            Community &nbsp; &nbsp;
                            <Link to="/FlaggedPost" className="btn btn-danger">
                              Flgged Post
                            </Link>
                            &nbsp; &nbsp;
                            <Link
                              to="/Community/AddCommunity"
                              className="btn btn-primary"
                            >
                              <i className="fa fa-plus"></i> Add
                            </Link>
                          </h4>
                        </div>
                      </div>
                      <div className="card-body collapse show">
                        <div className="card-block card-dashboard table-responsive">
                          <table className="table table-striped table-bordered zero-configuration">
                            <thead>
                              <tr>
                                <th style={{ width: "30%" }}>Post</th>
                                {/* <th>Description</th> */}
                                <th>Details</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>

                            <tbody>
                              {communitys.map((communityVal, index) => (
                                <tr>
                                  <td>
                                    {" "}
                                    {/* <Link
                                      to={`/CommunityDetails/${communityVal._id
                                        ? communityVal._id
                                        : null
                                        }`}
                                    > */}

                                    {communityVal?.mediaLink?.includes('s3')
                                      ? isVideoLink(communityVal.mediaLink).status ? <VideoFilePlayer s3Link={communityVal.mediaLink} format={isVideoLink(communityVal.mediaLink).format} /> : <img
                                        src={communityVal.mediaLink}
                                        alt=""
                                        style={{
                                          width: "150px",
                                          height: "100px",
                                        }}
                                      />
                                      : <VideoPlayer url={communityVal.mediaLink} />
                                    }

                                    {/* </Link> */}
                                  </td>

                                  <td>
                                    Added On: {communityVal.date}
                                    <br />
                                    Description: {communityVal.description}
                                    <br />
                                    LikesCount: {communityVal.likesCount}
                                    <br />
                                    CommentsCount: {communityVal.commentsCount}
                                  </td>
                                  <td>
                                    {" "}
                                    {communityVal.isApproved === false && (
                                      <button
                                        className="btn btn-warning"
                                        style={{
                                          height: "25px",
                                          padding: "0 10px",
                                        }}
                                      >
                                        Approval Pending/ Rejected
                                      </button>
                                    )}
                                    {communityVal.isApproved === true && (
                                      <button
                                        className="btn btn-success"
                                        style={{
                                          height: "25px",
                                          padding: "0 10px",
                                        }}
                                      >
                                        Approved
                                      </button>
                                    )}
                                  </td>

                                  <td>
                                    <button
                                      className="btn btn-success "
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                      onClick={() =>
                                        enableApprove(communityVal._id)
                                      }
                                    >
                                      Approve
                                    </button>
                                    &nbsp;
                                    <button
                                      className="btn btn-danger mt-2"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                      onClick={() =>
                                        disableApprove(communityVal._id)
                                      }
                                    >
                                      Reject
                                    </button>
                                    &nbsp;
                                    <button
                                      onClick={() =>
                                        deleteData(communityVal._id)
                                      }
                                      className="btn btn-warning"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
};

export default Community;
