import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import fitnessplansService from "../../services/fitnessplans.service";
import AuthService from "../../services/auth.service";


export const getFitnessPlans = createAsyncThunk(
    "fitness/get/fitness",
    async(thunkAPI) => {
        try {
            const data = await fitnessplansService.getFitnessCenterPlans();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)

export const fitnessCenterPlans = createAsyncThunk(
    "fitness/get/fitness",
    async(thunkAPI) => {
        try {
            const data = await fitnessplansService.fitnessCenterPlans();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)

export const fitnessCenterPlansById = createAsyncThunk(
    "fitness/get/fitness",
    async(thunkAPI) => {
        try {
            const data = await fitnessplansService.fitnessCenterPlansById(thunkAPI);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)

export const fitnessCenterRevenue = createAsyncThunk(
    "fitness/get/fitness",
    async(thunkAPI) => {
        try {
            const data = await fitnessplansService.getFitnessCenterRevenue();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)

export const getFitnessPlanDetails = createAsyncThunk(
    "fitness/get/fitness",
    async(thunkAPI) => {
        try {
            const data = await fitnessplansService.getFitnessCenterPlanDetails();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)

export const editPlan = createAsyncThunk(
    "fitness/put/fitness",
    async(item, thunkAPI) => {
        try {
            const data = await fitnessplansService.putfitnesscenterPlan(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
  );
export const getFitnessPlanCategory = createAsyncThunk(
    "fitness/get/fitness",
    async(thunkAPI) => {
        try {
            const data = await fitnessplansService.getFitnessPlanCategory();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)




// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
    loading: false,
    error: "",
    user: AuthService.getUserDetails() || null,
    isLoggedIn: false,
};

const fitnessSlice = createSlice({
    name: "fitness",
    initialState,
    extraReducers: {
        
      

        [getFitnessPlans.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getFitnessPlans.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getFitnessPlans.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },

        // [logout.fulfilled]: (state, action) => {
        //   state.isLoggedIn = false;
        //   state.user = null;
        // },
    },
});

const { reducer } = fitnessSlice;
export default reducer;