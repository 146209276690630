import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader, MessageHeader } from "semantic-ui-react";
import Navbarside from "../../Navbarside";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Footer from "../../footer";
import { FaVideo } from "react-icons/fa";
import { addplaylists } from "../../../store/slices/playlist";
import { API_PATHS, API_BASE_URL_HC } from "../../../utils/constants/api.constants";
import { put, get } from 'axios';

const AddPlaylist = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const goBack = () => navigate(-1);
  const searchParams = new URLSearchParams(location.search);
  const masterCategoryId = searchParams.get('masterCat');
  const mastCatName = searchParams.get('mastCatName');
  const categoryId = searchParams.get('cat')
  const catName = searchParams.get('catName');

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [mediaLink, setMediaLink] = useState("");
  const [details, setDetails] = useState("");

  const [id, setId] = useState(searchParams.get('id'));
  const [plData, setPlData] = useState("");

  const [message, setMessage] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const id = searchParams.get('id');
    if (id && !plData) {
      setId(id)
      get(`${API_BASE_URL_HC}/get/playlist?id=${id}`).then(res => {
        if (res.data?.data?.length) {
          setPlData(res.data.data[0])
          let { name, description, mediaLink } = res.data.data[0]

          setName(name)
          setDescription(description)
          setMediaLink(mediaLink)
        }
      }).catch(err => {
        alert(err.message)
      })
    }
  })

  async function addData(e) {
    e.preventDefault();
    dispatch(addplaylists({
      name,
      description,
      mediaLink,
      categoryId,
      masterCategoryId
    })).unwrap().then(() => {
      alert("Uploaded succesfully");
      navigate(-1);
    }).catch(({ message }) => {
      alert(message);
    });
  }

  async function updateData(e) {
    e.preventDefault();
    put(`${API_BASE_URL_HC}/put/playlist?id=${id}`, {
      set: {
        name,
        description,
        mediaLink,
      }
    }).then(res => {
      alert(res.data.message)
      navigate(-1)
    }).catch(err => alert(err.message))
  }

  async function imageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setMediaLink(data.data.location);
    setMessage(data.message);
  }

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">
                          {id ? 'Update Playlist' : 'Add Playlist'}
                        </h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <form>
                        <div className="row">
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Playlist title
                            </p>
                            <input
                              type="text"
                              placeholder="Enter the content title"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              className="form-control"
                            />
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Description
                            </p>
                            <input
                              type="text"
                              placeholder="Enter the Description"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              className="form-control"
                            />
                          </div>

                          {/* <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Details
                            </p>
                            <input
                              type="text"
                              placeholder="Enter the details"
                              value={details}
                              onChange={(e) => setDetails(e.target.value)}
                              className="form-control"
                            />
                          </div> */}
                          {id
                            ? <><div className="col-md-6">
                              <p
                                className="col-black"
                                style={{ marginBottom: "2px" }}
                              >
                                Image
                              </p>
                              {mediaLink
                                ? <img src={mediaLink} height="100" alt='mediaLink' />
                                : 'No image added'}
                            </div>

                            </>
                            : ''
                          }


                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              add/update image
                            </p>
                            <input
                              type="file"
                              className="form-control-file"
                              Placeholder="Price Tagline"
                              required
                              onChange={(e) => imageUpload(e)}
                            />
                            <p style={{ color: "green", fontWeight: "500" }}>
                              {message}
                            </p>
                          </div>
                          <div className="col-md-12 col-sm-12 pt-2">
                            <p className="col-black"
                              style={{ marginBottom: "2px" }}>Selected Master Category : {mastCatName}</p>
                          </div>
                          <div className="col-md-12 col-sm-12 pt-2">
                            <p className="col-black"
                              style={{ marginBottom: "2px" }}>Selected Category : {catName}</p></div>
                        </div>
                        <div className="d-flex justify-content-center pt-3">
                          {id
                            ? <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={updateData}
                            >
                              Update Category
                            </button>
                            : <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={addData}
                            >
                              Add Category
                            </button>
                          }
                          <button onClick={goBack} className="btn btn-danger">Go Back</button>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default AddPlaylist;
