import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../services/auth.service";

import HcConsultationService from './../../services/hcConsultation.service';


export const initiatefreeConsultation = createAsyncThunk(
  "const/post/const",
  async (item, thunkAPI) => {
    try {
      const data = await HcConsultationService.addconsultation(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const postHealthprescription = createAsyncThunk(
  "const/post/const",
  async (item, thunkAPI) => {
    try {
      const data = await HcConsultationService.addHCPrescriptions(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const HcConsultations = createAsyncThunk(
  "consultation/get/consultation",
  async (thunkAPI) => {
    try {
      // console.log("consultation")
      const data = await HcConsultationService.getEnquiry(localStorage.getItem('flow'));
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const getHcConsultation = createAsyncThunk(
  "heathcare/get/heathcare",
  async (thunkAPI) => {
    try {
      const data = await HcConsultationService.gethealthcareconsultation(thunkAPI);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
export const getUpcomingHcConsultation = createAsyncThunk(
  "heathcare/get/heathcare",
  async (thunkAPI) => {
    try {
      const data = await HcConsultationService.getupcominghealthcareconsultation();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const HcConsultationDetails = createAsyncThunk(
  "consultation/get/consultation",
  async (thunkAPI) => {
    try {
      // console.log("consultation")
      const data = await HcConsultationService.getConsultationDetails();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const updateconsultation = createAsyncThunk(
  "const/put/const",
  async (item, thunkAPI) => {
    try {
      const data = await HcConsultationService.putconsultation(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const updateavailablity = createAsyncThunk(
  "const/put/const",
  async (item, thunkAPI) => {
    try {
      const data = await HcConsultationService.editavailablity(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


export const getConsultationid = createAsyncThunk(
  "const/put/const",
  async (item, thunkAPI) => {
    try {
      const data = await HcConsultationService.addconsultation();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


export const getDocConsultation = createAsyncThunk(
  "const/put/const",
  async (thunkAPI) => {
    try {
      const data = await HcConsultationService.getDoctorConsultation();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);



export const getPatientConsultation = createAsyncThunk(
  "const/put/const",
  async (thunkAPI) => {
    try {
      const data = await HcConsultationService.getPatientConsultation();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
  loading: false,
  error: "",
  user: AuthService.getUserDetails() || null,
  isLoggedIn: false,
};

const consultationSlice = createSlice({
  name: "consultation",
  initialState,
  extraReducers: {
    [HcConsultations.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [HcConsultations.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [HcConsultations.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },

    [updateconsultation.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [updateconsultation.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [updateconsultation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },

    [getConsultationid.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [getConsultationid.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [getConsultationid.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },


    // [logout.fulfilled]: (state, action) => {
    //   state.isLoggedIn = false;
    //   state.user = null;
    // },
  },
});

const { reducer } = consultationSlice;
export default reducer;