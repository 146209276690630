import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import Navbarside from "../component/Navbarside";

import { category } from "./../store/slices/consultation";
import Footer from "./footer";

function Updateconsultphysiotherapy(e) {
  const [meetingLink, setMeetingLink] = useState("");
  const [status, setStatus] = useState("");

  const [alertmeetingLink, setAlertMeetingLink] = useState("");
  const [alertstatus, setAlertStatus] = useState("");

  const navigate = useNavigate("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  let id = "6230c1f39f30124474bfc38e";
  localStorage.setItem("id", id);
  async function upload(e) {
    e.preventDefault();
    let expertId = "2w32fadasd3asda";
    let item = {
      set: { meetingLink, status, expertId },
    };
  }

  //   const save = (e) => {
  //     const regName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
  //     if (regName.test(categoryName)) {
  //       setAlertcategoryName("");
  //     } else if (!regName.test(categoryName) && categoryName === "") {
  //       setAlertcategoryName("Please enter your name");
  //       e.preventDefault("");
  //     } else {
  //       setAlertcategoryName("Single and double quotes are not-valid");
  //       e.preventDefault();
  //     }
  //   };

  //   const clear = () => {
  //     setcategoryName("");
  //     setAlertcategoryName("");
  //   };

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Update Consultatin Physiotherapy
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form className="form" onSubmit={upload}>
                          <div className="row justify-content-md-center">
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Meeting Link
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter meeting link"
                                    value={meetingLink}
                                    onChange={(e) =>
                                      setMeetingLink(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">
                                    {alertmeetingLink}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Status</label>
                                  <select
                                    name="status"
                                    id="consult"
                                    className="form-control"
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                  >
                                    <option value="">Select Status</option>
                                    <option value="meetingLinkGenerated">
                                      meetingLinkGenerated
                                    </option>
                                    <option value="meetingLinkGenerated">
                                      meetingLinkGenerated
                                    </option>
                                  </select>
                                  <p className="alert-message">{alertstatus}</p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-actions center">
                            <button
                              type="submit"
                              className="btn btn-blue"
                              onClick=""
                            >
                              <i className="icon-note"></i> Save
                            </button>{" "}
                            &nbsp;
                            <button
                              type="reset"
                              className="btn btn-warning mr-1"
                              onClick=""
                            >
                              <i className="icon-trash"></i> Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default Updateconsultphysiotherapy;
