import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "./Navbarside";
import { getexercises } from "../store/slices/exercise";
import Footer from "./footer";
import { Link, useNavigate } from "react-router-dom";
import { TableHeader, Pagination, Search } from "./DataTable";
import { post } from 'axios';

const Exercise = () => {
  const dispatch = useDispatch();
  const [perPage] = useState(10);
  const [dataCount, setdataCount] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [exercises, setExercises] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 100;

  const headers = [
    { name: "Exercise Name", field: "name", sortable: true },
    { name: "Exercise Type", field: "exerciseType", sortable: true },
    { name: "Image", field: "body", sortable: false },
    { name: "Weight Unit", field: "weightUnit", sortable: false },
    { name: "Calorie Factor", field: "calorieFactor", sortable: false },
    { name: "Action", field: "", sortable: false },
  ];

  React.useEffect(() => {
    dispatch(getexercises())
      .unwrap()
      .then((data) => {
        setExercises(data.user.data);
        setdataCount(data.user.dataCount);
        setPageCount(Math.ceil(dataCount.length / perPage));
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);
  const commentsData = useMemo(() => {
    let computedComments = exercises;

    if (search) {
      computedComments = computedComments.filter((comment) =>
        comment.name.toLowerCase().includes(search.toLowerCase())
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [exercises, currentPage, search, sorting]);

  const resync = () => {
    post(`https://api-wm.healthonify.com/exercise/resync`).then(res => res.data == 'done'
      ? alert(`Resync in progress, mail will be sent info@healthonify and Aadesh upon completion (approx 3 mins)`)
      : alert('Something went wrong')).catch(err => alert(`${err.message}, the error details have been mailed to server@healthonify, please contact dev team to check`))
  }

  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-wrap bar-success">
                          <h4 className="card-title">
                            Exercise &nbsp; &nbsp; <button className="btn btn-success" onClick={resync}>Resync Data</button>
                          </h4>
                        </div>
                      </div>
                      <div className="card-body collapse show">
                        <div className="card-block card-dashboard table-responsive">
                          <div className="row">
                            <div
                              className="col-md-12 d-flex flex-row-reverse"
                              align="right"
                            >
                              <Search
                                onSearch={(value) => {
                                  setSearch(value);
                                  setCurrentPage(1);
                                }}
                              />
                            </div>
                          </div>
                          <br />
                          <table className="table table-striped">
                            <TableHeader
                              headers={headers}
                              onSorting={(field, order) =>
                                setSorting({ field, order })
                              }
                            />
                            <tbody>
                              {commentsData.map((comment) => (
                                <tr>
                                  <td>{comment.name}</td>
                                  <td>{comment.exerciseType}</td>
                                  <td>
                                    <img
                                      src={comment.mediaLink}
                                      alt="bodypart"
                                      style={{
                                        borderRadius: "10px",
                                        height: "60px",
                                        width: "60px",
                                      }}
                                    />
                                  </td>
                                  <td>{comment.weightUnit}</td>
                                  <td>{comment.calorieFactor}</td>
                                  <td className="d-flex justify-content-center">
                                    <Link
                                      to={`/EditExercise/${comment._id ? comment._id : null
                                        }`}
                                      className="btn btn-primary col-white"
                                    >
                                      Edit
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div className="row">
                            <div className="col-md-6">
                              <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={(page) => setCurrentPage(page)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
};

export default Exercise;
