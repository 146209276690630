import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Questionnaire from "../../services/questionnaire.service";
import AuthService from "../../services/auth.service";



export const Addquestion = createAsyncThunk(
    "addquestion/store/question",
    async(item, thunkAPI) => {
        try {
            const data = await Questionnaire.QuestionnaireService(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) || 
                error.message ||  
                error.toString(); 
            return thunkAPI.rejectWithValue({ message });  
        }
    }
);

export const getquestions = createAsyncThunk(
    "quation/get/quation",
    async(thunkAPI) => {
        try {
            const data = await Questionnaire.getquestion();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
    loading: false,
    error: "",
    user: AuthService.getUserDetails() || null,
    isLoggedIn: false,
};

const QuestionSlice = createSlice({
    name: "addquestion",
    initialState,
    extraReducers: {
    
        [Addquestion.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [Addquestion.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [Addquestion.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },


        [getquestions.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getquestions.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getquestions.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },



        // [logout.fulfilled]: (state, action) => {
        //   state.isLoggedIn = false;
        //   state.user = null;
        // },
    },
});

const { reducer } = QuestionSlice;
export default reducer;