import React from "react";
import Navbarside from "../component/Navbarside";
import Footer from "./footer";
import { Link } from "react-router-dom";
import { formatDate } from "../utils/appUtils";
function Weightmanagement() {
  const dateForQuery = ()=>{
    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    const formattedToday = formatDate(today);
    const formattedOneMonthAgo = formatDate(oneMonthAgo);
    return {startDate:formattedToday, endDate:formattedOneMonthAgo}
  }
  return (
    <div>
      <Navbarside />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">

                <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                  <Link to="/Enquiry?flow=manageWeight&specific=1">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-10 pb-10">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h5 className="font-medium-2 card-title mb-0">
                                Consultation Enquiry
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                  <Link to={`/SessionAnalysis?startDate=${dateForQuery().startDate}&&endDate=${dateForQuery().endDate}&&category=manageWeight`}>
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-10 pb-10">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h5 className="font-medium-2 card-title mb-0">
                                Session
                              </h5>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-chalkboard-teacher fa-edit-icon danger"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>


                <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                  <Link to="/Weightmanagement/Dish">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-10 pb-10">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h5 className="font-medium-2 card-title mb-0">
                                Food Library
                              </h5>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-mug-hot fa-edit-icon primary"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                  <Link to="/Weightmanagement/Recipes">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-10 pb-10">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h5 className="font-medium-2 card-title mb-0">
                                Recipes
                              </h5>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-utensils fa-edit-icon warning"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                  <Link to="/Weightmanagement/Foodcategory">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-10 pb-10">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h5 className="font-medium-2 card-title mb-0">
                                Food Category
                              </h5>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-hamburger fa-edit-icon info"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                  <Link to="/DietPlans">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-10 pb-10">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h5 className="font-medium-2 card-title mb-0">
                                Diet Plan
                              </h5>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-hamburger fa-edit-icon info"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                  <Link to="/Weightmanagement/Foodtype">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-10 pb-10">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h5 className="font-medium-2 card-title mb-0">
                                Food Type
                              </h5>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-pizza-slice fa-edit-icon warning"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                {/* <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                  <Link to="/Weightmanagement/Package">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-10 pb-10">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h5 className="font-medium-2 card-title mb-0">
                                Package
                              </h5>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-pizza-slice fa-edit-icon warning"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Weightmanagement;
