import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "./Navbarside";
import { getonboarding, editCorporateEnquiry } from "../store/slices/corporate";
import Footer from "./footer";
import { Link, useNavigate } from "react-router-dom";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Moment from "moment";
import { ExportToExcel } from "./ExportToExcel";
import { API_PATHS } from "../utils/constants/api.constants";
import axios from "axios";

const CorporateEnquiry = () => {
  const dispatch = useDispatch();
  const [onboarding, setOnboarding] = useState([]);

  const [data, setData] = useState([]);
  const fileName = "Company Enquire";

  React.useEffect(() => {
    const fetchData = () => {
      axios.get(API_PATHS.onboard).then((postData) => {
        const customHeadings = postData.data.data.map((item) => ({
          Name: item.name,
          "Mobile No.": item.contactNumber,
          Email: item.email,
          "Company Name": item.companyName,
        }));

        setData(customHeadings);
      });
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    dispatch(getonboarding())
      .unwrap()
      .then((data) => {
        setOnboarding(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  async function enableApprove(id) {
    let status = "closed";
    let item = { set: { status } };
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("id", id);
    dispatch(editCorporateEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Approved succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
    console.log(item);
    console.log(id);
  }

  async function disableApprove(id) {
    let status = "rejected";
    let item = { set: { status } };
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("id", id);
    dispatch(editCorporateEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Request rejected succesfully");
        // window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
    console.log(item);
    console.log(id);
  }

  const rejectData = (id) => {
    confirmAlert({
      title: "Want to reject the request?",
      message: "Are you sure you want to reject this request?",
      buttons: [
        {
          label: "Yes",
          onClick: () => disableApprove(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-wrap bar-success d-flex align-items-center">
                          <h4 className="card-title">Corporate Enquire</h4>{" "}
                          &nbsp; &nbsp; &nbsp;
                          <ExportToExcel
                            apiData={data}
                            fileName={fileName}
                            style={{
                              height: "25px",
                              padding: "0 10px",
                              marginBottom: "0%",
                            }}
                          />
                        </div>
                      </div>
                      <div className="card-body collapse show">
                        <div className="card-block card-dashboard table-responsive">
                          <table className="table table-striped table-bordered zero-configuration">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Contact Number</th>
                                <th>Message</th>
                                <th>Company Name</th>
                                <th>Enquiry Date/ Time</th>
                                <th>Status</th>
                              </tr>
                            </thead>

                            <tbody>
                              {onboarding.map((boardVal, index) => (
                                <tr>
                                  <td>{boardVal.name}</td>
                                  <td>{boardVal.email}</td>

                                  <td>{boardVal.contactNumber}</td>
                                  <td>{boardVal.message}</td>
                                  <td>{boardVal.companyName}</td>
                                  <td>
                                    {" "}
                                    {Moment(boardVal.created_at).format(
                                      "DD-MM-YYYY"
                                    )}
                                    /{" "}
                                    {Moment(boardVal.created_at).format(
                                      "hh:mm A"
                                    )}
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-success"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                      onClick={() =>
                                        enableApprove(boardVal._id)
                                      }
                                    >
                                      Approve
                                    </button>
                                    &nbsp;
                                    <button
                                      className="btn btn-danger"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                      onClick={() => rejectData(boardVal._id)}
                                    >
                                      Reject
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
};

export default CorporateEnquiry;
