import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dietService from "../../services/diet.service";
import AuthService from "../../services/auth.service";




export const getdietplan = createAsyncThunk(
  "exercise/store/exercise",
  async (thunkAPI) => {
    try {
      const data = await dietService.getdietplan(thunkAPI);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const getExpertdiet = createAsyncThunk(
  "exercise/store/exercise",
  async (thunkAPI) => {
    try {
      const data = await dietService.getExpertdietplan();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


export const getdietDetails = createAsyncThunk(
  "exercise/store/exercise",
  async (thunkAPI) => {
    try {
      const data = await dietService.getdietplanDetails();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


export const DeleteDietplan = createAsyncThunk(
  "dietplan/delete/dietPlan",
  async (thunkAPI) => {
    try {
      const data = await dietService.deleteDietplan();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
  loading: false,
  error: "",
  user: AuthService.getUserDetails() || null,
  isLoggedIn: false,
};

const TravelSlice = createSlice({
  name: "addtravels",
  initialState,
  extraReducers: {




    // [logout.fulfilled]: (state, action) => {
    //   state.isLoggedIn = false;
    //   state.user = null;
    // },
  },
});

const { reducer } = TravelSlice;
export default reducer;