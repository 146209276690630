import React from "react";
import { useDispatch } from "react-redux";
import Navbarside from "../component/Navbarside";
import { ayurvedaEnquiry } from "../store/slices/enquiry";
import Moment from "moment";
import Footer from "./footer";
import $ from "jquery";

const GeneralAyurvedaEnquiry = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(ayurvedaEnquiry())
      .unwrap()
      .then((data) => {
        $("#example").DataTable({
          data: data.user.data.map((e, index) => {
            return { index, ...e };
          }),
          columns: [
            {
              title: "#",
              render: (data, type, row) => {
                return ++row.index;
              },
            },
            {
              title: "User Details",
              render: (data, type, row) => {
                return `Name: ${row.name}<br> Email: ${row.email}<br>Mobile Number: ${row.contactNumber}`;
              },
            },
            {
              data: "message",
              title: "Message",
            },
            {
              title: "Date of Enquiry",
              render: (data, type, row) => {
                return `${Moment(row.created_at).format("DD-MM-YYYY")}/${Moment(
                  row.created_at
                ).format("hh:mm A")}`;
              },
            },
            {
              data: "status",
              title: "Status",
              render: (data, type, row) => {
                return row.status ?? "";
              },
            },
            {
              title: "Action",
              render: (data, type, row) => {
                const updateStatusBtn = `<div><a href="/UpdateEnquiry/${row._id ? row._id : null
                  }" class="btn btn-primary" style="height: 25px; padding: 0 10px">Update Status</a></div>`;
                const assignExpertBtn = `<div><a
                href="/ProcessEnquiry/:id'${row._id ? row._id : null
                  }?userId=${row.userId ? row.userId : 'guest'}&ticketNumber=${row.ticketNumber}"
                class="btn btn-warning"
                style="height: 25px; padding: 0 10px"
              >
                Assign Expert
              </a></div>`;
                return row.status === "Interested" ? updateStatusBtn + assignExpertBtn : updateStatusBtn;

              },
            },
          ],
          destroy: true,
        });
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">
                          General Ayurveda Enquiry                        </h4>{" "}
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table id="example" className="display"></table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default GeneralAyurvedaEnquiry;
