import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import labService from "../../services/lab.service";
import AuthService from "../../services/auth.service";

export const getlabvendorEnquiry = createAsyncThunk(
    "lab/get/lab",
    async(thunkAPI) => {
        try {
            const data = await labService.getlabEnquiry();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)



export const getLabs = createAsyncThunk(
    "lab/get/lab",
    async(thunkAPI) => {
        try {
            const data = await labService.getLabs();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)

export const getViewLabs = createAsyncThunk(
    "lab/get/viewlab",
    async(thunkAPI) => {
        try {
            const data = await labService.getViewLabs();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)

export const getVendorLabs = createAsyncThunk(
    "lab/get/lab",
    async(thunkAPI) => {
        try {
            const data = await labService.getVendorLabs();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)


export const getVendors = createAsyncThunk(
    "lab/get/Vendors",
    async(thunkAPI) => {
        try {
            const data = await labService.getVendors();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)

export const LabTestOrder = createAsyncThunk(
    "/fetch/labOrders",
    async(thunkAPI) => {
        try {
            const data = await labService.LabTestOrder();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)

export const getVendorLabsDetails = createAsyncThunk(
    "lab/get/VendorsDetails",
    async(thunkAPI) => {
        try {
            const data = await labService.getVendorLabsDetails();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)

export const getApprovedLabs = createAsyncThunk(
    "lab/get/lab",
    async(thunkAPI) => {
        try {
            const data = await labService.getApprovedLabs();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)

export const getLabDetails = createAsyncThunk(
    "lab/get/lab",
    async(thunkAPI) => {
        try {
            const data = await labService.getLabDetail();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
)

export const postlabdetails = createAsyncThunk(
    "lab/post/lab",
    async(item, thunkAPI) => {
        try {
            const data = await labService.ListLab(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const ApprovelabsvendorEnquiry = createAsyncThunk(
    "lab/put/lab",
    async(item, thunkAPI) => {
        try {
            const data = await labService.ApprovelabEnquiry(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
  );

  export const getlabtestresult = createAsyncThunk(
    "labtest/get/labtest",
    async(thunkAPI) => {
        try {
            const data = await labService.getlabtest();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getVendorRevenue = createAsyncThunk(
    "labtest/get/labtest",
    async(thunkAPI) => {
        try {
            const data = await labService.getRevenueVendor();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getLabRevenue = createAsyncThunk(
    "labtest/get/labtest",
    async(thunkAPI) => {
        try {
            const data = await labService.getRevenueLab();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const getlabtestresultDatewise = createAsyncThunk(
    "labtest/get/labtest",
    async(thunkAPI) => {
        try {
            const data = await labService.getlabtestDatewise();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getCitys = createAsyncThunk(
    "labtest/get/labtest",
    async(thunkAPI) => {
        try {
            const data = await labService.getCity();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getStates = createAsyncThunk(
    "labtest/get/labtest",
    async(thunkAPI) => {
        try {
            const data = await labService.getStates();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getlabtestresultdetails = createAsyncThunk(
    "labtest/get/labtest",
    async(thunkAPI) => {
        try {
            const data = await labService.getlabtestdetail();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const UpdateTestRequest = createAsyncThunk(
    "lab/put/lab",
    async(item, thunkAPI) => {
        try {
            const data = await labService.UpdatelabTest(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
  );

  export const AddReport = createAsyncThunk(
    "lab/put/lab",
    async(item, thunkAPI) => {
        try {
            const data = await labService.AddReports(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
  );




// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
    loading: false,
    error: "",
    user: AuthService.getUserDetails() || null,
    isLoggedIn: false,
};

const LabSlice = createSlice({
    name: "Lab",
    initialState,
    extraReducers: {
        [getlabvendorEnquiry.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getlabvendorEnquiry.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getlabvendorEnquiry.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },

     


        [ApprovelabsvendorEnquiry.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [ApprovelabsvendorEnquiry.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [ApprovelabsvendorEnquiry.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },

        

        // [logout.fulfilled]: (state, action) => {
        //   state.isLoggedIn = false;
        //   state.user = null;
        // },
    },
});

const { reducer } = LabSlice;
export default reducer;