import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "./Navbarside";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./footer";
import { API_PATHS } from "../utils/constants/api.constants";

const AddDiet = () => {
  const navigate = useNavigate("");
  const [alertname, setAlertName] = useState("");
  const [alertcaloriesPerRep, setAlertCaloriesPerRep] = useState("");
  const [alertminWeight, setAlertMinWeight] = useState("");
  const [alertweightUnit, setAlertWeightUnit] = useState("");
  const [alertmediaLink, setAlertMediaLink] = useState("");
  const [alertbodyPartId, setAlertBodyPartId] = useState("");
  const [alertbodyPartGroupId, setAlertBodyPartGroupId] = useState("");

  const [name, setName] = useState("");
  const [validity, setValidity] = useState("");
  const [error, setError] = useState("");
  const [note, setNote] = useState("");
  const [goal, setGoal] = useState("");
  const [level, setLevel] = useState("");
  const [type, setType] = useState("");
  const [planType, setplanType] = useState("");
  const [price, setPrice] = useState("");

  let formdata = new FormData();
  const imageUpload = (e) => {
    console.log(e.target.files[0]);
    const file = e.target.files[0];
    const validExtensions = ['xlsx', 'xls'];
    const validMimeTypes = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ];

    // Get the file extension
    const fileExtension = file.name.split('.').pop().toLowerCase();
    
    // Validate the file
    if (
      !validExtensions.includes(fileExtension) ||
      !validMimeTypes.includes(file.type)
    ) {
      setError('Please upload a valid Excel file (.xlsx or .xls)');
      e.target.value = null; // Clear the input
      return;
    }
    if (e.target && e.target.files[0]) {
      setError('')
      formdata.append("file", e.target.files[0]);
    }
  };

  async function addData(e) {
    e.preventDefault();
    formdata.append("name", name);
    formdata.append("planType", planType);
    formdata.append("validity", validity);
    formdata.append("note", note);
    formdata.append("goal", goal);
    formdata.append("level", level);
    formdata.append("type", type);
    formdata.append("price", price);
    formdata.append("adminId", "628e07c3295cbb2a64996d2d");

    var requestOptions = {
      method: "POST",
      body: formdata,
      // redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadDietPlan, requestOptions);
    let data = await response.json();
    alert("Uploaded succesfully");
    navigate(-1);
  }

  const cancel = () => {
    setName("");
    setAlertName("");
    setAlertCaloriesPerRep("");
    setAlertMinWeight("");
    setAlertWeightUnit("");
    setAlertMediaLink("");
    setAlertBodyPartId("");
    setAlertBodyPartGroupId("");
  };

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">Add Diet Plan</h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <form onSubmit={addData}>
                        <div className="row">
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Plan Name
                            </p>
                            <input
                              type="text"
                              placeholder="Enter the plan name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertname}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Plan Type
                            </p>
                            <select
                              name="type"
                              id="type"
                              className="form-control"
                              onChange={(e) => setplanType(e.target.value)}
                              value={planType}
                            >
                              <option value="">Select</option>
                              <option value="regular">Regular</option>
                              <option value="weekly">Weekly</option>
                            </select>
                            <p className="alert-message">
                              {alertcaloriesPerRep}
                            </p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Validity
                            </p>
                            <input
                              type="text"
                              placeholder="Enter validity"
                              value={validity}
                              onChange={(e) => setValidity(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertminWeight}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Goal
                            </p>
                            <select
                              name="goal"
                              id="goal"
                              className="form-control"
                              onChange={(e) => setGoal(e.target.value)}
                              value={goal}
                            >
                              <option value="">Select</option>
                              <option>Muscle Gain</option>
                              <option>Weight Gain</option>
                              <option>General Fitness</option>
                              <option>Maintain Weight</option>
                              <option>Improve Flexibility</option>
                              <option>Weight Loss</option>
                            </select>
                            <p className="alert-message">{alertweightUnit}</p>
                          </div>
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Level
                            </p>
                            <select
                              name="exercise"
                              id="exercise"
                              className="form-control"
                              onChange={(e) => setLevel(e.target.value)}
                              value={level}
                            >
                              <option value="">Select</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>General</option>
                            </select>
                            <p className="alert-message">{alertbodyPartId}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Type
                            </p>

                            <select
                              name="type"
                              id="type"
                              className="form-control"
                              onChange={(e) => setType(e.target.value)}
                              value={type}
                            >
                              <option value="">Select</option>
                              <option value="paid">Paid</option>
                              <option value="free">Free</option>
                              <option value="individual">Individual</option>
                            </select>
                            <p className="alert-message">
                              {alertbodyPartGroupId}
                            </p>
                          </div>
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Price
                            </p>
                            <input
                              type="number"
                              placeholder="Enter the price"
                              value={price}
                              onChange={(e) => setPrice(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertweightUnit}</p>
                          </div>
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Note
                            </p>
                            <input
                              type="text"
                              placeholder="Enter the note"
                              value={note}
                              onChange={(e) => setNote(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertweightUnit}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Excel File
                            </p>
                            <input
                              type="file"
                              className="form-control-file"
                              Placeholder=""
                              name="file"
                              required
                              onChange={(e) => imageUpload(e)}
                            />
                            {error?.length>0 && <p className="alert-message">{error}</p>}
                            {/* <p
                              style={{
                                color: "green",
                                fontWeight: "500",
                              }}
                            >
                              {message}
                            </p> */}
                          </div>
                        </div>
                        <div className="d-flex justify-content-center pt-3">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                          &nbsp; &nbsp; &nbsp;
                          <button type="reset" className="btn btn-warning">
                            Reset
                          </button>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default AddDiet;
