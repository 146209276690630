import React, { useState } from "react";
import Navbarside from "../Navbarside";
import {
  getChallengeDetails,
  editChallenge,
} from "../../store/slices/challenges";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API_PATHS, API_BASE_URL } from "../../utils/constants/api.constants";
import { useParams } from "react-router-dom";
import Moment from "moment";
import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ChallengeService from "../../services/challenge.service";
import Loader from "../Loader";

const EditChallenges = (e) => {
  const { id } = useParams();
  localStorage.setItem("challengeId", id);
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const [challengeCategorys, setchallengeCategorys] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [description, setDescription] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tips, setTips] = useState("");
  const [needToDo, setNeedToDo] = useState("");
  const [name, setName] = useState("");
  const [mediaLink, setmediaLink] = useState("");
  const [routinePerPoint, setRoutinePerPoint] = useState(0);
  const [routinePerTree, setRoutinePerTree] = useState(0);
  const [goalPoint, setGoalPoint] = useState(0);
  const [shortDescription, setShortDescription] = useState("");

  const [challengeCategoryId, setchallengeCategoryId] = useState([]);
  const [catName, setcatName] = useState("");
  const [challengeValue, setchallengeValue] = useState([]);
  const [challengeType, setchallengeType] = useState([]);
  const [routine, setRoutine] = useState([]);
  const [reward, setReward] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const goBack = () => navigate(-1);

  // React.useEffect(() => {
  //   setIsLoading(true)

  // }, [dispatch]);

  let sdate = Moment(startDate).format("DD-MM-YYYY");
  async function imageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setmediaLink(data.data.location);
  }

  async function addData(e) {
    e.preventDefault();
    let item = {
      set: {
        startDate,
        description,
        endDate,
        tips,
        name,
        mediaLink,
        shortDescription,
        needToDo,
        reward,
      },
    };
    if (challengeType == "Steps challenge") {
      item.set.stepsReward = {
        stepCountPerPoint: routinePerPoint,
        pointsPerTree: routinePerTree,
        goalPoints: goalPoint,
      };
    } else if (challengeType == "Meditation challenge") {
      item.set.meditationReward = {
        routines: routine,
        routinesPerPoint: routinePerPoint,
        pointsPerTree: routinePerTree,
        goalPoints: goalPoint,
      };
    } else if (challengeType == "Food challenge") {
      item.set.foodReward = {
        routines: routine,
        routinesPerPoint: routinePerPoint,
        pointsPerTree: routinePerTree,
        goalPoints: goalPoint,
      };
    }
    setIsLoading(true);
    dispatch(editChallenge(item))
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        if (res?.user?.status) {
          alert("Updated succesfully");
        }
        // navigate("/Challenges");
      })
      .catch(({ message }) => {
        setIsLoading(false);
        alert(message);
      });
  }
  const ChallengeCategorys = createAsyncThunk(
    "expertise/get/expertise",
    async (thunkAPI) => {
      try {
        const data = await ChallengeService.getChallengeCategories();
        return { user: data };
      } catch (error) {
        const sessionCount =
          (error.response &&
            error.response.data &&
            error.response.data.sessionCount) ||
          error.sessionCount ||
          error.toString();
        return thunkAPI.rejectWithValue({ sessionCount });
      }
    }
  );

  React.useEffect(() => {
    setIsLoading(true);
    dispatch(getChallengeDetails())
      .unwrap()
      .then((data) => {
        setIsLoading(false);
        if (data.user.data[0]._id === id) {
          setStartDate(data.user.data[0].startDate);
          setDescription(data.user.data[0].description);
          setEndDate(data.user.data[0].endDate);
          setTips(data.user.data[0].tips);
          setNeedToDo(data.user.data[0].needToDo);
          setName(data.user.data[0].name);
          setmediaLink(data.user.data[0].mediaLink);
          setShortDescription(data.user.data[0].shortDescription);
          setchallengeType(data.user.data[0].challengeType);
          data.user.data[0].challengeType === "Meditation challenge"
            ? setRoutinePerPoint(
                data.user.data[0].meditationReward?.routinesPerPoint
              )
            : data.user.data[0].challengeType === "Food challenge"
            ? setRoutinePerPoint(data.user.data[0].foodReward?.routinesPerPoint)
            : setRoutinePerPoint(
                data.user.data[0].stepsReward?.stepCountPerPoint
              );
          data.user.data[0].challengeType === "Meditation challenge"
            ? setRoutinePerTree(
                data.user.data[0].meditationReward?.pointsPerTree
              )
            : data.user.data[0].challengeType === "Food challenge"
            ? setRoutinePerTree(data.user.data[0].foodReward?.pointsPerTree)
            : setRoutinePerTree(data.user.data[0].stepsReward?.pointsPerTree);
          data.user.data[0].challengeType === "Meditation challenge"
            ? setGoalPoint(data.user.data[0].meditationReward?.goalPoints)
            : data.user.data[0].challengeType === "Food challenge"
            ? setGoalPoint(data.user.data[0].foodReward?.goalPoints)
            : setGoalPoint(data.user.data[0].stepsReward?.goalPoints);
          data.user.data[0].challengeType === "Meditation challenge"
            ? setRoutine(data.user.data[0].meditationReward?.routines)
            : data.user.data[0].challengeType === "Food challenge"
            ? setRoutine(data.user.data[0].foodReward?.routines)
            : setRoutine([]);
        }
      })
      .catch(({ message }) => {
        setIsLoading(false);
        alert(message);
      });
    dispatch(ChallengeCategorys())
      .unwrap()
      .then((data) => {
        setIsLoading(false);
        setchallengeCategorys(data.user.data);
        setchallengeCategoryId(data.user.data[0]._id);
      })
      .catch(({ sessionCount }) => {
        setIsLoading(false);
        alert(sessionCount);
      });
  }, [dispatch]);

  // Add meditation
  const addMeditationRoutine = () => {
    const newRoutine = {
      time: "09:00",
      contentId: "",
      routine: "Morning",
    };
    const updatedRoutines = [...routine, newRoutine];
    setRoutine(updatedRoutines);
  };

  // handling routine
  const handleMeditationRoutineChange = async (event, index) => {
    let { name, value } = event.target;
    let specialValue = "";
    if (name == "contentId") {
      value = event.clipboardData.getData("text/plain");
      if (value) {
        let res = await axios.get(
          API_BASE_URL + "search/content?title=" + value
        );

        let data = res?.data?.data;
        if (data?.length) specialValue = data[0]._id;
      }
    }
    const updatedRoutines = [...routine];
    updatedRoutines[index] = {
      ...updatedRoutines[index],
      [name]: specialValue ? specialValue : value,
    };
    setRoutine(updatedRoutines);
  };

  // Removing maditation
  const removeMeditationRoutine = (index) => {
    const updatedRoutines = [...routine];
    updatedRoutines.splice(index, 1);
    setRoutine(updatedRoutines);
  };

  // Add food Routine Handler
  const addFoodRoutine = () => {
    const newRoutine = {
      mealType: "Breakfast",
      startTime: "09:00",
    };
    const updatedRoutines = [...routine, newRoutine];
    setRoutine(updatedRoutines);
  };

  // Remove food handler
  const removeFoodRoutine = (index) => {
    const updatedRoutines = [...routine];
    updatedRoutines.splice(index, 1);
    setRoutine(updatedRoutines);
  };

  // Handle Routine Change
  const handleFoodRoutineChange = (event, index) => {
    const { name, value } = event.target;
    const updatedRoutines = [...routine];
    updatedRoutines[index] = {
      ...updatedRoutines[index],
      [name]: value,
    };
    setRoutine(updatedRoutines);
  };

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      {isLoading && <Loader />}

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">Edit Challenge</h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <form>
                        <div className="row">
                          <div className="col-md-6 pt-3 pb-2">
                            <span>Name</span>
                            <input
                              type="text"
                              placeholder="Enter  Name"
                              className="form-control"
                              value={name}
                              required
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                          <div className="col-md-12 pt-3 pb-2">
                            <span>Description</span>
                            <textarea
                              placeholder="Enter  Description"
                              className="form-control"
                              value={description}
                              required
                              onChange={(e) => setDescription(e.target.value)}
                            />
                          </div>

                          <div className="col-md-6 pt-3 pb-2">
                            <span>Short Description</span>
                            <input
                              type="text"
                              placeholder="Enter  Description"
                              className="form-control"
                              value={shortDescription}
                              required
                              onChange={(e) =>
                                setShortDescription(e.target.value)
                              }
                            />
                          </div>

                          <div className="col-md-6 pt-2 pb-2">
                            <span>Image</span>
                            <br />
                            <img
                              src={mediaLink}
                              alt=""
                              style={{
                                height: "100px",
                                width: "120px",
                                marginBottom: "10px",
                              }}
                            />
                            <br />
                            <input
                              type="file"
                              className="form-control"
                              Placeholder="Price Tagline"
                              name="imageUrl"
                              onChange={(e) => imageUpload(e)}
                            />
                          </div>

                          <div className="col-md-6 pt-3 pb-2">
                            <span>Start Date </span>
                            <input
                              type="date"
                              className="form-control"
                              value={Moment(startDate).format("YYYY-MM-DD")}
                              // required
                              onChange={(e) => setStartDate(e.target.value)}
                            />
                          </div>

                          <div className="col-md-6 pt-3 pb-2">
                            <span>End Date</span>
                            <input
                              type="date"
                              className="form-control"
                              value={Moment(endDate).format("YYYY-MM-DD")}
                              // required
                              onChange={(e) => setEndDate(e.target.value)}
                            />
                          </div>

                          <div className="col-md-6 pt-3 pb-2">
                            <span>Tips</span>
                            <input
                              type="text"
                              placeholder="Enter Tips"
                              className="form-control"
                              value={tips}
                              required
                              onChange={(e) => setTips(e.target.value)}
                            />
                          </div>

                          <div className="col-md-6 pt-3 pb-2">
                            <span>Need to Do</span>
                            <input
                              type="text"
                              placeholder="Enter Tips"
                              className="form-control"
                              value={needToDo}
                              required
                              onChange={(e) => setNeedToDo(e.target.value)}
                            />
                          </div>
                          <div className="col-md-12 col-sm-12 pt-2">
                            <div className="form-body">
                              <div className="form-group">
                                <label for="placeTextarea">
                                  Terms and Conditions
                                </label>
                                <textarea
                                  rows="5"
                                  className="form-control"
                                  Placeholder="Enter Terms and Conditions"
                                  // value={termsConditions}
                                  // onChange={(e) =>
                                  //   setTermsConditions(e.target.value)
                                  // }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6 pt-2">
                            <span>Reward (text)</span>
                            <input
                              type="text"
                              placeholder="Enter reward text"
                              className="form-control"
                              value={reward}
                              required
                              onChange={(e) => setReward(e.target.value)}
                            />
                          </div>

                          {challengeType === "Steps challenge" && (
                            <div className="col-md-12 pt-3">
                              <h4>Steps Reward:</h4>
                              <label htmlFor="stepCountPerPoint">
                                Step Count Per Point:
                              </label>
                              <input
                                type="number"
                                id="stepCountPerPoint"
                                className="form-control"
                                name="stepCountPerPoint"
                                value={routinePerPoint}
                                onChange={(e) =>
                                  setRoutinePerPoint(e.target.value)
                                }
                                // onChange={handleStepsRewardChange}
                              />

                              <label htmlFor="pointsPerTree">
                                Points Per Tree:
                              </label>
                              <input
                                type="number"
                                id="pointsPerTree"
                                className="form-control"
                                name="pointsPerTree"
                                value={routinePerTree}
                                onChange={(e) =>
                                  setRoutinePerTree(e.target.value)
                                }
                                // onChange={handleStepsRewardChange}
                              />

                              <label htmlFor="goalPoints">Goal Points:</label>
                              <input
                                type="number"
                                id="goalPoints"
                                className="form-control"
                                name="goalPoints"
                                value={goalPoint}
                                onChange={(e) => setGoalPoint(e.target.value)}
                                // onChange={handleStepsRewardChange}
                              />
                            </div>
                          )}

                          {challengeType === "Meditation challenge" && (
                            <div className="col-md-12 pt-3">
                              <h4>
                                Meditation Reward:{" "}
                                <a
                                  target="_blank"
                                  href="/LiveWellCategory"
                                  className="btn btn-info"
                                >
                                  Find Videos
                                </a>
                              </h4>
                              {routine?.map((routine, index) => (
                                <div className="row" key={index}>
                                  <div className="col-md-3 pt-3">
                                    <label htmlFor={`contentId-${index}`}>
                                      Content name:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id={`contentId-${index}`}
                                      name={`contentId`}
                                      // value=value
                                      onPaste={(event) =>
                                        handleMeditationRoutineChange(
                                          event,
                                          index
                                        )
                                      }
                                      placeholder="paste video title here"
                                    />
                                    <p>contentId : {routine.contentId}</p>
                                    <br />
                                  </div>

                                  <div className="col-md-3 pt-3">
                                    <label htmlFor={`time-${index}`}>
                                      Time:
                                    </label>
                                    <input
                                      type="time"
                                      id={`time-${index}`}
                                      className="form-control"
                                      name={`time`}
                                      value={routine.time}
                                      onChange={(event) =>
                                        handleMeditationRoutineChange(
                                          event,
                                          index
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="col-md-3 pt-3">
                                    <label htmlFor={`routine-${index}`}>
                                      Routine:
                                    </label>
                                    <select
                                      className="form-control"
                                      id={`routine-${index}`}
                                      name={`routine`}
                                      value={routine.routine}
                                      onChange={(event) =>
                                        handleMeditationRoutineChange(
                                          event,
                                          index
                                        )
                                      }
                                    >
                                      <option value="Morning">Morning</option>
                                      <option value="Evening">Evening</option>
                                      <option value="Night">Night</option>
                                    </select>
                                  </div>
                                  <div className="col-md-3">
                                    <button
                                      style={{ "margin-top": "20%" }}
                                      className="btn btn-warning"
                                      type="button"
                                      onClick={() =>
                                        removeMeditationRoutine(index)
                                      }
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              ))}

                              <button
                                type="button"
                                className="btn btn-info"
                                onClick={addMeditationRoutine}
                              >
                                Add Routine
                              </button>

                              <br />

                              <label htmlFor="routinesPerPoint">
                                Routines Per Point:
                              </label>
                              <input
                                className="form-control"
                                type="number"
                                id="routinesPerPoint"
                                name="routinesPerPoint"
                                value={routinePerPoint}
                                onChange={(e) =>
                                  setRoutinePerPoint(e.target.value)
                                }
                                // onChange={handleMeditationRewardChange}
                              />

                              <label htmlFor="pointsPerTree">
                                Points Per Tree:
                              </label>
                              <input
                                className="form-control"
                                type="number"
                                id="pointsPerTree"
                                name="pointsPerTree"
                                value={routinePerTree}
                                onChange={(e) =>
                                  setRoutinePerTree(e.target.value)
                                }
                                // onChange={handleMeditationRewardChange}
                              />

                              <label htmlFor="goalPoints">Goal Points:</label>
                              <input
                                className="form-control"
                                type="number"
                                id="goalPoints"
                                name="goalPoints"
                                value={goalPoint}
                                onChange={(e) => setGoalPoint(e.target.value)}
                                // onChange={handleMeditationRewardChange}
                              />
                            </div>
                          )}

                          {challengeType === "Food challenge" && (
                            <div className="col-md-12 pt-3">
                              <h4>Food Reward:</h4>
                              {routine?.map((routine, index) => (
                                <div className="row" key={index}>
                                  <div className="col-md-3 pt-3">
                                    <label htmlFor={`mealType-${index}`}>
                                      Meal Type:
                                    </label>
                                    <select
                                      className="form-control"
                                      id={`mealType-${index}`}
                                      name={`mealType`}
                                      value={routine.mealType}
                                      onChange={(event) =>
                                        handleFoodRoutineChange(event, index)
                                      }
                                    >
                                      <option value="Breakfast">
                                        Breakfast
                                      </option>
                                      <option value="Morning Snacks">
                                        Morning Snacks
                                      </option>
                                      <option value="Lunch">Lunch</option>
                                      <option value="Evening Snacks">
                                        Lunch
                                      </option>
                                      <option value="Dinner">Dinner</option>
                                    </select>
                                  </div>

                                  <div className="col-md-3 pt-3">
                                    <label htmlFor={`startTime-${index}`}>
                                      Time:
                                    </label>
                                    <input
                                      className="form-control"
                                      type="time"
                                      id={`startTime-${index}`}
                                      name={`startTime`}
                                      value={routine.startTime}
                                      onChange={(event) =>
                                        handleFoodRoutineChange(event, index)
                                      }
                                    />
                                  </div>

                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => removeFoodRoutine(index)}
                                  >
                                    Remove
                                  </button>
                                </div>
                              ))}

                              <button
                                type="button"
                                className="btn btn-info"
                                onClick={addFoodRoutine}
                              >
                                Add Routine
                              </button>
                              <br />

                              <label htmlFor="routinesPerPoint">
                                Routines Per Point:
                              </label>
                              <input
                                className="form-control"
                                type="number"
                                id="routinesPerPoint"
                                name="routinesPerPoint"
                                value={routinePerPoint}
                                onChange={(e) =>
                                  setRoutinePerPoint(e.target.value)
                                }
                              />

                              <label htmlFor="pointsPerTree">
                                Points Per Tree:
                              </label>
                              <input
                                className="form-control"
                                type="number"
                                id="pointsPerTree"
                                name="pointsPerTree"
                                value={routinePerTree}
                                onChange={(e) =>
                                  setRoutinePerTree(e.target.value)
                                }
                              />

                              <label htmlFor="goalPoints">Goal Points:</label>
                              <input
                                className="form-control"
                                type="number"
                                id="goalPoints"
                                name="goalPoints"
                                value={goalPoint}
                                onChange={(e) => setGoalPoint(e.target.value)}
                              />
                            </div>
                          )}

                          <div className="col-md-3" />
                          <div className="col-md-12" align="center">
                            <br />
                            <button
                              onClick={addData}
                              type="submit"
                              className="btn btn-primary"
                              style={{ width: "150px" }}
                              // disabled={!mediaLink}
                            >
                              Update
                            </button>
                            &nbsp;
                            <button
                              type="reset"
                              onClick={goBack}
                              className="btn btn-danger"
                              style={{ width: "150px" }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
};

export default EditChallenges;
