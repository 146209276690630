import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postPackage } from "../../store/slices/package";
import Footer from "../footer";
import Navbarside from "../../component/Navbarside";
import ExpertiseService from "../../services/expertise.service";
import { getAllExpertise, getHealthConditions } from "../../store/slices/hcexpert";
import get from "axios";
import { API_BASE_URL, API_PATHS } from "../../utils/constants/api.constants";

function AddPackage(e) {
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const [benefits, setbenefits] = useState("");
  const [description, setDescription] = useState("");
  const [price, setprice] = useState("");
  const [name, setname] = useState("");
  const [sessionCount, setsessionCount] = useState("");
  const [durationInDays, setdurationInDays] = useState("");
  const [allExpertise, setExpertise] = useState(null);
  const [selectedExpertise, setSelectedExpertise] = useState(null)
  const [selectedSubExpertise, setSelectedSubExpertise] = useState(null)
  const [selectedSubCatName, setSelectedSubCatName] = useState(null)
  const [selectedOption, setSelectedOption] = useState('');
  const [flow, setFlow] = useState('');
  const [showImageUploader, setShowImageUploader] = useState(false);
  const [message, setMessage] = useState("");
  const [mediaLink, setMediaLink] = useState(null);
  const [alertdescription, setAlertdescription] = useState("");
  const [alertprice, setAlertprice] = useState("");
  const [alertname, setAlertname] = useState("");
  const [alertsession, setAlertsession] = useState("");
  const [alertvalidity, setAlertvalidity] = useState("");
  const [healthConditions, setHealthConditions] = useState([]);
  const [bodyParts, setBodyParts] = useState([]);
  const [selectedHc, setSelectedHc] = useState([]);
  const [selectedBp, setSelectedBp] = useState([]);
  const [packageType, setPackageType] = useState(null);
  const [hardnessLevel, setHardnessLevel] = useState(null);
  const [alertexpertise, setAlertexpertise] = useState("");
  const [alertbenefits, setAlertbenefits] = useState("");
  const [ayurvedaCondition, setAyurvedaCondition] = useState([]);
  const [selectedAc, setSelectedAc] = useState([]);
  const [experts, setExperts] = useState([]);
  const [selectedExp, setSelectedExp] = useState(null);
  const [services, setServices] = useState([]);
  //   Alert-sessionCount

  useEffect(() => {
    dispatch(getAllExpertise()).unwrap().then((data) => {
      setExpertise(data.allExpertise);
    }).catch(({ message }) => {
    });
  }, [dispatch]);

  const handleAddForms1 = () => {
    let hotel = [...services];
    hotel.push({
      count: "",
      serviceName: "",
      serviceId: "",
    });
    setServices(hotel);
  };
  const handleDeleteForms1 = (e) => {
    if (services.length > 1) {
      let hotel = [...services];
      hotel.pop();
      setServices(hotel);
    }
  };
  const handleRowChange1 = (e, index) => {
    if (services.length > 0) {
      let addtionalService = [...services];
      let { name, value } = e.target
      if (name == 'serviceName') {
        value = value.split('/')
        addtionalService[index][name] = value[0]
        addtionalService[index]['serviceId'] = value[1]
      } else {
        addtionalService[index][name] = value;
      }
      setServices(addtionalService);
    }
    // setServices()
    // console.log(hotelDescription);
  };

  async function imageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setMediaLink(data.data.location);
  }
  const handleExpertiseChange = (e) => {
    let { value } = e.target
    value = value.split('/')
    setFlow(value[0])
    setSelectedExpertise(value[1])
    setSelectedOption('');
    setSelectedSubCatName('');
    setSelectedSubExpertise('');
    setSelectedHc([]);
    setSelectedExp('');
    setSelectedAc([])
    setSelectedBp([])
    setPackageType('')
    setHardnessLevel('')
    setMessage('');

    if (!healthConditions.length && value[0] === 'physioTherapy') {
      get(`${API_BASE_URL}get/healthCondition`, {
        params: { category: value[0] }
      }).then(res => setHealthConditions(res.data.data))
    }

    if (!bodyParts.length && value[0] === 'physioTherapy') {
      get(`${API_BASE_URL}get/bodyPart`, {
        params: { category: value[0] }
      }).then(res => setBodyParts(res.data.data))
    }

    if (value[0] === 'fitness') {
      get(`${API_BASE_URL}get/user`, {
        params: { topExpertise: value[1] }
      }).then(res => setExperts(res.data.data))
    }
  }
  const handleSubExpertiseChange = e => {
    let { value } = e.target
    value = value.split('*')
    setSelectedSubCatName(value[0])
    setSelectedSubExpertise(value[1])

    if (value[0] === 'Ayurveda' && !ayurvedaCondition.length) {
      get(`${API_BASE_URL}get/ayurvedaCondition`).then(res => setAyurvedaCondition(res.data.data))
    }
  }
  const handleOptionChange = (e) => {
    let { value } = e.target;
    let [key, val] = value.split('/')
    if (key === 'packageType') setPackageType(val)
    if (key === 'hardnessLevel') setHardnessLevel(val)

    if (flow === 'fitness' && key === 'packageType') {
      setShowImageUploader(val === 'expert-curated' || val === 'personal-trainer');
    }
  };
  const handleChange = (e) => {
    let { value } = e.target
    value = value.split('/')
    if (value[2] === 'healthCondition') setSelectedHc(value[1])
    if (value[2] === 'bodyPart') setSelectedBp(value[1])
    if (value[2] === 'ayurvedaCondition') setSelectedAc(value[1])
    if (value[2] === 'experts') setSelectedExp(value[1])
  }
  async function save(e) {
    try {
      e.preventDefault();
      // let flow = '';
      let isActive = true;
      let expertiseId = selectedExpertise;
      let subCategoryId = selectedSubExpertise;
      let item = {
        name,
        // benefits,
        description,
        price,
        sessionCount,
        isActive,
        flow,
        durationInDays,
        services,
        expertiseId,
        subCategoryId,
        mediaLink: mediaLink || null,
        healthCondition: selectedHc,
        bodyPart: selectedBp,
        hardnessLevel,
        packageType,
        ayurvedaConditions: selectedAc,
        expertId: selectedExp || null
      };

      if (!services.length) throw new Error('Please add services')
      else if (!(services.find(x => x.serviceId == expertiseId))) {
        throw new Error('Atleast one service should be of selected category')
      } else {
        let servicesCount = services.reduce((accu, service) => accu + Number(service.count), 0)
        console.log(servicesCount)
        if (Number(sessionCount) != servicesCount) throw new Error('Total no. of services session should be equal to sesion count')
      }

      dispatch(postPackage(item)).unwrap().then(() => {
        alert("Uploaded successfully");
        navigate("/package");
      }).catch(({ message }) => {
        alert(message);
      });

    } catch (e) {
      alert(e.message)
    }
  }
  const cancel = () => {
    setbenefits("");
    setDescription("");
    setprice("");
    setname("");
    setAlertbenefits("");
    setAlertdescription("");
    setAlertprice("");
    setAlertsession("");
    setAlertname("");
    setsessionCount("");
  };

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Upload Package
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form className="form" onSubmit={save}>
                          <div className="row justify-content-md-center">
                            <div id="packageName" className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    Package Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Package Name"
                                    value={name}
                                    onChange={(e) => setname(e.target.value)}
                                    required
                                  />
                                  <p className="alert-message">{alertname}</p>
                                </div>
                              </div>
                            </div>

                            <div id="price" className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">price</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    Placeholder="Enter Amount (default value 999)"
                                    value={price}
                                    onChange={(e) => setprice(e.target.value)}
                                    required
                                  />
                                  <p className="alert-message">{alertprice}</p>
                                </div>
                              </div>
                            </div>

                            <div id="sessionCount" className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">
                                    No.of Sessions
                                  </label>
                                  <input
                                    type="number"
                                    min="0"
                                    className="form-control"
                                    Placeholder="Enter count (default value 5)"
                                    defaultValue="5"
                                    name="sessionCount"
                                    value={sessionCount}
                                    onChange={(e) => setsessionCount(e.target.value)}
                                    required
                                  />
                                  <p className="alert-message">
                                    {alertsession}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div id="category" className="col-md-6 col-sm-12 pt-2">
                              <p className="col-black" style={{ marginBottom: "2px" }}>
                                Category
                              </p>
                              <select
                                className="form-control"
                                onChange={handleExpertiseChange}
                                value={`${flow}/${selectedExpertise}`}
                                required
                              >
                                <option value="">Select Expertise</option>
                                {allExpertise && allExpertise.data && allExpertise.data.length
                                  ? allExpertise.data.map((option) => {
                                    if (!option.parentExpertiseId || option.parentExpertiseId == null) {
                                      return (
                                        <option key={option._id} value={`${option.flowName}/${option._id}`}>
                                          {option.name}
                                        </option>
                                      );
                                    }
                                  })
                                  : ""}
                              </select>
                            </div>

                            {/* healthCondition and body part dropdown */}
                            {flow && flow === 'physioTherapy' && (
                              <>
                                <div className="col-md-6 col-sm-12 pt-2">
                                  <p className="col-black" style={{ marginBottom: "2px" }}>Conditions</p>
                                  <select className="form-control" onChange={handleChange}>
                                    <option value="" disabled selected>Select Condition</option>
                                    {healthConditions && healthConditions.length
                                      ? healthConditions.map(hc => {
                                        return (
                                          <option value={`${hc.name}/${hc._id}/healthCondition`}>{hc.name}</option>
                                        )
                                      })
                                      : ""
                                    }
                                  </select>
                                </div>

                                <div className="col-md-6 col-sm-12 pt-2">
                                  <p className="col-black" style={{ marginBottom: "2px" }}>Body Parts</p>
                                  <select className="form-control" onChange={handleChange}>
                                    <option value="" disabled selected>Select BodyPart</option>

                                    {bodyParts && bodyParts.length
                                      ? bodyParts.map(part => {
                                        return (
                                          <option value={`${part.name}/${part._id}/bodyPart`}>{part.name}</option>
                                        )
                                      })
                                      : ""
                                    }
                                  </select>
                                </div>
                              </>
                            )}

                            {flow && flow === 'fitness' && (
                              <>
                                <div className="col-md-6 col-sm-12 pt-2">
                                  <p className="col-black" style={{ marginBottom: "2px" }}>
                                    Package Type
                                  </p>
                                  <select
                                    id="packageType"
                                    className="form-control"
                                    onChange={handleOptionChange}
                                  >
                                    <option disabled selected>Selected Package type</option>
                                    <option value="packageType/free">Free</option>
                                    <option value="packageType/expertCurated">Expert Curated</option>
                                    <option value="packageType/personalTrainer">Personal Trainer</option>
                                  </select>
                                </div>

                                <div className="col-md-6 col-sm-12 pt-2">
                                  <p className="col-black" style={{ marginBottom: "2px" }}>
                                    Levels
                                  </p>
                                  <select
                                    id="hardnessLevel"
                                    className="form-control"
                                    onChange={handleOptionChange}
                                  >
                                    <option disabled selected>Selected hardness level </option>
                                    <option value="hardnessLevel/beginner">Beginner</option>
                                    <option value="hardnessLevel/intermediate">Intermediate</option>
                                    <option value="hardnessLevel/advanced">Advanced</option>
                                  </select>
                                </div>

                                <div className="col-md-6 col-sm-12 pt-2">
                                  <p className="col-black" style={{ marginBottom: "2px" }}>Expert</p>
                                  <select className="form-control" onChange={handleChange}>
                                    <option value="" disabled selected>Select Expert</option>
                                    {experts && experts.length
                                      ? experts.map(exp => {
                                        return (
                                          <option value={`${exp.firstName}/${exp._id}/experts`}>{exp.firstName} {exp.lastName}</option>
                                        )
                                      })
                                      : ""
                                    }
                                  </select>
                                </div>

                              </>
                            )}

                            {flow && ((flow === 'fitness' && (packageType === 'expertCurated' || packageType === 'personalTrainer')) || flow == 'physioTherapy' || selectedSubCatName == 'Ayurveda') && (
                              <div className="col-md-6 col-sm-12 pt-2">
                                <div className="form-body">
                                  <div className="form-group">
                                    <p
                                      className="col-black"
                                      style={{ marginBottom: "2px" }}
                                    >
                                      Image Uploader
                                    </p>
                                    <input
                                      type="file"
                                      className="form-control-file"
                                      Placeholder=""
                                      name="imageUrl"
                                      onChange={(e) => imageUpload(e)}
                                    />

                                    <p
                                      style={{
                                        color: "green",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {message}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}

                            <div className="col-md-6 col-sm-12 pt-2">
                              <p
                                className="col-black"
                                style={{ marginBottom: "2px" }}
                              >
                                Sub Category
                              </p>
                              <select
                                className="form-control"
                                onChange={handleSubExpertiseChange}
                                required
                              >
                                <option value="">Select Sub - Expertise</option>
                                {selectedExpertise
                                  ? allExpertise.data.map(expertise => {
                                    if (expertise.parentExpertiseId == selectedExpertise) {
                                      return (
                                        <option value={`${expertise.name}*${expertise._id}`}>
                                          {expertise.name}
                                        </option>
                                      )
                                    }
                                  })
                                  : ''
                                }
                              </select>
                            </div>

                            {selectedSubCatName && selectedSubCatName === 'Ayurveda' && (
                              <>
                                <div className="col-md-6 col-sm-12 pt-2">
                                  <p className="col-black" style={{ marginBottom: "2px" }}>Ayurveda Conditions</p>

                                  <select className="form-control" onChange={handleChange}>
                                    <option value="" disabled selected>Select Condition</option>
                                    {ayurvedaCondition && ayurvedaCondition.length
                                      ? ayurvedaCondition.map(ac => {
                                        return (
                                          <option value={`${ac.name}/${ac._id}/ayurvedaCondition`}>{ac.name}</option>
                                        )
                                      })
                                      : ""
                                    }
                                  </select>
                                </div>
                              </>
                            )}

                            <div className="col-md-6 col-sm-12 pt-2">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Validity (No. of days)</label>
                                  <input
                                    type="number"
                                    min="0"
                                    className="form-control"
                                    Placeholder="Enter days (default value 30)"
                                    defaultValue="30"
                                    value={durationInDays}
                                    onChange={(e) => setdurationInDays(e.target.value)}
                                    required
                                  />
                                  <p className="alert-message">
                                    {alertvalidity}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6 col-sm-12 pt-2">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Description</label>
                                  <textarea
                                    rows="5"
                                    className="form-control"
                                    Placeholder="Enter Description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                  />
                                  <p className="alert-message">
                                    {alertdescription}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <h2>Services</h2>
                            </div>
                            <br />

                            <div className="col-md-4"></div>
                            <div className="col-md-4" align="center">
                              <button
                                type="button"
                                className="btn btn-success"
                                onClick={(e) => handleAddForms1(e)}
                              >
                                <i className="fa fa-plus-circle" />
                              </button>
                              &nbsp; &nbsp;
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={(e) => handleDeleteForms1(e)}
                              >
                                <i className="fa fa-minus-circle" />
                              </button>
                            </div>
                            <div className="col-md-4"></div>
                            {services.map((hotel, index) => (
                              <div key={index} className="form-group row">
                                <div className="col-md-4">
                                  <label for="eventInput5">Service Name</label>
                                  <select
                                    className="form-control"
                                    onChange={(e) => handleRowChange1(e, index)}
                                    name="serviceName"
                                    required
                                  >
                                    <option value="">Select Expertise</option>
                                    {allExpertise && allExpertise.data && allExpertise.data.length
                                      ? allExpertise.data.map(option => {
                                        if (!option.parentExpertiseId || option.parentExpertiseId == null) {
                                          return (
                                            <option value={`${option.flowName}/${option._id}`}>
                                              {option.name}
                                            </option>
                                          )
                                        }
                                      })
                                      : ''
                                    }
                                  </select>
                                </div>
                                <div className="col-md-4">
                                  <label for="eventInput5">
                                    No.of Session
                                  </label>
                                  <input
                                    onChange={(e) => handleRowChange1(e, index)}
                                    className="form-control"
                                    placeholder="No.of Consultations"
                                    name="count"
                                    type="text"
                                    value={hotel.count}
                                    required
                                  />
                                </div>
                                {/* <div className="col-md-4">
                                  <label for="eventInput5">
                                    Mode of Consultation
                                  </label>
                                  <input
                                    onChange={(e) => handleRowChange1(e, index)}
                                    className="form-control"
                                    placeholder="Mode of Consultation"
                                    name="mode"
                                    type="text"
                                    required
                                    value={hotel.mode}
                                  />
                                </div> */}
                              </div>
                            ))}
                          </div>

                          <div className="form-actions center">
                            <button
                              type="submit"
                              className="btn btn-primary"
                            >
                              <i className="icon-note"></i> Save
                            </button>{" "}
                            &nbsp;
                            <button
                              type="reset"
                              className="btn btn-warning"
                              onClick={cancel}
                            >
                              <i className="icon-trash"></i> Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default AddPackage;
