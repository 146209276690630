import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "../../component/Navbarside";
import Footer from "../footer";
import Moment from "moment";
import axios from "axios";
import { getSubscriptions } from "../../store/slices/analysis";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useLocation, Link } from "react-router-dom";
import { get } from 'axios';
import { API_BASE_URL, API_PATHS } from "../../utils/constants/api.constants";
import Loader from "../Loader";


const MembershipAnalysis = () => {
  const location = useLocation();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [params, setParams] = useState({});
  const [subscriptions, setSubscription] = useState([]);
  const [flow, setFlow] = useState('');
  const [expertise, setExpertise] = useState('');
  const [subCategoryId, setSubCatId] = useState('');
  const [isLoading, setIsLoading] =useState(false)
  const tableRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const flow = searchParams.get('flow');
    const expertiseId = searchParams.get('expertiseId');
    const subCategoryId = searchParams.get('subCategoryId');
    let paramsNew = { ...params }
    if (flow) paramsNew.flow = flow
    if (expertiseId) paramsNew.expertiseId = expertiseId
    if (subCategoryId) paramsNew.subCategoryId = subCategoryId
    paramsNew.status = 'packageSubscribed'
    setParams(paramsNew)
    // dispatch(getSubscriptions(paramsNew)).unwrap().then((subscriptions) => {
    //   setSubscription(subscriptions.data.data)
    // }).catch(({ message }) => {
    //   // alert(message);
    // });
    setIsLoading(true)
    get(API_BASE_URL + 'fetch/subscription', {
      params: paramsNew
    }).then(res => {
      setIsLoading(false)
      setSubscription(res.data.data)
    }).catch(err => 
      {
      setIsLoading(false)
      alert(err.message)})

  }, [dispatch,]);

  const handleFilterSubmit = () => {
    let paramsNew = { ...params }
    if (fromDate) paramsNew.fromDate =fromDate
    if (toDate) paramsNew.toDate = toDate
    setParams(paramsNew)
    setIsLoading(true)
    axios.get('https://api.healthonify.com/fetch/subscription', {
      params: {
        ...paramsNew,
        status: 'packageSubscribed'
      }
    }).then(res => {
      setIsLoading(false)
      setSubscription(res.data.data)}).catch((err)=>{
      setIsLoading(false)

      })
  }

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Membership Analysis Report",
    sheet: "Sheet1",
  });

  return (
    <div>
      <Navbarside />
      {isLoading && <Loader/>}

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Membership Analysis Report</h4>
                        {/* &nbsp;
                        <ExportToExcel apiData={data} fileName={fileName} /> */}
                      </div>
                    </div>
                    <section className="form-section">
                      <div className="row">
                        <form className="row">
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p className="col-black" style={{ marginBottom: "2px" }}
                            >
                              From Date
                            </p>
                            <input
                              type="date"
                              placeholder="Enter the recipe name"
                              value={fromDate}
                              onChange={(e) => setFromDate(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message"></p>
                          </div>
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              To Date
                            </p>
                            <input
                              type="date"
                              placeholder="Enter the recipe name"
                              value={toDate}
                              onChange={(e) => setToDate(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message"></p>
                          </div>
                        </form>

                        <div className="col-md-2 col-sm-12 pt-2 mt-3">
                          <button className="btn btn-primary" onClick={handleFilterSubmit} >
                            Submit
                          </button>
                        </div>
                        <div className="col-md-2 col-sm-12 pt-2 mt-3">
                          <button
                            onClick={onDownload}
                            className="btn btn-primary"
                          >
                            Export to Excel
                          </button>
                        </div>
                      </div>
                    </section>

                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        {subscriptions !== "" && (
                          <div >

                            <table
                              className="table table-striped table-bordered zero-configuration"
                              ref={tableRef}
                            >
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Ticket number</th>
                                  <th>Package Name</th>
                                  <th>Category</th>
                                  <th>Active / Inactive</th>
                                  <th>Bill Amount</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>

                              <tbody>
                                {subscriptions?.length
                                  ? subscriptions?.map((dataVal, index) => {
                                    if (dataVal?.packageId && dataVal?.ticketNumber) {
                                      return (
                                        <tr>
                                          <td>
                                            {" "}
                                            {Moment(dataVal.startDate).format(
                                              "DD-MM-YYYY"
                                            )} {Moment(dataVal.startDate).format(
                                              "hh:mm A"
                                            )}
                                          </td>
                                          <td>{dataVal.ticketNumber}</td>
                                          <td>
                                            {dataVal?.packageId?.name}
                                          </td>
                                          <td>{dataVal?.packageId?.expertiseId.name}</td>
                                          <td>{dataVal.isActive ? 'Yes' : 'No'}</td>
                                          <td>{dataVal?.paymentId?.netAmount}</td>
                                          <td>{dataVal?.status}</td>
                                          {/* <td>
                                            {dataVal.status == 'packageSubscribed'
                                              ? <div>
                                                <a
                                                  href={`/ListSessions?ticketNumber=${dataVal.ticketNumber}`}
                                                  class='btn btn-success'
                                                >
                                                  View Sessions
                                                </a>
                                                <a
                                                  href={`/AssignSessionExpert?ticketNumber=${dataVal.ticketNumber}&flow=${dataVal.flow}`}
                                                  class='btn btn-success'
                                                  style={{ "margin": "5px" }}
                                                >
                                                  Assign Expert
                                                </a></div>
                                              : 'No Action'
                                            }
    
                                          </td> */}
                                          <td>
                                            <Link to={`/ViewSubscription/${dataVal.ticketNumber}?flow=${dataVal.flow}`}>
                                              <button class='btn btn-success'>View Details</button>
                                            </Link>
                                          </td>
                                        </tr>
                                      )
                                    }
                                  }).filter(x => x)
                                  : ''
                                }
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >

      <Footer />
    </div >
  );
};

export default MembershipAnalysis;


