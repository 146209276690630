import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Navbarside from "./Navbarside";
import Loader from "./Loader";
import { useDispatch, useSelector } from "react-redux";
import Footer from "./footer";
import { getVendorLabsDetails } from "../store/slices/lab";

const LabVendorDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  localStorage.setItem("LabVal", id);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [labEmail, setLabEmail] = useState("");
  const [labMobileNo, setLabMobileNo] = useState("");
  const [isAdminApproved, setIsAdminApproved] = useState("");
  const [Id, setId] = useState("");

  React.useEffect(() => {
    setIsLoading(true);
    dispatch(getVendorLabsDetails())
      .unwrap()
      .then((data) => {
        setIsLoading(false);
        if (data?.user?.data[0]) {
          console.log("ijgbvbiuhbsljhdihk", data?.user?.data[0].firstName);
          setName(data?.user.data[0].firstName);
          setLabEmail(data?.user.data[0].email);
          setLabMobileNo(data?.user.data[0].mobileNo);
          setIsAdminApproved(data?.user.data[0].isAdminApproved);
          setId(data?.user.data[0]._id);
        }
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />
      {isLoading && <Loader />}

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Lab Vendors Details</h4>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive ">
                        <div className="row">
                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="d-flex justify-content-between text">
                              <p>Lab Name:</p>
                              <span>{name}</span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="d-flex justify-content-between text">
                              <p>Lab Email:</p>
                              <span>{labEmail}</span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="d-flex justify-content-between text">
                              <p>Contect Number:</p>
                              <span>{labMobileNo}</span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="d-flex justify-content-between text">
                              <p>Is Verified:</p>
                              <span>
                                {" "}
                                <button
                                  className={
                                    isAdminApproved === false
                                      ? "btn btn-warning"
                                      : "btn btn-success"
                                  }
                                  style={{
                                    height: "25px",
                                    padding: "0 10px",
                                    cursor: "default",
                                  }}
                                >
                                  {isAdminApproved === false
                                    ? "Disable"
                                    : "Verified"}
                                </button>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center mt-3">
                          <Link
                            to={`/MyLabs/LabVendorDetails/ViewLab/${Id ? Id : null}`}
                            className="btn btn-primary"
                            style={{
                              height: "25px",
                              padding: "0 10px",
                              marginRight: "10px",
                            }}
                          >
                            View Lab
                          </Link>
                          <Link
                            to={`/MyLabs/LabVendorDetails/ViewLab/${Id ? Id : null}`}
                            className="btn btn-primary"
                            style={{
                              height: "25px",
                              padding: "0 10px",
                              marginRight: "10px",
                            }}
                          >
                            Revenue
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LabVendorDetails;
