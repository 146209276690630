import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "../../component/Navbarside";
import { getcommunity, DeletePost } from "../../store/slices/Community";
import Footer from "../footer";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";

const ExpertCommunity = () => {
  const dispatch = useDispatch();
  const [communitys, setCommunitys] = useState([]);

  React.useEffect(() => {
    dispatch(getcommunity())
      .unwrap()
      .then((data) => {
        setCommunitys(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  const Delete = async function deletePlaylist(id) {
    localStorage.setItem("id", id);
    dispatch(DeletePost())
      .unwrap()
      .then(() => {
        // alert("Uploaded succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });

    console.log(id);
  };
  const deleteData = (id) => {
    confirmAlert({
      title: "Want to delete?",
      message: "Are you sure you want to delete this post?",
      buttons: [
        {
          label: "Yes",
          onClick: () => Delete(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };
  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-wrap bar-success">
                          <h4 className="card-title">
                            Community &nbsp; &nbsp;
                            <Link
                              to="/Community/AddCommunity"
                              className="btn btn-primary"
                            >
                              <i className="fa fa-plus"></i> Add
                            </Link>
                          </h4>
                        </div>
                      </div>
                      <div className="card-body collapse show">
                        <div className="card-block card-dashboard table-responsive">
                          <div className="row">
                            {communitys.map((communityVal, index) => (
                              <div className="col-md-3 p-3">
                                <Link
                                  to={`/CommunityDetails/${communityVal._id ? communityVal._id : null
                                    }`}
                                >
                                  <img
                                    src={communityVal.mediaLink}
                                    alt=""
                                    style={{
                                      width: "200px",
                                      height: "180px",
                                      borderRadius: "10px",
                                    }}
                                  />
                                  <br />
                                  <b> {communityVal.description}</b>
                                </Link>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
};

export default ExpertCommunity;
