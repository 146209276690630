import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class formsService {
   
   
    static fitnessForms() {
      let api = '';      
      let userId = localStorage.getItem("userId"); 
      api = API_PATHS.fitnessform+ '?userId=' + userId;
        return axios
          .get(api)
        .then((response) => {
          if (response.data) {
           
          }
          return response.data;
        });
    }


    static medicalHistory() {
        let api = '';      
        
        let userId = localStorage.getItem("userId"); 
               api = API_PATHS.getmedicalHistory+ '?userId=' + userId;
          return axios
            .get(api)
          .then((response) => {
            if (response.data) {
             
            }
            return response.data;
          });
      }


      static lifeStyleForm() {
        let api = '';      
        let userId = localStorage.getItem("userId"); 
        api = API_PATHS.getlifestyle+ '?userId=' + userId;
          return axios
            .get(api)
          .then((response) => {
            if (response.data) {
             
            }
            return response.data;
          });
      }

      
      static measurements() {
        let api = '';      
        let userId = localStorage.getItem("userId"); 
        api = API_PATHS.measurements+ '?userId=' + userId;
          return axios
            .get(api)
          .then((response) => {
            if (response.data) {
             
            }
            return response.data;
          });
      }

      static weightLog() {
        let api = '';      
        let userId = localStorage.getItem("userId"); 
        api = API_PATHS.weightLog+ '?userId=' + userId;
          return axios
            .get(api)
          .then((response) => {
            if (response.data) {
             
            }
            return response.data;
          });
      }

      static Labreports() {
        let api = '';      
        let userId = localStorage.getItem("userId"); 
        api = API_PATHS.getLabReport+ '?userId=' + userId;
          return axios
            .get(api)
          .then((response) => {
            if (response.data) {
             
            }
            return response.data;
          });
      }

      static surgeryHistory() {
        let api = '';      
        let userId = localStorage.getItem("userId"); 
        api = API_PATHS.surgeryHistory+ '?userId=' + userId;
          return axios
            .get(api)
          .then((response) => {
            if (response.data) {
             
            }
            return response.data;
          });
      }
      static allergyHistory() {
        let api = '';      
        let userId = localStorage.getItem("userId"); 
        api = API_PATHS.allergyHistory+ '?userId=' + userId;
          return axios
            .get(api)
          .then((response) => {
            if (response.data) {
             
            }
            return response.data;
          });
      }
      static familyIllness() {
        let api = '';      
        let userId = localStorage.getItem("userId"); 
        api = API_PATHS.familyIllnessHistory+ '?userId=' + userId;
          return axios
            .get(api)
          .then((response) => {
            if (response.data) {
             
            }
            return response.data;
          });
      }
      static majorIllness() {
        let api = '';      
        let userId = localStorage.getItem("userId"); 
        api = API_PATHS.majorIllnessHistory+ '?userId=' + userId;
          return axios
            .get(api)
          .then((response) => {
            if (response.data) {
             
            }
            return response.data;
          });
      }
      static socialHabits() {
        let api = '';      
        let userId = localStorage.getItem("userId"); 
        api = API_PATHS.socialHabits+ '?userId=' + userId;
          return axios
            .get(api)
          .then((response) => {
            if (response.data) {
             
            }
            return response.data;
          });
      }

      static HealthLocker() {
        let api = '';      
        let userId = localStorage.getItem("userId"); 
        api = API_PATHS.healthLocker+ '?userId=' + userId;
          return axios
            .get(api)
          .then((response) => {
            if (response.data) {
             
            }
            return response.data;
          });
      }

      static userBp() {
        let api = '';      
        let userId = localStorage.getItem("userId"); 
        api = API_PATHS.bpLog+ '?userId=' + userId + '&dateType=monthly';
          return axios
            .get(api)
          .then((response) => {
            if (response.data) {
             
            }
            return response.data;
          });
      }

      static bloodGlucose() {
        let api = '';      
        let userId = localStorage.getItem("userId"); 
        api = API_PATHS.bloodGlucose+ '?userId=' + userId + '&dateType=monthly';
          return axios
            .get(api)
          .then((response) => {
            if (response.data) {
             
            }
            return response.data;
          });
      }
      static hba1c() {
        let api = '';      
        let userId = localStorage.getItem("userId"); 
        api = API_PATHS.hba1c+ '?userId=' + userId + '&dateType=monthly';
          return axios
            .get(api)
          .then((response) => {
            if (response.data) {
             
            }
            return response.data;
          });
      }

      static dietPlan() {
        let api = '';      
        let userId = localStorage.getItem("userId"); 
        api = API_PATHS.userDietPlan+ '?userId=' + userId;
          return axios
            .get(api)
          .then((response) => {
            if (response.data) {
             
            }
            return response.data;
          });
      }

      static WorkoutPlan() {
        let api = '';      
        let userId = localStorage.getItem("userId"); 
        api = API_PATHS.getworkoutplan+ '?userId=' + userId;
          return axios
            .get(api)
          .then((response) => {
            if (response.data) {
             
            }
            return response.data;
          });
      }
    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default formsService;