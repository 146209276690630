import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader, MessageHeader } from "semantic-ui-react";
import Navbarside from "./Navbarside";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./footer";
import { FaVideo } from "react-icons/fa";

import { updateavailablity } from "../store/slices/HcConsultation";

import { API_PATHS } from "../utils/constants/api.constants";


const  Availability=()=> {
 
  const navigate = useNavigate("");
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();

  const [day, setDay] = useState ("");
  const [availability, setAvailablity] = useState ([{
    timings:"",
    day:""
  }]);

  async function addData(e) {
    e.preventDefault();
    let item = {
      set: {
        availability,
      },
    };
    dispatch(updateavailablity(item))
      .unwrap()
      .then(() => {
        alert("Edited succesfully");
        // navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const handleAddForms1 = () => {
    let doctor = [...availability];
    doctor.push({
     timings:""
    });
    setAvailablity(doctor);
  };

  const handleDeleteForms1 = (e) => {
    if (availability.length > 1) {
      let doctor = [...availability];
      doctor.pop();
      setAvailablity(doctor);
    }
  };
  const handleRowChange1 = (e, index) => {
    const doctor = [...availability];
    doctor[index][e.currentTarget.name] = e.currentTarget.value;
    setAvailablity(doctor);
    // console.log(hotelDescription);
  };


  return (
    <body data-col="2-columns" className=" 2-columns ">
     
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-header">
                        <div className="card-title-wrap bar-danger d-flex align-items-center">
                          <h4 className="card-title">
                            Availablity
                            {/* <Link
                              to="/UploadPackage"
                              className="btn btn-primary"
                            >
                              <i className="fa fa-plus"></i> Add
                            </Link> */}
                          </h4>
                       
                        </div>
                        </div>
                      
                        <section className="form-section">
                       <form onSubmit={addData}>

                       <div className="col-lg-3 col-md-4 col-sm-12">

                              
                              <select name="weeks" id="weeks" className="form-control" value={day} onChange={(e) => setDay(e.target.value)}>
  <option value="">Select Day</option>
                       <option value="sunday">Sunday</option>
                       <option value="monday">Monday</option>
                       <option value="tuesday">Tuesday</option> 
                       <option value="wednesday">Wednesday</option>
                       <option value="thursday">Thursday</option>
                       <option value="friday">Friday</option>
                       <option value="saturday">Saturday</option>
</select>

                       </div>

                       <div className="d-flex justify-content-center pt-5 ">
<button type="button" className="btn btn-success"   onClick={(e) => handleAddForms1(e)}>
<i className="fa fa-plus-circle" />


</button>
&nbsp;
&nbsp;
&nbsp;
                              
                              <button type="button" className="btn btn-danger" onClick={(e) => handleDeleteForms1(e)}>
<i className="fa fa-minus-circle" />

</button>
</div>
{availability.map((doctor, index) => (

<div className="d-flex justify-content-center align-items-center pt-2">
<div className="col-lg-3 col-md-4 col-sm-12">
<input type="time"   onChange={(e) => handleRowChange1(e, index)} value={doctor.timings} className="form-control"/>
</div>
&nbsp;
&nbsp;
<b>To</b>
&nbsp;
&nbsp;
<div className="col-lg-3 col-md-4 col-sm-12">
<input type="time"   onChange={(e) => handleRowChange1(e, index)} value={doctor.timings} className="form-control"/>
</div>
</div>
))}
<div className="d-flex justify-content-center pt-4">
<button type="submit" className="btn btn-success col-white">Submit</button>
&nbsp;
&nbsp;
<button type="reset" className="btn btn-danger col-white">Cancel</button> 

</div>
                       </form>
                        </section>

                      </div>
                    </div>
                  </div>
            </div>
            </div>
            </div>
            </div>

       

          <Footer />
    

    </body>
  );
}

export default Availability;
