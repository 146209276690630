import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class womenSpecialSetting {
  static getWomenSpecialSetting() {
    return axios.get(API_PATHS.getWomenSpecialSetting).then((response) => {
      if (response.data) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
  }

  static postWomenSpecialSetting(item) {
    return axios
      .post(API_PATHS.postWomenSpecialSetting, item)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static editWomenSpecialSetting(item) {
    let api = "";
    let id = localStorage.getItem("id");
    api = API_PATHS.putWomenSpecialSetting + "?id=" + id;
    localStorage.removeItem("id");
    return axios.put(api, item).then((response) => {
      if (response.data) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
  }

  static deleteWomenSpecialSetting() {
    let api = "";
    let id = localStorage.getItem("id");
    api = API_PATHS.deleteWomenSpecialSetting + "?id=" + id;
    localStorage.removeItem("id");
    return axios.delete(api).then((response) => {
      if (response.data) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
  }

  static removeUserDetails() {
    localStorage.removeItem("user");
  }

  static getUserDetails() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default womenSpecialSetting;
