import React, { useState, useEffect } from "react";
import Navbarside from "./Navbarside";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "./footer";
import { API_PATHS } from "../utils/constants/api.constants";
import { useDispatch } from "react-redux";
import foodService from "./../services/food.service";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import {uploadDish} from "../store/slices/food";
import axios from "axios";
import ExpertiseService from "./../services/expertise.service";

const AddDish = () => {
  const dispatch = useDispatch();
  const [alertExpertise, setalertExpertise] = useState("");

  const [formData, setFormData] = useState({
    name: '',
    foodTypeId: '',
    foodCategoryId: '',
    foodSubCategoryId: '',
    perUnit: [
      { weight: { quantity: 0 } },
      { calories: { quantity: 0 } },
      { quantity: { quantity: 0 } },
      { weightIn: { quantity: 0 } },
      { weightInGm: { quantity: 0 } },

    ],
    nutrition: [
      { proteins: { quantity: 12 } },
      { carbs: { quantity: 12 } },
      { fats: { quantity: 12 } },
      { fiber: { quantity: 12 } },
    ],
    nutritionInGm: {
      proteins: 12,
      carbs: 12,
      fats: 12,
      fiber: 12,
    },
    mediaLink: '',
    unit: 0,
    isVeg: true,
    source: 'testData',
  });

  const [foodCategories, setFoodCategories] = useState([]);
  const [foodSubcategories, setFoodSubcategories] = useState([]);
  const [foodTypes, setFoodTypes] = useState([]);
  const [mediaLink, setMediaLink] = useState("");
  const [expertiseId, setexpertiseId] = useState("");
  const [message, setMessage] = useState("");
  const [expertises, setExpertises] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState('');


  const validate = () => {
    let errors = {};

    if (!formData.foodTypeId) {
      errors.foodTypeId = 'Food Type is required';
    }

    if (!formData.foodCategoryId) {
      errors.foodCategoryId = 'Food Category is required';
    }

    if (!formData.foodSubCategoryId) {
      errors.foodSubCategoryId = 'Food Subcategory is required';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedFile) {
      setErrors('Please select a file.');
      return;
    }
    try {
      console.log("formData",formData)
      await axios.post('https://api-wm.healthonify.com/post/dish', formData);
      console.log('Dish created successfully!');

    } catch (error) {
      console.error(error);
    }

  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleFileChange = (e) => {
    setErrors('');
    const file = e.target.files[0];
    const allowedExtensions = /(\.xlsx|\.xls)$/i;

    // Check if the selected file is an Excel file
    if (!allowedExtensions.test(file.name)) {
      setErrors('Please select a valid Excel file.');
      return;
    }

    setSelectedFile(file);
  };
  const Expertises = createAsyncThunk(
    "getfoodcategory/get/foodCategory",
    async (thunkAPI) => {
      try {
        // console.log("consultation")
        const data = await foodService.getcategoryfood();
        console.log("data", data);
        return { user: data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  const handleCategoryChange = (e) => {
    var value = expertises.filter(function (Expertises) {
      return Expertises.name == e.target.value;
    });
    setexpertiseId(value[0]._id);
  };

  React.useEffect(() => {
    dispatch(Expertises())
      .unwrap()
      .then((data) => {
        setExpertises(data.user.data);
        console.log("values", data.user.data);
        setexpertiseId(data.user.data[0]._id);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  async function imageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setMediaLink(data.data.location);
    setFormData((prevFormData) => ({
      ...prevFormData,
      ["mediaLink"]: data.data.location,
    }));
    alert(data.message);
  }

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">
                          Add Food
                        </h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Dish Name
                            </p>
                            <input
                              type="text"
                              name="name"
                              placeholder="Enter the Dish name"
                              value={formData.name}
                              onChange={handleChange}
                              className="form-control"
                            />
                            <p className="alert-message"></p>
                          </div>
                          <div className="col-md-6">
                            <div className="form-body">
                              <div className="form-group">
                                <label for="placeTextarea">FoodType</label>
                                <select
                                  className="form-control"
                                  onChange={handleChange}
                                  required
                                  name="foodTypeId"
                                >
                                  <option value="">Select</option>
                                  {expertises.map((option) => (
                                    <option value={option._id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                                <p className="alert-message">
                                  {alertExpertise}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-body">
                              <div className="form-group">
                                <label for="placeTextarea">FoodCategory</label>
                                <select
                                  className="form-control"
                                  onChange={handleChange}
                                  required
                                  name="foodCategoryId"
                                >
                                  <option value="">Select</option>
                                  {expertises.map((option) => (
                                    <option value={option._id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                                <p className="alert-message">
                                  {alertExpertise}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-body">
                              <div className="form-group">
                                <label for="placeTextarea">FoodSubCategory</label>
                                <select
                                  className="form-control"
                                  onChange={handleChange}
                                  required
                                  name="foodSubCategoryId"

                                >
                                  <option value="">Select</option>
                                  {expertises.map((option) => (
                                    <option value={option._id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                                <p className="alert-message">
                                  {alertExpertise}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-body">
                              <div className="form-group">
                                <label for="placeTextarea">Dish Image</label>
                                <input
                                  type="file"
                                  className="form-control-file"
                                  Placeholder=""
                                  name="imageUrl"
                                  onChange={(e) => imageUpload(e)}
                                />
                                <p
                                  style={{
                                    color: "green",
                                    fontWeight: "500",
                                  }}
                                >
                                  {message}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mt-3">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              PerUnit
                            </p>
                          </div>
                          <div className="col-12">
                            <div className="row">
                              <div className="col-md-3 col-sm-12 pt-2 pb-1" >
                                <p>Calories</p>
                                <input
                                  type="number"
                                  name="perUnit.calories"
                                  placeholder="Count of calories"
                                  value={formData.perUnit.calories}
                                  onChange={handleChange}
                                  className="form-control"
                                />
                                <p className="alert-message"></p>
                              </div>

                              <div className="col-md-3 col-sm-12 pt-2 pb-1">
                                <p>Weight</p>
                                <input
                                  min="0"
                                  type="number"
                                  className="form-control"
                                  placeholder="Weight"
                                  value={formData.perUnit.weight}
                                  onChange={handleChange}
                                // name="perUnit:weight"
                                />
                              </div>

                              <div className="col-md-3 col-sm-12 pt-2 pb-1">
                                <p>Quantity</p>
                                <input
                                  min="0"
                                  type="number"
                                  className="form-control"
                                  placeholder="Quantity"
                                  value={formData.perUnit.quantity}
                                  onChange={handleChange}
                                // name="perUnit.quantity"
                                />
                              </div>

                              <div className="col-md-3 col-sm-12 pt-2 pb-1">
                                <p>Weigh In</p>
                                <input
                                  min="0"
                                  type="number"
                                  className="form-control"
                                  placeholder="Weight In"
                                  value={formData.nutrition.fiber}
                                  onChange={handleChange}
                                // name="nutrition.fiber"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-12 mt-3">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Nutrition
                            </p>
                          </div>

                          <div className="col-12">
                            <div className="row">
                              <div className="col-md-3 col-sm-12 pt-2 pb-1">
                                <p>Protein</p>
                                <input
                                  min="0"
                                  type="number"
                                  className="form-control"
                                  placeholder="Protein quantity"
                                  value={formData.nutrition.proteins}
                                  onChange={handleChange}
                                  name="proteins"
                                />
                              </div>

                              <div className="col-md-3 col-sm-12 pt-2 pb-1">
                                <p>Carbs</p>
                                <input
                                  min="0"
                                  type="number"
                                  className="form-control"
                                  placeholder="Carbs quantity"
                                  value={formData.nutrition.carbs}
                                  onChange={handleChange}
                                  name="carbs"
                                />
                              </div>

                              <div className="col-md-3 col-sm-12 pt-2 pb-1">
                                <p>Fat</p>
                                <input
                                  min="0"
                                  type="number"
                                  className="form-control"
                                  placeholder="Fat quantity"
                                  value={formData.nutrition.fats}
                                  onChange={handleChange}
                                  name="fats"
                                />
                              </div>

                              <div className="col-md-3 col-sm-12 pt-2 pb-1">
                                <p>Fiber</p>
                                <input
                                  min="0"
                                  type="number"
                                  className="form-control"
                                  placeholder="Fiber quantity"
                                  value={formData.nutrition.fiber}
                                  onChange={handleChange}
                                  name="fiber"
                                />
                              </div>
                            </div>

                          </div>
                          {/* <div className="col-md-12">
                            <div className="form-body">
                              <div className="form-group">
                                <label for="placeTextarea">Food Library Excel Sheet</label>
                                <input
                                  type="file"
                                  className="form-control-file"
                                  id="file-upload"
                                  Placeholder=""
                                  accept=".xlsx, .xls"
                                  name="imageUrl"
                                  onChange={handleFileChange}
                                />
                                <p
                                  style={{
                                    color: "green",
                                    fontWeight: "500",
                                  }}
                                >
                                  {error && <p>{error}</p>}

                                  {selectedFile && (
                                    <p>Selected file: {selectedFile.name}</p>
                                  )}                                </p>
                              </div>
                            </div>
                          </div> */}
                        </div>
                        
                        <div className="d-flex justify-content-center pt-3">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                          &nbsp; &nbsp; &nbsp;
                          <button type="reset" className="btn btn-warning">
                            Reset
                          </button>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );

};

export default AddDish;

