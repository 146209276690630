import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import Navbarside from "../component/Navbarside";
import { Link } from "react-router-dom";
import Footer from "./footer";
import { FaVideo } from "react-icons/fa";
import { getConsultationid } from "./../store/slices/consultation";

function MyConsultation() {
  const [myconsultations, setMyConsultations] = useState([]);
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getConsultationid())
      .unwrap()
      .then((data) => {
        setMyConsultations(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header d-flex align-items-center">
                      <h4 className="card-title">My Consultations</h4>
                      &nbsp; &nbsp;
                      <Link
                        to="/Updateconsult-physiotherapy"
                        className="btn btn-primary"
                      >
                        <i className="fa fa-plus"></i> Add
                      </Link>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Patient Name</th>
                              <th>Start Time</th>
                              <th>Start Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          {loading ? (
                            <Loader active inline />
                          ) : (
                            <>
                              <tbody>
                                {myconsultations.map((consult, index) => (
                                  <tr key={index}>
                                    <td>{/*{consult.userId[1].firstName}*/}</td>

                                    <td>{consult.startTime}</td>
                                    <td>{consult.startDate}</td>
                                    <td align="center">
                                      {consult.meetingLink && (
                                        <Link to={consult.meetingLink}>
                                          <FaVideo />
                                        </Link>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
}

export default MyConsultation;
