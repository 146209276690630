import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hcexpertService from "../../services/hcexpert.service";
import AuthService from "../../services/auth.service";
import axios from 'axios'

export const getWmExperts = createAsyncThunk(
  "expert/get/expert",
  async (thunkAPI) => {
    try {
      const [allExpertise, experts, enquiryData] = await Promise.all([
        hcexpertService.getAllExpertise(),
        hcexpertService.getExperts(localStorage.getItem('category')),
        hcexpertService.getEnquiryData(localStorage.getItem('ticketNumber')),
      ])

      return {
        allExpertise,
        experts,
        enquiryData
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const getAllExpertise = createAsyncThunk(
  "expert/get/allExpertise",
  async (thunkAPI) => {
    try {
      const [allExpertise] = await Promise.all([
        hcexpertService.getAllExpertise()
      ])

      return {
        allExpertise,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const getHealthConditions = createAsyncThunk(
  "expert/get/allExpertise",
  async (params, thunkAPI) => {
    try {
      return await Promise.all([
        hcexpertService.getHealthConditions(params)
      ])

    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const getFitnessExperts = createAsyncThunk(
  "expert/get/expert",
  async (thunkAPI) => {
    try {
      const data = await hcexpertService.getFitnessExperts();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
export const getHcExperts = createAsyncThunk(
  "/user/assignHcExpert",
  async (thunkAPI) => {
    try {
      const data = await hcexpertService.getHcExperts();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const getHcExpertsdetails = createAsyncThunk(
  "/user/assignHcExpert",
  async (thunkAPI) => {
    try {
      const data = await hcexpertService.getHcexpertdetail();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const getPhysioExperts = createAsyncThunk(
  "expert/get/expert",
  async (thunkAPI) => {
    try {
      const data = await hcexpertService.getPhysioExperts();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
export const posthcexperts = createAsyncThunk(
  "expert/post/expert",
  async (item, thunkAPI) => {
    try {
      const data = await hcexpertService.posthcexpert(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
  loading: false,
  error: "",
  user: AuthService.getUserDetails() || null,
  isLoggedIn: false,
};

const labdetailsSlice = createSlice({
  name: "healthcareconsultation",
  initialState,
  extraReducers: {

    [posthcexperts.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [posthcexperts.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [posthcexperts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },



    // [logout.fulfilled]: (state, action) => {
    //   state.isLoggedIn = false;
    //   state.user = null;
    // },
  },
});

const { reducer } = labdetailsSlice;
export default reducer;