import React from 'react';

const VideoFilePlayer = ({ s3Link, format }) => {
  return (
    <video controls width="300" height="200">
      <source src={s3Link} type={`video/${format}`} />
      Your browser does not support the video tag.
    </video>
  );
};

export default VideoFilePlayer;