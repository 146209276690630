import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class foodService {

  static getcategoryfood() {
    let api = '';
    let id = localStorage.getItem("id");
    api = id ? API_PATHS.getfoodcategory + '?_id=' + id : API_PATHS.getfoodcategory;
    localStorage.removeItem("id");
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }


  static gettypefood() {
    let api = '';
    let id = localStorage.getItem("id");
    api = id ? API_PATHS.getfoodtype + '?_id=' + id : API_PATHS.getfoodtype;
    localStorage.removeItem("id");
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static getfooddish(params) {
    let api = '';
    let id = localStorage.getItem("id");
    api = id ? API_PATHS.getdish + '?_id=' + id : API_PATHS.getdish;
    localStorage.removeItem("id");
    return axios
      .get(api,{ params })
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }



  static posttypefood(item) {
    let api = '';
    api = API_PATHS.postfoodtype;
    return axios
      .post(api, item)
      .then((response) => {
        if (response.data) {

        }
        return response.data;
      });
  }

  static postcategoryfood(item) {
    let api = '';
    api = API_PATHS.postfoodcategory;
    return axios
      .post(api, item)
      .then((response) => {
        if (response.data) {

        }
        return response.data;
      });
  }


  static removeUserDetails() {
    localStorage.removeItem("user");
  }

  static getUserDetails() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default foodService