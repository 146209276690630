import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Navbarside from "../component/Navbarside";
import { datewisequiry } from "../store/slices/enquiry";
import Moment from "moment";
import Footer from "./footer";

const Enquiry = () => {
  const [expertises, setexpertise] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const values = location.state;
  localStorage.setItem("fdate", values.fromdate);
  localStorage.setItem("tdate", values.todate);

  React.useEffect(() => {
    dispatch(datewisequiry())
      .unwrap()
      .then((data) => {
        setexpertise(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success">
                        <h4 className="card-title">Enquiry</h4>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Contact </th>
                              <th>Date of Enquiry</th>
                              <th>Message</th>
                            </tr>
                          </thead>
                          <tbody>
                            {expertises.map((enqVal, index) => (
                              <tr key={index}>
                                <td> {enqVal.name}</td>
                                <td>{enqVal.email}</td>

                                <td>{enqVal.contactNumber}</td>

                                <td>
                                  {" "}
                                  {Moment(enqVal.created_at).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>

                                <td>{enqVal.message}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Enquiry;
