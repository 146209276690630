import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "./Navbarside";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./footer";

import { postexercise } from "../store/slices/exercise";
import { getbodypartgroup } from "../store/slices/body";
import { getbodyspart } from "../store/slices/body";

import { API_PATHS } from "../utils/constants/api.constants";

const AddExercise = () => {
  const navigate = useNavigate("");
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [calorieFactor, setcalorieFactor] = useState("");
  const [minWeight, setMinWeight] = useState("");
  const [weightUnit, setWeightUnit] = useState("");
  const [mediaLink, setMediaLink] = useState("");
  const [message, setMessage] = useState("");
  const [description, setDescription] = useState("");

  const [alertname, setAlertName] = useState("");
  const [alertcaloriesPerRep, setAlertCaloriesPerRep] = useState("");
  const [alertminWeight, setAlertMinWeight] = useState("");
  const [alertweightUnit, setAlertWeightUnit] = useState("");
  const [alertmediaLink, setAlertMediaLink] = useState("");
  const [alertbodyPartId, setAlertBodyPartId] = useState("");
  const [alertbodyPartGroupId, setAlertBodyPartGroupId] = useState("");

  const [bodypart, setBodypart] = useState([]);
  const [bodyPartId, setBodyPartId] = useState("");
  const [bodyPartname, setBodyPartname] = useState("");

  const [bodypartgroup, setBodypartgroup] = useState([]);
  const [bodyPartGroupId, setBodyPartGroupId] = useState("");
  const [bodyPartGroupname, setBodyPartGroupname] = useState("");

  React.useEffect(() => {
    dispatch(getbodyspart())
      .unwrap()
      .then((data) => {
        setBodypart(data.user.data);
        setBodyPartId(data.user.data[0]._id);
        setBodyPartname(data.user.data[0].name);
        console.log(data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getbodypartgroup())
      .unwrap()
      .then((data) => {
        setBodypartgroup(data.user.data);
        setBodyPartGroupId(data.user.data[0]._id);
        setBodyPartGroupname(data.user.data[0].name);
        console.log(data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  const handlebodypartgroup = (e) => {
    var value = bodypartgroup.filter(function (bodypartgroup) {
      return bodypartgroup.name == e.target.value;
    });
    setBodyPartGroupId(value[0]._id);
    setBodyPartGroupname(value[0].name);
  };

  const handlebodypart = (e) => {
    var value = bodypart.filter(function (bodypart) {
      return bodypart.name == e.target.value;
    });
    setBodyPartId(value[0]._id);
    setBodyPartname(value[0].name);
  };

  async function imageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setMediaLink(data.data.location);
    setMessage(data.message);
  }

  async function addData(e) {
    e.preventDefault();
    let item = {
      name,
      calorieFactor,
      description,
      weightUnit,
      mediaLink,
      bodyPartId,
      bodyPartGroupId,
    };
    dispatch(postexercise(item))
      .unwrap()
      .then(() => {
        alert("Uploaded succesfully");
        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const save = (e) => {
    const regtext = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regtext.test(name)) {
      setAlertName("");
    } else if (!regtext.test(name) && name === "") {
      setAlertName("Please enter the body part name");
      e.preventDefault();
    } else {
      setAlertName("");
    }

    const regtext2 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regtext2.test(weightUnit)) {
      setAlertWeightUnit("");
    } else if (!regtext2.test(weightUnit) && weightUnit === "") {
      setAlertWeightUnit("Please enter the weight unit");
      e.preventDefault();
    } else {
      setAlertWeightUnit("");
    }

    const regnumber1 = /^[0-9][0-9]?$|^100$/;
    if (regnumber1.test(calorieFactor)) {
      setAlertCaloriesPerRep("");
    } else if (!regnumber1.test(calorieFactor) && calorieFactor === "") {
      setAlertCaloriesPerRep("Please enter the calorie factor");
      e.preventDefault();
    } else {
      setAlertCaloriesPerRep("");
    }

    const regnumber2 = /^[0-9][0-9]?$|^100$/;
    if (regnumber2.test(weightUnit)) {
      setAlertMinWeight("");
    } else if (!regnumber2.test(weightUnit) && weightUnit === "") {
      setAlertMinWeight("Please enter the weight unit");
      e.preventDefault();
    } else {
      setAlertMinWeight("");
    }

    const regimage = /(gif|jpe?g|tiff?|png|webp|bmp)$/i;
    if (regimage.test(mediaLink)) {
      setAlertMediaLink("");
    } else if (!regimage.test(mediaLink) && mediaLink === "") {
      setAlertMediaLink("Please enter image link");
      e.preventDefault();
    } else {
      setAlertMediaLink("Invalid file");
      e.preventDefault();
    }

    const option1 = /bodyPartId/i;
    if (option1.test(bodyPartId)) {
      setAlertBodyPartId("");
    } else if (!option1.test(bodyPartId) && bodyPartId === "") {
      setAlertBodyPartId("Please select the body part");
      e.preventDefault();
    } else {
      setAlertBodyPartId("");
    }

    const option2 = /bodyPartGroupId/i;
    if (option2.test(bodyPartGroupId)) {
      setAlertBodyPartGroupId("");
    } else if (!option2.test(bodyPartGroupId) && bodyPartGroupId === "") {
      setAlertBodyPartGroupId("Please select the body part group");
      e.preventDefault();
    } else {
      setAlertBodyPartGroupId("");
    }
  };

  const cancel = () => {
    setName("");
    setcalorieFactor("");
    setMinWeight("");
    setWeightUnit("");
    setMediaLink("");
    setMessage("");
    setAlertName("");
    setAlertCaloriesPerRep("");
    setAlertMinWeight("");
    setAlertWeightUnit("");
    setAlertMediaLink("");
    setAlertBodyPartId("");
    setAlertBodyPartGroupId("");
    setBodyPartId("");
    setBodyPartGroupId("");
  };

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">Add Exercise</h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <form onSubmit={addData}>
                        <div className="row">
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Exercise Name
                            </p>
                            <input
                              type="text"
                              placeholder="Enter exercise name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertname}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Calorie Factor
                            </p>
                            <input
                              type="text"
                              placeholder="Enter calorie factor"
                              value={calorieFactor}
                              onChange={(e) => setcalorieFactor(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">
                              {alertcaloriesPerRep}
                            </p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Weight Unit
                            </p>
                            <input
                              type="text"
                              placeholder="Enter the weight unit"
                              value={weightUnit}
                              onChange={(e) => setWeightUnit(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertweightUnit}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Exercise GIF/Image
                            </p>
                            <input
                              type="file"
                              className="form-control-file"
                              Placeholder=""
                              name="imageUrl"
                              onChange={(e) => imageUpload(e)}
                            />
                            <p className="alert-message">{alertmediaLink}</p>
                            <p
                              style={{
                                color: "green",
                                fontWeight: "500",
                              }}
                            >
                              {message}
                            </p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Body Part
                            </p>
                            <select
                              name="exercise"
                              id="exercise"
                              className="form-control"
                              onChange={handlebodypart}
                            >
                              <option value="">Select Body Part</option>
                              {bodypart.map((bodypartVal) => (
                                <option value={bodypartVal._id}>
                                  {bodypartVal.name}
                                </option>
                              ))}
                            </select>
                            <p className="alert-message">{alertbodyPartId}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Body Part Group
                            </p>

                            <select
                              name="cars"
                              id="cars"
                              className="form-control"
                              onChange={handlebodypartgroup}
                            >
                              <option value="">Select Body Part Group </option>
                              {bodypartgroup.map((bodygroupVal) => (
                                <option value={bodygroupVal._id}>
                                  {bodygroupVal.name}
                                </option>
                              ))}
                            </select>
                            <p className="alert-message">
                              {alertbodyPartGroupId}
                            </p>
                          </div>
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Description
                            </p>
                            <textarea
                              placeholder="Enter description"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertweightUnit}</p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center pt-3">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                          &nbsp; &nbsp; &nbsp;
                          <button type="reset" className="btn btn-warning">
                            Reset
                          </button>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default AddExercise;
