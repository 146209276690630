import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "../component/Navbarside";
import Footer from "./footer";
import Moment from "moment";
import {
  SalesAnalysisDetails,
  enquiryAnalysisDetails,
} from "../store/slices/analysis";
import { useDownloadExcel } from "react-export-table-to-excel";
import { formatDate } from "../utils/appUtils";
import Loader from "./Loader";
const EnquiryAnalysis = () => {
  const dispatch = useDispatch();
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const tableRef = useRef(null);
  const [errorStart, setErrorStart] = useState("");
  const [errorEnd, setErrorEnd] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("");

  const statusDropDown = [
    { name: "initiated", label: "Initiated" },
    { name: "generalEnquiryInitiated", label: "General Enquiry Initiated" },
    { name: "paymentRequested", label: "Payment Requested" },
    { name: "consultationScheduled", label: "Consultation Scheduled" },
    { name: "closed", label: "Closed" },
    { name: "consulationCompleted", label: "Consulation Completed" },
    { name: "packagePaymentRequested", label: "Package Payment Requested" },
    { name: "packageSubscribed", label: "Package Subscribed" },
  ];

  useEffect(() => {
    const sDate = dateForQuery().startDate;
    const eDate = dateForQuery().endDate;
    const status = "";
    analysisData(sDate, eDate, status);
    setstartDate(sDate);
    setendDate(eDate);
    // const catDataRender =categories?.filter((data)=>data?.name===cat)[0]
    // setCateg(catDataRender?.name)
  }, []);

  async function analysisData(startDate, endDate, status = "") {
    if (endDate?.length <= 0 && startDate?.length <= 0) {
      setErrorStart("Please select start date");
      setErrorEnd("Please select end date");
      return;
    } else if (startDate?.length <= 0) {
      setErrorStart("Please select start date");
      return;
    } else if (endDate?.length <= 0) {
      setErrorEnd("Please select end date");
      return;
    } else {
      setErrorEnd("");
      setErrorStart("");
    }
    let item = {};
    startDate?.length && (item["startDate"] = startDate);
    endDate?.length && (item["endDate"] = endDate);
    status?.length && (item["status"] = status);
    setIsLoading(true);
    dispatch(enquiryAnalysisDetails(item))
      .unwrap()
      .then((data) => {
        setIsLoading(false);
        setData(data?.data);
      })
      .catch(({ message }) => {
        setIsLoading(false);

        // alert(message);
      });
  }

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "EnquiryAnalysis Analysis Report",
    sheet: "Sheet1",
  });

  const dateForQuery = () => {
    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    const formattedToday = formatDate(today);
    const formattedOneMonthAgo = formatDate(oneMonthAgo);
    return { startDate: formattedToday, endDate: formattedOneMonthAgo };
  };

  return (
    <div>
      <Navbarside />
      {isLoading && <Loader />}

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">EnquiryAnalysis Report</h4>
                        {/* &nbsp;
                        <ExportToExcel apiData={data} fileName={fileName} /> */}
                      </div>
                    </div>
                    <section className="form-section">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          analysisData(startDate, endDate, status);
                        }}
                      >
                        <div className="row">
                          <div className="col-md-3 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              From Date
                            </p>
                            <input
                              type="date"
                              placeholder="Enter the recipe name"
                              value={startDate}
                              onChange={(e) => setstartDate(e.target.value)}
                              className="form-control"
                            />
                            {errorStart?.length > 0 && (
                              <div className="text-danger">{errorStart}</div>
                            )}
                            <p className="alert-message"></p>
                          </div>
                          <div className="col-md-3 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              To Date
                            </p>
                            <input
                              type="date"
                              placeholder="Enter the recipe name"
                              value={endDate}
                              onChange={(e) => setendDate(e.target.value)}
                              className="form-control"
                            />
                            {errorEnd?.length > 0 && (
                              <div className="text-danger">{errorEnd}</div>
                            )}
                            <p className="alert-message"></p>
                          </div>
                          <div className="col-md-3 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Status
                            </p>
                            <select
                              className="form-control"
                              onChange={(e) => setStatus(e.target.value)}
                              value={status}
                              //  onChange={handleExpertiseChange}
                            >
                              <option value="">Select Status</option>
                              {statusDropDown?.map((option) => (
                                <option value={option.name}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-3 col-sm-12 pt-2 mt-3">
                            <button type="submit" className="btn btn-primary">
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </section>

                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        {data !== "" && (
                          <div>
                            <button
                              onClick={onDownload}
                              className="btn btn-primary"
                            >
                              Export to Excel
                            </button>
                            {data?.length > 0 ? (
                              <table
                                className="table table-striped table-bordered zero-configuration"
                                ref={tableRef}
                              >
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th>Enquiry User Name</th>
                                    <th>Ticket Number</th>
                                    <th>Category</th>
                                    <th>Status</th>
                                    <th>Assigned Agent</th>
                                    <th>Follow Up Status</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {data?.map((dataVal, index) => (
                                    <tr key={index}>
                                      <td>
                                        {" "}
                                        {Moment(dataVal?.created_at).format(
                                          "DD-MM-YYYY"
                                        )}
                                        ,{" "}
                                        {Moment(dataVal?.created_at).format(
                                          "hh:mm A"
                                        )}
                                      </td>
                                      <td>
                                        {`${
                                          dataVal?.userId?.firstName ?? "User"
                                        } ${dataVal?.userId?.lastName ?? ""}`}
                                      </td>
                                      <td>{dataVal?.ticketNumber}</td>
                                      <td>{dataVal?.flow}</td>
                                      <td>{dataVal?.status?.toUpperCase()}</td>
                                      <td>
                                        {" "}
                                        {`${
                                          dataVal?.expertId?.firstName ?? ""
                                        } ${dataVal?.expertId?.lastName ?? ""}`}
                                      </td>
                                      <td></td>
                                      {/* <td>{dataVal?.invoiceNumber}</td>
                                                            <td>{dataVal?.grossAmount}</td> */}
                                      {/* <td>{dataVal?.paymentFor}</td>
                                                            <td>{dataVal?.grossAmount}</td>
                                                            <td>{dataVal?.paymentType}</td>
                                                            <td>{dataVal?.gstAmount}</td>
                                                            <td>{dataVal?.netAmount}</td> */}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            ) : (
                              <p>No data found in this range </p>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default EnquiryAnalysis;
