import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class bodyService {
 
    static getbodygroup() {
        return axios
            .get(API_PATHS.getpartbodygroup)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }


    static getbodyparts(params) {
        return axios
            .get(API_PATHS.getbodypart, {params})
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }


    static postbodygroup(item) {
        return axios
            .post(API_PATHS.postpartbodygroup,
                item
            )
            .then((response) => {
                if (response.data) {
                     // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static editbodygroup(item) {
        let api = '';
        let id = localStorage.getItem("id");
          api = API_PATHS.putpartbodygroup + '?id=' + id ;
        localStorage.removeItem("id");
        return axios
            .put(api,item)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static deleteBodygroup() {
       let api = '';
       let id = localStorage.getItem("id");
         api = API_PATHS.deletepartbodygroup + '?id=' + id ;
       localStorage.removeItem("id");
       return axios
           .delete(api)
           .then((response) => {
               if (response.data) {
                   // localStorage.setItem("user", JSON.stringify(response.data));
               }
               return response.data;
           });
   }

    static postbodypart(item) {
        return axios
            .post(API_PATHS.postpartbody,
                item
            )
            .then((response) => {
                if (response.data) {
                     // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static editbodypart(item) {
        let api = '';
        let id = localStorage.getItem("id");
          api = API_PATHS.putpartbody + '?id=' + id ;
        localStorage.removeItem("id");
        return axios
            .put(api,item)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static deleteBodyparts() {
       let api = '';
       let id = localStorage.getItem("id");
         api = API_PATHS.deletepartbody + '?id=' + id ;
       localStorage.removeItem("id");
       return axios
           .delete(api)
           .then((response) => {
               if (response.data) {
                   // localStorage.setItem("user", JSON.stringify(response.data));
               }
               return response.data;
           });
   }

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default bodyService;