import React from "react";
import { Link } from "react-router-dom";
import Navbarside from "./Navbarside";
import { useDispatch, useSelector } from "react-redux";
import { getlabtestcategory, enablevendors } from "../store/slices/Labcategory";
import {} from "../store/slices/lab";
import { useState } from "react";
import Footer from "./footer";

const LabTestcategory = () => {
  const [vendor, setVendor] = useState([]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getlabtestcategory())
      .unwrap()
      .then((data) => {
        setVendor(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  async function enableApprove(id) {
    let isApproved = true;
    let item = { set: { isApproved } };
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("id", id);

    dispatch(enablevendors())
      .unwrap()
      .then(() => {
        // alert("Uploaded succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
    console.log(item);
    console.log(id);
  }

  async function disableApprove(id) {
    let isApproved = false;
    let item = { isApproved };
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("id", id);

    dispatch(enablevendors())
      .unwrap()
      .then(() => {
        // alert("Uploaded succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
    console.log(item);
    console.log(id);
  }

  return (
    <div>
      <Navbarside />

      <div className="container-fluid response-cover pb-5">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Lab Test Category</h4>
                        &nbsp; &nbsp; &nbsp;
                        <Link
                          to="/Labs/LabTestcategory/AddLabTestCategory"
                          className="btn btn-success col-white"
                        >
                          Add
                        </Link>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Price</th>
                              {/* <th>Status</th> */}
                              <th>Action </th>
                            </tr>
                          </thead>

                          <tbody>
                            {vendor.map((LabVal, index) => (
                              <tr key={index}>
                                <td>{LabVal.name}</td>

                                <td>{LabVal.price}</td>

                                {/* <td>
                                  {LabVal.isApproved === false && (
                                    <button
                                      className="btn btn-danger"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                      onClick={() => enableApprove(LabVal._id)}
                                    >
                                      No
                                    </button>
                                  )}
                                  {LabVal.isApproved === true && (
                                    <button
                                      className="btn btn-success"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                      onClick={() => disableApprove(LabVal._id)}
                                    >
                                      Yes
                                    </button>
                                  )}
                                </td> */}

                                <td>
                                  <Link
                                    to={`/Labs/LabTestcategory/EditLabTestCategory/${
                                      LabVal._id ? LabVal._id : null
                                    }`}
                                    className="btn btn-primary col-white"
                                  >
                                    Edit
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default LabTestcategory;
