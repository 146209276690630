import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Navbarside from "../Navbarside";
import { getPackage, DeletePackage } from "../../store/slices/package";
import Footer from "../footer";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import $ from "jquery";
import M from 'materialize-css';
import { getAllExpertise } from "../../store/slices/hcexpert";
import axios from 'axios'
import moment from 'moment'
import { useLocation } from "react-router-dom";

const Package = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  // M.AutoInit();

  const [allExpertise, setExpertise] = useState(null);
  const [selectedExpertise, setSelectedExpertise] = useState(null)
  const [selectedSubExpertise, setSelectedSubExpertise] = useState(null)
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");

  useEffect(() => {
    dispatch(getAllExpertise()).unwrap().then((data) => {
      setExpertise(data.allExpertise)
    }).catch(({ message }) => {
      // alert(message);
    });
  }, [dispatch,]);

  const handleExpertiseChange = e => {
    setSelectedSubExpertise(null)
    setSelectedExpertise(e.target.value)
  }
  const handleSubExpertiseChange = e => setSelectedSubExpertise(e.target.value)
  const handleAction = e => console.log(e.target.value)

  const setTable = (data) => {
    $("#example").DataTable({
      data: data.map((e, index) => {
        return {
          index,
          ...e,
          category: e.expertiseId?.name,
          subCategory: e.subCategoryId?.name,
          session: e.sessionCount,
          duration: e.durationInDays,
        };
      }),
      columns: [
        {
          title: "#",
          render: (data, type, row) => {
            return ++row.index;
          },
        },
        {
          title: "Package Name",
          data: "name",
        },
        {
          title: "Category",
          data: "category",
          render: (data, type, row) => {
            return row.category ?? "";
          },
        },
        {
          title: "Sub Category",
          data: "subCategory",
          render: (data, type, row) => {
            return row.subCategory ?? "";
          },
        },
        {
          title: "Session",
          data: "session",
          render: (data, type, row) => {
            return row.session ?? "";
          },
        },
        {
          title: "Duration",
          data: "duration",
          render: (data, type, row) => {
            return row.duration ?? "";
          },
        },
        {
          title: "Status",
          render: (data, type, row) => {
            if (row.isActive === true) {
              return `<button class="btn btn-success" style="height: 25px; padding: 0 10px">Active</button>`;
            } else {
              return `<button class="btn btn-danger" style="height: 25px; padding: 0 10px">Inactive</button>`;
            }
          },
        },
        {
          title: "Action",
          render: (data, type, row) => {
            return `
            <button
              data-id="${row._id}"
              class="btn btn-warning delete"
              style="height: 25px; padding: 0 10px"
            >
            Delete
            </button>
            <a 
              href='/MembershipAnalysis?expertiseId=${row.expertiseId?._id}&subCategoryId=${row.subCategoryId?._id}&flow=${row.expertiseId.flowName}'
             class='btn btn-success'
              onClick="${handleAction}"
              data-id="${row._id}"
              style="height: 25px; padding: 0 10px"
            >
            View Subscription
            </a>
            `;
          },
        },
      ],
      destroy: true,
    });
  }

  const handleFilterSubmit = () => {
    let params = {}
    if (selectedExpertise) params.expertiseId = selectedExpertise
    if (selectedSubExpertise) params.subCategoryId = selectedSubExpertise
    if (startDate) params.fromDate = moment(startDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
    if (endDate) params.toDate = moment(endDate, 'YYYY-MM-DD').format('MM/DD/YYYY')

    axios.get('https://api.healthonify.com/get/package', {
      params
    }).then(res => setTable(res.data.data))

  };

  useEffect(() => {
    dispatch(getPackage({})).unwrap().then((data) => {
      setTable(data.user.data)
    }).catch(({ message }) => {
      // alert(message);
    });

    $("#example").on("click", ".delete", function (e) {
      e.stopPropagation();
      deleteData($(this).data("id"));
    });
  }, [dispatch]);

  const Delete = (id) => {
    localStorage.setItem("id", id);
    dispatch(DeletePackage())
      .unwrap()
      .then(() => {
        // alert("Uploaded succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });

    console.log(id);
  };
  const deleteData = (id) => {
    confirmAlert({
      title: "Want to delete?",
      message: "Are you sure you want to delete this package?",
      buttons: [
        {
          label: "Yes",
          onClick: () => Delete(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };
  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-wrap bar-success">
                          <h4 className="card-title">
                            Package &nbsp; &nbsp;
                            <Link to="/AddPackage" className="btn btn-primary">
                              <i className="fa fa-plus"></i> Add
                            </Link>
                          </h4>
                        </div>
                        <section className="form-section">
                          <div className="row">
                            <form className="row">
                              <div className="col-md-5 col-sm-12 pt-2">
                                <p className="col-black" style={{ marginBottom: "2px" }}>Category</p>
                                <select className="form-control" onChange={handleExpertiseChange} >
                                  <option value="">Select Expertise</option>
                                  {allExpertise && allExpertise.data && allExpertise.data.length
                                    ? allExpertise.data.map(option => {
                                      if (!option.parentExpertiseId || option.parentExpertiseId == null) {
                                        return (
                                          <option value={option._id}>
                                            {option.name}
                                          </option>
                                        )
                                      }
                                    })
                                    : ''
                                  }
                                </select>
                              </div>

                              <div className="col-md-5 col-sm-12 pt-2">
                                <p
                                  className="col-black"
                                  style={{ marginBottom: "2px" }}
                                >
                                  Sub Category
                                </p>
                                <select
                                  className="form-control"
                                  onChange={handleSubExpertiseChange}
                                >
                                  <option value="">Select Sub - Expertise</option>
                                  {selectedExpertise
                                    ? allExpertise.data.map(expertise => {
                                      if (expertise.parentExpertiseId == selectedExpertise) {
                                        return (
                                          <option value={expertise._id}>
                                            {expertise.name}
                                          </option>
                                        )
                                      }
                                    })
                                    : ''
                                  }
                                </select>
                              </div>

                            </form>
                            <div className="col-md-2 col-sm-12 pt-2 mt-3">

                              <button className="btn btn-primary" onClick={handleFilterSubmit}>
                                Apply Filter
                              </button>
                            </div>
                          </div>
                        </section>
                      </div>
                      <div className="card-body collapse show">
                        <div className="card-block card-dashboard table-responsive">
                          <table id="example" className="display"></table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
};

export default Package;