import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Navbarside from "../component/Navbarside";
import Footer from "./footer";
import { Link, useParams } from "react-router-dom";
import { clientDetail } from "../store/slices/auth";
const ClientDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  localStorage.setItem("clientId", id);
  const [details, setDetails] = useState("");

  React.useEffect(() => {
    dispatch(clientDetail())
      .unwrap()
      .then((data) => {
        setDetails(data.user.data[0]);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  return (
    <div>
      <Navbarside />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="card-block">
                        <div className="align-self-center halfway-fab text-center">
                          <a className="profile-image">
                            <img
                              src={details.imageUrl}
                              alt=""
                              className="rounded-circle img-border gradient-summer width-100"
                            />
                          </a>
                        </div>

                        <div className="text-center">
                          <span className="font-medium-2 text-uppercase">
                            {details.firstName} {details.lastName}
                          </span>
                          <p>
                            Gender: {details.gender}
                            <br />
                            Age: {details.age}
                          </p>
                        </div>
                      </div>
                      <div className="container">
                        <div className="row">
                          <div className="col-md-2">
                            <Link
                              to={`/MedicalForm/${details._id}`}
                              className="btn btn-success"
                            >
                              Medical Form
                            </Link>
                          </div>

                          <div className="col-md-2">
                            <Link
                              to={`/LifestyleForm/${details._id}`}
                              className="btn btn-success"
                            >
                              Lifestyle Form
                            </Link>
                          </div>
                          <div className="col-md-2">
                            <Link
                              to={`/FitnessForm/${details._id}`}
                              className="btn btn-success"
                            >
                              Fitness Form
                            </Link>
                          </div>
                          <div className="col-md-2">
                            <Link
                              to={`/UserGoals/${details._id}`}
                              className="btn btn-success"
                            >
                              Goals
                            </Link>
                          </div>
                          <div className="col-md-2">
                            <Link
                              to={`/UserMeasurement/${details._id}`}
                              className="btn btn-success"
                            >
                              Measurement
                            </Link>
                          </div>
                          <div className="col-md-2">
                            <Link
                              to={`/UserVitals/${details._id}`}
                              className="btn btn-success"
                            >
                              Vitals
                            </Link>
                          </div>
                        </div>
                        <div className="row mt-3 mb-3">
                          <div className="col-md-2">
                            <Link
                              to={`/PatientConsultation/${details._id}`}
                              className="btn btn-success"
                            >
                              Consultation History
                            </Link>
                          </div>

                          <div className="col-md-2">
                            <Link
                              to={`/UserMedicalHistory/${details._id}`}
                              className="btn btn-success"
                            >
                              Medical History
                            </Link>
                          </div>
                          <div className="col-md-2">
                            <Link
                              to={`/UserWeightLog/${details._id}`}
                              className="btn btn-success"
                            >
                              Weight Log
                            </Link>
                          </div>

                          <div className="col-md-2">
                            <Link
                              to={`/UserLabReports/${details._id}`}
                              className="btn btn-success"
                            >
                              Lab reports
                            </Link>
                          </div>
                          <div className="col-md-2">
                            <Link
                              to={`/UserHealthLocker/${details._id}`}
                              className="btn btn-success"
                            >
                              Health Locker
                            </Link>
                          </div>
                          <div className="col-md-2">
                            <Link
                              to={`/UserDietPlans/${details._id}`}
                              className="btn btn-success"
                            >
                              Diet Plan
                            </Link>
                          </div>
                        </div>
                        <div className="row pb-5">
                          <div className="col-md-2">
                            <Link
                              to={`/UserWorkoutplans/${details._id}`}
                              className="btn btn-success"
                            >
                              Workout Plan
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <Footer />
    </div>
  );
};

export default ClientDetails;
