import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class CommunityService{
    static getCommunitys() {
        let api = '';
        let data = JSON.parse(localStorage.getItem("user"));
         let userId=data.data.id;
        api =  API_PATHS.getcommunity + '?userId=' + userId + '&isApproved=' + true;
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

   
    static getAllCommunity() {
        let api = '';
        let data = JSON.parse(localStorage.getItem("user"));
         let userId=data.data.id;
        api =  API_PATHS.getcommunity + '?userId=' + userId;
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }
    static putCommunityPost() {
        let api = '';
        let postId = localStorage.getItem("postsId");
        let item = JSON.parse(localStorage.getItem("item"));
        api = API_PATHS.approve + '?id=' + postId;
        return axios
            .put(api,item)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static getPostDetails() {
        let api = '';
        let data = JSON.parse(localStorage.getItem("user"));
         let userId=data.data.id;
         let postId = localStorage.getItem("postsId");
        api =  API_PATHS.getcommunity + '?userId=' + userId + '&id=' + postId;
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

  
    static deletePost() {
        let api = '';
        let id = localStorage.getItem("id");
          api = API_PATHS.deletepost + '?id=' + id ;
        localStorage.removeItem("id");
        return axios
            .delete(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static postcommunity(item) {
        return axios
            .post(API_PATHS.postcommunity,
                item
            )
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }


    static postlike(item) {
        return axios
            .post(API_PATHS.addlikes,
                item
            )
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static getFlaggedPost() {
        let api = '';
        api =  API_PATHS.getFlaggedPost ;
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }


    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default CommunityService;