import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "../../component/Navbarside";
import { getFlaggedPost } from "../../store/slices/Community";
import Footer from "../footer";
import Moment from "moment";
import { Link, useNavigate } from "react-router-dom";

const FlaggedCommunityPost = () => {
  const dispatch = useDispatch();
  const [communitys, setCommunitys] = useState([]);

  React.useEffect(() => {
    dispatch(getFlaggedPost())
      .unwrap()
      .then((data) => {
        setCommunitys(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-wrap bar-success">
                          <h4 className="card-title">Flagged Community Post</h4>
                        </div>
                      </div>
                      <div className="card-body collapse show">
                        <div className="card-block card-dashboard table-responsive">
                          <table className="table table-striped table-bordered zero-configuration">
                            <thead>
                              <tr>
                                <th style={{ width: "30%" }}>Post</th>
                                <th>Posted On</th>
                                <th>Flagged By</th>
                              </tr>
                            </thead>

                            <tbody>
                              {communitys.map((communityVal, index) => (
                                <tr>
                                  <td>
                                    {" "}
                                    <Link
                                      to={`/CommunityDetails/${communityVal.postId._id
                                          ? communityVal.postId._id
                                          : null
                                        }`}
                                    >
                                      <img
                                        src={communityVal.postId.mediaLink}
                                        alt=""
                                        style={{
                                          width: "120px",
                                          height: "80px",
                                        }}
                                      />
                                      <br />
                                      {communityVal.postId.description}
                                    </Link>
                                  </td>
                                  <td>
                                    {Moment(communityVal.postId.date).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>

                                  <td>
                                    {" "}
                                    {communityVal.flaggedBy.firstName}{" "}
                                    {communityVal.flaggedBy.lastName}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
};

export default FlaggedCommunityPost;
