import React, { useState } from "react";

import Navbarside from "../Navbarside";
import Footer from "../footer";
import { getCommunitydetail, addlike } from "../../store/slices/Community";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { get } from 'axios'
import { API_BASE_URL } from "../../utils/constants/api.constants";

const CommunityDetails = () => {
  const dispatch = useDispatch();
  const [postData, setPost] = useState({})

  const [commentsCount, setCommentsCount] = useState("");
  const [date, setDate] = useState("");
  const [likesCount, setLikesCount] = useState("");
  const [firstName, setFirstName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [lastName, setLastName] = useState("");

  const [mediaLink, setmediaLink] = useState("");
  const [description, setDescription] = useState("");
  const { id } = useParams();

  localStorage.setItem("postsId", id);

  React.useEffect(() => {

    get(API_BASE_URL + 'get/communityPost?id=' + id).then(res => {
      if (res.data.status == 1) setPost(res.data.data[0])
      else throw new Error(res.data.error)
    }).catch(ex => alert(ex.message))

  }, [dispatch]);

  async function like(id) {
    let action = "like";
    let likedBy = "62b19dc0d18a138255d39bcd";
    let postId = "62fb4080c7917f0c6a391268";

    let item = { action, likedBy, postId };
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("id", id);

    dispatch(addlike())
      .unwrap()
      .then(() => {
        // alert("Uploaded succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
    console.log(item);
    console.log(id);
  }

  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row pt-5 pb-5">
                  <div className="col-lg-2 col-md-1" />

                  <div className="col-lg-8 col-md-10 col-sm-12">
                    <div className="col-lg-12 col-sm-12 col-md-12">
                      <div className="post-card mt-2 mb-2">
                        <p className="post-name">
                          <img src={postData?.userId?.imageUrl} className="post-icon" alt="" />
                          {postData?.userId?.firstName} {postData?.userId?.lastName}
                        </p>
                        <p className="post-date-c">{date}</p>
                        <div className="description-res">
                          <p className="post-Description">
                            {postData.description}
                          </p>
                        </div>
                        {postData.mediaLink != null && (
                          <img
                            src={postData.mediaLink}
                            className="post-image-media"
                            style={{ "height": "200", width: "300" }}
                            alt=""
                          />
                        )}

                        {/*
        <div className='d-flex justify-content-end pt-4'>
        // {action === "" && (
          <button
            className=""
            style={{
              height: "25px",
              padding: "0 10px",
            }}
            // onClick={() =>
            //   like(categoryVal._id)
            // }
          >
            <SlLike/>
          </button>
        )}
        {action === "like" && (
          <Link
            className=""
            style={{
              height: "25px",
              padding: "0 10px",
            }}
            onClick={() =>
              disablelike(categoryVal._id)
            }
          >
           <AiFillLike/>
          </Link>
        )}
          </div>*/}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-1" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
};

export default CommunityDetails;
