import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import womenSpecialSetting from "../../services/womenSpecialSetting.service";
import AuthService from "../../services/auth.service";

export const getWomenSpecialSetting = createAsyncThunk(
  "womenSpecialSetting/get",
  async (thunkAPI) => {
    try {
      const data = await womenSpecialSetting.getWomenSpecialSetting();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const postWomenSpecialSetting = createAsyncThunk(
  "womenSpecialSetting/post",
  async (item, thunkAPI) => {
    try {
      const data = await womenSpecialSetting.postWomenSpecialSetting(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const editWomenSpecialSetting = createAsyncThunk(
  "womenSpecialSetting/put",
  async (item, thunkAPI) => {
    try {
      const data = await womenSpecialSetting.editWomenSpecialSetting(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const deleteWomenSpecialSetting = createAsyncThunk(
  "womenSpecialSetting/delete",
  async (thunkAPI) => {
    try {
      const data = await womenSpecialSetting.deleteWomenSpecialSetting();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

const initialState = {
  loading: false,
  error: "",
  user: AuthService.getUserDetails() || null,
  isLoggedIn: false,
};

const womenSpecialSettingSlice = createSlice({
  name: "womenSpecialSetting",
  initialState,
  extraReducers: {
    [getWomenSpecialSetting.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [getWomenSpecialSetting.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [getWomenSpecialSetting.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },

    [postWomenSpecialSetting.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [postWomenSpecialSetting.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [postWomenSpecialSetting.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },

    [editWomenSpecialSetting.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [editWomenSpecialSetting.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [editWomenSpecialSetting.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },

    [deleteWomenSpecialSetting.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [deleteWomenSpecialSetting.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [deleteWomenSpecialSetting.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});

const { reducer } = womenSpecialSettingSlice;
export default reducer;
