import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "./Navbarside";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "./footer";
import { posthcexperts } from "../store/slices/hcexpert";
import { HcConsultationDetails } from "./../store/slices/HcConsultation";
import specialistService from "../services/specialist.service";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getHcExperts } from "../store/slices/hcexpert";
const AssignHealthCareExpert = () => {
  const [consutltationDetails, setconsultationDetails] = useState("");
  const [amount, setAmount] = useState("");
  const [discount, setDiscount] = useState("");
  const [meetingLink, setMeetingLink] = useState("");
  const [gstAmount, setGstAmount] = useState("");
  const [alertamount, setAlertAmount] = useState("");
  const [alertgstAmount, setAlertGstAmount] = useState("");
  const [Experts, setExperts] = useState([]);
  const [expertId, setexpertId] = useState("");
  const [userId, setUserId] = useState("");
  const [expert, setExpert] = useState("");
  const [alertdiscount, setAlertDiscount] = useState("");
  const [alertmeetingLink, setAlertMeetingLink] = useState("");
  const [expertises, setExpertises] = useState("");
  const navigate = useNavigate("");
  const dispatch = useDispatch();

  const { id } = useParams();
  localStorage.setItem("consultationid", id);

  React.useEffect(() => {
    dispatch(HcConsultationDetails())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id == id) {
          setconsultationDetails(data.user.data[0]);
          setUserId(data.user.data[0].userId[0]._id);
          setExpertises(data.user.data[0].expertiseId[0].name);
        }
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);
  const exp = expertises;
  localStorage.setItem("expertise", exp);

  const doctors = createAsyncThunk(
    "specialist/get/specialist",
    async (thunkAPI) => {
      try {
        const data = await specialistService.getspecialist();
        return { user: data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  React.useEffect(() => {
    dispatch(getHcExperts())
      .unwrap()
      .then((data) => {
        setExperts(data.user.data);
        setexpertId(data.user.data[0]._id);
        setExpert(data.user.data[0].firstName);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  const save = (e) => {

  };

  const cancel = () => {
    navigate(-1);
  };

  async function upload(e) {
    e.preventDefault();
    let expertId = "62ab1dcbf6a310565bf58247";
    let consultationId = id;
    let grossAmount = amount;
    let netAmount = amount;
    let currency = "INR";
    let item = {
      userId,
      expertId,
      consultationId,
      amount,
      grossAmount,
      gstAmount,
      currency,
      netAmount,
      discount,
      meetingLink,
    };

    dispatch(posthcexperts(item))
      .unwrap()
      .then(() => {
        alert("Expert assigned succesfully");
        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }
  const handleExpertChange = (e) => {
    var value = Experts.filter(function (doctors) {
      return doctors.firstName == e.target.value;
    });
    setexpertId(value[0]._id);
    setExpert(value[0].firstName);
  };
  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">
                          Assign Health Care Expert {exp}
                        </h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <form onSubmit={upload}>
                        <div className="row">
                          <div className="col-md-6 col-sm-12 pt-2">
                            <label>Consultation Charges</label>
                            <input
                              type="number"
                              placeholder="Consultation Charges"
                              value={amount}
                              onChange={(e) => setAmount(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertamount}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <label>Discount</label>
                            <input
                              type="number"
                              placeholder="Discount"
                              value={discount}
                              onChange={(e) => setDiscount(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertdiscount}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <label>GST</label>
                            <input
                              type="number"
                              placeholder="GST %"
                              value={gstAmount}
                              onChange={(e) => setGstAmount(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertgstAmount}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <label>Expert</label>
                            <select
                              className="form-control"
                              onChange={handleExpertChange}
                            >
                              <option value="">Select Expert</option>
                              {Experts.map((option) => (
                                <option value={option.expertId}>
                                  {option.firstName}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-6 col-sm-12 pt-2">
                            <input
                              type="text"
                              placeholder="Enter the meeting link"
                              value={consutltationDetails._id}
                              onChange={(e) => setMeetingLink(e.target.value)}
                              className="form-control"
                              style={{ display: "none" }}
                            />
                            <p className="alert-message">{alertmeetingLink}</p>
                          </div>
                        </div>

                        <div className="d-flex justify-content-center pt-3">
                          <button
                            type="submit"
                            className="btn btn-success"
                            onClick={save}
                          >
                            Submit
                          </button>
                          &nbsp; &nbsp; &nbsp;
                          <button
                            type="reset"
                            className="btn btn-warning"
                            onClick={cancel}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default AssignHealthCareExpert;