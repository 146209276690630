import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class LivewellService {
    static getCategory() {
        let api = '';
        let id = localStorage.getItem("mastercategoryId");
          api = API_PATHS.getCategory + '?parentCategoryId=' + id;
        
        localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static getCategoryDetails() {
        let api = '';
        let id = localStorage.getItem("pid");
          api = API_PATHS.getCategory + '?id=' + id ;
        localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static getMasterCategories() {
        return axios
            .get(API_PATHS.getMasterCategory)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static uploadCategory(item) {
        return axios
            .post(API_PATHS.uploadCategory,
                item
            )
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static postcategories(item) {
        return axios
            .post(API_PATHS.postmastercategory,
                item
            )
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static editCategory(item) {
        let api = '';
        let id = localStorage.getItem("id");
        api = API_PATHS.editCategorys + '?id=' + id;
        localStorage.removeItem("id");
        return axios
          .put(api,item)
          .then((response) => {
            if (response.data) {
            
            }
            return response.data;
          });
      }

      static editContent(item) {
        let api = '';
        let id = localStorage.getItem("id");
        api = API_PATHS.putcontent + '?id=' + id;
        localStorage.removeItem("id");
        return axios
          .put(api,item)
          .then((response) => {
            if (response.data) {
            
            }
            return response.data;
          });
      }


      static getContents() {
        let api = '';
        let id = localStorage.getItem("categoryId");
          api = API_PATHS.getcontent + '?categoryId=' + id ;
        
        localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    
    static getContentDetails() {
        let api = '';
        let id = localStorage.getItem("contentId");
          api = API_PATHS.getcontent + '?_id=' + id ;
        
        localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static postcontents(item) {
        return axios
            .post(API_PATHS.postcontent,
                item
            )
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default LivewellService;