import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "../Navbarside";
import { Link } from "react-router-dom";
import Footer from "../footer";
import { getChallenge, deleteChallenge } from "../../store/slices/challenges";
import { confirmAlert } from "react-confirm-alert";
import $ from "jquery";
import Loader from "../Loader";

function Challenges() {
  const dispatch = useDispatch();
  const [isLoading, setIsloading] = useState(false);
  const [table, setTable] = useState([]);

  React.useEffect(() => {
    setIsloading(true);
    onPageLoading();

    // $("#example").on("click", ".delete", function (e) {
    //   e.stopPropagation();
    //   deleteData($(this).data("id"));
    // });
  }, [dispatch]);

  // initial call
  const onPageLoading = () => {
    dispatch(getChallenge())
      .unwrap()
      .then((data) => {
        console.log("data");
        setIsloading(false);
        setTable(data?.user?.data);
        setIsloading(false);
//  For Old table code
        // $("#example").DataTable({
        //   data: data.user.data.map((e, index) => {
        //     return { index, ...e };
        //   }),
        //   columns: [
        //     {
        //       title: "#",
        //       render: (data, type, row) => {
        //         return ++row.index;
        //       },
        //     },
        //     {
        //       data: "name",
        //       title: "Name",
        //     },
        //     {
        //       data: "description",
        //       title: "Description",
        //     },
        //     {
        //       title: "Status",
        //       render: (data, type, row) => {
        //         if (row.isActive === true) {
        //           return `<button class="btn btn-success" style="height: 25px; padding: 0 10px">Active</button>`;
        //         } else {
        //           return `<button class="btn btn-danger" style="height: 25px; padding: 0 10px">Inactive</button>`;
        //         }
        //       },
        //     },
        // {
        //   title: "Action",
        //   render: (data, type, row) => {
        //     return `<a href="/EditChallenge/${row._id ? row._id : null}"
        //       class="btn btn-primary"
        //       style="height: 25px; padding: 0 10px"
        //     >
        //     Edit
        //     </a>
        //     <button
        //     data-id="${row._id}"
        //     class="btn btn-warning delete"
        //     style="height: 25px; padding: 0 10px"
        //   >
        //     Delete
        //   </button>`;
        //   },
        // },
        //   ],
        //   destroy: true,
        // });
      })
      .catch(({ message }) => {
        setIsloading(false);
        alert(message);
      });
  };

  const Delete = async function deletePlaylist(e, id) {
    // e.preventDefault()
    localStorage.setItem("id", id);
    dispatch(deleteChallenge())
      .unwrap()
      .then(() => {
        setIsloading(true);
        onPageLoading();
      })
      .catch(({ message }) => {
        alert(message);
      });

    console.log(id);
  };

  //  onDeleteHandler
  const onDelete = (e, id) => {
    e.preventDefault();
    deleteData(id);
  };
  const deleteData = (id) => {
    confirmAlert({
      title: "Want to delete?",
      message: "Are you sure you want to delete this post?",
      buttons: [
        {
          label: "Yes",
          onClick: (e) => Delete(e, id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      {isLoading && <Loader />}

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Challenges</h4>
                        &nbsp; &nbsp;
                        <Link
                          to="/AddChallenge"
                          className="btn btn-primary"
                          style={{ marginBottom: "0%" }}
                        >
                          <i className="fa fa-plus"></i> Add
                        </Link>{" "}
                      </div>
                    </div>

                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        {/* <table id="example" className="display"></table> */}
                        {table.length > 0 && (
                          <table className="custom-table">
                            <thead className="table-header">
                              <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {table?.map((data, index) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{data?.name}</td>
                                  <td>{data?.description}</td>
                                  <td>
                                    {data?.isActive ? (
                                      <button
                                        class="btn btn-success"
                                        style={{
                                          height: "25px",
                                          padding: "0 10px",
                                        }}
                                      >
                                        Active
                                      </button>
                                    ) : (
                                      <button
                                        class="btn btn-danger"
                                        style={{
                                          height: "25px",
                                          padding: "0 10px",
                                        }}
                                      >
                                        Inactive
                                      </button>
                                    )}
                                  </td>
                                  <td>
                                    <Link
                                      to={`/EditChallenge/${
                                        data._id && data._id
                                      }`}
                                      class="btn btn-primary"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Edit
                                    </Link>

                                    <button
                                      data-id="${row._id}"
                                      class="btn btn-warning delete"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                      onClick={(e) => {
                                        onDelete(e, data?._id);
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td
                                  colSpan="5"
                                  className="table-border-bottom"
                                ></td>
                              </tr>
                              <tr></tr>
                            </tfoot>
                          </table>
                        )}
                        <span className="total-elements">
                          Showing 1 to {table.length} of {table.length} entries
                        </span>

                        {/* <div> <b onClick={()=>{console.log("ohk")}}>Prev</b> <input value={1} onChange={(e) => { console.log("tt"); }} type="number" /> <b onClick={()=>{console.log("ohk")}}>Next</b> </div> */}
                        {/* <h1>Showing 1 to 5 of 5 entries</h1> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
}

export default Challenges;
