import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "../component/Navbarside";
import Moment from "moment";
import Footer from "./footer";
import { ExportToExcel } from "./ExportToExcel";
import { API_PATHS } from "../utils/constants/api.constants";
import axios from "axios";
import $ from "jquery";

const PhysiotherapyEnquiry = () => {
  const [data, setData] = useState([]);
  const fileName = "PhysiotherapyEnquiry";

  React.useEffect(() => {
    const fetchData = () => {
      axios.get(API_PATHS.getConsultation).then((postData) => {
        const customHeadings = postData.data.data.map((item) => ({
          name: `${item.userId[0]?.firstName} ${item.userId[0]?.lastName}`,
          startDate: item.startDate,
          startTime: item.startTime,
        }));

        setData(customHeadings);
        $("#example").DataTable({
          columnDefs: [{
            "defaultContent": "-",
            "targets": "_all"
          }],
          data: postData.data.data.map((e, index) => {
            return { index, ...e };
          }),
          columns: [
            {
              title: "#",
              render: (data, type, row) => {
                return ++row.index;
              },
            },
            {
              title: "User Name",
              render: (data, type, row) => {
                return `${row.userId[0]?.firstName} ${row.userId[0]?.lastName}`;
              },
            },
            {
              title: "Date of Enquiry",
              render: (data, type, row) => {
                return `${Moment(row.startDate).format("DD-MM-YYYY")} ${row.startTime
                  }`;
              },
            },
            {
              title: "Action",
              render: (data, type, row) => {
                if (row.status === "initiated") {
                  return `<a
                    href="/ProcessEnquiry/:id'${row._id ? row._id : null
                    }"
                    class="btn btn-warning"
                    style="height: 25px; padding: 0 10px"
                  >
                    Assign Expert
                  </a>`;
                }

                if (row.status === "expertAssigned") {
                  return `<button
                    class="btn btn-success"
                    style="height: 25px; padding: 0 10px"
                  >
                    Expert Assigned
                  </button>`;
                }
              },
            },
          ],
          destroy: true,
        });
      });
    };
    fetchData();
  }, []);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Physiotherapy Enquiry</h4>{" "}
                        &nbsp; &nbsp; &nbsp;
                        <ExportToExcel apiData={data} fileName={fileName} />
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table id="example" className="display"></table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PhysiotherapyEnquiry;