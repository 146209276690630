import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Navbarside from "./Navbarside";
import { useNavigate } from "react-router-dom";
import Footer from "./footer";
import { addfitnesscenter } from "../store/slices/fitness";
import { API_PATHS } from "../utils/constants/api.constants";
import { getfitnessServices } from "../store/slices/fitness";
import { getAmenities } from "../store/slices/fitness";
const AddFitnessCenter = () => {
  const navigate = useNavigate("");
  const [category, setCategory] = useState([]);
  const [amenity, setAmenity] = useState([]);
  const [fcName, setFcName] = useState("");
  const [fcEmail, setFcEmail] = useState("");
  const [fcMobileNo, setFcMobileNo] = useState("");
  const [agreement, setAgreement] = useState("");
  const [logo, setLogo] = useState("");
  const [about, setAbout] = useState("");
  const [mediaLink, setmediaLink] = useState([]);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [doorNoAndStreetName, setDoorNoAndStreetName] = useState("");

  const [alertfcName, setAlertFcName] = useState("");
  const [alertfcEmail, setAlertFcEmail] = useState("");
  const [alertfcMobileNo, setAlertFcMobileNo] = useState("");
  const [alertagreement, setAlertAgreement] = useState("");
  const [alertlogo, setAlertLogo] = useState("");
  const [alertabout, setAlertAbout] = useState("");

  const [alertcity, setAlertCity] = useState("");
  const [alertstate, setAlertState] = useState("");
  const [alertpincode, setAlertPincode] = useState("");
  const [alertdoorNoAndStreetName, setAlertDoorNoAndStreetName] = useState("");

  const [location] = useState({
    type: "Point",
    coordinates: [12.327608, 76.611868],
  });
  const [checked, setChecked] = useState([]);
  const [tagchecked, setTagChecked] = useState([]);
  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState("");
  const dispatch = useDispatch();

  const save = (e) => {
    const regname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (regname.test(fcName)) {
      setAlertFcName("");
    } else if (!regname.test(fcName) && fcName === "") {
      setAlertFcName("Please enter the fitness center name");
      e.preventDefault();
    } else {
      setAlertFcName("Please enter a valid name");
      e.preventDefault();
    }

    const cityregex = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (cityregex.test(city)) {
      setAlertCity("");
    } else if (!cityregex.test(city) && city === "") {
      setAlertCity("Please enter the city");
      e.preventDefault();
    } else {
      setAlertCity("Please enter a valid city name");
      e.preventDefault();
    }

    const stateregex = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (stateregex.test(state)) {
      setAlertState("");
    } else if (!stateregex.test(state) && state === "") {
      setAlertState("Please enter the state");
      e.preventDefault();
    } else {
      setAlertState("Please enter a valid state name");
      e.preventDefault();
    }

    const emailrgex =
      /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (emailrgex.test(fcEmail)) {
      setAlertFcEmail("");
    } else if (!emailrgex.test(fcEmail) && fcEmail === "") {
      setAlertFcEmail("Please enter the fitness center email");
      e.preventDefault();
    } else {
      setAlertFcEmail("Please enter a valid email");
    }

    const mobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (mobile.test(fcMobileNo)) {
      setAlertFcMobileNo("");
    } else if (!mobile.test(fcMobileNo) && fcMobileNo === "") {
      setAlertFcMobileNo("Please enter the mobile number");
      e.preventDefault();
    } else {
      setAlertFcMobileNo("Please enter a valid mobile number");
    }

    const pinregex = /^[0-9][0-9]?$|^100$/;
    if (pinregex.test(pincode)) {
      setAlertPincode("");
    } else if (!pinregex.test(pincode) && pincode === "") {
      setAlertPincode("Please enter the pincode");
      e.preventDefault();
    } else {
      setAlertPincode("");
    }

    const regdescription = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (regdescription.test(about)) {
      setAlertAbout("");
    } else if (!regdescription.test(about) && about === "") {
      setAlertAbout("Please enter about the fitness center");
      e.preventDefault();
    } else {
      setAlertAbout("");
    }

    const regdoornumb = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (regdoornumb.test(doorNoAndStreetName)) {
      setAlertDoorNoAndStreetName("");
    } else if (
      !regdoornumb.test(doorNoAndStreetName) &&
      doorNoAndStreetName === ""
    ) {
      setAlertDoorNoAndStreetName("Please enter the address");
      e.preventDefault();
    } else {
      setAlertDoorNoAndStreetName("");
    }

    const regimage = /(pdf|txt|docx|doc|gif|jpe?g|tiff?|png|webp|bmp)$/i;
    if (regimage.test(agreement)) {
      setAlertAgreement("");
    } else if (!regimage.test(agreement) && agreement === "") {
      setAlertAgreement("Please upload the agreement");
      e.preventDefault();
    } else {
      setAlertAgreement("Invalid file");
      e.preventDefault();
    }

    const regcheque = /(gif|jpe?g|tiff?|png|webp|bmp)$/i;
    if (regcheque.test(logo)) {
      setAlertLogo("");
    } else if (!regcheque.test(logo) && logo === "") {
      setAlertLogo("Please enter image link");
      e.preventDefault();
    } else {
      setAlertLogo("invalid image");
      e.preventDefault();
    }
  };

  const cancel = () => {
    setFcName("");
    setFcEmail("");
    setFcMobileNo("");
    setAgreement("");
    setLogo("");
    setAbout("");
    setCity("");
    setState("");
    setPincode("");
    setDoorNoAndStreetName("");
    setAlertFcName("");
    setAlertFcEmail("");
    setAlertFcMobileNo("");
    setAlertAgreement("");
    setAlertLogo("");
    setAlertAbout("");
    setAlertCity("");
    setAlertState("");
    setAlertPincode("");
    setAlertDoorNoAndStreetName("");
    setMessage("");
    setMessage2("");
  };
  let fitnessServices = checked;
  let amenities = tagchecked;
  async function addData(e) {
    e.preventDefault();
    let fcAdminId = JSON.parse(localStorage.getItem("user")).data.id;

    let fcAddress = {
      city,
      state,
      pincode,
      doorNoAndStreetName,
    };

    let item = {
      fcAdminId,
      fcName,
      fcEmail,
      fcMobileNo,
      agreement,
      logo,
      about,
      fcAddress,
      location,
      fitnessServices,
      amenities,
      mediaLink,
    };
    dispatch(addfitnesscenter(item))
      .unwrap()
      .then(() => {
        alert("Added succesfully");
        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  const handleTagCheck = (event) => {
    var updatedLists = [...tagchecked];
    if (event.target.tagchecked) {
      updatedLists = [...tagchecked, event.target.value];
    } else {
      updatedLists.splice(tagchecked.indexOf(event.target.value), 1);
    }
    setTagChecked(updatedLists);
  };

  React.useEffect(() => {
    dispatch(getfitnessServices())
      .unwrap()
      .then((data) => {
        setCategory(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getAmenities())
      .unwrap()
      .then((data) => {
        setAmenity(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  async function aggrementpdf(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setAgreement(data.data.location);
    setMessage(data.message);
  }

  async function imageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setLogo(data.data.location);
    setMessage2(data.message);
  }

  async function fitnesscenterimageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    const files = [];
    files.push(...e.target.files);
    files.forEach((file) => formdata.append("file", file));
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.multiUploadImage, requestOptions);
    let data = await response.json();
    let imgurl = [];
    for (let i = 0; i < data.data.length; i++) {
      imgurl.push(data.data[i]);
    }
    setmediaLink(imgurl);
    setMessage(data.message);
  }

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">
                          Add Fitness Center
                          {/* <Link
                              to="/UploadPackage"
                              className="btn btn-primary"
                            >
                              <i className="fa fa-plus"></i> Add
                            </Link> */}
                        </h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <form onSubmit={addData}>
                        <div className="row">
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Fitness Center Name
                            </p>
                            <input
                              type="text"
                              placeholder="Enter the fitness center name"
                              value={fcName}
                              onChange={(e) => setFcName(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertfcName}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Email
                            </p>
                            <input
                              type="text"
                              placeholder="Enter the fitness center email"
                              value={fcEmail}
                              onChange={(e) => setFcEmail(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertfcEmail}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Mobile No.
                            </p>
                            <input
                              type="number"
                              placeholder="Enter the contact number"
                              value={fcMobileNo}
                              onChange={(e) => setFcMobileNo(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertfcMobileNo}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              About{" "}
                            </p>
                            <input
                              type="text"
                              placeholder="Enter about your fitness center"
                              value={about}
                              onChange={(e) => setAbout(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertabout}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              City
                            </p>
                            <input
                              type="text"
                              placeholder="Enter the city "
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertcity}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              State
                            </p>
                            <input
                              type="text"
                              placeholder="Enter the state"
                              value={state}
                              onChange={(e) => setState(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertstate}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Pincode
                            </p>
                            <input
                              type="number"
                              placeholder="Enter the pincode"
                              value={pincode}
                              onChange={(e) => setPincode(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertpincode}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Address
                            </p>
                            <input
                              type="text"
                              placeholder="Enter address"
                              value={doorNoAndStreetName}
                              onChange={(e) =>
                                setDoorNoAndStreetName(e.target.value)
                              }
                              className="form-control"
                            />
                            <p className="alert-message">
                              {alertdoorNoAndStreetName}
                            </p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Logo
                            </p>
                            <input
                              type="file"
                              className="form-control-file"
                              Placeholder="Price Tagline"
                              required
                              onChange={(e) => imageUpload(e)}
                            />
                            <p className="alert-message">{alertlogo}</p>
                            <p style={{ color: "green", fontWeight: "500" }}>
                              {message2}
                            </p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Agreement
                            </p>
                            <input
                              type="file"
                              className="form-control-file"
                              Placeholder="Price Tagline"
                              required
                              onChange={(e) => aggrementpdf(e)}
                            />
                            <p className="alert-message">{alertagreement}</p>
                            <p style={{ color: "green", fontWeight: "500" }}>
                              {message}
                            </p>
                          </div>
                          <div className="col-6">
                            <p className="col-black">
                              Fitness Center Images (Max 10 images and
                              resolution 1920X1080)
                            </p>
                            <input
                              type="File"
                              name="mediaLink"
                              multiple
                              onChange={(e) => fitnesscenterimageUpload(e)}
                              id="inputmediaLink"
                              className="form-control-file"
                              required
                            />
                            <br></br>
                            <p>{message}</p>
                          </div>

                          <div className="col-12 mt-3">
                            <p className="col-black">Services Provided</p>
                          </div>

                          {category.map((LabVal, index) => (
                            <div className=" col-lg-6 col-md-6 col-sm-12">
                              <input
                                type="checkbox"
                                name="options"
                                value={LabVal._id}
                                onChange={handleCheck}
                              />
                              &nbsp;
                              {LabVal.name}
                            </div>
                          ))}

                          <div className="col-12">
                            <p className="col-black">Amenities</p>
                          </div>

                          {amenity.map((AmenityVal, index) => (
                            <div className=" col-lg-6 col-md-6 col-sm-12">
                              <input
                                type="checkbox"
                                name="options"
                                value={AmenityVal._id}
                                onChange={handleTagCheck}
                              />
                              &nbsp;
                              {AmenityVal.name}
                            </div>
                          ))}
                        </div>
                        <div className="form-actions center">
                          <button
                            type="submit"
                            className="btn btn-success"
                            onClick={save}
                            disabled={!message2}
                          >
                            Submit
                          </button>
                          &nbsp; &nbsp; &nbsp;
                          <button
                            type="reset"
                            className="btn btn-warning mt-2"
                            onClick={cancel}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default AddFitnessCenter;
