import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Navbarside from "./Navbarside";
import { useDispatch, useSelector } from "react-redux";
import Footer from "./footer";
import { getLabDetails } from "../store/slices/lab";

const LabDetails = () => {
  
  const dispatch = useDispatch();
  const { id } = useParams();
  localStorage.setItem("labId", id);
  
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileno, setMobileno] = useState("");
  const [address, setAddress] = useState("");
  const [homeServicePercent, setHomeServicePercent] = useState("");
  const [serviceType, setserviceType] = useState("");
  const [hplCommission, sethplCommission] = useState("");
  const [platformCostPercent, setPlatformCostPercent] = useState("");
  const [gstPercent, setGstPercent] = useState("");
  const [labtestname, setLabtestname] = useState("");

  React.useEffect(() => {
    dispatch(getLabDetails())

      .unwrap()
      .then((data) => {
        if (data.user.data[0]) {
        setName(data.user.data[0].name);
        setEmail(data.user.data[0].labEmail);
        setMobileno(data.user.data[0].labMobileNo);
        setAddress(data.user.data[0].address);
        sethplCommission(data.user.data[0].hplCommission);
        setHomeServicePercent(data.user.data[0].homeServicePercent);
        setserviceType(data.user.data[0].serviceType[0]);
        setGstPercent(data.user.data[0].gstPercent);
        setPlatformCostPercent(data.user.data[0].platformCostPercent);
        setLabtestname(data.user.data[0].labTestCategoryId[0].name);
        }
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Lab Details</h4>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive ">

<div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="d-flex justify-content-between text">
                        <p>Lab Name:</p> 
                           <span>{name}</span>
                        </div>
                        </div>
                           
                        
                      <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="d-flex justify-content-between text">
                      <p>Lab Email:</p>
                      <span>{email}</span>
                      </div>
                      </div>
                          
                          <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="d-flex justify-content-between text">

                          <p>Contect Number:</p>
                          <span>{mobileno}</span>
                        </div>
                        </div>


                        <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="d-flex justify-content-between text">
                          <p>Service Type:</p>
                          <span>{serviceType}</span>
                        </div> 
                        </div> 

                        <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="d-flex justify-content-between text">

                          <p>Home Service Charges:</p>
                          <span>{homeServicePercent} % </span>
                          </div>
                          </div>

                         <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="d-flex justify-content-between text">

                          <p>HPL Commission:</p>
                          <span>{hplCommission} %</span>
                        </div> 
                        </div> 

                        <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="d-flex justify-content-between text">

                           <p> Platform Cost Percent:</p> 
                           <span> {platformCostPercent} %</span>
                           </div> 
                           </div>

                         <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="d-flex justify-content-between text">

                          <p>GST Percent:</p>
                          <span>{gstPercent} %</span>
                        </div>
                        </div>

                       <div className="col-lg-6 col-md-12 col-sm-12">
                       <div className="text">
                       <p style={{color:"gray"}}><b>Test Provided:</b></p>
                            <span>{labtestname}</span>
                       </div>
                          
                        </div>

                        
                        <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="d-flex justify-content-between text">

                          <p>Address:</p>
                          <span>{address}</span>
                        </div> 
                        </div>  

                        </div>


                      </div>
                    </div>
          </div>
          </div>
          </div>
          </div>
          </div>
          </div>
          </div>
      <Footer />
    </div>
  );
};

export default LabDetails;
