import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../footer";
import Navbarside from "../../component/Navbarside";
import { getquestions } from "../../store/slices/questionnaire";
import axios from "axios";
import { API_BASE_URL_HC, API_PATHS } from "../../utils/constants/api.constants";

function Questionnaire() {
  const [qna, setQna] = useState([]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getquestions())
      .unwrap()
      .then((data) => {
        setQna(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  const deleteData = (id) => {
    axios.delete(`${API_BASE_URL_HC}/delete/category?id=${id}`).then(res => {
      if (res.data?.status === 1) alert('deleted successfully')
    }).catch(err => alert(err.message))
  }

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <div className="d-flex align-items-center">
                          <h4
                            className="card-title"
                            id="basic-layout-form-center"
                          >
                            Questionnaire
                          </h4>
                          &nbsp; &nbsp; &nbsp;
                          <Link
                            to="/Questionnaire/AddQuestionnaire"
                            className="btn btn-success"
                          >
                            Add
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Order</th>
                              <th>Questions</th>
                              <th>Answer</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {qna.map((QuestionVal, index) => (
                              <tr key={index}>
                                <td>{QuestionVal.order}</td>
                                <td>{QuestionVal.question}</td>


                                {QuestionVal.options.map((ansVal, index) => (
                                  <p>
                                    Option points :{ansVal.points}
                                    Option Value:{ansVal.optionValue}
                                  </p>
                                ))}
                                <td>
                                  <button
                                    className="btn btn-danger"
                                    onClick={() => deleteData(QuestionVal._id)}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default Questionnaire;
