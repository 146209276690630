import React from "react";
import Navbarside from "../component/Navbarside";
import Footer from "./footer";
import { Link } from "react-router-dom";

const Corporate = () => {
  return (
    <div>
      <Navbarside />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                  <Link to="/Corporate/Company">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-10 pb-10">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-2 card-title mb-0">
                                Company
                              </h4>
                            </div>
                            <div className="media-right text-right">
                            <i class='far fa-building fa-edit-icon col-gray'></i>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                  <Link to="/Corporate/CorporateEnquiry">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-10 pb-10">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-2 card-title mb-0">
                              Corporate Enquiry
                              </h4>
                            </div>
                            <div className="media-right text-right">
                            <i class='fa fa-comments-o fa-edit-icon danger'></i>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Corporate;
