import axios from "axios";
import { API_BASE_URL, API_PATHS } from "../utils/constants/api.constants";

class hcexpertService {

  static posthcexpert(item) {
    return axios
      .post(API_PATHS.posthcexpert,
        item
      )
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }
  static getRoles = () => axios.get('https://api.healthonify.com/get/role').then(roles => roles.data)

  static getWmExperts() {
    let api = '';
    let id = '6229a968eb71920e5c85b0af';
    api = API_PATHS.getuser + '?topExpertise=' + id;

    localStorage.removeItem("id");
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static getExperts = async (expertiseName) => {
    let selectedExpertise = await axios.get(`https://api.healthonify.com/get/expertise?name=${expertiseName}`).then(res => res.data.data)
    let experts = await axios.get(`${API_PATHS.getuser}?topExpertise=${selectedExpertise[0]._id}`).then(res => res.data)
    return experts
  }

  static getHealthConditions = async ({ name, category }) => {
    return await axios.get(`${API_BASE_URL}/get/healthCondition?category=${name || category}`).then(res => res.data)
  }

  static getRole = (roleName) => axios.get(`https://api.healthonify.com/get/role?name=${roleName}`).then(res => res.data)
  static getEnquiryData = (ticketNumber) => axios.get(`https://api.healthonify.com/get/enquiry?ticketNumber=${ticketNumber}`).then(res => res.data)

  static getHcExperts() {
    let api = '';
    let id = '6343acb2f427d20b635ec853';
    api = API_PATHS.getuser;

    localStorage.removeItem("id");
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }
  static getFitnessExperts() {
    let api = '';
    let id = '63ef2333eef2ad2bdf410333';
    api = API_PATHS.getuser + '?topExpertise=' + id;

    localStorage.removeItem("id");
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }
  static getPhysioExperts() {
    let api = '';
    let id = '6229a980305897106867f787';
    api = API_PATHS.getuser + '?topExpertise=' + id;

    localStorage.removeItem("id");
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static getHcexpertdetail() {

    let api = '';
    let id = localStorage.getItem("id");
    api = API_PATHS.getuser + '?_id=' + id;
    return axios.get(api).then((response) => response.data);
  }

  static getTopExpertise = () => axios.get('https://api.healthonify.com/get/expertise?getTopExpertise=1').then(topExpertise => topExpertise.data)

  static getExpertise = (name) => axios.get(`http://api.healthonify.com/get/expertise?name=${name}`).then(res => res.data.data)

  static getAllExpertise = () => axios.get('https://api.healthonify.com/get/expertise').then(allExpertise => allExpertise.data)
  static getAllUser = () => axios.get('https://api.healthonify.com/get/user').then(allUser => allUser.data)


  static removeUserDetails() {
    localStorage.removeItem("user");
  }

  static getUserDetails() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default hcexpertService