import React from "react";
import Navbarside from "./Navbarside";
import { useDispatch } from "react-redux";
import {
  getfitnesscenterEnquiry,
  ApprovefitnessEnquiry,
} from "../store/slices/fitness";
import { useState } from "react";
import Footer from "./footer";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Table from "./common/Table";
import Pagination from "./common/Pagination";

const FitnessCenterEnquiry = () => {
  const [fitnessenquire, setFitnessenquire] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setpage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [column, setColumn] = useState([
    { isHtml: false, name: "Name" },
    { isHtml: false, name: "Email" },
    { isHtml: false, name: "Mobile No." },
    { isHtml: false, name: "Message" },
    { isHtml: true, name: "Fitness Center Name" },
    { isAction: true, name: "Status" },
  ]);
  const [row, setRow] = useState([]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    // dispatch(getfitnesscenterEnquiry())
    //   .unwrap()
    //   .then((data) => {
    //     setFitnessenquire(data.user.data);
    //   })
    //   .catch(({ message }) => {
    //     alert(message);
    //   });

    fetchFitnessEnquirey();
  }, [dispatch]);

  const fetchFitnessEnquirey = (page = 0) => {
    dispatch(getfitnesscenterEnquiry({ limit: 10, page: page }))
      .unwrap()
      .then((data) => {
        setIsLoading(false);
        setpage(page);
        setTotalCount(data.data?.totalCount);

        console.log("newImp", data);
        const customHeadings = data?.user?.data?.map((obj, key) => {
          let newObj = {
            'Name': obj?.name,
            'Email': obj?.email,
            "Mobile No.": obj?.contactNumber,
            'Message': obj?.message,
            "Fitness Center Name": obj?.fitnessCenterName,
          };

          if (obj?.status === "fitnessCenterRegistrationRequest") {
            newObj["Status"] = [
              <button
                className="btn btn-primary"
                style={{
                  height: "25px",
                  padding: "0 10px",
                }}
                onClick={() => enableApprove(obj?._id)}
              >
                Approve
              </button>,
            ];
          }
          if (obj?.status === "fitnessCenterRegistrationRequest") {
            newObj["Status"] = [
              <button
                className="btn btn-warning"
                style={{
                  height: "25px",
                  padding: "0 10px",
                }}
                onClick={() => rejectData(obj?._id)}
              >
                Reject
              </button>,
            ];
          }
          if (obj?.status === "rejected") {
            newObj["Status"] = [
              <button
                className="btn btn-warning"
                style={{
                  height: "25px",
                  padding: "0 10px",
                }}
              >
                Rejected
              </button>,
            ];
          }
          if (obj?.status === "approved") {
            newObj["Status"] = [
              <button
                className="btn btn-success"
                style={{
                  height: "25px",
                  padding: "0 10px",
                }}
              >
                Approved
              </button>,
            ];
          }

          return newObj;
        });
        setRow(customHeadings);
        setFitnessenquire(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  async function enableApprove(ids) {
    let status = "approved";
    let id = ids;
    let item = { id, status };
    dispatch(ApprovefitnessEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Approved succesfully");
        // window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
    console.log(item);
    console.log(id);
  }

  async function disableApprove(ids) {
    let status = "rejected";
    let id = ids;
    let item = { id, status };
    localStorage.setItem("item", JSON.stringify(item));
    dispatch(ApprovefitnessEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Request rejected succesfully");
        // window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
    console.log(item);
    console.log(id);
  }

  const rejectData = (id) => {
    confirmAlert({
      title: "Want to reject the request?",
      message: "Are you sure you want to reject this request?",
      buttons: [
        {
          label: "Yes",
          onClick: () => disableApprove(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  return (
    <div>
      <Navbarside />

      <div className="container-fluid response-cover pb-5">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Fitness Center Enquiry</h4>
                        &nbsp; &nbsp; &nbsp;
                        {/* <Link to="/AddLabvendors" className="btn btn-success col-white">Add</Link>*/}
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <Table data={row} columns={column} isFooter={true} />
                        <div className="PaginationContainer">
                          <span className="total-elements">
                            Showing {parseInt(page) * 10 + 1} to{" "}
                            {parseInt(page) * 10 + 10} of {totalCount} entries
                          </span>
                          <Pagination
                            currentPage={parseInt(page) + 1}
                            totalCount={totalCount}
                            itemsPerPage={10}
                            onPageChange={(page) => {
                              // fetchData(parseInt(page) - 1);
                            }}
                          />
                        </div>

                        {/* <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>

                              <th>Mobile No.</th>
                              <th>Message</th>

                              <th>Fitness Center Name</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {fitnessenquire.map((fitnessVal, index) => (
                              <tr key={index}>
                                <td>{fitnessVal.name}</td>
                                <td>{fitnessVal.email}</td>
                                <td>{fitnessVal.contactNumber}</td>
                                <td>{fitnessVal.message}</td>
                                <td>{fitnessVal.fitnessCenterName}</td>

                                <td>
                                  {fitnessVal.status ===
                                    "fitnessCenterRegistrationRequest" && (
                                    <button
                                      className="btn btn-primary"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                      onClick={() =>
                                        enableApprove(fitnessVal._id)
                                      }
                                    >
                                      Approve
                                    </button>
                                  )}
                                  &nbsp; &nbsp; &nbsp;
                                  {fitnessVal.status ===
                                    "fitnessCenterRegistrationRequest" && (
                                    <button
                                      className="btn btn-warning"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                      onClick={() => rejectData(fitnessVal._id)}
                                    >
                                      Reject
                                    </button>
                                  )}
                                  &nbsp; &nbsp; &nbsp;
                                  {fitnessVal.status === "rejected" && (
                                    <button
                                      className="btn btn-warning"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Rejected
                                    </button>
                                  )}
                                  {fitnessVal.status === "approved" && (
                                    <button
                                      className="btn btn-success"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Approved
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default FitnessCenterEnquiry;
