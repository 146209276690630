import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "../..//Navbarside";
import { Link, useParams } from "react-router-dom";
import Footer from "../../footer";
import { get } from 'axios';
import { API_BASE_URL_HC } from "../../../utils/constants/api.constants";

function Contents() {
  const [contents, setContents] = useState([]);
  const dispatch = useDispatch();
  const { id } = useParams();

  localStorage.setItem("categoryId", id);

  React.useEffect(() => {
    get(API_BASE_URL_HC + '/get/content').then(res => {
      if (res.data?.data?.length) {
        setContents(res.data.data)
      }
    }).catch(({ message }) => alert(message))
  }, [dispatch]);

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">
                          Contents
                          <Link
                            to="/UploadPackage"
                            className="btn btn-primary"
                          >
                            <i className="fa fa-plus"></i> Add
                          </Link>
                        </h4>
                        &nbsp; &nbsp;
                        <Link
                          to={`/LiveWell/Contents/AddContent/${id ? id : null}`}
                          className="btn btn-primary"
                        >
                          <i className="fa fa-plus"></i> Add
                        </Link>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Content Name</th>
                              <th>Description</th>
                              <th>Thumbnail</th>
                              <th>Video</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {contents?.map((contentVal, index) => (
                              <tr key={index}>
                                <td>{contentVal.title}</td>

                                <td>{contentVal.description}</td>

                                <td className="d-flex justify-content-center">
                                  <img
                                    src={contentVal.thumbnail}
                                    alt="content"
                                    style={{
                                      height: "70px",
                                      width: "70px",
                                      borderRadius: "10px",
                                    }}
                                  />
                                </td>

                                <td>
                                  <a
                                    href={contentVal.mediaLink}

                                  >
                                    View
                                  </a>
                                </td>

                                <td>
                                  <Link
                                    to={`/EditContent/${contentVal._id ? contentVal._id : null
                                      }`}
                                    className="btn bg-purple col-white "
                                    style={{ width: "80px" }}
                                  >
                                    Edit
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
}

export default Contents;
