import React from "react";
import Navbarside from "../component/Navbarside";
import Footer from "./footer";
import { Link } from "react-router-dom";

const FitnessLibrary = () => {
  return (
    <div>
      <Navbarside />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                  <Link to="/FitnessCenter">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-10 pb-10">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                Fitness Center
                              </h4>
                            </div>
                            <div className="media-right text-right">
                              <i className="icon-cup font-large-1 primary"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                  <Link to="/FitnessLibrary/FitnessCenterRequest">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-10 pb-10">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                Fitness Center Request
                              </h4>
                            </div>
                            <div className="media-right text-right">
                              <i className="icon-wallet font-large-1 warning"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                  <Link to="/FitnessCenterEnquiry">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-10 pb-10">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                Fitness Center Enquiry
                              </h4>
                            </div>
                            <div className="media-right text-right">
                              <i className="icon-basket-loaded font-large-1 success"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                {/* <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                  <div className="card bg-white">
                    <div className="card-body">
                      <div className="card-block pt-10 pb-10">
                        <div className="media">
                          <div className="media-body white text-left">
                            <h4 className="font-medium-5 card-title mb-0">
                              Head text here
                            </h4>
                          </div>
                          <div className="media-right text-right">
                            <i className="icon-basket-loaded font-large-1 success"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
  </div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default FitnessLibrary;
