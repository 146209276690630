import React from "react";
import Navbarside from "./Navbarside";
import { useDispatch } from "react-redux";
import { getDocConsultation } from "../store/slices/HcConsultation";
import { useState } from "react";
import Footer from "./footer";
import Moment from "moment";
import { MdVideoCameraFront } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const TodayAppointment = () => {
  const [consultation, setConsultation] = useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getDocConsultation())
      .unwrap()
      .then((data) => {
        setConsultation(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid response-cover pb-5">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Appointments</h4>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Client Name</th>
                              <th>Issue</th>
                              <th>Consultation Details</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {consultation.map((TodayAppointmentVal, index) => (
                              <tr key={index}>
                                <td>
                                  {TodayAppointmentVal.userId[0].firstName}
                                  &nbsp;
                                  {TodayAppointmentVal.userId[0].lastName}
                                </td>

                                <td>{TodayAppointmentVal.description}</td>
                                <td>
                                  Consultation Date:
                                  {Moment(TodayAppointmentVal.startDate).format(
                                    "DD-MM-YYYY"
                                  )}
                                  <br />
                                  Consultation Time:
                                  {TodayAppointmentVal.startTime}
                                </td>
                                <td>
                                  {TodayAppointmentVal.status ===
                                    "scheduled" && (
                                    <a
                                      href={`https://meet.jit.si/${
                                        TodayAppointmentVal._id
                                          ? TodayAppointmentVal._id
                                          : null
                                      }`}
                                      target="_blank"
                                      className="btn btn-success"
                                    >
                                      <MdVideoCameraFront className="btn-icon" />
                                      &nbsp; Join
                                    </a>
                                  )}
                                  &nbsp;
                                  {TodayAppointmentVal.status !=
                                    "paymentRequested" && (
                                    <Link
                                      to={`/AddPrescription/${
                                        TodayAppointmentVal._id
                                          ? TodayAppointmentVal._id
                                          : null
                                      }`}
                                      className="btn btn-primary mt-2"
                                    >
                                      <FaPlus /> Prescription
                                    </Link>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default TodayAppointment;
