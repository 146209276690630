import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "../Navbarside";
import { Link, useLocation } from "react-router-dom";
import Footer from "../footer";
import { getMasterCategory } from "../../store/slices/livewell";
import { ExportToExcel } from "../ExportToExcel";
import { API_BASE_URL_HC, API_PATHS } from "../../utils/constants/api.constants";
import axios from "axios";
import sampleImage from '../../img/livewell.png'

function LiveWellCategory() {
  const location = useLocation();

  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const fileName = "Live Well";
  const searchParams = new URLSearchParams(location.search);
  const parentCategoryId = searchParams.get('parentCategoryId');
  const parentCategoryName = searchParams.get('parentCategoryName');

  useEffect(() => {
    if (!categories.length) {
      if (parentCategoryId) {
        axios.get(`${API_BASE_URL_HC}/get/category?parentCategoryId=${parentCategoryId}`).then(res => {
          if (res.data?.data?.length) {
            setCategories(res.data.data)
          }
        }).catch(err => alert(err.message))
      } else {
        dispatch(getMasterCategory())
          .unwrap()
          .then((data) => {
            setCategories(data.user.data);
          })
          .catch(({ message }) => {
            // alert(message);
          });
      }
    }
  }, [dispatch]);

  const deleteData = (id) => {
    axios.delete(`${API_BASE_URL_HC}/delete/category?id=${id}`).then(res => {
      if (res.data?.status === 1) alert('deleted successfully')
    }).catch(err => alert(err.message))
  }

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">
                          {parentCategoryName
                            ? `SubCategories of ${parentCategoryName}`
                            : 'Master Categories'
                          }
                        </h4>
                        &nbsp; &nbsp;
                        <Link
                          to={`/LiveWell/AddCategory?parentCategoryId=${parentCategoryId}&parentCategoryName=${parentCategoryName}`}
                          className="btn btn-primary"
                          style={{ marginBottom: "0%" }}
                        >
                          <i className="fa fa-plus"></i> Add
                        </Link>{" "}
                        &nbsp; &nbsp;
                        <ExportToExcel
                          apiData={data}
                          fileName={fileName}
                          style={{
                            height: "25px",
                            padding: "0 10px",
                            marginBottom: "0%",
                          }}
                        />
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Description</th>
                              <th>Image</th>
                              <th>{parentCategoryName
                                ? 'Playlists'
                                : 'Sub Categories'
                              }</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {categories.map((LivewellVal, index) => (
                              <tr key={index}>
                                <td>{LivewellVal.name}</td>

                                <td>{LivewellVal.description}</td>

                                <td>
                                  <img src={LivewellVal.mediaLink || sampleImage} height="100" alt='livewllVal.mediaLink' />
                                </td>

                                <td>
                                  {parentCategoryId ? <Link to={`/Playlist/view?masterCat=${parentCategoryId}&cat=${LivewellVal._id}&catName=${LivewellVal.name}&mastCatName=${parentCategoryName}`} className="btn btn-info">
                                    View
                                  </Link> : <a
                                    href={`/LiveWellCategory?parentCategoryId=${LivewellVal._id}&parentCategoryName=${LivewellVal.name}`}
                                    className="btn btn-warning"
                                  >
                                    View
                                  </a>}


                                </td>

                                <td>
                                  <Link to={`/LiveWell/AddCategory?id=${LivewellVal._id}&parentCategoryId=${parentCategoryId}&parentCategoryName=${parentCategoryName}`} className="btn btn-warning">
                                    Edit
                                  </Link>
                                  <button
                                    className="btn btn-danger"
                                    onClick={() => deleteData(LivewellVal._id)}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
}

export default LiveWellCategory;