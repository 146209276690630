import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import onBoardService from "../../services/corporate.service";
import AuthService from "../../services/auth.service";

export const getonboarding = createAsyncThunk(
    "onboarding/get/onboarding",
    async(thunkAPI) => {
        try {
            const data = await onBoardService.getonboard();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getcompany = createAsyncThunk(
    "onboarding/get/onboarding",
    async(thunkAPI) => {
        try {
            const data = await onBoardService.getcompanies();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const postonboarding = createAsyncThunk(
    "onboarding/post/onboarding",
    async(item, thunkAPI) => {
        try {
            const data = await onBoardService.postonboard(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const editCorporateEnquiry = createAsyncThunk(
    "onBoard/put/enquiry",
    async(item, thunkAPI) => {
        try {
            const data = await onBoardService.onboardstatus(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
  );

export const logout = createAsyncThunk("auth/logout", async () => {
  AuthService.logout();
});

const initialState = {
    loading: false,
    error: "",
    user: AuthService.getUserDetails() || null,
    isLoggedIn: false,
};

const onboardSlice = createSlice({
    name: "board",
    initialState,
    extraReducers: {
        [getonboarding.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getonboarding.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getonboarding.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },

        [postonboarding.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [postonboarding.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [postonboarding.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },

        [logout.fulfilled]: (state, action) => {
          state.isLoggedIn = false;
          state.user = null;
        },
    },
});

const { reducer } = onboardSlice;
export default reducer;