import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "../component/Navbarside";
import Footer from "./footer";
import { useParams } from "react-router-dom";
import { fitnessCenterRevenue } from "../store/slices/fitnessplans";
import Moment from "moment";
import Loader from "./Loader";
import { excelDownload } from "../utils/appUtils";
function FitnessCenterRevenue() {
  const { id } = useParams();
  localStorage.setItem("fitnesscenterId", id);
  const [data, setData] = useState([]);
  const [revenueVendor, setrevenueVendor] = useState([]);
  const [isLoading, setisLoading] = useState(false)
  const { loading } = useSelector((state) => state.auth);
  const [row, setRow] = useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setisLoading(true)
    dispatch(fitnessCenterRevenue())
      .unwrap()
      .then((data) => {
        setisLoading(false)
        setrevenueVendor(data.user.data);
        const customRow = data?.user?.data?.map((value) => {
          let newObj = {
            'Plan Details':  `Name-
            ${ value?.fitnessCenterId?.fcName}
                Plan Type- ${value?.fitnessPlanId?.name}
                `,
            'Date':  `${Moment(value?.updated_at).format(
              "DD-MM-YYYY"
            )}`,
            'Net Amount':`Rs. ${value?.netAmount}`,
            'Commission': `Rs. ${value?.commission}`,
            'Payout': `Rs. ${value?.payout}`,
            'Service Tax': `Rs. ${value?.serviceTax}`,
          };
          return newObj
        });
        setRow(customRow)
      })
      .catch(({ message }) => {
        setisLoading(false)
        alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />
      {isLoading && <Loader/>}

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Revenue</h4> &nbsp; &nbsp;
                        &nbsp;
                        <div className="col-md-2 col-sm-12 pt-2 mt-3">
                        <button
                          onClick={()=>{excelDownload(row, "Revenue.xlsx")}}
                          className="btn btn-primary"
                        >
                          Export
                        </button>
                      </div>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Plan Details</th>
                              <th>Date</th>
                              <th>Net Amount</th>
                              <th>Commission</th>
                              <th>Payout</th>
                              <th>Service Tax</th>
                            </tr>
                          </thead>
                          <tbody>
                            {revenueVendor.map((RevenueVal, index) => (
                              <tr key={index}>
                                <td>
                                  Name-{" "}
                                  {
                                    RevenueVal?.fitnessCenterId?.fcName
                                  }
                                  <br />
                                  Plan Type- {RevenueVal?.fitnessPlanId?.name}
                                </td>
                                <td>
                                  {" "}
                                  {Moment(RevenueVal?.updated_at).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                                <td>Rs. {RevenueVal?.netAmount}</td>
                                <td>Rs. {RevenueVal?.commission}</td>
                                <td>Rs. {RevenueVal?.payout}</td>
                                <td>Rs. {RevenueVal?.serviceTax}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default FitnessCenterRevenue;
