import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "./Navbarside";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "./footer";
import {
  getHealthConditions,
  editHealthConditions,
} from "../store/slices/healthConditions";
import Loader from "./Loader";

const EditHealthConditions = () => {
  const [name, setName] = useState("");
  const [checkBoxError, setCheckBoxError] = useState("");
  const [alertname, setAlertName] = useState("");
  const [isCat, setIsCat] = useState(false);
  const [isPshyio, setIsPshyio] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate("");
  const dispatch = useDispatch();

  const { id } = useParams();
  localStorage.setItem("id", id);

  React.useEffect(() => {
    setIsLoading(true);
    dispatch(getHealthConditions({ id: id }))
      .unwrap()
      .then((data) => {
        setIsLoading(false);
        setIsCat(data?.user?.data?.[0]?.category?.includes("fitness"));
        setIsPshyio(data?.user?.data?.[0]?.category?.includes("physioTherapy"));
        setName(data?.user?.data?.[0]?.name);
      })
      .catch(({ message }) => {
        setIsLoading(false);

        // alert(message);
      });
  }, [dispatch]);

  const save = (e) => {
    const regname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (regname.test(name)) {
      setAlertName("");
    } else if (!regname.test(name) && name === "") {
      setAlertName("Please enter the body part name");
      e.preventDefault();
    } else {
      setAlertName("Single and double quotes are not-valid");
      e.preventDefault();
    }
  };

  const cancel = () => {
    setName("");
    setAlertName("");
  };

  async function updateData(e) {
    e.preventDefault();
    if (!isCat && !isPshyio) {
      setCheckBoxError("Please select either category or pshyiotherepy");
      return;
    }
    let cat = [];
    cat[0] = isCat && "fitness";
    cat[1] = isPshyio && "physioTherapy";
    let item = {
      set: {
        name,
        category: cat,
      },
    };
    setIsLoading(true);
    dispatch(editHealthConditions(item))
      .unwrap()
      .then(() => {
        setIsLoading(false);
        alert("Updated succesfully");
        navigate("/Physiotherapy/HealthConditions");
      })
      .catch(({ message }) => {
        setIsLoading(false);
        alert(message);
      });
  }

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />
      {isLoading && <Loader />}

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Update Health Conditions
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form className="form" onSubmit={updateData}>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                  <p className="alert-message">{alertname}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-md-6 col-sm-12 pt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "49%",
                            }}
                          >
                            <p
                              className="col-black"
                              style={{
                                marginBottom: "0px",
                                marginRight: "10px",
                              }}
                            >
                              Fitness:
                            </p>
                            <input
                              type="checkbox"
                              style={{ marginRight: "50px" }}
                              checked={isCat}
                              onChange={(e) => setIsCat(e.target.checked)}
                              // disabled={disabled}
                            />
                            <p
                              className="col-black"
                              style={{
                                marginBottom: "0px",
                                marginRight: "10px",
                              }}
                            >
                              Physiotherapy:
                            </p>
                            <input
                              type="checkbox"
                              style={{ marginRight: "50px" }}
                              checked={isPshyio}
                              onChange={(e) => setIsPshyio(e.target.checked)}
                              // disabled={disabled}
                            />
                          </div>
                          <p
                            className="alert-message"
                            style={{ marginRight: "70%" }}
                          >
                            {checkBoxError}
                          </p>

                          <div className="form-actions center">
                            <button
                              type="submit"
                              className="btn btn-primary wd-100"
                              onClick={save}
                              // disabled={!message}
                            >
                              <i className="icon-note"></i> Save
                            </button>{" "}
                            &nbsp;
                            <button
                              type="reset"
                              className="btn btn-warning"
                              onClick={cancel}
                            >
                              <i className="icon-trash"></i> Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default EditHealthConditions;
