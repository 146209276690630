import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class onBoardService {

    static getonboard() {
       let api='';
        api = API_PATHS.onboard ;
        localStorage.removeItem("id");
        return axios
        .get( api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static getcompanies() {
        let api='';
         api = API_PATHS.getcompany;
        
         return axios
         .get( api)
             .then((response) => {
                 if (response.data) {
                     // localStorage.setItem("user", JSON.stringify(response.data));
                 }
                 return response.data;
             });
     }

     static postonboard(item) {
        return axios
            .post(API_PATHS.addonboard,
                item
            )
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }


    
  static onboardstatus() {
    let api='';
    let item = JSON.parse(localStorage.getItem("item"));
    let id = localStorage.getItem("id");
    console.log(item)
    api = API_PATHS.putenquiry + '?id=' + id;
    return axios
      .put(api,item)
      .then((response) => {
        if (response.data) {
        
        }
        return response.data;
      });
  }

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default onBoardService; 