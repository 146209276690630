import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class HcConsultationService {
  static getConsultation() {
    let api = '';
    let user = JSON.parse(localStorage.getItem("user"));
    let id = localStorage.getItem("id");
    let role = user.data.roles[0];
    //if(role === 'ROLE_HEALTHADMIN'){
    api = API_PATHS.getHCconsultation;
    // }else if(role === 'ROLE_EXPERT'){
    //   api = id ? API_PATHS.getHCconsultation + '?_id=' + id + '&expertId=' + user.data.id: API_PATHS.getHCconsultation + '?expertId=' + user.data.id;
    // }
    localStorage.removeItem("id");
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static getEnquiry = (flow) => axios.get(`https://api.healthonify.com/get/enquiry?flow=${flow}`).then(enquires => enquires.data)

  static getConsultationDetails() {
    let api = '';
    let id = localStorage.getItem("consultationid");
    api = API_PATHS.getHCconsultation + '?id=' + id;
    localStorage.removeItem("consultationid");
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static getDoctorConsultation() {
    let api = '';
    let user = JSON.parse(localStorage.getItem("user"));
    let id = user.data.id;
    api = API_PATHS.GetConsultations + '?expertId=' + id;
    localStorage.removeItem("id");
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }



  static getPatientConsultation() {
    let api = '';
    let user = JSON.parse(localStorage.getItem("user"));
    let id = user.data.id;
    let patientId = localStorage.getItem("patientId");
    api = API_PATHS.getHCconsultation + '?expertId=' + id + '&userId=' + patientId;
    localStorage.removeItem("id");
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static addconsultation(item) {
    let api = '';
    api = API_PATHS.wminitiateConsultation;
    return axios
      .post(api, item)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static editavailablity(item) {
    let api = '';
    api = API_PATHS.putavailablity;
    return axios
      .post(api, item)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static addHCPrescriptions(item) {
    let api = '';
    api = API_PATHS.postHcPrescription;
    return axios
      .post(api, item)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static putconsultation(item) {
    let api = '';
    // let user = JSON.parse(localStorage.getItem("user"));
    let id = localStorage.getItem("id");
    api = id ? API_PATHS.getConsultation + '?id=' + id : API_PATHS.getConsultation;
    localStorage.removeItem("id");
    return axios
      .put(api, item)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static gethealthcareconsultation(params) {
    let api = '';
    api = API_PATHS.getHCconsultation;
    localStorage.removeItem("id");
    return axios
      .get(api, {params})
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }
  static getupcominghealthcareconsultation() {
    let api = '';
    let user = JSON.parse(localStorage.getItem("user"));
    let id = user.data.id;
    api = API_PATHS.getHCconsultation + '?userId=' + id + '&status=' + "scheduled";
    localStorage.removeItem("id");
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }


  static removeUserDetails() {
    localStorage.removeItem("user");
  }

  static getUserDetails() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default HcConsultationService;