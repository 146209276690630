import React, { useState } from "react";

import Navbarside from "./Navbarside";
import Footer from "./footer";
import { getlabtestresultdetails } from "../store/slices/lab";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Moment from "moment";
const Labtestdetails = () => {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [date, setDate] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [testFor, setTestFor] = useState("");
  const [city, setCity] = useState("");
  const [doorNoAndStreetName, setDoorNoAndStreetName] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [time, setTime] = useState("");
  const [testType, setTestType] = useState("");
  const [labTestCategoryId, setLabTestCategoryId] = useState([]);

  const dispatch = useDispatch();
  const { id } = useParams();
  localStorage.setItem("labId", id);

  React.useEffect(() => {
    dispatch(getlabtestresultdetails())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id === id) {
          setFname(data.user.data[0].userId.firstName);
          setLname(data.user.data[0].userId.lastName);
          setLabTestCategoryId(data.user.data[0].labTestCategoryId);
          setGender(data.user.data[0].gender);
          setAge(data.user.data[0].age);
          setDate(data.user.data[0].date);
          setTime(data.user.data[0].time);
          setMobileNo(data.user.data[0].mobileNo);
          setTestFor(data.user.data[0].testFor);
          setTestType(data.user.data[0].testType);
          // address
          setCity(data.user.data[0].address.city);
          setDoorNoAndStreetName(data.user.data[0].address.doorNoAndStreetName);
          setPincode(data.user.data[0].address.pincode);
          setState(data.user.data[0].address.state);
        }
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);
  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-lg-12 col-md-10 col-sm-12">
                    <div className="card-details">
                      <h2 className="text-center pb-3">Lab Test Details</h2>

                      <div className="card-title-wrap bar-success">
                        <h4 className="card-title">Patient Info</h4>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-gap-bet">
                          <div className="d-flex justify-content-between">
                            <div className="info-head">
                              <p>Patient Name</p>
                              <p>Test For</p>
                              <p>Test Type</p>
                              <p>Date</p>
                            </div>

                            <div className="dot">
                              <p>:</p>
                              <p>:</p>
                              <p>:</p>
                              <p>:</p>
                            </div>

                            <div className="info-subtext">
                              <p>
                                {fname} {lname}
                              </p>
                              <p>{testFor}</p>
                              <p>{testType}</p>
                              <p> {Moment(date).format("DD-MM-YYYY")}</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-12 col-sm-12 col-gap-bet">
                          <div className="d-flex justify-content-between">
                            <div className="info-head">
                              <p>Time</p>
                              <p>Age</p>
                              <p>Gender</p>
                              <p>Contact Number</p>
                            </div>

                            <div className="dot">
                              <p>:</p>
                              <p>:</p>
                              <p>:</p>
                              <p>:</p>
                            </div>

                            <div className="info-subtext">
                              <p>{time}</p>
                              <p>{age}</p>
                              <p>{gender}</p>
                              <p>{mobileNo}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card-details mt-2 mb-4">
                      <div className="card-title-wrap bar-success">
                        <h4 className="card-title">Lab Test Name</h4>
                      </div>

                      <div className="row">
                        {labTestCategoryId.map((LabVal, index) => (
                          <div className="col-md-4 col-sm-12">
                            <ul className="info-subtext">
                              <li
                                style={{ listStyleType: "disclosure-closed" }}
                              >
                                {LabVal.name}
                              </li>
                            </ul>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="card-details mt-2 mb-4">
                      <div className="card-title-wrap bar-success">
                        <h4 className="card-title">Location</h4>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-gap-bet">
                          <div className="d-flex justify-content-between">
                            <div className="info-head">
                              <p>DoorNo & Street Name</p>
                              <p>City</p>
                            </div>

                            <div className="dot">
                              <p>:</p>
                              <p>:</p>
                            </div>

                            <div className="info-subtext">
                              <p>{doorNoAndStreetName}</p>
                              <p>{city}</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-gap-bet">
                          <div className="d-flex justify-content-between">
                            <div className="info-head">
                              <p>State</p>
                              <p>Pin Code</p>
                            </div>

                            <div className="dot">
                              <p>:</p>
                              <p>:</p>
                            </div>

                            <div className="info-subtext">
                              <p>{state}</p>
                              <p>{pincode}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
};

export default Labtestdetails;
