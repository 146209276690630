import React, { useState } from "react";

import Navbarside from "./Navbarside";
import Footer from "./footer";
import { getdietplan, DeleteDietplan } from "../store/slices/diet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import Table from "./common/Table";
import Pagination from "./common/Pagination";
import Loader from "./Loader";
const DietPlans = () => {
  const dispatch = useDispatch();
  const [diet, setdiet] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setpage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [column, setColumn] = useState([
    { isHtml: false, name: "Dietplan Name" },
    { isHtml: false, name: "Level" },
    { isHtml: false, name: "Plan Type" },
    { isHtml: false, name: "Goal" },
    { isHtml: false, name: "Type" },
    { isHtml: false, name: "Validity" },
    { isAction: true, name: "Action" },
  ]);
  const [row, setRow] = useState([]);

  React.useEffect(() => {
    fetchDietPlan();
    // dispatch(getdietplan())
    //   .unwrap()
    //   .then((data) => {
    //     setdiet(data.user.data);
    //   })
    //   .catch(({ message }) => {
    //     // alert(message);
    //   });
  }, [dispatch]);

  const fetchDietPlan = (page = 0) => {
    setIsLoading(true);
    dispatch(getdietplan({ page: page, limit: 10 }))
      .unwrap()
      .then((data) => {
        setIsLoading(false);
        setpage(page);
        setTotalCount(data?.user?.totalCount);
        const customHeadings = data?.user?.data?.map((obj, key) => {
          console.log("newImp", obj);
          // "Name": item.name,
          let newObj = {
            // Modify key names
            "Dietplan Name": [
              <Link to={`/DietPlanDetails/${obj._id ? obj._id : null}`}>
                {obj.name}
              </Link>,
            ],
            Level: obj?.level,
            "Plan Type": obj?.planType,
            Goal: obj?.goal,
            Type: obj?.type,
            Validity: `${obj?.validity} Days`,
            Action: [
              <button
                onClick={() => deleteData(obj._id)}
                className="btn btn-warning"
                style={{
                  height: "25px",
                  padding: "0 10px",
                }}
              >
                Delete
              </button>,
            ],
          };

          return newObj;
        });
        setRow(customHeadings);
        setdiet(data.user.data);
      })
      .catch(({ message }) => {
        setIsLoading(false);
        // alert(message);
      });
  };

  const Delete = async function deletePlaylist(id) {
    localStorage.setItem("id", id);
    setIsLoading(true);
    dispatch(DeleteDietplan())
      .unwrap()
      .then(() => {
        setIsLoading(false);
        fetchDietPlan();
        // alert("Uploaded succesfully");
        // window.location.reload(false);
      })
      .catch(({ message }) => {
        setIsLoading(false);
        alert(message);
      });

    console.log(id);
  };
  const deleteData = (id) => {
    confirmAlert({
      title: "Want to delete?",
      message: "Are you sure you want to delete this diet plan?",
      buttons: [
        {
          label: "Yes",
          onClick: () => Delete(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />
        {isLoading && <Loader />}

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-wrap bar-success">
                          <h4 className="card-title">
                            Diet Plans &nbsp; &nbsp;
                            <Link to="/AddDietPlan" className="btn btn-primary">
                              <i className="fa fa-plus"></i> Add
                            </Link>
                          </h4>
                        </div>
                      </div>
                      <div className="card-body collapse show">
                        <div className="card-block card-dashboard table-responsive">
                          <Table data={row} columns={column} isFooter={true} />
                          <div className="PaginationContainer">
                            <span className="total-elements">
                              Showing {parseInt(page) * 10 + 1} to{" "}
                              {parseInt(page) * 10 + 10} of {totalCount} entries
                            </span>
                            <Pagination
                              currentPage={parseInt(page) + 1}
                              totalCount={totalCount}
                              itemsPerPage={10}
                              onPageChange={(page) => {
                                fetchDietPlan(parseInt(page) - 1);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
};

export default DietPlans;
// import React, { useState } from "react";

// import Navbarside from "./Navbarside";
// import Footer from "./footer";
// import {
//   getworkoutplans,
//   DeleteWorkoutplan,
// } from "../store/slices/workoutplan";
// import { useDispatch, useSelector } from "react-redux";
// import { Link, useParams } from "react-router-dom";
// import { confirmAlert } from "react-confirm-alert";
// const Workoutplans = () => {
//   const dispatch = useDispatch();
//   const [workout, setWorkout] = useState([]);

//   React.useEffect(() => {
//     dispatch(getworkoutplans())
//       .unwrap()
//       .then((data) => {
//         setWorkout(data.user.data);
//       })
//       .catch(({ message }) => {
//         // alert(message);
//       });
//   }, [dispatch]);

//   const Delete = async function deletePlaylist(id) {
//     localStorage.setItem("id", id);
//     dispatch(DeleteWorkoutplan())
//       .unwrap()
//       .then(() => {
//         // alert("Uploaded succesfully");
//         window.location.reload(false);
//       })
//       .catch(({ message }) => {
//         alert(message);
//       });

//     console.log(id);
//   };
//   const deleteData = (id) => {
//     confirmAlert({
//       title: "Want to delete?",
//       message: "Are you sure you want to delete this workout plan?",
//       buttons: [
//         {
//           label: "Yes",
//           onClick: () => Delete(id),
//         },
//         {
//           label: "No",
//           //onClick: () => alert('Click No')
//         },
//       ],
//     });
//   };

//   return (
//     <div>
//       <body data-col="2-columns" className=" 2-columns ">
//         <Navbarside />

//         <div className="container-fluid pb-5 response-cover">
//           <div className="row">
//             <div className="col-lg-2 col-md-4" />
//             <div className="col-lg-10 col-md-8">
//               <div className="container-fluid pt-5">
//                 <div className="row">
//                   <div className="col-12">
//                     <div className="card">
//                       <div className="card-header">
//                         <div className="card-title-wrap bar-success">
//                           <h4 className="card-title">
//                             Workout Plans &nbsp; &nbsp;
//                             <Link
//                               to="/AddWorkoutPlan"
//                               className="btn btn-primary"
//                             >
//                               <i className="fa fa-plus"></i> Add
//                             </Link>
//                           </h4>
//                         </div>
//                       </div>
//                       <div className="card-body collapse show">
//                         <div className="card-block card-dashboard table-responsive">
//                           <table className="table table-striped table-bordered zero-configuration">
//                             <thead>
//                               <tr>
//                                 <th>Workout Name</th>
//                                 <th>Days In Week</th>
//                                 <th>Goal</th>
//                                 <th>Type</th>
//                                 <th>Level</th>
//                                 <th>Action</th>
//                               </tr>
//                             </thead>

//                             <tbody>
//                               {workout.map((workVal, index) => (
//                                 <tr>
//                                   <td>
//                                     <Link
//                                       to={`/Fitness/Workout-plans/Workoutdetails/${
//                                         workVal._id ? workVal._id : null
//                                       }`}
//                                     >
//                                       {workVal.name}
//                                     </Link>
//                                   </td>
//                                   <td>{workVal.daysInweek}</td>
//                                   <td>{workVal.goal}</td>
//                                   <td>{workVal.type}</td>
//                                   <td>{workVal.level}</td>
//                                   <td>
//                                     <button
//                                       onClick={() => deleteData(workVal._id)}
//                                       className="btn btn-warning"
//                                       style={{
//                                         height: "25px",
//                                         padding: "0 10px",
//                                       }}
//                                     >
//                                       Delete
//                                     </button>
//                                   </td>
//                                 </tr>
//                               ))}
//                             </tbody>
//                           </table>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <Footer />
//       </body>
//     </div>
//   );
// };

// export default Workoutplans;
