import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import Navbarside from "./Navbarside";
import {
  getHealthConditions,
  deleteHealthConditions,
} from "../store/slices/healthConditions";
import Footer from "./footer";
import $ from "jquery";

import { Link, useNavigate } from "react-router-dom";
import Table from "./common/Table";
import Pagination from "./common/Pagination";
import { limitForPagination } from "../utils/appUtils";
import Loader from "./Loader";

const HealthConditions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [column, setColumn] = useState([
    { isHtml: false, name: "#" },
    { isHtml: false, name: "Name" },
    { isHtml: false, name: "Category" },
    { isAction: true, name: "Action" },
  ]);
  const [page, setpage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [row, setRow] = useState([]);

  React.useEffect(() => {
    console.log("ohk");

    fetchData();
    // $("#example").on("click", ".delete", function (e) {
    //   e.stopPropagation();
    //   deleteData($(this).data("id"));
    // });
  }, []);

  // Fetch Data
  const fetchData = (page = 0) => {
    setIsLoading(true);
    dispatch(getHealthConditions())
      .unwrap()
      .then((data) => {
        setIsLoading(false);
        const modifiedArray = data?.user?.data?.map((obj, key) => {
          let newObj = {
            // Modify key names
            "#": parseInt(page) * 10 + 1 + key,
            Name: obj?.name ?? "",
            Category: obj?.category?.join(",")?.toUpperCase(),
            Action: [
              <button
                key="edit"
                onClick={() => {
                  navigate(`/Physiotherapy/EditHealthConditions/${obj?._id}`);
                }}
                className="btn btn-primary"
                style={{ height: "25px", padding: "0 10px", marginRight: "3%" }}
              >
                Edit
              </button>,
              <button
                key="delete"
                onClick={() => {
                  deleteData(obj?._id);
                }}
                className="btn btn-warning"
                style={{ height: "25px", padding: "0 10px" }}
              >
                Delete
              </button>,
            ],
          };
          return newObj;
        });
        setRow(modifiedArray);
      })
      .catch(({ message }) => {
        setIsLoading(false);
        // alert(message);
      });
  };

  const Delete = async function (id) {
    localStorage.setItem("id", id);
    dispatch(deleteHealthConditions())
      .unwrap()
      .then(() => {
        // alert("Uploaded succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });

    console.log(id);
  };

  const deleteData = (id) => {
    confirmAlert({
      title: "Want to delete?",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => Delete(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />
        {isLoading && <Loader />}

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-wrap bar-success">
                          <h4 className="card-title">
                            Health Conditions &nbsp; &nbsp;
                            <Link
                              to="/Physiotherapy/AddHealthConditions"
                              className="btn btn-primary"
                            >
                              <i className="fa fa-plus"></i> Add
                            </Link>
                          </h4>
                        </div>
                      </div>
                      <div className="card-body collapse show">
                        <div className="card-block card-dashboard table-responsive">
                          {/* <table id="example" className="display"></table> */}
                          {row.length > 0 && (
                            <Table
                              columns={column}
                              data={row}
                              isFooter={true}
                            />
                          )}
                          <div className="PaginationContainer">
                            <span className="total-elements">
                              Showing {parseInt(page) * 10 + 1} to{" "}
                              {parseInt(page) * 10 + 10} of {totalCount} entries
                            </span>
                            <Pagination
                              currentPage={parseInt(page) + 1}
                              totalCount={totalCount}
                              itemsPerPage={limitForPagination()}
                              onPageChange={(page) => {
                                fetchData(parseInt(page) - 1);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
};

export default HealthConditions;
