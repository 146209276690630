import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Navbarside from "./Navbarside";
import { useNavigate, useParams, Link } from "react-router-dom";
import Footer from "./footer";
import { putRecipe, recipeDetails } from "../store/slices/recipe";
import { API_PATHS } from "../utils/constants/api.constants";

const EditRecipes = () => {
  const { id } = useParams();
  localStorage.setItem("recipeId", id);
  const [name, setName] = useState("");
  const [mediaLink, setMediaLink] = useState([""]);
  const [ingredients, setIngredients] = useState("");
  const [method, setMethod] = useState("");
  const [calories, setCalories] = useState("");
  const [durationInMinutes, setDurationInMinutes] = useState("");

  const [proteins, setProteins] = useState("");
  const [carbs, setCarbs] = useState("");
  const [fats, setFats] = useState("");
  const [fiber, setFiber] = useState("");

  const [nutrition, setNutrition] = useState([
    {
      proteins: "",
      carbs: "",
      fats: "",
      fiber: "",
    },
  ]);

  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate("");

  const handleRowChange = (e, index) => {
    const quantitys = [...nutrition];
    quantitys[index][e.currentTarget.name] = e.currentTarget.value;
    setNutrition(quantitys);
    // console.log(nutrition);
  };

  React.useEffect(() => {
    dispatch(recipeDetails())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id == id) {
          setName(data.user.data[0].name);
          setCalories(data.user.data[0].calories);
          setIngredients(data.user.data[0].description.ingredients);
          setMethod(data.user.data[0].description.method);
          setDurationInMinutes(data.user.data[0].durationInMinutes);
          setMediaLink(data.user.data[0].mediaLink[0]);
          setNutrition(data.user.data[0].nutrition);
        }
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  async function addData(e) {
    e.preventDefault();
    let isActive = true;
    let description = {
      ingredients,
      method,
    };

    let item = {
      set: {
        name,
        mediaLink,
        description,
        calories,
        durationInMinutes,
        isActive,
        nutrition,
      },
    };
    dispatch(putRecipe(item))
      .unwrap()
      .then(() => {
        alert("Updated succesfully");
        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">
                          Edit Recipe
                          {/* <Link
                              to="/UploadPackage"
                              className="btn btn-primary"
                            >
                              <i className="fa fa-plus"></i> Add
                            </Link> */}
                        </h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <form onSubmit={addData}>
                        <div className="row">
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Recipe Name
                            </p>
                            <input
                              type="text"
                              placeholder="Enter the recipe name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message"></p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Calories
                            </p>
                            <input
                              type="number"
                              placeholder="Enter the no of calories"
                              value={calories}
                              onChange={(e) => setCalories(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message"></p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Duration In Minutes
                            </p>
                            <input
                              type="number"
                              placeholder="Enter the duration in minutes"
                              value={durationInMinutes}
                              onChange={(e) =>
                                setDurationInMinutes(e.target.value)
                              }
                              className="form-control"
                            />
                            <p className="alert-message"></p>
                          </div>

                          <div className="col-12">
                            <h5>Description</h5>
                          </div>

                          <div className="col-md-12 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Ingredients
                            </p>
                            <textarea
                              placeholder="Enter the recipe ingredients"
                              value={ingredients}
                              onChange={(e) => setIngredients(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message"></p>
                          </div>

                          <div className="col-md-12 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Preparation Method
                            </p>
                            <textarea
                              placeholder="Enter the recipe method"
                              value={method}
                              onChange={(e) => setMethod(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message"></p>
                          </div>

                          <div className="col-md-12 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Recipe Image
                            </p>

                            <input
                              type="text"
                              value={mediaLink}
                              className="form-control-file mt-1"
                              Placeholder="Image/ Video Link"
                              onChange={(e) => setMediaLink(e.target.value)}
                            />
                            <p className="alert-message">{}</p>
                            <p style={{ color: "green", fontWeight: "500" }}>
                              {message}
                            </p>
                          </div>
                          <div className="col-12 mt-3">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Nutrition
                            </p>
                          </div>

                          {nutrition.map((quantitys, index) => (
                            <div className="col-12">
                              <div key={index} className="row">
                                <div className="col-md-3 col-sm-12 pt-2 pb-1">
                                  <p>Protein</p>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Protein Quantity"
                                    onChange={(e) => handleRowChange(e, index)}
                                    value={quantitys.proteins}
                                    name="proteins"
                                  />
                                </div>

                                <div className="col-md-3 col-sm-12 pt-2 pb-1">
                                  <p>Carbs</p>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Carbs Quantity"
                                    onChange={(e) => handleRowChange(e, index)}
                                    value={quantitys.carbs}
                                    name="carbs"
                                  />
                                </div>

                                <div className="col-md-3 col-sm-12 pt-2 pb-1">
                                  <p>Fat</p>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Fat Quantity"
                                    onChange={(e) => handleRowChange(e, index)}
                                    value={quantitys.fats}
                                    name="fats"
                                  />
                                </div>

                                <div className="col-md-3 col-sm-12 pt-2 pb-1">
                                  <p>Fiber</p>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Fiber Quantity"
                                    onChange={(e) => handleRowChange(e, index)}
                                    value={quantitys.fiber}
                                    name="fiber"
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="d-flex justify-content-center pt-3">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                          &nbsp; &nbsp; &nbsp;
                          <button type="reset" className="btn btn-warning">
                            Reset
                          </button>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default EditRecipes;
