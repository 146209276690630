import React from "react";
import Navbarside from "./Navbarside";
import { useDispatch } from "react-redux";
import { fitnessCenterPlans } from "../store/slices/fitnessplans";
import { useState } from "react";
import Footer from "./footer";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import Loader from "./Loader";
import { API_PATHS } from "../utils/constants/api.constants";
import axios from "axios";

const FitnessCenterPlan = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const  {state} = location
  const {fitnessCenterId} = state || {}
  const { id } = useParams();
  localStorage.setItem("fitnesscenterId", id);
  const [fitnessenquire, setFitnessenquire] = useState([]);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  React.useEffect(() => {
    const fetchData = () => {};
    fetchData();
  }, []);

  React.useEffect(() => {
    fetchPlan()
  }, [dispatch]);
  const fetchPlan = () =>{
    setIsLoading(true);
    dispatch(fitnessCenterPlans())
      .unwrap()
      .then((data) => {
        setIsLoading(false);
        setFitnessenquire(data.user.data);
      })
      .catch(({ message }) => {
        setIsLoading(false);
        alert(message);
      });
  }

  const deleteFitnessPlanc = async(id)=>{
    try{
      console.log("checkigId",id)
      const fitnessCat = await axios.delete(`${API_PATHS.fPlanDelete}?id=${id}`);
      if (fitnessCat) {
        fetchPlan()
      }
    }catch(e){
      console.log("e")
    }
  }

  return (
    <div>
      <Navbarside />
      {isLoading && <Loader/>}

      <div className="container-fluid response-cover pb-5">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        {/* <h4 className="card-title">Food Library</h4> */}
                        <h4 className="card-title">Fitness Center Plans</h4>
                        &nbsp; &nbsp; &nbsp;
                        <button
                        // disabled ={true}
                          onClick={()=>{navigate(`/FitnessCenterPlan/add`, {state:{fcAdminId:id,fitnessCenterId:fitnessCenterId}})}}
                          className="btn btn-success col-white"
                        >
                          Add
                        </button>
                        &nbsp; &nbsp; &nbsp;
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Plan Name</th>
                              <th>Plan Validity</th>
                              <th>Price</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {fitnessenquire.map((fitnessVal, index) => (
                              <tr key={index}>
                                <td>{fitnessVal.name}</td>
                                <td>{fitnessVal.memberShipDurationInDays}</td>
                                <td>{fitnessVal.price}</td>
                                <td>
                                  {" "}
                                  {fitnessVal.isActive === true && (
                                    <button
                                      className="btn btn-warning"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Active
                                    </button>
                                  )}
                                  &nbsp;
                                  {fitnessVal.isActive === false && (
                                    <button
                                      className="btn btn-success"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Inactive
                                    </button>
                                  )}{" "}
                                </td>
                                <td>
                                  <button
                                    onClick={()=>{navigate(`/FitnessCenterPlan/edit/${fitnessVal?._id}`, {state:{fcAdminId:id,fitnessCenterId:fitnessCenterId}})}}
                                    // disabled ={true}
                                    className="btn btn-primary"
                                    style={{
                                      height: "25px",
                                      padding: "0 10px",
                                      marginRight: "10px"
                                    }}
                                  >
                                    Edit
                                  </button>
                                  <button
                                    onClick={()=>{deleteFitnessPlanc(fitnessVal?._id)}}
                                    className="btn btn-danger"
                                    style={{
                                      height: "25px",
                                      padding: "0 10px",
                                    }}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default FitnessCenterPlan;
