import axios from "axios";
import { API_PATHS, API_BASE_URL } from "../utils/constants/api.constants";

class WmConsultationService {
  static getConsultation() {
    let api = '';
    let user = JSON.parse(localStorage.getItem("user"));
    let id = localStorage.getItem("id");
    let role = user.data.roles[0];
    if (role === 'ROLE_SUPERADMIN') {
      api = id ? API_PATHS.WMConsultations + '?_id=' + id : API_PATHS.WMConsultations;
    } else if (role === 'ROLE_EXPERT') {
      api = id ? API_PATHS.WMConsultations + '?_id=' + id + '&expertId=' + user.data.id : API_PATHS.WMConsultations + '?expertId=' + user.data.id;
    }
    localStorage.removeItem("id");
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static addconsultation(item) {
    let api = '';
    api = API_PATHS.wminitiateConsultation;
    return axios
      .post(api, item)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static processEnquiry(item) {
    let url = `${API_BASE_URL}enquiry/processEnquiry`
    return axios.post(url, item)
  }

  static putconsultation(item) {
    let api = '';
    // let user = JSON.parse(localStorage.getItem("user"));
    let id = localStorage.getItem("id");
    api = id ? API_PATHS.getConsultation + '?id=' + id : API_PATHS.getConsultation;
    localStorage.removeItem("id");
    return axios
      .put(api, item)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static getweightConsultation(params) {
    let api = '';
    api = API_PATHS.WMConsultations;
    localStorage.removeItem("id");
    return axios
      .get(api, {params})
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static gewmclientConsultation() {
    let api = '';
    let user = JSON.parse(localStorage.getItem("user"));
    let id = user.data.id;
    let patientId = localStorage.getItem("patientId");
    api = API_PATHS.WMConsultations + '?expertId=' + id + '&userId=' + patientId;

    localStorage.removeItem("id");
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static gewmExpertConsultation() {
    let api = '';
    let user = JSON.parse(localStorage.getItem("user"));
    let id = user.data.id;

    api = API_PATHS.WMConsultations + '?expertId=' + id;

    localStorage.removeItem("id");
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }


  static getweightSession() {
    let api = '';
    api = API_PATHS.WMSessions;

    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }
  static getweightSessionExpert() {
    let api = '';
    let user = JSON.parse(localStorage.getItem("user"));
    let id = user.data.id;
    api = API_PATHS.WMSessions + '?expertId=' + id;

    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }


  static removeUserDetails() {
    localStorage.removeItem("user");
  }

  static getUserDetails() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default WmConsultationService;