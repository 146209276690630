import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "../Navbarside";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Footer from "../footer";
import { postcategory } from "../../store/slices/livewell";
import { get, put, post } from 'axios';
import { API_BASE_URL_HC, API_PATHS } from "../../utils/constants/api.constants";


const AddCategory = () => {
  const location = useLocation();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [mediaLink, setMediaLink] = useState("");

  const [id, setId] = useState('');
  const [catData, setCatData] = useState('');
  const [masterCategories, setMasterCategories] = useState([]);
  const [selectedParentCat, setSelectedParentCat] = useState({});

  const [alertname, setAlertName] = useState("");
  const [alertdescription, setAlertDescription] = useState("");

  const navigate = useNavigate("");
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(location.search);
  const parentCatId = searchParams.get('parentCategoryId');
  const parentCatName = searchParams.get('parentCategoryName');


  useEffect(() => {
    const id = searchParams.get('id');
    if (id && !catData) {
      setId(id)
      get(`${API_BASE_URL_HC}/get/category?id=${id}`).then(res => {
        if (res.data?.data?.length) {
          setCatData(res.data.data[0])
          let { name, description, mediaLink, parentCategoryId } = res.data.data[0]

          setName(name)
          setDescription(description)
          setMediaLink(mediaLink)
          if (!Object.keys(selectedParentCat).length && parentCategoryId) {
            setSelectedParentCat(parentCategoryId)
          }
        }
      }).catch(err => {
        console.log(err.message)
      })
    }
    if (!masterCategories.length) {
      get(`${API_BASE_URL_HC}/get/category?master=1`).then(res => {
        setMasterCategories(res.data.data)
      }).catch(err => alert(err.message))
    }
    console.log(selectedParentCat, 'selectedParentCat')
    if (parentCatId != null && !Object.keys(selectedParentCat).length) {
      setSelectedParentCat({
        name: parentCatName,
        _id: parentCatId
      })
    }
  })

  async function imageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setMediaLink(data.data.location);
  }

  const cancel = () => {
    setName("");
    setDescription("");
    setAlertName("");
    setAlertDescription("");
  };

  async function addData(e) {
    e.preventDefault();
    console.log({ selectedParentCat })
    post(`${API_BASE_URL_HC}/post/category`, {
      name,
      description,
      mediaLink,
      parentCategoryId: selectedParentCat._id,
    }).then(res => {
      alert(res.data.message)
      navigate(-1)
    }).catch(err => alert(err.message))
  }

  const updateData = (e) => {
    e.preventDefault();
    put(`${API_BASE_URL_HC}/put/category?id=${id}`, {
      set: {
        name,
        description,
        mediaLink,
        parentCategoryId: selectedParentCat._id
      }
    }).then(res => {
      alert(res.data.message)
      navigate(-1)
    }).catch(err => alert(err.message))
  }

  const handleParentChange = (e) => {
    let { value } = e.target
    setSelectedParentCat(JSON.parse(value))
  }
  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">{id ? 'Update Category' : 'Add Category'}</h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <form>
                        <div className="row">
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Name
                            </p>
                            <input
                              type="text"
                              placeholder="Enter category name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertname}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Description
                            </p>
                            <input
                              type="text"
                              placeholder="Enter the Description"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertdescription}</p>
                          </div>

                          {id
                            ? <><div className="col-md-6">
                              <p
                                className="col-black"
                                style={{ marginBottom: "2px" }}
                              >
                                Image
                              </p>
                              {mediaLink
                                ? <img src={mediaLink} height="100" alt='mediaLink' />
                                : 'No image added'}
                            </div>
                              {selectedParentCat?.name
                                ? <div className="col-md-6 col-sm-12 pt-2">
                                  <p className="col-black" style={{ marginBottom: "2px" }}>
                                    Master Category
                                  </p>
                                  <select
                                    className="form-control"
                                    onChange={handleParentChange}
                                  ><option value="" disabled selected>add/update Parent Category</option>
                                    {masterCategories?.length
                                      ? masterCategories.map((mastCat) => {
                                        return (
                                          <option key={mastCat._id} value={`${JSON.stringify(mastCat)}`}>
                                            {mastCat.name}
                                          </option>
                                        );
                                      })
                                      : ""}
                                  </select>
                                </div>
                                : ''}

                            </>
                            : ''
                          }

                          {selectedParentCat?.name
                            ? <div className="col-md-6 col-sm-12 pt-2">
                              <p className="col-black" style={{ marginBottom: "2px" }}>
                                Selected Parent Category : {selectedParentCat.name}
                              </p>

                            </div>
                            : ''
                          }

                          <div className="form-body">
                            <div className="form-group">
                              <p
                                className="col-black"
                                style={{ marginBottom: "2px" }}
                              >
                                add/update Image
                              </p>
                              <input
                                type="file"
                                className="form-control-file"
                                Placeholder=""
                                name="imageUrl"
                                onChange={(e) => imageUpload(e)}
                              />
                            </div>
                          </div>



                        </div>


                        <div className="d-flex justify-content-center pt-3">
                          {id
                            ? <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={updateData}
                            >
                              Update Category
                            </button>
                            : <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={addData}
                            >
                              Add Category
                            </button>
                          }

                          &nbsp; &nbsp; &nbsp;
                          {/* <button
                            type="reset"
                            className="btn btn-warning"
                            onClick={cancel}
                          >
                            Cancel
                          </button> */}
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default AddCategory;
