import React from "react";
import Navbarside from "./Navbarside";
import { useDispatch } from "react-redux";
import { getfitcenter } from "../store/slices/fitness";
import { useState } from "react";
import Footer from "./footer";
import { Link, useNavigate } from "react-router-dom";
import { ExportToExcel } from "./ExportToExcel";
import { API_PATHS } from "../utils/constants/api.constants";
import axios from "axios";
import { ApproveRequestFitnesss } from "./../store/slices/fitness";
import $ from "jquery";
import Table from "./common/Table";
import Pagination from "./common/Pagination";
import Loader from "./Loader";

const FitnessCenter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setpage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [column, setColumn] = useState([
    { isHtml: false, name: "#" },
    { isHtml: false, name: "Name" },
    { isHtml: false, name: "Email" },
    { isHtml: false, name: "Mobile No." },
    { isHtml: false, name: "Agreement" },
    { isHtml: true, name: "Address" },
    { isAction: false, name: "Status" },
    { isHtml: false, name: "Plans" },
    { isAction: false, name: "Revenue" },
    { isAction: true, name: "Action" },
  ]);
  const [row, setRow] = useState([]);
  const fileName = "Fitness Center";

  React.useEffect(() => {
    fetchData();
  }, []);
  const fetchData = (page = 0) => {
    setIsLoading(true)
    axios.get(API_PATHS.getfitnesscenter, { limit: 10, page: page }).then((postData) => {
      setIsLoading(false);
      setpage(page);
      setTotalCount(postData.data?.totalCount);
      const customHeadings = postData?.data?.data?.map((obj, key) => {
        console.log("newImp", obj);
        let newObj = {
          "#": key + 1,
          Name: [
            <Link to={`/FitnessCenterDetails/${obj._id}`}>{obj?.fcName}</Link>,
          ],
          Email: obj?.fcEmail,
          "Mobile No.": obj?.fcMobileNo,
          Agreement: [
            <button
              onClick={() => {
                navigate(obj?.agreement);
              }}
              style={{ height: "25px", padding: "0 10px" }}
              class="btn btn-primary col-white"
            >
              View
            </button>,
          ],
          Address: `${obj?.fcAddress?.doorNoAndStreetName} ${obj?.fcAddress?.city}, ${obj?.fcAddress?.state}`,
        };
        if (obj?.status === "approvalPending") {
          newObj["Status"] = [
            <Link
              to={`/FitnessrequestApprove/${obj?._id}`}
              className="btn btn-primary"
              style={{ height: "25px", padding: "0 10px" }}
            >
              Approve
            </Link>,
            <Link
              onClick={disableApprove(obj?.id)}
              className="btn btn-danger"
              style={{ height: "25px", padding: "0 10px" }}
            >
              Reject
            </Link>,
          ];
        } else if (obj?.status === "rejected") {
          newObj["Status"] = [
            <button
              className="btn btn-danger"
              style={{ height: "25px", padding: "0 10px" }}
            >
              Rejected
            </button>,
          ];
        } else if (obj?.status === "approved") {
          newObj["Status"] = [
            <button
              className="btn btn-primary"
              style={{ height: "25px", padding: "0 10px" }}
            >
              Approved
            </button>,
          ];
        }

        newObj["Plans"] = [
          <button
            onClick={()=>{navigate(`/FitnessCenterPlan/${obj?.fcAdminId}`,{state:{fitnessCenterId:obj?._id}})}}
            style={{ height: "25px", padding: "0 10px" }}
             class="btn btn-primary"

          >
            View
          </button>,
        ];
        newObj["Revenue"] = [
          <button
            onClick={()=>{navigate(`/FitnessCenterRevenue/${obj?.fcAdminId}`)}}
            style={{ height: "25px", padding: "0 10px" }}
             class="btn btn-primary"

          >
            View
          </button>,
        ];
        newObj["Action"] = [
          <button
            onClick={()=>{deleteFitnessCentre(obj?._id)}}
            style={{ height: "25px", padding: "0 10px" }}
            className ="btn btn-danger"

          >
            Delete
          </button>,
        ];
        return newObj;
      });
      setRow(customHeadings);
      // setData(customHeadings);
    });

    // setData(customHeadings);
  };


  // DeleteHandler
  const deleteFitnessCentre = async(id)=>{
    try{
      console.log("checkigId",id)
      const fitnessCat = await axios.delete(`${API_PATHS.deletefitnesscenter}?id=${id}`);
      if (fitnessCat) {
        fetchData()
      }
    }catch(e){
      console.log("e")
    }
  }


  async function disableApprove(ids) {
    let status = "rejected";
    let id = ids;
    let isApproved = false;
    let item = { id, status, isApproved };
    localStorage.setItem("item", JSON.stringify(item));
    setIsLoading(true)
    dispatch(ApproveRequestFitnesss(item))
      .unwrap()
      .then(() => {
    setIsLoading(false)
        alert("Request rejected succesfully");
        // window.location.reload(false);
      })
      .catch(({ message }) => {
    setIsLoading(false)
        alert(message);
      });
  }

  return (
    <div>
      <Navbarside />

      {isLoading && <Loader/>}

      <div className="container-fluid response-cover pb-5">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Fitness Center </h4>
                        &nbsp; &nbsp; &nbsp;
                        <ExportToExcel
                          apiData={data}
                          fileName={fileName}
                          style={{
                            height: "25px",
                            padding: "0 10px",
                            marginBottom: "0%",
                          }}
                        />
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        {/* <table id="example" className="display"></table> */}
                        <Table data={row} columns={column} isFooter={true} />
                        <div className="PaginationContainer">
                          <span className="total-elements">
                            Showing {parseInt(page) * 10 + 1} to{" "}
                            {parseInt(page) * 10 + 10} of {totalCount} entries
                          </span>
                          <Pagination
                            currentPage={parseInt(page) + 1}
                            totalCount={totalCount}
                            itemsPerPage={10}
                            onPageChange={(page) => {
                              fetchData(parseInt(page) - 1);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default FitnessCenter;
