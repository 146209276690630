import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_PATHS } from "../../utils/constants/api.constants";
import { postblog } from "../../store/slices/blog";
import Footer from "../footer";
import Navbarside from "../../component/Navbarside";
import ExpertiseService from "../../services/expertise.service";
import JoditEditor from "jodit-react";
import CircularProgress from '@mui/material/CircularProgress';

function Addblog(e) {
  const editor = useRef(null);
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const [expertiseId, setexpertiseId] = useState("");
  const [mediaLink, setMediaLink] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [blogTitle, setBlogTitle] = useState("");
  const [message, setMessage] = useState("");
  const [expertises, setExpertises] = useState([]);

  const [showLoader, setLoader] = useState(false);

  //   Alert-message

  const [alertmediaLink, setAlertmediaLink] = useState("");
  const [alertdescription, setAlertdescription] = useState("");
  const [alertdate, setAlertdate] = useState("");
  const [alertblogTitle, setAlertblogTitle] = useState("");
  const [alertExpertise, setalertExpertise] = useState("");

  async function upload(e) {
    e.preventDefault();
    let isActive = true;
    let item = {
      mediaLink,
      description,
      date,
      blogTitle,
      expertiseId,
      isActive,
    };

    dispatch(postblog(item))
      .unwrap()
      .then(() => {
        alert("Uploaded successfully");
        // navigate("/Blog");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const Expertises = createAsyncThunk(
    "expertise/get/expertise",
    async (thunkAPI) => {
      try {
        // console.log("consultation")
        const data = await ExpertiseService.getExpertise();
        return { user: data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  const handleCategoryChange = (e) => {
    var value = expertises.filter(function (Expertises) {
      return Expertises.name == e.target.value;
    });
    setexpertiseId(value[0]._id);
  };

  React.useEffect(() => {
    dispatch(Expertises())
      .unwrap()
      .then((data) => {
        setExpertises(data.user.data);
        setexpertiseId(data.user.data[0]._id);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  async function imageUpload(e) {
    setLoader(true)

    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setLoader(false)

    setMediaLink(data.data.location);
    setMessage(data.message);
  }

  const save = (e) => {
    const regtext = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regtext.test(blogTitle)) {
      setAlertblogTitle("");
    } else if (!regtext.test(blogTitle) && blogTitle === "") {
      setAlertblogTitle("Please enter blog title");
      e.preventDefault();
    } else {
      setAlertblogTitle("");
    }

    if (expertiseId == "Select") {
      setalertExpertise("Please select expertise");
      e.preventDefault();
    } else {
      setalertExpertise("");
    }

    if (description === "") {
      setAlertdescription("Please enter blog description");
      e.preventDefault();
    } else {
      setAlertdescription("");
    }

    const regdate =
      /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[-]([0]?[1-9]|[1][0-2])[-]([0-9]{4}|[0-9]{2})$/;
    if (regdate.test(date)) {
      setAlertdate("");
    } else if (!regdate.test(date) && date === "") {
      setAlertdate("Please enter date");
      e.preventDefault();
    } else {
      setAlertdate("");
    }
  };

  const cancel = () => {
    setMediaLink("");
    setDescription("");
    setDate("");
    setBlogTitle("");
    setAlertmediaLink("");
    setAlertdescription("");
    setAlertdate("");
    setAlertblogTitle("");
    setMessage("");
  };
  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        {showLoader ? <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </div> : ''}
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row match-height">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-warning">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Upload Blog
                        </h4>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="px-3">
                        <form className="form" onSubmit={upload}>
                          <div className="row justify-content-md-center">
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Blog Title</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    Placeholder="Enter Blog Title"
                                    value={blogTitle}
                                    onChange={(e) =>
                                      setBlogTitle(e.target.value)
                                    }
                                  />
                                  <p className="alert-message">
                                    {alertblogTitle}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Date</label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    Placeholder=""
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                  />
                                  <p className="alert-message">{alertdate}</p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Blog Image</label>
                                  <input
                                    type="file"
                                    className="form-control-file"
                                    Placeholder=""
                                    name="imageUrl"
                                    onChange={(e) => imageUpload(e)}
                                  />

                                  <p
                                    style={{
                                      color: "green",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {message}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Expertise</label>
                                  <select
                                    className="form-control"
                                    onChange={handleCategoryChange}
                                    required
                                  >
                                    <option value="">Select</option>
                                    {expertises.map((option) => (
                                      <option value={option.expertiseId}>
                                        {option.name}
                                      </option>
                                    ))}
                                  </select>
                                  <p className="alert-message">
                                    {alertExpertise}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-body">
                                <div className="form-group">
                                  <label for="placeTextarea">Description</label>
                                  <JoditEditor
                                    ref={editor}

                                    value={description}
                                    // config={config}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={(newContent) =>
                                      setDescription(newContent)
                                    } // preferred to use only this option to update the content for performance reasons
                                    onChange={(newContent) => { }}
                                  />
                                  <p className="alert-message">
                                    {alertdescription}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-actions center">
                            <button
                              type="submit"
                              className="btn btn-primary wd-100"
                              onClick={save}
                              disabled={showLoader}
                            >
                              <i className="icon-note"></i> Save
                            </button>{" "}
                            &nbsp;
                            <button
                              type="reset"
                              className="btn btn-warning"
                              onClick={cancel}
                            >
                              <i className="icon-trash"></i> Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default Addblog;
