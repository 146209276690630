import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class exercseService {
 
    static getexercise() {
        return axios
            .get(API_PATHS.getexpercise)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }



    static getexercisedetials() {
        let api='';
        //  let id = localStorage.getItem("id"); 
        //  api = id ? API_PATHS.getexpercise + '?_id=' + id : API_PATHS.getexpercise;
         let user = JSON.parse(localStorage.getItem("user"));
         let id = user.data.id;
     api =API_PATHS.getexpercise + '?id=' + id ;
         localStorage.removeItem("id");
         return axios
         .get( api)
             .then((response) => {
                 if (response.data) {
                     // localStorage.setItem("user", JSON.stringify(response.data));
                 }
                 return response.data;
             });
     }


    static postexercises(item) {
        return axios
            .post(API_PATHS.postexpercise,
                item
            )
            .then((response) => {
                if (response.data) {
                    //  localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    
    static editexercise(item) {
        let api = '';
        let id = localStorage.getItem("id");
        api = API_PATHS.putexpercise + '?id=' + id;
        
        localStorage.removeItem("id");
        return axios
          .put(api,item)
          .then((response) => {
            if (response.data) {
            
            }
            return response.data;
          });
      }

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default exercseService;