import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class RecipeService {
    static getRecipe(params) {
        let api = '';
     
    api =API_PATHS.getrecipes;
   
        return axios
            .get(api, {params})
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static uploadRecipe(item) {
        return axios
            .post(API_PATHS.postrecipes,
                item
            )
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static editrecipe(item) {
        let api = '';
        let id = localStorage.getItem("recipeId");
        api = API_PATHS.putrecipes + '?id=' + id;
        localStorage.removeItem("id");
        return axios
          .put(api,item)
          .then((response) => {
            if (response.data) {
            
            }
            return response.data;
          });
      }

      
     static deleteRecipes() {
        let api = '';
        let id = localStorage.getItem("id");
          api = API_PATHS.deleterecipes + '?id=' + id ;
        localStorage.removeItem("id");
        return axios
            .delete(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

      static getRecipeDetails() {
        let api = '';
        let id = localStorage.getItem("recipeId");
          api = API_PATHS.getrecipes + '?id=' + id ;
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }
   
    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default RecipeService;