import React from "react";
import Navbarside from "../component/Navbarside";
import Footer from "./footer";
import { Link } from "react-router-dom";

const Analysis = () => {
  return (
    <div>
      <Navbarside />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                  <Link to="/SalesAnalysis">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-10 pb-10">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-2 card-title mb-0">
                                Sales Analysis
                              </h4>
                            </div>
                            <div className="media-right text-right">
                              {/* <i class="fas fa-heartbeat fa-edit-icon success"></i> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                  <Link to="/AppointmentAnalysis">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-10 pb-10">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-2 card-title mb-0">
                                Appointment Analysis
                              </h4>
                            </div>
                            <div className="media-right text-right">
                              <i className="icon-wallet font-large-1 warning"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                  <Link to="/SessionAnalysis">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-10 pb-10">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-2 card-title mb-0">
                                Session Analysis
                              </h4>
                            </div>
                            <div className="media-right text-right">
                              {/* <i class="far fa-comment warning fa-edit-icon"></i> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                  <Link to="/MembershipAnalysis">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-10 pb-10">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-2 card-title mb-0">
                                Membership Analysis
                              </h4>
                            </div>
                            <div className="media-right text-right">
                              {/* <i class="far fa-comment warning fa-edit-icon"></i> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                  <Link to="/FollowUpAnalysis">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-10 pb-10">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-2 card-title mb-0">
                                Follow Up Analysis
                              </h4>
                            </div>
                            <div className="media-right text-right">
                              {/* <i class="far fa-comment warning fa-edit-icon"></i> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                  <Link to="/EnquiryAnalysis">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-10 pb-10">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-2 card-title mb-0">
                                Enquiry Analysis
                              </h4>
                            </div>
                            <div className="media-right text-right">
                              {/* <i class="far fa-comment warning fa-edit-icon"></i> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                  <Link to="/ConversionAnalysis">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-10 pb-10">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-2 card-title mb-0">
                                Conversion Analysis
                              </h4>
                            </div>
                            <div className="media-right text-right">
                              {/* <i class="far fa-comment warning fa-edit-icon"></i> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Analysis;
