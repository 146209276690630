import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../services/auth.service";
import WmConsultationService from '../../services/wmconsultation.service';


export const initiatefreeConsultation = createAsyncThunk(
  "const/post/const",
  async (item, thunkAPI) => {
    try {
      const data = await WmConsultationService.processEnquiry(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const Consultations = createAsyncThunk(
  "consultation/get/consultation",
  async (thunkAPI) => {
    try {
      const data = await WmConsultationService.getConsultation();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const Weightmanagementconsultation = createAsyncThunk(
  "consultation/get/consultation",
  async (thunkAPI) => {
    try {
      // console.log("consultation")
      const data = await WmConsultationService.getweightConsultation(thunkAPI);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const wmClientconsultation = createAsyncThunk(
  "consultation/get/consultation",
  async (thunkAPI) => {
    try {
      // console.log("consultation")
      const data = await WmConsultationService.gewmclientConsultation();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const wmExpertconsultation = createAsyncThunk(
  "consultation/get/consultation",
  async (thunkAPI) => {
    try {
      // console.log("consultation")
      const data = await WmConsultationService.gewmExpertConsultation();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


export const Weightmanagementsession = createAsyncThunk(
  "consultation/get/session",
  async (thunkAPI) => {
    try {
      // console.log("consultation")
      const data = await WmConsultationService.getweightSession();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const WeightmanagementsessionExpert = createAsyncThunk(
  "consultation/get/session",
  async (thunkAPI) => {
    try {
      // console.log("consultation")
      const data = await WmConsultationService.getweightSessionExpert();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
export const updateconsultation = createAsyncThunk(
  "const/put/const",
  async (item, thunkAPI) => {
    try {
      const data = await WmConsultationService.putconsultation(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


export const getConsultationid = createAsyncThunk(
  "const/put/const",
  async (item, thunkAPI) => {
    try {
      const data = await WmConsultationService.addconsultation();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
  loading: false,
  error: "",
  user: AuthService.getUserDetails() || null,
  isLoggedIn: false,
};

const consultationSlice = createSlice({
  name: "consultation",
  initialState,
  extraReducers: {
    [Consultations.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [Consultations.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [Consultations.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },

    [updateconsultation.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [updateconsultation.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [updateconsultation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },

    [getConsultationid.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [getConsultationid.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [getConsultationid.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },
    // [logout.fulfilled]: (state, action) => {
    //   state.isLoggedIn = false;
    //   state.user = null;
    // },
  },
});

const { reducer } = consultationSlice;
export default reducer;