import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Navbarside from "./Navbarside";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "./footer";
import { postHealthprescription } from "../store/slices/HcConsultation";
import { HcConsultationDetails } from "./../store/slices/HcConsultation";
const AddPrescription = () => {
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const { id } = useParams();
  localStorage.setItem("consultationid", id);

  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [referredBy, setreferredBy] = useState("");
  const [address, setAddress] = useState("");
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [bloodPressure, setbloodPressure] = useState("");
  const [followUpDate, setfollowUpDate] = useState("");
  const [chiefComplaints, setchiefComplaints] = useState("");
  const [chiefFindings, setchiefFindings] = useState("");
  const [notes, setNotes] = useState("");
  const [proceduresConducted, setproceduresConducted] = useState("");
  const [investigations, setinvestigations] = useState("");
  const [knownHistory, setknownHistory] = useState("");
  const [diagnosis, setDiagnosis] = useState("");
  const [advices, setAdvices] = useState("");
  const [userId, setuserId] = useState("");
  const [expertId, setexpertId] = useState("");
  const [medicines, setMedicines] = useState([
    {
      medicineName: "",
      dosage: "",
      description: "",
      duration: "",
      count: "",
    },
  ]);

  // alert message

  const [alertname, setAlertName] = useState("");
  const [alertage, setAlertAge] = useState("");
  const [alertgender, setAlertGender] = useState("");
  const [alertreferredBy, setAlertreferredBy] = useState("");
  const [alertaddress, setAlertAddress] = useState("");
  const [alertweight, setAlertWeight] = useState("");
  const [alertheight, setAlertHeight] = useState("");
  const [alertbloodPressure, setAlertbloodPressure] = useState("");
  const [alertfollowUpDate, setAlertfollowUpDate] = useState("");
  const [alertchiefComplaints, setAlertchiefComplaints] = useState("");
  const [alertchiefFindings, setAlertchiefFindings] = useState("");
  const [alertnotes, setAlertNotes] = useState("");
  const [alertproceduresConducted, setAlertproceduresConducted] = useState("");
  const [alertinvestigations, setAlertinvestigations] = useState("");
  const [alertknownHistory, setAlertknownHistory] = useState("");
  const [alertdiagnosis, setAlertDiagnosis] = useState("");
  const [alertadvices, setAlertAdvices] = useState("");

  React.useEffect(() => {
    dispatch(HcConsultationDetails())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id == id) {
          setuserId(data.user.data[0].userId[0]._id);
          setexpertId(data.user.data[0].expertId[0]._id);
        }
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);
  async function addData(e) {
    e.preventDefault();
    // let expertId = "62ab1dcbf6a310565bf58247";
    // let userId = "628e07c3295cbb2a64996d2d";
    let hcConsultationId = id;
    let reportName = "hcPrescription";
    let item = {
      name,
      age,
      gender,
      referredBy,
      address,
      weight,
      reportName,
      height,
      bloodPressure,
      followUpDate,
      chiefComplaints,
      chiefFindings,
      notes,
      proceduresConducted,
      investigations,
      knownHistory,
      diagnosis,
      advices,
      medicines,
      expertId,
      userId,
      hcConsultationId,
    };
    dispatch(postHealthprescription(item))
      .unwrap()
      .then(() => {
        alert("Prescription uploaded succesfully");
        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const handleAddForms1 = () => {
    let hotel = [...medicines];
    hotel.push({
      destination: "",
      HotelName: "",
      address: "",
      capacityPerRoom: "",
    });
    setMedicines(hotel);
  };

  const handleDeleteForms1 = (e) => {
    if (medicines.length > 1) {
      let hotel = [...medicines];
      hotel.pop();
      setMedicines(hotel);
    }
  };
  const handleRowChange1 = (e, index) => {
    const hotel = [...medicines];
    hotel[index][e.currentTarget.name] = e.currentTarget.value;
    setMedicines(hotel);
    // console.log(hotelDescription);
  };

  const save = (e) => {
    const regname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (regname.test(name)) {
      setAlertName("");
    } else if (!regname.test(name) && name === "") {
      setAlertName("Please enter the patient name");
      e.preventDefault();
    } else {
      setAlertName("");
      // e.preventDefault();
    }

    const regnum1 = /^[1-9][0-9][0-9]?$|^100$/;
    if (regnum1.test(age)) {
      setAlertAge("");
    } else if (!regnum1.test(age) && age === "") {
      setAlertAge("Please enter the age");
      e.preventDefault();
    } else {
      setAlertAge("");
      // e.preventDefault();
    }

    const reggender = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (reggender.test(gender)) {
      setAlertGender("");
    } else if (!reggender.test(gender) && gender === "") {
      setAlertGender("Please enter your gender");
      e.preventDefault();
    } else {
      setAlertGender("");
      // e.preventDefault();
    }

    const regnum2 = /^[1-9][0-9][0-9]?$|^100$/;
    if (regnum2.test(height)) {
      setAlertHeight("");
    } else if (!regnum2.test(height) && height === "") {
      setAlertHeight("Please enter the height");
      e.preventDefault();
    } else {
      setAlertHeight("");
      // e.preventDefault();
    }

    const regnum3 = /^[1-9][0-9][0-9]?$|^100$/;
    if (regnum3.test(weight)) {
      setAlertWeight("");
    } else if (!regnum3.test(weight) && weight === "") {
      setAlertWeight("Please enter the weight");
      e.preventDefault();
    } else {
      setAlertWeight("");
      // e.preventDefault();
    }

    const regtext3 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (regtext3.test(referredBy)) {
      setAlertreferredBy("");
    } else if (!regtext3.test(referredBy) && referredBy === "") {
      setAlertreferredBy("Please enter ");
      e.preventDefault();
    } else {
      setAlertreferredBy("");
      // e.preventDefault();
    }

    const regtext6 = /^[1-9][0-9][0-9]?$|^100$/;
    if (regtext6.test(bloodPressure)) {
      setAlertbloodPressure("");
    } else if (!regtext6.test(bloodPressure) && bloodPressure === "") {
      setAlertbloodPressure("Please enter the BP");
      e.preventDefault();
    } else {
      setAlertbloodPressure("");
      // e.preventDefault();
    }

    const regtext4 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (regtext4.test(chiefComplaints)) {
      setAlertchiefComplaints("");
    } else if (!regtext4.test(chiefComplaints) && chiefComplaints === "") {
      setAlertchiefComplaints("Please enter the chief complaints");
      e.preventDefault();
    } else {
      setAlertchiefComplaints("");
      // e.preventDefault();
    }

    const regtext5 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (regtext5.test(chiefFindings)) {
      setAlertchiefFindings("");
    } else if (!regtext5.test(chiefFindings) && chiefFindings === "") {
      setAlertchiefFindings("Please enter the chief findings");
      e.preventDefault();
    } else {
      setAlertchiefFindings("");
      // e.preventDefault();
    }

    const regtext7 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (regtext7.test(proceduresConducted)) {
      setAlertproceduresConducted("");
    } else if (
      !regtext7.test(proceduresConducted) &&
      proceduresConducted === ""
    ) {
      setAlertproceduresConducted("Please enter the procedures conducted");
      e.preventDefault();
    } else {
      setAlertproceduresConducted("");
      // e.preventDefault();
    }

    const regtext8 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (regtext8.test(investigations)) {
      setAlertinvestigations("");
    } else if (!regtext8.test(investigations) && investigations === "") {
      setAlertinvestigations("Please enter");
      e.preventDefault();
    } else {
      setAlertinvestigations("");
      // e.preventDefault();
    }

    const regtext9 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (regtext9.test(knownHistory)) {
      setAlertknownHistory("");
    } else if (!regtext9.test(knownHistory) && knownHistory === "") {
      setAlertknownHistory("Please enter the known history");
      e.preventDefault();
    } else {
      setAlertknownHistory("");
      // e.preventDefault();
    }

    const regtext10 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (regtext10.test(diagnosis)) {
      setAlertDiagnosis("");
    } else if (!regtext10.test(diagnosis) && diagnosis === "") {
      setAlertDiagnosis("Please enter the diagnosis");
      e.preventDefault();
    } else {
      setAlertDiagnosis("");
      // e.preventDefault();
    }

    const regtext11 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (regtext11.test(advices)) {
      setAlertAdvices("");
    } else if (!regtext11.test(advices) && advices === "") {
      setAlertAdvices("Please enter the advices");
      e.preventDefault();
    } else {
      setAlertAdvices("");
      // e.preventDefault();
    }

    const regtext12 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (regtext12.test(notes)) {
      setAlertNotes("");
    } else if (!regtext12.test(notes) && notes === "") {
      setAlertNotes("Please enter the notes");
      e.preventDefault();
    } else {
      setAlertNotes("");
      // e.preventDefault();
    }

    const regtext13 = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (regtext13.test(address)) {
      setAlertAddress("");
    } else if (!regtext13.test(address) && address === "") {
      setAlertAddress("Please enter the address");
      e.preventDefault();
    } else {
      setAlertAddress("");
      // e.preventDefault();
    }

    const regtext14 = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (regtext14.test(followUpDate)) {
      setAlertfollowUpDate("");
    } else if (!regtext14.test(followUpDate) && followUpDate === "") {
      setAlertfollowUpDate("Please enter the follow up date");
      e.preventDefault();
    } else {
      setAlertfollowUpDate("");
      // e.preventDefault();
    }
  };

  const cancel = () => {
    setName("");
    setAlertName("");
    setAge("");
    setAlertAge("");
    setGender("");
    setreferredBy("");
    setAlertGender("");
    setAlertreferredBy("");
    setAddress("");
    setWeight("");
    setHeight("");
    setAlertAddress("");
    setAlertWeight("");
    setAlertHeight("");
    setbloodPressure("");
    setfollowUpDate("");
    setchiefComplaints("");
    setchiefFindings("");
    setAlertbloodPressure("");
    setAlertfollowUpDate("");
    setAlertchiefComplaints("");
    setAlertchiefFindings("");
    setNotes("");
    setproceduresConducted("");
    setinvestigations("");
    setknownHistory("");
    setDiagnosis("");
    setAdvices("");
    setAlertNotes("");
    setAlertproceduresConducted("");
    setAlertinvestigations("");
    setAlertknownHistory("");
    setAlertDiagnosis("");
    setAlertAdvices("");
  };

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">Add Prescription</h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <form onSubmit={addData}>
                        <div className="row">
                          <div className="col-md-4 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Patient Name
                            </p>
                            <input
                              type="text"
                              placeholder="Patient Name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertname}</p>
                          </div>
                          <div className="col-md-4 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Patient Age
                            </p>
                            <input
                              type="number"
                              placeholder="Age"
                              value={age}
                              onChange={(e) => setAge(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertage}</p>
                          </div>
                          <div className="col-md-4 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Gender
                            </p>
                            <input
                              type="text"
                              placeholder="Gender"
                              value={gender}
                              onChange={(e) => setGender(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertgender}</p>
                          </div>

                          <div className="col-md-4 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Referred By
                            </p>
                            <input
                              type="text"
                              placeholder="Referred By"
                              value={referredBy}
                              onChange={(e) => setreferredBy(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertreferredBy}</p>
                          </div>
                          <div className="col-md-4 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Weight
                            </p>
                            <input
                              type="number"
                              placeholder="Weight"
                              value={weight}
                              onChange={(e) => setWeight(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertweight}</p>
                          </div>
                          <div className="col-md-4 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Height (In cm)
                            </p>
                            <input
                              type="number"
                              placeholder="Height"
                              value={height}
                              onChange={(e) => setHeight(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertheight}</p>
                          </div>
                          <div className="col-md-4 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Blood Pressure
                            </p>
                            <input
                              type="text"
                              placeholder="Blood Pressure"
                              value={bloodPressure}
                              onChange={(e) => setbloodPressure(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">
                              {alertbloodPressure}
                            </p>
                          </div>
                          <div className="col-md-4 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Chief Complaints
                            </p>
                            <textarea
                              rows="5"
                              placeholder=" Chief Complaints"
                              value={chiefComplaints}
                              onChange={(e) =>
                                setchiefComplaints(e.target.value)
                              }
                              className="form-control"
                            />
                            <p className="alert-message">
                              {alertchiefComplaints}
                            </p>
                          </div>
                          <div className="col-md-4 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Chief Findings
                            </p>
                            <textarea
                              rows="5"
                              placeholder="Chief Findings"
                              value={chiefFindings}
                              onChange={(e) => setchiefFindings(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">
                              {alertchiefFindings}
                            </p>
                          </div>
                          <div className="col-md-4 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Procedures Conducted
                            </p>
                            <textarea
                              rows="5"
                              placeholder="Procedures Conducted"
                              value={proceduresConducted}
                              onChange={(e) =>
                                setproceduresConducted(e.target.value)
                              }
                              className="form-control"
                            />
                            <p className="alert-message">
                              {alertproceduresConducted}
                            </p>
                          </div>
                          <div className="col-md-4 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Investigations
                            </p>
                            <textarea
                              rows="5"
                              placeholder="Investigations"
                              value={investigations}
                              onChange={(e) =>
                                setinvestigations(e.target.value)
                              }
                              className="form-control"
                            />
                            <p className="alert-message">
                              {alertinvestigations}
                            </p>
                          </div>
                          <div className="col-md-4 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Known History
                            </p>
                            <textarea
                              rows="5"
                              placeholder="Known History"
                              value={knownHistory}
                              onChange={(e) => setknownHistory(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertknownHistory}</p>
                          </div>
                          <div className="col-md-4 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Diagnosis
                            </p>
                            <textarea
                              rows="5"
                              placeholder=" Diagnosis"
                              value={diagnosis}
                              onChange={(e) => setDiagnosis(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertdiagnosis}</p>
                          </div>
                          <div className="col-md-4 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Advices
                            </p>
                            <textarea
                              rows="5"
                              placeholder="Advices"
                              value={advices}
                              onChange={(e) => setAdvices(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertadvices}</p>
                          </div>

                          <div className="col-md-4 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Comments
                            </p>
                            <textarea
                              rows="5"
                              placeholder="comments"
                              value={notes}
                              onChange={(e) => setNotes(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertnotes}</p>
                          </div>
                          <div className="col-md-4 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Followup Date
                            </p>
                            <input
                              type="date"
                              placeholder=" Followup Date"
                              value={followUpDate}
                              onChange={(e) => setfollowUpDate(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertfollowUpDate}</p>
                          </div>
                          <div className="col-md-4 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Address
                            </p>
                            <textarea
                              rows="5"
                              placeholder="Address"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertaddress}</p>
                          </div>
                          <div className="col-md-4 col-sm-12 pt-2"></div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-md-12">
                            <h2>Medicines</h2>
                          </div>
                          <br />

                          <div
                            className="d-flex justify-content-center"
                            style={{ width: "100%" }}
                          >
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => handleAddForms1(e)}
                            >
                              <i className="fa fa-plus-circle" />
                            </button>
                            &nbsp; &nbsp;
                            <button
                              type="button"
                              className="btn btn-warning"
                              onClick={(e) => handleDeleteForms1(e)}
                            >
                              <i className="fa fa-minus-circle" />
                            </button>
                          </div>

                          <br />
                          {medicines.map((hotel, index) => (
                            <div key={index} className="form-group row">
                              <div className="col-md-3">
                                <label for="eventInput5">Medicine Name</label>
                                <input
                                  onChange={(e) => handleRowChange1(e, index)}
                                  className="form-control"
                                  placeholder="Medicine Name"
                                  name="medicineName"
                                  type="text"
                                  value={hotel.medicineName}
                                  required
                                />
                              </div>
                              <div className="col-md-2">
                                <label for="eventInput5">Dosage</label>
                                <input
                                  onChange={(e) => handleRowChange1(e, index)}
                                  className="form-control"
                                  placeholder="Dosage"
                                  name="dosage"
                                  type="text"
                                  value={hotel.HotelName}
                                  required
                                />
                              </div>
                              <div className="col-md-3">
                                <label for="eventInput5">Description</label>
                                <input
                                  onChange={(e) => handleRowChange1(e, index)}
                                  className="form-control"
                                  placeholder="Description"
                                  name="description"
                                  type="text"
                                  required
                                  value={hotel.Duration}
                                />
                              </div>
                              <div className="col-md-2">
                                <label for="eventInput5">Duration</label>
                                <input
                                  onChange={(e) => handleRowChange1(e, index)}
                                  className="form-control"
                                  placeholder="Duration"
                                  name="duration"
                                  type="number"
                                  value={hotel.capacityPerRoom}
                                  required
                                />
                              </div>
                              <div className="col-md-2">
                                <label for="eventInput5">Count</label>
                                <input
                                  onChange={(e) => handleRowChange1(e, index)}
                                  className="form-control"
                                  placeholder="Count"
                                  name="count"
                                  type="number"
                                  value={hotel.capacityPerRoom}
                                  required
                                />
                              </div>
                            </div>
                          ))}

                          <div
                            className="d-flex justify-content-center pt-3"
                            style={{ width: "100%" }}
                          >
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={save}
                            >
                              Submit
                            </button>
                            &nbsp; &nbsp; &nbsp;
                            <button
                              type="reset"
                              className="btn btn-warning"
                              onClick={cancel}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default AddPrescription;
