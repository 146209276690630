import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Navbarside from "../Navbarside";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../footer";
import { initiatefreeConsultation } from "../../store/slices/Wmconsultation";
import { getWmExperts } from "../../store/slices/hcexpert";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { editEnquiry, enquiryDetails } from "../../store/slices/enquiry";
import { Card, CardHeader, CardContent } from '@mui/material';
import moment from 'moment';

const ProcessEnquiry = () => {
  const location = useLocation();
  const [startTime, setstartTime] = useState("");
  const [startDates, setstartDates] = useState("");
  const [durationInMinutes, setdurationInMinutes] = useState("");
  const [consultationCharge, setConsultationCharge] = useState("");
  const [experts, setExperts] = useState([]);
  const [expertId, setexpertId] = useState("");
  const [expertfirstname, setExpertFirstname] = useState("");
  const [expertlastname, setExpertLastname] = useState("");
  const [allExpertise, setExpertise] = useState(null);
  const [selectedExpertise, setSelectedExpertise] = useState(null)
  const [selectedSubExpertise, setSelectedSubExpertise] = useState(null)
  const [category, setCategory] = useState(null)
  const [ticketNumber, setTicketNumber] = useState(null)
  const [enquiryData, setEnquiryData] = useState(null)

  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const cancel = () => {
    navigate(-1);
  };

  const { id } = useParams();
  localStorage.setItem("enquiryId", id);
  useEffect(() => {
    dispatch(enquiryDetails()).unwrap().then((data) => {
      if (data.user.data[0]._id == id) {
        setName(data.user.data[0].name);
        setEmail(data.user.data[0].email);
      }
    })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const ticketNumber = searchParams.get('ticketNumber');
    const category = searchParams.get('category');
    setTicketNumber(ticketNumber)
    setCategory(category)
    localStorage.setItem('ticketNumber', ticketNumber)
    localStorage.setItem('category', category)

    dispatch(getWmExperts()).unwrap().then((data) => {
      setExperts(data.experts.data);
      setExpertise(data.allExpertise)
      setEnquiryData(data.enquiryData.data[0])
      setSelectedExpertise((data.allExpertise.data.find(x => x.name === category))._id)
    }).catch(({ message }) => {
      // alert(message);
    });


  }, [dispatch, location.search]);

  const showErrorToast = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    });
  };
  async function upload(e) {
    e.preventDefault();
    if (!startTime || !startDates || !durationInMinutes) {
      showErrorToast('Please fill in all the fields');
      return;
    }
    console.log({ startDates })
    const startDate = moment(startDates, 'YYYY-MM-DD').format('MM/DD/YYYY');
    let item = {
      time: startTime,
      date: startDate,
      durationInMinutes,
      consultationCharge,
      expertId,
      expertiseId: selectedExpertise,
      subExpertiseId: selectedSubExpertise,
      ticketNumber
    };
    console.log(item, 'item')

    dispatch(initiatefreeConsultation(item)).unwrap().then((res) => {
      // console.log(res.data)
      alert("Expert has been assigned and payment link is sent to the customer");
    }).catch(({ message }) => {
      alert(message);
    });
  }
  async function updateEnquiry(e) {
    e.preventDefault();
    let item = {
      id,
      status,
      email,
      name,
    };

    dispatch(editEnquiry(item))
      .unwrap()
      .then(() => {
        alert("Enquiry status updated");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }
  const handleExpertChange = (e) => {
    var value = experts.filter(function (expert) {
      return expert.firstName == e.target.value;
    });
    setexpertId(value[0]._id);
    setExpertFirstname(value[0].firstName);
    // setExpertLastname(value[0].lastName);
  };
  const handleExpertiseChange = e => setSelectedExpertise(e.target.value)
  const handleSubExpertiseChange = e => setSelectedSubExpertise(e.target.value)

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="card">
                <div className="card-header">
                  <div className="card-title-wrap bar-danger d-flex align-items-center">
                    <h4 className="card-title">                Ticket Number : {
                      enquiryData ? enquiryData.ticketNumber : ''
                    }</h4>
                  </div>
                </div>

                <section className="form-section">
                  <form onSubmit={updateEnquiry}>
                    <div className="row">
                      <div className="col-md-4">


                        <select
                          name="health"
                          id="health"
                          className="form-control"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value="" className="" disabled>
                            {
                              enquiryData
                                ? enquiryData.status
                                : 'Update enquiry status'
                            }
                          </option>
                          <option>Interested</option>
                          <option>Not Interested</option>
                          <option>Invalid Enquiry</option>
                          <option>Other</option>
                        </select>
                      </div>

                      <div className="col-md-6">

                        <button type="submit" className="btn btn-primary">
                          Update Enquiry Status
                        </button>
                        &nbsp; &nbsp; &nbsp;
                      </div>
                    </div>

                  </form>
                </section>

                <Card variant='outlined'>
                  <CardHeader title="Enquiry Details" />
                  <CardContent>
                    {enquiryData
                      ? Object.keys(enquiryData).map((x, i) => {
                        if (
                          x != "_id"
                          && x != "__v"
                          && x != "source"
                          && x != "flow"
                          && x != "updated_at"
                          && x != "ticketNumber"
                          && x != "status"
                        ) {
                          return <p> {x} : {enquiryData[x]}</p>
                        }
                        else return ''
                      })
                      : ''
                    }
                  </CardContent>
                </Card>
              </div>
              <div className="row">
                <div className="col-12">

                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">Create Consultation and Assign Expert</h4>
                      </div>
                    </div>

                    <section className="form-section">

                      <form onSubmit={upload}>
                        <div className="row">
                          <div className="col-md-6 col-sm-12 pt-2">
                            <label>Start Time</label>
                            <input
                              type="time"
                              step="1"
                              value={startTime}
                              onChange={(e) => setstartTime(e.target.value)}
                              className="form-control"
                            />
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <label>Start Date</label>
                            <input
                              type="date"
                              value={startDates}
                              onChange={(e) => setstartDates(e.target.value)}
                              className="form-control"
                            />
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <label>Duration In Minutes</label>
                            <input
                              type="number"
                              placeholder="Duration"
                              min="1"
                              value={durationInMinutes}
                              onChange={(e) =>
                                setdurationInMinutes(e.target.value)
                              }
                              className="form-control"
                            />
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <label>Expertise</label>
                            <select
                              className="form-control"
                              onChange={handleExpertiseChange}
                            >
                              <option value="" disabled>Select Expertise</option>
                              {allExpertise && allExpertise.data && allExpertise.data.length
                                ? allExpertise.data.map(option => {
                                  if (!option.parentExpertiseId || option.parentExpertiseId == null) {
                                    return (
                                      <option
                                        value={option._id}
                                        selected={category && category === option.name
                                          ? true
                                          : false}
                                      >
                                        {option.name}
                                      </option>
                                    )
                                  }
                                })
                                : ''
                              }
                            </select>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <label>Sub - Expertise</label>
                            <select
                              className="form-control"
                              onChange={handleSubExpertiseChange}
                            >
                              <option value="">Select Sub - Expertise</option>
                              {selectedExpertise
                                ? allExpertise.data.map(expertise => {
                                  if (expertise.parentExpertiseId == selectedExpertise) {
                                    return (
                                      <option value={expertise._id}>
                                        {expertise.name}
                                      </option>
                                    )
                                  }
                                })
                                : ''
                              }
                            </select>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <label>Expert</label>
                            <select
                              className="form-control"
                              onChange={handleExpertChange}
                            >
                              <option value="">Select Expert</option>
                              {/* {selectedExpertise
                                ? Experts.map(expert => {
                                  if (expert.expertiseId == selectedExpertise) {
                                    return (
                                      <option value={expert.expertId}>
                                        {expert.firstName}
                                      </option>
                                    )
                                  }
                                })
                                : ''
                              } */}
                              {experts.map((option) => (
                                <option value={option.expertId}>
                                  {option.firstName}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <label>Consultation Charge</label>
                            <input
                              type="number"
                              placeholder="Amount in INR"
                              value={consultationCharge}
                              onChange={(e) =>
                                setConsultationCharge(e.target.value)
                              }
                              className="form-control"
                            />
                          </div>
                        </div>


                        <div className="d-flex justify-content-center pt-3">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                          &nbsp; &nbsp; &nbsp;
                          <button
                            type="reset"
                            className="btn btn-warning"
                            onClick={cancel}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </body>
  );
};

export default ProcessEnquiry;
