import React, { useState } from "react";
import Footer from "./footer";
import { lifestyleData } from "../store/slices/forms";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "../component/Navbarside";
import { useParams } from "react-router-dom";

const LifeStyle = () => {
  const [lifestyle, setLifestyle] = useState([]);
  const { id } = useParams();
  localStorage.setItem("userId", id);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(lifestyleData())
      .unwrap()
      .then((data) => {
        setLifestyle(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid response-cover pb-5">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Lifestyle Form</h4>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th className="wd-15p">Question</th>
                              <th>Answer</th>
                            </tr>
                          </thead>
                          {lifestyle.map((lifeVal, index) => (
                            <tbody>
                              {lifeVal.qna.map((QNAVal, index) => (
                                <tr key={index}>
                                  <td>{QNAVal.question} </td>
                                  <td>{QNAVal.answer[0]}</td>
                                </tr>
                              ))}
                            </tbody>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default LifeStyle;
