import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbarside from "./Navbarside";
import { useDispatch, useSelector } from "react-redux";
import { getapprovelab } from "../store/slices/labapprove";
import { useNavigate, useParams } from "react-router-dom";
import { postapprovelab } from "../store/slices/labapprove";

import Footer from "./footer";

const LabOnboardRequest = () => {
  const [approve, setApprove] = useState([]);
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [dataCount, setdataCount] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  React.useEffect(() => {
    getApprovalLab()
 
  }, [dispatch]);

  const getApprovalLab = ()=>{
    setIsLoading(true)
    dispatch(getapprovelab('enquiryFor=labVendor'))
    .unwrap()
    .then((data) => {
    setIsLoading(false)
      setApprove(data?.user?.data);
    })
    .catch(({ message }) => {
    setIsLoading(false)
      // alert(message);
    });
  }

  async function disableApprove(ids) {
    let status = "rejected";
    let isApproved = false;
    let id = ids;
    let item = { id, status, isApproved };
    setIsLoading(true)
    dispatch(postapprovelab(item))
    setIsLoading(false)
      .unwrap()
      .then(() => {
        alert("Request rejected succesfully");
        getApprovalLab()
        // window.location.reload(false);
      })
      .catch(({ message }) => {
    setIsLoading(false)
        alert(message);
      });

  }

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Lab Onboard Request</h4>
                        &nbsp; &nbsp; &nbsp;
                        {/* <Link to="" className="btn btn-warning col-white">
                          Add
                        </Link> */}
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Lab Name</th>
                              <th>Address</th>
                              <th>Email</th>
                              <th>Mobile Number</th>
                              <th>Service Type</th>
                              <th>Agreement</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {approve.map((LabVal, index) => (
                              <tr key={index}>
                                <td>{LabVal?.name}</td>
                                <td>{LabVal?.address}</td>
                                <td>{LabVal?.email}</td>
                                <td>{LabVal?.contactNumber}</td>
                                <td>{LabVal?.serviceType?.[0]}</td>
                                <td>{LabVal?.status?.toUpperCase()}</td>
                                <td>
                                  <Link
                                    to={LabVal.agreement}
                                    
                                    style={{
                                      height: "25px",
                                      padding: "0 10px",
                                    }}
                                    className="btn btn-primary col-white"
                                  >
                                    View
                                  </Link>
                                </td>
                                <td>
                                  <button
                                    className="btn btn-primary"
                                    disabled = {LabVal?.status == 'approved'}
                                    style={{
                                      height: "25px",
                                      padding: "0 10px",
                                    }}
                                    // to={`/AddLabapprove/${
                                    //   LabVal._id ? LabVal._id : null
                                    // }`}
                                    onClick={()=>{navigate(`/AddLabapprove/${
                                      LabVal._id ? LabVal._id : null
                                    }`)}}
                                  >
                                    Approve
                                  </button>
                                  &nbsp;
                                  <button
                                    className="btn btn-warning"
                                    disabled = {LabVal?.status=='approved' || LabVal?.status=='rejected' }
                                    style={{
                                      height: "25px",
                                      padding: "0 10px",
                                    }}
                                    onClick={() => disableApprove(LabVal._id)}
                                  >
                                    Reject
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LabOnboardRequest;