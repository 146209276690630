import React from "react";
import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import Navbarside from "./Navbarside";
import { getbodypartgroup, DeleteBodypartgroup } from "../store/slices/body";
import Footer from "./footer";
import $ from "jquery";

import { Link, useNavigate } from "react-router-dom";

const Bodypartgroup = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getbodypartgroup())
      .unwrap()
      .then((data) => {
        $("#example").DataTable({
          data: data.user.data.map((e, index) => {
            return { index, ...e };
          }),
          columns: [
            {
              title: "#",
              render: (data, type, row) => {
                return ++row.index;
              },
            },
            {
              data: "name",
              title: "Name",
            },
            {
              title: "Image",
              render: (data, type, row) => {
                return `<img
                src=${row.mediaLink}
                alt="mediaLink"
                style="border-radius: 10px; height: 60px; width: 60px"
                }}
              />`;
              },
            },
            {
              title: "Action",
              render: (data, type, row) => {
                return `<a href="/Fitness/Bodypartgroup/Editbodypartgroup/${
                  row._id ? row._id : null
                }"
                  class="btn btn-primary"
                  style="height: 25px; padding: 0 10px"
                >
                Edit
                </a>
                <button
                data-id="${row._id}"
                class="btn btn-warning delete"
                style="height: 25px; padding: 0 10px"
              >
                Delete
              </button>`;
              },
            },
          ],
          destroy: true,
        });
      })
      .catch(({ message }) => {
        // alert(message);
      });

      $("#example").on("click", ".delete", function (e) {
        e.stopPropagation();
        deleteData($(this).data("id"));
      });
  }, [dispatch]);

  const Delete = async function (id) {
    localStorage.setItem("id", id);
    dispatch(DeleteBodypartgroup())
      .unwrap()
      .then(() => {
        // alert("Uploaded succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const deleteData = (id) => {
    confirmAlert({
      title: "Want to delete?",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => Delete(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-wrap bar-success">
                          <h4 className="card-title">
                            Body Part Group &nbsp; &nbsp;
                            <Link
                              to="/Conditions/Bodypartgroup/Addbodypartgroup"
                              className="btn btn-primary"
                            >
                              <i className="fa fa-plus"></i> Add
                            </Link>
                          </h4>
                        </div>
                      </div>
                      <div className="card-body collapse show">
                        <div className="card-block card-dashboard table-responsive">
                          <table id="example" className="display"></table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
};

export default Bodypartgroup;
