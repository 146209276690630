import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { register } from "../store/slices/auth";
import { VscEye } from "react-icons/vsc";
import logo from "../img/healthonify-logo.png";

function RegisterEmploye(e) {
  const [passwordShown, setPasswordShown] = useState(false);

  const [firstName, setfirstName] = useState("");
  const [email, setEmail] = useState("");
  const [lastName, setlastName] = useState("");
  const [mobileNo, setmobileNo] = useState("");

  const [password, setPassword] = useState("");

  //Alert Message Start
  const [alertfirstname, setAlertfirstname] = useState("");
  const [alertemail, setAlertemail] = useState("");
  const [alertlastName, setAlertlastName] = useState("");
  const [alertmobileNo, setAlertmobileNo] = useState("");
  const [alertpassword, setAlertpassword] = useState("");

  //Alert Message End
  const navigate = useNavigate("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const [alertfn, setAlertfn] = useState("");
  const [alertln, setAlertln] = useState("");
  const [alertmes, setAlertmes] = useState("");
  const [alertmail, setAlertmail] = useState("");
  const [alertrole, setAlertrole] = useState("");
  const [alertpass, setAlertpass] = useState("");
  let roles = "corporateEmployee";
  async function signUp(e) {
    e.preventDefault();
    let item = { firstName, email, mobileNo, password, lastName, roles };

    dispatch(register(item))
      .unwrap()
      .then(() => {
        navigate("/VerifyOtpEmploye");
      })
      .catch(({ message }) => {
        alert(message);
      });

    const fName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (fName.test(firstName)) {
      setAlertfn("");
    } else if (!fName.test(firstName) && firstName === "") {
      setAlertfn("Please enter your first name");
    } else {
      setAlertfn("Please enter a valid first name");
    }

    const role = /^([a-zA-Z])$/;
    if (role.test(roles)) {
      setAlertrole("");
    } else if (!role.test(roles) && roles === "") {
      setAlertrole("Please select your role");
    } else {
      setAlertrole("");
    }

    const passReg = /^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,10}$/;
    if (passReg.test(password)) {
      setAlertpass("");
    } else if (!passReg.test(password) && password === "") {
      setAlertpass("Please enter your password");
    } else {
      setAlertpass(
        "Password must be min 6 characters, should contain upper case,lower case, numeric values"
      );
    }

    const lName = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (lName.test(lastName)) {
      setAlertln("");
    } else if (!lName.test(lastName) && lastName === "") {
      setAlertln("Please enter your last name");
    } else {
      setAlertln("Please enter a valid last name");
    }

    const mobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (mobile.test(mobileNo)) {
      setAlertmes("");
    } else if (!mobile.test(mobileNo) && mobileNo === "") {
      setAlertmes("Please fill your mobile number");
    } else {
      setAlertmes("Please enter a valid mobile number");
    }

    const textvil = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (textvil.test(email)) {
      setAlertmail("");
    } else if (!textvil.test(email) && email === "") {
      setAlertmail("Please enter your email");
    } else {
      setAlertmail("Please enter a valid email");
    }
  }

  const clear = () => {
    setAlertfirstname("");
    setAlertemail("");
    setAlertlastName("");
    setAlertmobileNo("");
    setAlertpassword("");
    setAlertfn("");
    setAlertln("");
    setAlertmes("");
    setAlertmail("");
    setAlertrole("");
    setAlertpass("");
    setfirstName("");
    setEmail("");
    setlastName("");
    setmobileNo("");

    setPassword("");
  };

  return (
    <div>
      <section id="login">
        <div className="container-fluid">
          <div className="row full-height-vh">
            <div className="col-12 d-flex align-items-center overflow-auto justify-content-center login-bg-image">
              <div className="card px-4 py-2 box-shadow-2 width-800">
                <div className="d-flex justify-content-center">
                  <img src={logo} alt="logo" className="logo-form" />
                </div>
                {/* <div className="card-body">
                  <div className="card-block"> */}
                <form onSubmit={signUp}>
                  <h2
                    align="center"
                    style={{ color: "black", fontWeight: "500" }}
                  >
                    Employe Registration
                  </h2>
                  <br />
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control form-control-lg text-black-ph"
                        name="firstName"
                        onChange={(e) => setfirstName(e.target.value)}
                        id="inputFirstname"
                        value={firstName}
                        placeholder="First Name"
                      />
                      <p className="alert-message">{alertfn}</p>
                    </div>

                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control form-control-lg text-black-ph"
                        name="lastName"
                        onChange={(e) => setlastName(e.target.value)}
                        id="inputLastname"
                        value={lastName}
                        placeholder="Last Name"
                      />
                      <p className="alert-message">{alertln}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <input
                        maxlength="10"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        type="text"
                        className="form-control form-control-lg text-black-ph"
                        name="inputmobileNo"
                        id="inputmobileNo"
                        placeholder="Mobile No"
                        value={mobileNo}
                        onChange={(e) => setmobileNo(e.target.value)}
                      />
                      <p className="alert-message">{alertmes}</p>
                    </div>

                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control form-control-lg text-black-ph"
                        name="inputEmail"
                        id="inputEmail"
                        value={email}
                        placeholder="Email Address"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <p className="alert-message">{alertmail}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg text-black-ph"
                        name="inputPass"
                        id="inputPass"
                        type={passwordShown ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                      />
                      <VscEye className="eye_icon" onClick={togglePassword} />
                      <p className="alert-message">{alertpass}</p>
                    </div>

                    {/* <div className="col-md-6">
                      <select
                        className="form-control form-control-lg text-black-ph"
                        onChange={(e) => setRoles(e.target.value)}
                      >
                        <option value="">Select Role</option>
                        <option value="travelAgent">Travel Agent</option>
                        <option value="hotelAdmin">Hotel</option>
                      </select>
                      <p className="alert-message">{alertrole}</p>
                    </div> */}
                  </div>
                  {loading ? (
                    <Loader active inline />
                  ) : (
                    <>
                      {/* <div className="row"> */}
                      <div className=" d-flex justify-content-center text-center col-md-12">
                        <button
                          type="submit"
                          className="btn btn-primary px-4 py-2  white font-small-4 box-shadow-2 border-0"
                        >
                          Register
                        </button>{" "}
                        &nbsp; &nbsp;
                        <button
                          type="reset"
                          onClick={clear}
                          className="btn btn-danger px-4 py-2 text-uppercase white font-small-4 box-shadow-2 border-0"
                        >
                          Cancel
                        </button>
                      </div>
                      {/* </div> */}
                    </>
                  )}
                </form>
                {/* </div>
                </div> */}
                <div className="card-footer grey darken-1">
                  <div className="text-center mb-1">
                    Already Have Account?
                    <Link to="/">
                      <b>Login</b>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default RegisterEmploye;
