import React, { useState } from "react";
import Navbarside from "./Navbarside";
import { useDispatch, useSelector } from "react-redux";
import { expertDetail } from "../store/slices/auth";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "./footer";
import ExpertiseService from "./../services/expertise.service";

const Expertdetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  localStorage.setItem("expertId", id);
  const [details, setDetails] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [expertiseId, setExpertiseId] = useState("");
  // const [expertise, setExpertise] = useState([]);
  const [topExpertise, settopExpertise] = useState("");
  const [certificate, setCertificate] = useState([]);
  const [availability, setAvailability] = useState([]);
  const [isAdminApproved, setisAdminApproved] = useState();
  const [isVerified, setisVerified] = useState("");
  const [dob, setDob] = useState("");
  React.useEffect(() => {
    dispatch(expertDetail())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id == id) {
          setfirstName(data.user.data[0].firstName);
          setlastName(data.user.data[0].lastName);
          setmobileNo(data.user.data[0].mobileNo);
          setEmail(data.user.data[0].email);
          setExpertiseId(data.user.data[0].expertiseId[0].id);
        }
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="head d-flex justify-content-between align-items-center mb-3">
                    <h3>Expert Details</h3>

                    <div className="flag">
                      {isAdminApproved === false && (
                        <p className="bg-warning"> Approval Pending </p>
                      )}

                      {isAdminApproved === true && (
                        <p className="bg-success"> Approved </p>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div
                        className="bg-white padding-cover"
                        style={{ borderRadius: "10px" }}
                      >
                        <div className="d-flex justify-content-between">
                          <ul
                            className="main-strings"
                            style={{ padding: "0%", margin: "0%" }}
                          >
                            <li>
                              Name: {firstName} {lastName}
                            </li>
                            <li>Mobile No.: {mobileNo}</li>
                            <li>Email: {email}</li>
                            <li>Expertise: {expertiseId}</li>
                          </ul>

                          <ul
                            className="strings-identifire"
                            style={{ padding: "0%", margin: "0%" }}
                          >
                            <li></li>
                            <li></li>
                            <li></li>
                            {/* <li>{details.expertise[0].name}</li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Expertdetails;
