import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class labaproveService {
  
    

  static getlabsapproves(query) {
    let api = '';
    api =API_PATHS.getLabOnBoardRequestNew;
    if(query?.length>0){
        api =`${api}?${query}`
    }
    console.log("apicheck",api, query)
    
    return axios
        .get(api)
        .then((response) => {
            if (response.data) {
                // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
}

 

static addlabapprove(item) {
    return axios
        .post(API_PATHS.approveLabNew,
            item
        )
        .then((response) => {
            if (response.data) {
                // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
}


static Approvelabs(item) {
    let api='';
    api = API_PATHS.postlabapprove;
    return axios
      .post(api,item)
      .then((response) => {
        if (response.data) {
        
        }
        return response.data;
      });
  }

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default labaproveService