import axios from "axios";
import { API_PATHS, API_BASE_URL } from "../utils/constants/api.constants";

class ConsultationService {
  static getConsultation(item) {
    let api = '';
    let user = JSON.parse(localStorage.getItem("user"));
    let id = localStorage.getItem("id");
    let role = user.data.roles[0];
    if (role === 'ROLE_SUPERADMIN') {
      api = id ? API_PATHS.getConsultation + '?_id=' + id : API_PATHS.getConsultation;
    } else if (role === 'ROLE_EXPERT') {
      api = id ? API_PATHS.getConsultation + '?_id=' + id + '&expertId=' + user.data.id : API_PATHS.getConsultation + '?expertId=' + user.data.id;
    }
    localStorage.removeItem("id");
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static fetchConsultation(params) {
    return axios.get(`${API_BASE_URL}fetch/consultation`, {
      params
    })
  }

  static getConsultationDetails() {
    let api = '';
    let id = localStorage.getItem("consultationid");
    api = API_BASE_URL.getConsultation + '?id=' + id;
    localStorage.removeItem("consultationid");
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static addconsultation() {
    let api = '';
    // let user = JSON.parse(localStorage.getItem("user"));
    let id = localStorage.getItem("id");
    api = id ? API_PATHS.getConsultation + '?id=' + id : API_PATHS.getConsultation;
    localStorage.removeItem("id");
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static putConsultation(item) {
    return axios
      .put(API_PATHS.putConsultation,
        item
      )
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }


  static addHcPrescription() {
    let api = '';
    // let user = JSON.parse(localStorage.getItem("user"));
    let id = localStorage.getItem("id");
    api = id ? API_PATHS.getConsultation + '?id=' + id : API_PATHS.getConsultation;
    localStorage.removeItem("id");
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }
  static removeUserDetails() {
    localStorage.removeItem("user");
  }

  static getUserDetails() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default ConsultationService;