import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class PlaylistServices {

    static getcategoryplaylists() {
        let api = '';
        let id = localStorage.getItem("id");
          api = id ? API_PATHS.getplaylist + '?_id=' + id : API_PATHS.getplaylist;
        
        localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    
    static postplaylists(item) {
        return axios
            .post(API_PATHS.postplaylist,
                item
            )
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default PlaylistServices;