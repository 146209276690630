import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "../../Navbarside";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Footer from "../../footer";
import { API_BASE_URL_HC, API_PATHS } from "../../../utils/constants/api.constants";
import { post } from "axios";
import CircularProgress from '@mui/material/CircularProgress';


const AddContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const goBack = () => navigate(-1);

  const searchParams = new URLSearchParams(location.search);
  const playlistId = searchParams.get('playlistId');
  const playlistName = searchParams.get('playlistName');

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [mediaLink, setmediaLink] = useState("");
  const [format, setFormat] = useState();
  const [thumbnail, setThumbnail] = useState('');

  const [contentType, setContentType] = useState('videoLink');
  const [showLoader, setLoader] = useState(false);

  async function addData(e) {
    try {
      e.preventDefault();
      let item = {
        title,
        description,
        mediaLink,
        format,
        thumbnail,
        playlistId,
      }
      let checked = checkValues(item)
      if (!checked.status) throw new Error(checked.key + ' is required')

      post(API_BASE_URL_HC + '/store/playlistContent', {
        title,
        description,
        mediaLink,
        format,
        thumbnail,
        playlistId,
      }).then(res => {
        if (res.data.status == 1) {
          alert('Added successfully')
          navigate(-1);
        } else {
          alert(res.data.message)
        }
      }).catch(err => alert(err.message))
    } catch (e) {
      alert(e.message)
    }
  }
  async function imageUpload(e, inputName) {
    try {
      setLoader(true)
      e.preventDefault();
      var formdata = new FormData();
      formdata.append("file", e.target.files[0]);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      let response = await fetch(API_PATHS.uploadImage, requestOptions);
      let data = await response.json();
      setLoader(false)
      if (inputName == 'thumbnail') setThumbnail(data.data.location);
      else setmediaLink(data.data.location)
    } catch (e) {
      alert(e.message)
    }
  }
  const handleSelectChange = (e) => setContentType(e.target.value)
  function checkValues(json) {
    for (let key in json) {
      if (!json[key]) {
        return { status: false, key };
      }
    }
    return { status: true };
  }
  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />
      <div className="container-fluid pb-5 response-cover">
        {showLoader ? <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </div> : ''}
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">
                          Add Content for {playlistName}
                        </h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <form onSubmit={addData}>
                        <div className="row">
                          <div className="col-md-8 pt-2 pb-3">
                            <span className="label">Title</span>
                            <input
                              type="text"
                              placeholder="Enter content name"
                              className="form-control"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />

                          </div>
                          <div className="col-md-4 pt-2 pb-3">
                            <span className="label">Format</span>
                            <select
                              className="form-control"
                              onChange={(e) => setFormat(e.target.value)}
                            >
                              <option value="">Select</option>
                              <option>Audio</option>
                              <option>Video</option>
                              <option>Pdf file</option>
                            </select>

                          </div>

                          <div className="col-md-12 pt-2 pb-3">
                            <span className="label">Description</span>
                            <textarea
                              placeholder="Enter description"
                              className="form-control"
                              value={description}
                              rows="5"
                              onChange={(e) => setDescription(e.target.value)}
                            />
                          </div>
                          <div className="col-md-4 pt-2 pb-3">
                            <span className="label">Thumbnail</span>
                            <input
                              type="file"
                              className="form-control"
                              Placeholder="Enter content link"
                              name="thumbnail"
                              onChange={(e) => imageUpload(e, 'thumbnail')}
                            />
                          </div>

                          <div className="col-md-4 pt-2 pb-3">
                            <span className="label">Content type</span>
                            <select
                              className="form-control"
                              onChange={handleSelectChange}
                              required
                            >
                              <option value="videoLink" selected>Video Link</option>
                              <option value="fileUpload">File Upload</option>
                            </select>
                          </div>


                          <div className="col-md-8 pt-2 pb-3">
                            <span className="label">Content </span>
                            {contentType == 'fileUpload' ?
                              <input
                                type="file"
                                className="form-control"
                                Placeholder="upload file"
                                name="mediaLink"
                                onChange={(e) => imageUpload(e, 'mediaLink')}
                              />
                              : <input
                                type="text"
                                className="form-control"
                                Placeholder="Enter content link"
                                name="format"
                                onChange={(e) => setmediaLink(e.target.value)}
                              />
                            }


                          </div>
                          <div className="col-md-12" align="center">
                            <br />
                            <button
                              type="submit"
                              className={`btn btn-primary ${thumbnail && mediaLink ? '' : 'btn-disabled'}`}
                              style={{ width: "150px" }}
                              disabled={!thumbnail || !mediaLink}
                            // onClick={save}
                            >
                              Submit
                            </button>
                            &nbsp;
                            <button
                              type="reset"
                              onClick={goBack}
                              className="btn btn-danger"
                              style={{ width: "150px" }}
                            >
                              Back
                            </button>
                          </div>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default AddContent;
