import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class labcategoryService {
    static getlab() {
        let api = '';
        let id = localStorage.getItem("id");
        api = id ? API_PATHS.getlabtestcategory + '?_id=' + id : API_PATHS.getlabtestcategory;
        
        localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }



      static postlabvendors(item) {
        return axios
            .post(API_PATHS.postlabtestcategory,
                item
            )
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static editvendor(item) {
        let api = '';
        let id = localStorage.getItem("id");
        api = API_PATHS.editlabtestcategory + '?id=' + id;
        
        localStorage.removeItem("id");
        return axios
          .put(api,item)
          .then((response) => {
            if (response.data) {
            
            }
            return response.data;
          });
      }

      static enablelabs() {
        let api='';
        let item = JSON.parse(localStorage.getItem("item"));
        let id = localStorage.getItem("id");
        console.log(item)
        api = API_PATHS.editlabvendorcategory + '?id=' + id;
        return axios
          .put(api,item)
          .then((response) => {
            if (response.data) {
            
            }
            return response.data;
          });
      }

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default labcategoryService