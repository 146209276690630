import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class AuthService {
    static login({ mobileNo, password }) {
        return axios
            .post(API_PATHS.login, {
                mobileNo,
                password,
            })
            .then((response) => {
                if (response.data) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static register(item) {
        return axios
            .post(API_PATHS.register,
                item
            )
            .then((response) => {
                if (response.data) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }


    static ForgotPassword(item) {
        return axios
            .post(API_PATHS.postforgotpass,
                item
            )
            .then((response) => {
                if (response.data) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static UpdatePassword(item) {
        return axios
            .post(API_PATHS.postupdatepassword,
                item
            )
            .then((response) => {
                if (response.data) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static UpdatePasswordemployee(item) {
        return axios
            .post(API_PATHS.postupdatepassword,
                item
            )
            .then((response) => {
                if (response.data) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static updateprofile(item) {
        let api = '';
        let user = JSON.parse(localStorage.getItem("user"));
        let userId=user.data.id;
          api =API_PATHS.putuser + '?id=' + userId;
        localStorage.removeItem("id");
        return axios
            .put(api,item)
            .then((response) => {
                if (response.data) {
                 //   localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static editexpertprofile(item) {
        let api = '';
        let id = localStorage.getItem("expertId");
          api =API_PATHS.putuser + '?id=' + id;
        localStorage.removeItem("id");
        return axios
            .put(api,item)
            .then((response) => {
                if (response.data) {
                  //  localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    
    static enableexpertprofile() {
        let api = '';
        let id = localStorage.getItem("expertId");
        let item = JSON.parse(localStorage.getItem("item"));
          api =API_PATHS.putuser + '?id=' + id;
        localStorage.removeItem("id");
        return axios
            .put(api,item)
            .then((response) => {
                if (response.data) {
                  //  localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }


    static getuserDetails() {
        let api = '';
        let user = JSON.parse(localStorage.getItem("user"));
        let id = user.data.id;
    api =API_PATHS.getuser + '?id=' + id ;
    localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static clientDetails() {
        let api = '';
        let id = localStorage.getItem("clientId");
    api =API_PATHS.getuser + '?id=' + id ;
    // localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }


    static expertDetails() {
        let api = '';
        let id = localStorage.getItem("expertId");
    api =API_PATHS.getuser + '?id=' + id ;
    // localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }


    static agencyregister(item) {
        return axios
            .post(API_PATHS.addAgency,
                item
            )
            .then((response) => {
                if (response.data) {
             
                }
                return response.data;
            });
    }

    static expertprofile(item) {
        return axios
            .post(API_PATHS.addExpert,
                item
            )
            .then((response) => {
                if (response.data) {
             
                }
                return response.data;
            });
    }

    static otpVerification({
        mobileNo,
        otp,
    }) {
        return axios
            .post(API_PATHS.verifyOtp, {
                mobileNo,
                otp,
            })
            .then((response) => {
                if (response.data) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }



    static otpVerificationemployee({
        email,
        otp,
    }) {
        return axios
            .post(API_PATHS.verifyOtp, {
                email,
                otp,
            })
            .then((response) => {
                if (response.data) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default AuthService;