import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class EnquiryService {

  static datewiseEnquiry() {
    let api = '';
    let fromdate = localStorage.getItem("fdate");
    let todate = localStorage.getItem("tdate");
    api = API_PATHS.getEnquiry + '?fromDate=' + fromdate + '&toDate=' + todate;
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }


  static getWmEnquiry() {
    let api = '';
    api = API_PATHS.getWmEnquiry;
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static getFitnessEnquiry() {
    let api = '';
    api = API_PATHS.getWmEnquiry + '?enquiryFor=Fitness';
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }


  static editWmEnquiry() {
    let api = '';
    let id = localStorage.getItem("id");
    let item = JSON.parse(localStorage.getItem("item"));
    api = API_PATHS.editWmEnquiry + '?id=' + id;
    return axios
      .put(api, item)
      .then((response) => {
        if (response.data) {

        }
        return response.data;
      });
  }


  static getPhysioEnquiry() {
    let api = '';
    api = API_PATHS.getEnquiry + '?enquiryFor=Physiotherapy';
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static getWeightEnquiry() {
    let api = '';
    api = API_PATHS.getEnquiry + '?enquiryFor=Weight-Loss';
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }


  static getEnquiry(params) {
    let api = '';
    api = API_PATHS.getEnquiry;
    return axios.get(api, { params }).then((response) => {
      return response.data;
    });
  }

  static generalEnquiry(params) {
    let api = '';
    api = API_PATHS.getEnquiry;
    return axios
      .get(api, { params })
      .then((response) => {
        return response.data;
      });
  }

  static enquiryDetails() {
    let api = '';
    let id = localStorage.getItem("enquiryId");
    api = API_PATHS.getEnquiry + '?id=' + id;
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }


  static getGeneralFitnessEnquiry() {
    let api = '';
    api = API_PATHS.getEnquiry + '?category=Gyms';
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static getHcEnquiry() {
    let api = '';
    api = API_PATHS.getEnquiry + '?category=Healthcare';
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static AyurvedaEnquiry() {
    let api = '';
    api = API_PATHS.getEnquiry + '?category=Ayurveda';
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }


  static editEnquiry(item) {
    let api = '';
    api = API_PATHS.putEnquiry;
    return axios
      .put(api, item)
      .then((response) => {
        if (response.data) {

        }
        return response.data;
      });
  }




  static removeUserDetails() {
    localStorage.removeItem("user");
  }

  static getUserDetails() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default EnquiryService;
