import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "./Navbarside";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "./footer";

import { getbodypartgroup } from "../store/slices/body";
import { getbodyspart } from "../store/slices/body";

import { putexercise } from "../store/slices/exercise";
import { getdetialsexercise } from "../store/slices/exercise";

import { API_PATHS } from "../utils/constants/api.constants";

const EditExercise = () => {
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [weightUnit, setWeightUnit] = useState("");
  const [mediaLink, setMediaLink] = useState("");
  const [description, setDescription] = useState("");
  const [stepsToPerform, setStepsToPerform] = useState("");
  const [bodyPartIdname, setBodyPartIdname] = useState("");
  const [bodyPartGroupIdname, setBodyPartGroupIdname] = useState("");

  const [message, setMessage] = useState("");

  const [alertname, setAlertName] = useState("");
  const [alertcaloriesPerRep, setAlertCaloriesPerRep] = useState("");
  const [alertminWeight, setAlertMinWeight] = useState("");
  const [alertweightUnit, setAlertWeightUnit] = useState("");
  const [alertmediaLink, setAlertMediaLink] = useState("");
  const [alertbodyPartId, setAlertBodyPartId] = useState("");
  const [alertbodyPartGroupId, setAlertBodyPartGroupId] = useState("");
  const [alertdescription, setAlertDescription] = useState("");
  const [alertstepsToPerform, setAlertStepsToPerform] = useState("");

  const [bodypart, setBodypart] = useState([]);
  const [bodyPartId, setBodyPartId] = useState("");
  const [bodyPartname, setBodyPartname] = useState("");

  const [bodypartgroup, setBodypartgroup] = useState([]);
  const [bodyPartGroupId, setBodyPartGroupId] = useState("");
  const [bodyPartGroupname, setBodyPartGroupname] = useState("");

  React.useEffect(() => {
    dispatch(getbodyspart())
      .unwrap()
      .then((data) => {
        setBodypart(data.user.data);
        setBodyPartId(data.user.data[0]._id);
        setBodyPartname(data.user.data[0].name);
        console.log(data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getbodypartgroup())
      .unwrap()
      .then((data) => {
        setBodypartgroup(data.user.data);
        setBodyPartGroupId(data.user.data[0]._id);
        setBodyPartGroupname(data.user.data[0].name);
        console.log(data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  const handlebodypartgroup = (e) => {
    var value = bodypartgroup.filter(function (bodypartgroup) {
      return bodypartgroup.name == e.target.value;
    });
    setBodyPartGroupId(value[0]._id);
    setBodyPartGroupname(value[0].name);
  };

  const handlebodypart = (e) => {
    var value = bodypart.filter(function (bodypart) {
      return bodypart.name == e.target.value;
    });
    setBodyPartId(value[0]._id);
    setBodyPartname(value[0].name);
  };

  async function imageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setMediaLink(data.data.location);
    setMessage(data.message);
  }

  const { id } = useParams();
  localStorage.setItem("id", id);
  React.useEffect(() => {
    dispatch(getdetialsexercise())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id == id) {
          setWeightUnit(data.user.data[0].weightUnit);
          setBodyPartId(data.user.data[0].bodyPartId[0]._id);
          setBodyPartIdname(data.user.data[0].bodyPartId[0].name);
          setBodyPartGroupId(data.user.data[0].bodyPartGroupId[0]._id);
          setBodyPartGroupIdname(data.user.data[0].bodyPartGroupId[0].name);
          setName(data.user.data[0].name);
          setMediaLink(data.user.data[0].mediaLink);
          setDescription(data.user.data[0].description);
          setStepsToPerform(data.user.data[0].stepsToPerform);
        }
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  async function addData(e) {
    e.preventDefault();
    let item = {
      set: {
        name,
        weightUnit,
        mediaLink,
        description,
        stepsToPerform,
        bodyPartId,
        bodyPartGroupId,
      },
    };
    dispatch(putexercise(item))
      .unwrap()
      .then(() => {
        alert("Edited succesfully");
        // navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  const save = (e) => {
    const regtext = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regtext.test(name)) {
      setAlertName("");
    } else if (!regtext.test(name) && name === "") {
      setAlertName("Please enter the body part name");
      e.preventDefault();
    } else {
      setAlertName("");
    }

    const regtext2 = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/;
    if (regtext2.test(weightUnit)) {
      setAlertWeightUnit("");
    } else if (!regtext2.test(weightUnit) && weightUnit === "") {
      setAlertWeightUnit("Please enter the weight unit");
      e.preventDefault();
    } else {
      setAlertWeightUnit("");
    }

    const regtext3 = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (regtext3.test(description)) {
      setAlertDescription("");
    } else if (!regtext3.test(description) && description === "") {
      setAlertDescription("Please enter the weight unit");
      e.preventDefault();
    } else {
      setAlertDescription("");
    }

    const regtext4 = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (regtext4.test(stepsToPerform)) {
      setAlertStepsToPerform("");
    } else if (!regtext4.test(stepsToPerform) && stepsToPerform === "") {
      setAlertStepsToPerform("Please enter the weight unit");
      e.preventDefault();
    } else {
      setAlertStepsToPerform("");
    }

    const regnumber2 = /^[0-9][0-9]?$|^100$/;
    if (regnumber2.test(weightUnit)) {
      setAlertMinWeight("");
    } else if (!regnumber2.test(weightUnit) && weightUnit === "") {
      setAlertMinWeight("Please enter the weight unit");
      e.preventDefault();
    } else {
      setAlertMinWeight("");
    }

    const regimage = /(gif|jpe?g|tiff?|png|webp|bmp)$/i;
    if (regimage.test(mediaLink)) {
      setAlertMediaLink("");
    } else if (!regimage.test(mediaLink) && mediaLink === "") {
      setAlertMediaLink("Please enter image link");
      e.preventDefault();
    } else {
      setAlertMediaLink("Invalid file");
      e.preventDefault();
    }
  };

  const cancel = () => {
    navigate(-1);
  };

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">Edit Exercise</h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <form onSubmit={addData}>
                        <div className="row">
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Exercise Name
                            </p>
                            <input
                              type="text"
                              placeholder="Enter the  Exercise name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertname}</p>
                          </div>
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Weight Unit
                            </p>
                            <input
                              type="text"
                              placeholder="Enter the weight unit"
                              value={weightUnit}
                              onChange={(e) => setWeightUnit(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertweightUnit}</p>
                          </div>
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Description
                            </p>
                            <textarea
                              rows="5"
                              placeholder="Enter the description"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertdescription}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Step To Perform
                            </p>
                            <textarea
                              placeholder="Enter the step to perform the exercise"
                              value={stepsToPerform}
                              rows="5"
                              onChange={(e) =>
                                setStepsToPerform(e.target.value)
                              }
                              className="form-control"
                            />
                            <p className="alert-message">
                              {alertstepsToPerform}
                            </p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Body Part
                            </p>

                            <select
                              id="cars"
                              className="form-control"
                              onChange={handlebodypart}
                            >
                              <option value="">Select</option>
                              {bodypart.map((bodypartVal) => (
                                <option value={bodypartVal._id}>
                                  {bodypartVal.name}
                                </option>
                              ))}
                            </select>
                            <p className="alert-message"></p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Body Part Group
                            </p>

                            <select
                              value={bodyPartIdname}
                              id="cars"
                              className="form-control"
                              onChange={handlebodypartgroup}
                            >
                              <option value="">Select </option>
                              {bodypartgroup.map((bodygroupVal) => (
                                <option value={bodygroupVal._id}>
                                  {bodygroupVal.name}
                                </option>
                              ))}
                            </select>
                            <p className="alert-message"></p>
                          </div>
                        </div>

                        <div className="d-flex ">
                          <div className="col-md-6 col-sm-12 pt-2">
                            <div className="form-body">
                              <div className="form-group">
                                <p className="col-black">Image</p>

                                <img
                                  src={mediaLink}
                                  style={{
                                    height: "100px",
                                    width: "100px",
                                    borderRadius: "10px",
                                  }}
                                  alt=""
                                />
                                <input
                                  type="file"
                                  className="form-control-file"
                                  Placeholder=""
                                  name="imageUrl"
                                  onChange={(e) => imageUpload(e)}
                                />
                                <p className="alert-message">
                                  {alertmediaLink}
                                </p>
                                <p
                                  style={{
                                    color: "green",
                                    fontWeight: "500",
                                  }}
                                >
                                  {message}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-center pt-3">
                          <button
                            type="submit"
                            className="btn btn-success"
                            onClick={save}
                            disabled={!mediaLink}
                          >
                            Submit
                          </button>
                          &nbsp; &nbsp; &nbsp;
                          <button
                            type="reset"
                            className="btn btn-warning"
                            onClick={cancel}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default EditExercise;
