import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "../component/Navbarside";
import Footer from "./footer";
import Moment from "moment";
import { AppointmentAnalysisDetails } from "../store/slices/analysis";
import { useDownloadExcel } from "react-export-table-to-excel";
import { formatDate } from "../utils/appUtils";
import Loader from "./Loader";

const AppointmentAnalysis = () => {
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const tableRef = useRef(null);
  const [errorStart, setErrorStart] = useState("");
  const [errorEnd, setErrorEnd] = useState("");

  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const sData = dateForQuery().startDate;
    const eDate = dateForQuery().endDate;
    setstartDate(sData);
    setendDate(eDate);
    analysisData(sData, eDate);
  }, []);

  async function analysisData(startDate, endDate) {
    if (endDate?.length <= 0 && startDate?.length <= 0) {
      setErrorStart("Please select start date");
      setErrorEnd("Please select end date");
      return;
    } else if (startDate?.length <= 0) {
      setErrorStart("Please select start date");
      return;
    } else if (endDate?.length <= 0) {
      setErrorEnd("Please select end date");
      return;
    }
    let item = {};
    startDate?.length && (item["startDate"] = startDate);
    endDate?.length && (item["endDate"] = endDate);
    setIsLoading(true);
    dispatch(AppointmentAnalysisDetails(item))
      .unwrap()
      .then((data) => {
        console.log("data", data);
        setIsLoading(false);
        setData(data?.user?.data);
      })
      .catch(({ message }) => {
        setIsLoading(false);
        // alert(message);
      });
  }

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Appointment Analysis Report",
    sheet: "Sheet1",
  });

  const dateForQuery = () => {
    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    const formattedToday = formatDate(today);
    const formattedOneMonthAgo = formatDate(oneMonthAgo);
    return { startDate: formattedToday, endDate: formattedOneMonthAgo };
  };

  return (
    <div>
      <Navbarside />
      {isLoading && <Loader />}

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">
                          Appointment Analysis Report
                        </h4>
                      </div>
                    </div>
                    <section className="form-section">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          analysisData(startDate, endDate);
                        }}
                      >
                        <div className="row">
                          <div className="col-md-4 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              From Date
                            </p>
                            <input
                              type="date"
                              placeholder="Enter the recipe name"
                              value={startDate}
                              onChange={(e) => setstartDate(e.target.value)}
                              className="form-control"
                            />
                            {errorStart?.length > 0 && (
                              <div className="text-danger">{errorStart}</div>
                            )}
                            <p className="alert-message"></p>
                          </div>
                          <div className="col-md-4 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              To Date
                            </p>
                            <input
                              type="date"
                              placeholder="Enter the recipe name"
                              value={endDate}
                              onChange={(e) => setendDate(e.target.value)}
                              className="form-control"
                            />
                            {errorEnd?.length > 0 && (
                              <div className="text-danger">{errorEnd}</div>
                            )}
                            <p className="alert-message"></p>
                          </div>
                          <div className="col-md-4 col-sm-12 pt-2 mt-3">
                            <button type="submit" className="btn btn-primary">
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </section>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        {data !== "" && (
                          <div>
                            <button
                              onClick={onDownload}
                              className="btn btn-primary"
                            >
                              Export to Excel
                            </button>
                            <table
                              className="table table-striped table-bordered zero-configuration"
                              ref={tableRef}
                            >
                              <thead>
                                <tr>
                                  <th>Appointment Date</th>
                                  <th>Appointment Time</th>
                                  <th> Category</th>
                                  <th>Appointment Created By</th>
                                  <th>Appointment Status Scheduled / Closed</th>
                                  <th>Appointment Assigned to Expert</th>
                                  <th>Membership Plan Name</th>
                                </tr>
                              </thead>

                              <tbody>
                                {data?.map((dataVal, index) => (
                                  <tr>
                                    <td>
                                      {" "}
                                      {Moment(dataVal?.created_at).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </td>
                                    <td>
                                      {Moment(dataVal?.created_at).format(
                                        "hh:mm A"
                                      )}
                                    </td>

                                    <td>{dataVal?.category}</td>
                                    <td>{dataVal?.status}</td>
                                    <td>
                                      <p>
                                        {dataVal?.expert == null
                                          ? null
                                          : dataVal?.expert?.[0]?.firstName}
                                        &nbsp;
                                        {dataVal?.expert
                                          ? dataVal?.expert?.[0]?.lastName
                                          : null}
                                      </p>
                                    </td>
                                    <td>
                                      {dataVal?.userId?.[0]?.firstName
                                        ? dataVal?.userId?.[0]?.firstName
                                        : null}
                                      &nbsp;
                                      {dataVal?.userId?.[0]?.lastName
                                        ? dataVal?.userId?.[0]?.lastName
                                        : null}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AppointmentAnalysis;
