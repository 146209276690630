import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAllExpertise } from "../store/slices/hcexpert";
import axios from 'axios'
import moment from 'moment'
import { getHcExperts } from "../store/slices/hcexpert";
// import  from '../

const FilterComponent = ({ onCategoryChange, onApply }) => {
  const [allExpertise, setExpertise] = useState(null);
  const [selectedExpertise, setSelectedExpertise] = useState(null)
  const [selectedSubExpertise, setSelectedSubExpertise] = useState(null)
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const dispatch = useDispatch();

  const handleFilterSubmit = () => {
    let params = {}
    if (selectedExpertise) params.expertiseId = selectedExpertise
    if (selectedSubExpertise) params.subCategoryId = selectedSubExpertise
    if (startDate) params.fromDate = moment(startDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
    if (endDate) params.toDate = moment(endDate, 'YYYY-MM-DD').format('MM/DD/YYYY')

    axios.get('https://api.healthonify.com/get/user', {
      params
    }).then(res => setExpertise(res.data.data))

  };

  React.useEffect(() => {
    dispatch(getAllExpertise()).unwrap().then((data) => {
      setExpertise(data.allExpertise)
    }).catch(({ message }) => {
      // alert(message);
    });
  }, [dispatch,]);

  const handleExpertiseChange = (e, cat) => {
    if (cat == 'subExp') {
      setSelectedSubExpertise(e.target.value)
      onCategoryChange(selectedExpertise, e.target.value)
    } else {
      setSelectedExpertise(e.target.value)
      onCategoryChange(selectedExpertise)
    }
  }

  const sumbitFilter = () => {
    console.log('clieck', selectedExpertise)
    if (selectedExpertise) onApply({ selectedExpertise, selectedSubExpertise })
  }

  return (
    <section className="form-section">
      <div className="row">
        <div className="col-md-5 col-sm-12 pt-2">
          <p
            className="col-black"
            style={{ marginBottom: "2px" }}
          >
            Category
          </p>
          <select
            className="form-control"
            onChange={(e) => handleExpertiseChange(e, 'exp')}
          >
            <option value="">Select Expertise</option>
            {allExpertise && allExpertise.data && allExpertise.data.length
              ? allExpertise.data.map(option => {
                if (!option.parentExpertiseId || option.parentExpertiseId == null) {
                  return (
                    <option value={option._id}>
                      {option.name}
                    </option>
                  )
                }
              })
              : ''
            }
          </select>
        </div>

        <div className="col-md-5 col-sm-12 pt-2">
          <p
            className="col-black"
            style={{ marginBottom: "2px" }}
          >
            Sub Category
          </p>
          <select
            className="form-control"
            onChange={(e) => handleExpertiseChange(e, 'subExp')}
          >
            <option value="">Select Sub - Expertise</option>
            {selectedExpertise
              ? allExpertise.data.map(expertise => {
                if (expertise.parentExpertiseId == selectedExpertise) {
                  return (
                    <option value={expertise._id}>
                      {expertise.name}
                    </option>
                  )
                }
              })
              : ''
            }
          </select>
        </div>

        <div className="col-md-2 col-sm-12 pt-2 mt-3">
          <button type="submit" onClick={sumbitFilter} className="btn btn-primary">
            Apply Filter
          </button>
        </div>
      </div>
    </section>
  );
};

export default FilterComponent;
