import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "./Navbarside";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "./footer";
import { addfitnesscenterPlan } from "../store/slices/fitness";
import { API_PATHS } from "../utils/constants/api.constants";
import { getFitnessPlanCategory } from "../store/slices/fitnessplans";
const AddFitnessPlan = () => {
  const { id } = useParams();
  let fitnessCenterId = id;
  const navigate = useNavigate("");
  const [category, setCategory] = useState([]);
  const [name, setName] = useState("");
  const [memberShipDurationInDays, setmemberShipDurationInDays] = useState("");
  const [agreement, setAgreement] = useState("");
  const [mediaLink, setmediaLink] = useState("");
  const [description, setDescription] = useState("");
  const [termsAndConditions, settermsAndConditions] = useState("");
  const [price, setprice] = useState("");

  const [alertname, setAlertname] = useState("");
  const [alertmemberShipDurationInDays, setAlertmemberShipDurationInDays] =
    useState("");
  const [alertmediaLink, setAlertmediaLink] = useState("");
  const [alertdescription, setAlertdescription] = useState("");
  const [alerttermsAndConditions, setAlerttermsAndConditions] = useState("");
  const [alertprice, setAlertprice] = useState("");
  const [fitnessPlanCategoryId, setfitnessPlanCategoryId] = useState("");
  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState("");
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const target = e.target;
    if (target.checked) {
      setfitnessPlanCategoryId(target.value);
    }
  };

  const save = (e) => {
    const regname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (regname.test(name)) {
      setAlertname("");
    } else if (!regname.test(name) && name === "") {
      setAlertname("Please enter the fitness center name");
      e.preventDefault();
    } else {
      setAlertname("Please enter a valid name");
      e.preventDefault();
    }

    const mobile = /^(\+\d{1,3}[- ]?)?\d{3}$/;
    if (mobile.test(memberShipDurationInDays)) {
      setAlertmemberShipDurationInDays("");
    } else if (
      !mobile.test(memberShipDurationInDays) &&
      memberShipDurationInDays === ""
    ) {
      setAlertmemberShipDurationInDays("Please enter the duration");
      e.preventDefault();
    } else {
      setAlertmemberShipDurationInDays("Please enter a valid duration");
    }

    const regdescription = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (regdescription.test(description)) {
      setAlertdescription("");
    } else if (!regdescription.test(description) && description === "") {
      setAlertdescription("Please enter description");
      e.preventDefault();
    } else {
      setAlertdescription("");
    }

    const regcheque = /(gif|jpe?g|tiff?|png|webp|bmp)$/i;
    if (regcheque.test(mediaLink)) {
      setAlertmediaLink("");
    } else if (!regcheque.test(mediaLink) && mediaLink === "") {
      setAlertmediaLink("Please upload image");
      e.preventDefault();
    } else {
      setAlertmediaLink("Invalid image");
      e.preventDefault();
    }
  };

  const cancel = () => {
    setName("");
    setmemberShipDurationInDays("");
    setAgreement("");
    setmediaLink("");
    setDescription("");
    settermsAndConditions("");
    setprice("");
    setAlertname("");
    setAlertmemberShipDurationInDays("");
    setAlertmediaLink("");
    setAlertdescription("");
    setAlerttermsAndConditions("");
    setAlertprice("");
    setMessage("");
    setMessage2("");
  };

  async function addData(e) {
    e.preventDefault();
    let fcAdminId = JSON.parse(localStorage.getItem("user")).data.id;
    let appointmentType = "Normal";
    let isActive = true;
    let item = {
      fcAdminId,
      name,
      memberShipDurationInDays,
      mediaLink,
      description,
      fitnessPlanCategoryId,
      termsAndConditions,
      price,
      isActive,
      fitnessCenterId,
      appointmentType,
    };
    dispatch(addfitnesscenterPlan(item))
      .unwrap()
      .then(() => {
        alert("Added succesfully");
        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  React.useEffect(() => {
    dispatch(getFitnessPlanCategory())
      .unwrap()
      .then((data) => {
        setCategory(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  async function imageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setmediaLink(data.data.location);
    setMessage2(data.message);
  }

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">Add Plan</h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <form onSubmit={addData}>
                        <div className="row">
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Plan Name
                            </p>
                            <input
                              type="text"
                              placeholder="Enter plan name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertname}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Plan Duration (In Days)
                            </p>
                            <input
                              type="number"
                              placeholder="Plan Duration"
                              value={memberShipDurationInDays}
                              onChange={(e) =>
                                setmemberShipDurationInDays(e.target.value)
                              }
                              className="form-control"
                            />
                            <p className="alert-message">
                              {alertmemberShipDurationInDays}
                            </p>
                          </div>

                          <div className="col-md-12 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Description{" "}
                            </p>
                            <textarea
                              rows="5"
                              placeholder="Enter plan details"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertdescription}</p>
                          </div>

                          <div className="col-md-12 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Terms and Conditions
                            </p>
                            <textarea
                              rows="5"
                              placeholder="Enter the termsAndConditions "
                              value={termsAndConditions}
                              onChange={(e) =>
                                settermsAndConditions(e.target.value)
                              }
                              className="form-control"
                            />
                            <p className="alert-message">
                              {alerttermsAndConditions}
                            </p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Price
                            </p>
                            <input
                              type="text"
                              placeholder="Plan Price"
                              value={price}
                              onChange={(e) => setprice(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{alertprice}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Image
                            </p>
                            <input
                              type="file"
                              className="form-control-file"
                              Placeholder="Price Tagline"
                              required
                              onChange={(e) => imageUpload(e)}
                            />
                            <p className="alert-message">{alertmediaLink}</p>
                            <p style={{ color: "green", fontWeight: "500" }}>
                              {message2}
                            </p>
                          </div>

                          <div className="col-md-12 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Plan Category
                            </p>
                          </div>

                          {category.map((categoryVal, index) => (
                            <div className=" col-lg-6 col-md-6 col-sm-12">
                              <input
                                type="radio"
                                name="category"
                                value={categoryVal._id}
                                checked={
                                  fitnessPlanCategoryId === categoryVal._id
                                }
                                onChange={handleChange}
                              />
                              &nbsp;
                              {categoryVal.name}
                            </div>
                          ))}
                        </div>

                        <div className="form-actions center">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={save}
                            disabled={!message2}
                          >
                            Submit
                          </button>
                          &nbsp; &nbsp; &nbsp;
                          <button
                            type="reset"
                            className="btn btn-warning"
                            onClick={cancel}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default AddFitnessPlan;
