import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { editContent, getcontentDetails } from "../../../store/slices/livewell";
import { useNavigate } from "react-router-dom";
import { useParams, Link } from "react-router-dom";
import Navbarside from "../../Navbarside";
import Footer from "../../footer";
import { API_PATHS } from "../../../utils/constants/api.constants";
const EditContent = (e) => {
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const [masterCategoryId, setmasterCategoryId] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [mediaLink, setmediaLink] = useState("");
  const [format, setFormat] = useState();
  const [thumbnail, setThumbnail] = useState();
  const [alertitle, setAlertitle] = useState("");
  const [alertdescription, setAlertdescription] = useState("");
  const [alertmediaLink, setAlertmediaLink] = useState("");
  const [alertthumbnail, setAlertthumbnail] = useState("");
  const [alertformat, setAlertformat] = useState("");
  const [catName, setcatName] = useState("");
  const [message, setMessage] = useState();
  const { id } = useParams();
  localStorage.setItem("contentId", id);

  React.useEffect(() => {
    dispatch(getcontentDetails())
      .unwrap()
      .then((data) => {
        if (data.user.data[0]._id === id) {
          setTitle(data.user.data[0].title);
          setmediaLink(data.user.data[0].mediaLink);
          setDescription(data.user.data[0].description);
          setThumbnail(data.user.data[0].thumbnail);
          setFormat(data.user.data[0].format);
          setmasterCategoryId(data.user.data[0].masterCategoryId);
        }
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);
  async function imageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setThumbnail(data.data.location);
    setMessage(data.message);
  }

  async function addData(e) {
    e.preventDefault();
    let item = {
      set: { title, description, mediaLink, masterCategoryId, thumbnail },
    };
    dispatch(editContent(item))
      .unwrap()
      .then(() => {
        alert("Updated succesfully");
        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }
  async function clear() {
    navigate(-1);
  }

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">Edit Content</h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <form onSubmit={addData}>
                        <div className="row">
                          <div className="col-md-8 pt-2 pb-3">
                            <span className="label">Title</span>
                            <input
                              type="text"
                              placeholder="Enter title"
                              className="form-control"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                            <p className="alert-message">{alertitle}</p>
                          </div>

                          <div className="col-md-4 pt-2 pb-3">
                            <span className="label">Content Type</span>
                            <select
                              className="form-control"
                              value={format}
                              onChange={(e) => setFormat(e.target.value)}
                            >
                              <option>Select</option>
                              <option>Audio</option>
                              <option>Video</option>
                              <option>Pdf file</option>
                            </select>

                            <p className="alert-message">{alertformat}</p>
                          </div>
                          <div className="col-md-12 pt-2 pb-3">
                            <span className="label">Thumbnail</span>
                            <br />
                            <img
                              src={thumbnail}
                              alt=""
                              style={{
                                height: "80px",
                                width: "120px",
                                marginBottom: "10px",
                              }}
                            />

                            <input
                              type="file"
                              className="form-control"
                              name="thumbnail"
                              onChange={(e) => imageUpload(e)}
                            />
                            <p className="alert-message">{alertthumbnail}</p>
                            <b>{message}</b>
                          </div>
                          <div className="col-md-12 pt-2 pb-3">
                            <span className="label">Description</span>
                            <textarea
                              placeholder="Enter description"
                              className="form-control"
                              value={description}
                              rows="5"
                              onChange={(e) => setDescription(e.target.value)}
                            />
                            <p className="alert-message">{alertdescription}</p>
                          </div>
                          <div className="col-md-12">
                            <span className="label">Content Link</span>
                            <input
                              type="text"
                              className="form-control"
                              Placeholder="Enter content link"
                              value={mediaLink}
                              onChange={(e) => setmediaLink(e.target.value)}
                            />
                            <p className="alert-message">{alertmediaLink}</p>
                          </div>

                          <div className="col-md-12" align="center">
                            <br />
                            <button
                              type="submit"
                              className="btn btn-primary col-white"
                              style={{ width: "150px" }}
                            // disabled={!message}
                            >
                              Submit
                            </button>
                            &nbsp;
                            <button
                              type="submit"
                              onClick={clear}
                              className="btn btn-danger col-white"
                              style={{ width: "150px" }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default EditContent;
