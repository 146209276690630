import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Navbarside from "./Navbarside";
import { useDispatch, useSelector } from "react-redux";
import { getlabtestresult, UpdateTestRequest } from "../store/slices/lab";
import Footer from "./footer";
import Moment from "moment";
import axios from "axios";
import { ExportToExcel } from "./ExportToExcel";
import { API_PATHS } from "../utils/constants/api.constants";
const LabTestRequest = () => {
  const [detailslab, setDetailslab] = useState([]);
  const dispatch = useDispatch();
  const { id } = useParams();
  const fileName = "Lab Test Request";
  const [data, setData] = useState([]);
  localStorage.setItem("labId", id);
  React.useEffect(() => {
    dispatch(getlabtestresult())
      .unwrap()
      .then((data) => {
        setDetailslab(data.user.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    const fetchData = () => {
      axios.get(API_PATHS.getlabtests + "?labId=" + id).then((postData) => {
        const customHeadings = postData.data.data.map((item) => ({
          Patient_Name: item.labTestId.userId.firstName,
          Test_Type: item.labTestId.testType,
          Time: item.time,
          Date: item.date,
          Status: item.status,
        }));

        setData(customHeadings);
      });
    };
    fetchData();
  }, []);

  async function disableApprove(ids) {
    let status = "rejected";
    let id = ids;
    let item = { id, status };
    localStorage.setItem("item", JSON.stringify(item));
    dispatch(UpdateTestRequest(item))
      .unwrap()
      .then(() => {
        alert("Request rejected succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
    // console.log(item);
    // console.log(id);
  }

  async function enableApprove(ids) {
    let status = "scheduled";
    let id = ids;
    let item = { id, status };
    localStorage.setItem("item", JSON.stringify(item));
    dispatch(UpdateTestRequest(item))
      .unwrap()
      .then(() => {
        alert("Request accepted succesfully");
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Lab Test Bookings</h4> &nbsp;
                        &nbsp; &nbsp;
                        <ExportToExcel
                          apiData={data}
                          fileName={fileName}
                          style={{
                            height: "25px",
                            padding: "0 10px",
                            marginBottom: "0%",
                          }}
                        />
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Booking Details</th>
                              <th>Test Details</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {detailslab.map((LabVal, index) => (
                              <tr key={index}>
                                <td>
                                  <Link
                                    to={`/Labtest/LabTestRequest/LabTestdetails/${
                                      LabVal._id ? LabVal._id : null
                                    }`}
                                  >
                                    <p>
                                      Name-{LabVal.userId.firstName}{" "}
                                      {LabVal.userId.lastName}
                                      <br />
                                      Date-{" "}
                                      {Moment(LabVal.created_at).format(
                                        "DD-MM-YYYY"
                                      )}
                                      <br />
                                      Preferred Time- {LabVal.time}
                                    </p>
                                  </Link>
                                </td>
                                <td>
                                  {LabVal.testType === "home" && (
                                    <p>Test Type- Home Collection</p>
                                  )}
                                  {LabVal.testType === "center" && (
                                    <p>Test Type- Lab Visit</p>
                                  )}
                                  <p>
                                    Test Name-
                                    {LabVal.labTestCategoryId.map(
                                      (TestVal, index) => (
                                        <span>{TestVal.name}</span>
                                      )
                                    )}
                                  </p>
                                </td>
                                <td>
                                  {LabVal.status === "awaitingPayment" && (
                                    <button
                                      className="btn btn-warning"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Payment Pending
                                    </button>
                                  )}

                                  {LabVal.status === "submitted" && (
                                    <button
                                      className="btn btn-success"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Payment Successful
                                    </button>
                                  )}

                                  {LabVal.status === "rejected" && (
                                    <button
                                      className="btn btn-danger"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Rejected
                                    </button>
                                  )}
                                  {LabVal.status === "scheduled" && (
                                    <button
                                      className="btn btn-primary"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Scheduled
                                    </button>
                                  )}
                                  {LabVal.status === "completed" && (
                                    <button
                                      className="btn btn-success"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Report Generated
                                    </button>
                                  )}
                                </td>

                                <td>
                                  {LabVal.status === "submitted" && (
                                    <button
                                      className="btn btn-primary"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                      onClick={() => enableApprove(LabVal._id)}
                                    >
                                      Approve
                                    </button>
                                  )}
                                  &nbsp;
                                  {LabVal.status === "submitted" && (
                                    <button
                                      className="btn btn-warning"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                      onClick={() => disableApprove(LabVal._id)}
                                    >
                                      Reject
                                    </button>
                                  )}
                                  {LabVal.status === "scheduled" && (
                                    <Link
                                      to={`/AddTestReport/${
                                        LabVal._id ? LabVal._id : null
                                      }`}
                                      className="btn btn-primary"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Add Report
                                    </Link>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default LabTestRequest;
