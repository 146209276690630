import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class ChallengeService {

  static getChallengeCategories() {
    return axios
      .get(API_PATHS.getChallengeCategory)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }



  static postChallenge(item) {
    return axios
      .post(API_PATHS.postChallenge,
        item
      )
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static editChallenge(item) {
    let api = '';
    let id = localStorage.getItem("challengeId");
    api = API_PATHS.putChallenge + '?id=' + id;
    localStorage.removeItem("id");
    return axios
      .put(api, item)
      .then((response) => {
        if (response.data) {

        }
        return response.data;
      });
  }

  static deleteChallenge() {
    let api = '';
    let id = localStorage.getItem("id");
    api = API_PATHS.deleteChallenge + '?id=' + id;
    localStorage.removeItem("id");
    return axios
      .delete(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }


  static getChallenges() {
    let api = '';

    api = API_PATHS.getChallenge;

    localStorage.removeItem("id");
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }
  static getChallengeDetails() {
    let api = '';
    let id = localStorage.getItem("challengeId");
    api = API_PATHS.getChallenge + '?id=' + id;
    localStorage.removeItem("id");
    return axios
      .get(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }



  static removeUserDetails() {
    localStorage.removeItem("user");
  }

  static getUserDetails() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default ChallengeService;