import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import EnquiryService from "../../services/enquiry.service";
import AuthService from "../../services/auth.service";
import ConsultationService from "../../services/consultation.service";



export const datewisequiry = createAsyncThunk(
  "enquiry/get/enquiry",
  async (thunkAPI) => {
    try {
      const data = await EnquiryService.datewiseEnquiry();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const enquiryDetails = createAsyncThunk(
  "enquiry/get/enquiry",
  async (thunkAPI) => {
    try {
      const data = await EnquiryService.enquiryDetails();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const fetchSession = createAsyncThunk(
  "session/get",
  async (thunkAPI) => {
    try {
      const data = await EnquiryService.enquiryDetails();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const fetchConsultation = createAsyncThunk(
  "fetch/consultation",
  async (thunkAPI) => {
    try {
      const ticketNumber = localStorage.getItem("ticketNumber");
      const flow = localStorage.getItem("flow");
      const comments = localStorage.getItem("comments");
      const data = await ConsultationService.fetchConsultation({
        ticketNumber,
        flow,
        comments
      });
      return data.data
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
)

export const Wenquiry = createAsyncThunk(
  "enquiry/get/enquiry",
  async (thunkAPI) => {
    try {
      const data = await EnquiryService.getWmEnquiry();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
export const EditWmenquiry = createAsyncThunk(
  "enquiry/put/enquiry",
  async (item, thunkAPI) => {
    try {
      const data = await EnquiryService.editWmEnquiry(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const physioEnquiry = createAsyncThunk(
  "enquiry/get/enquiry",
  async (thunkAPI) => {
    try {
      const data = await EnquiryService.getPhysioEnquiry();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const WeightmanagementEnquiry = createAsyncThunk(
  "enquiry/get/enquiry",
  async (thunkAPI) => {
    try {
      const data = await EnquiryService.getWeightEnquiry();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const generalEnquiry = createAsyncThunk(
  "enquiry/get/enquiry",
  async (thunkAPI) => {
    try {
      // let query
      // let queryParam = JSON.parse(localStorage.getItem('queryParam'))
      // query = {...thunkAPI, ...queryParam}

      const data = await EnquiryService.getEnquiry(thunkAPI);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const fitnessEnquiry = createAsyncThunk(
  "enquiry/get/enquiry",
  async (thunkAPI) => {
    try {
      const data = await EnquiryService.getFitnessEnquiry();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const hcEnquiry = createAsyncThunk(
  "enquiry/get/enquiry",
  async (thunkAPI) => {
    try {
      const data = await EnquiryService.getHcEnquiry();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const ayurvedaEnquiry = createAsyncThunk(
  "enquiry/get/enquiry",
  async (thunkAPI) => {
    try {
      const data = await EnquiryService.AyurvedaEnquiry();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);



export const editEnquiry = createAsyncThunk(
  "enquiry/put/travelenquiry",
  async (item, thunkAPI) => {
    try {
      const data = await EnquiryService.editEnquiry(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
  loading: false,
  error: "",
  user: AuthService.getUserDetails() || null,
  isLoggedIn: false,
};

const enquirySlice = createSlice({
  name: "enquiry",
  initialState,
  extraReducers: {
    [Wenquiry.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [Wenquiry.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [Wenquiry.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },
    [physioEnquiry.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [physioEnquiry.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [physioEnquiry.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },

    [editEnquiry.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [editEnquiry.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [editEnquiry.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },




    // [logout.fulfilled]: (state, action) => {
    //   state.isLoggedIn = false;
    //   state.user = null;
    // },
  },
});

const { reducer } = enquirySlice;
export default reducer;