import React, { useState } from "react";
import Footer from "./footer";
import { userBp, bloodGlucose, hba1c } from "../store/slices/forms";
import { useDispatch, useSelector } from "react-redux";
import Navbarside from "../component/Navbarside";
import { useParams } from "react-router-dom";
import Moment from "moment";
const UserVitals = () => {
  const [uuserBp, setuuserBp] = useState([]);
  const [ubloodGlucose, setubloodGlucose] = useState([]);
  const [uhba1c, setuhba1c] = useState([]);

  const { id } = useParams();
  localStorage.setItem("userId", id);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(userBp())
      .unwrap()
      .then((data) => {
        setuuserBp(data.user.data.recentLogs);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(bloodGlucose())
      .unwrap()
      .then((data) => {
        setubloodGlucose(data.user.data.recentLogs);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(hba1c())
      .unwrap()
      .then((data) => {
        setuhba1c(data.user.data.recentLogs);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid response-cover pb-5">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">User Vitals</h4>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="container">
                        <h3>Blood Pressure</h3>
                        <div className="card-block card-dashboard table-responsive">
                          <table className="table table-striped table-bordered zero-configuration">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Systolic</th>
                                <th>Diastolic</th>
                                <th>Pulse</th>
                              </tr>
                            </thead>
                            <tbody>
                              {uuserBp.map((userBpVal, index) => (
                                <tr>
                                  <td> {userBpVal.date}</td>

                                  <td>{userBpVal.systolic}</td>
                                  <td> {userBpVal.diastolic}</td>
                                  <td>{userBpVal.pulse}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="container">
                        <h4>Blood Glucose</h4>
                        <div className="card-block card-dashboard table-responsive">
                          <table className="table table-striped table-bordered zero-configuration">
                            <thead>
                              <tr>
                                <th>Date/ Time</th>
                                <th>Test Type</th>
                                <th>Blood Glucose</th>
                              </tr>
                            </thead>
                            <tbody>
                              {ubloodGlucose.map((ubloodGlucoseVal, index) => (
                                <tr>
                                  <td>
                                    {" "}
                                    {ubloodGlucoseVal.date} /
                                    {ubloodGlucoseVal.time}
                                  </td>

                                  <td>{ubloodGlucoseVal.testType}</td>

                                  <td> {ubloodGlucoseVal.bloodGlucoseLevel}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="container">
                        <h4>HbA1C</h4>
                        <div className="card-block card-dashboard table-responsive">
                          <table className="table table-striped table-bordered zero-configuration">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Value</th>
                              </tr>
                            </thead>
                            <tbody>
                              {uhba1c.map((uhba1cVal, index) => (
                                <tr>
                                  <td> {uhba1cVal.date}</td>

                                  <td>{uhba1cVal.time}</td>
                                  <td> {uhba1cVal.hba1cLevel}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default UserVitals;
