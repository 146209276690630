import React, { useState, useEffect } from "react";
import Navbarside from "../Navbarside";
import { postChallenge } from "../../store/slices/challenges";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  API_BASE_URL,
  API_BASE_URL_HC,
  API_PATHS,
} from "../../utils/constants/api.constants";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ChallengeService from "../../services/challenge.service";
import SearchBox from "../common/searchBox";
import axios from "axios";

const AddChallenges = (e) => {
  const { id } = useParams();
  localStorage.setItem("id", id);
  const navigate = useNavigate("");
  const dispatch = useDispatch();

  const goBack = () => navigate(-1);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [challengeCategorys, setchallengeCategorys] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [tip, setTip] = useState();
  const [tips, setTips] = useState([]);
  const [challengeCategoryId, setchallengeCategoryId] = useState([]);
  const [termsConditions, setTermsConditions] = useState("");
  const [reward, setReward] = useState("");

  const [needToDo, setNeedToDo] = useState("");
  const [needToDos, setNeedToDos] = useState([]);
  const [mediaLink, setmediaLink] = useState("");

  const [challengeType, setchallengeType] = useState("step");
  const [errorMessage, setErrorMsg] = useState("This field is required.");
  const [challengeData, setChallengeData] = useState({
    stepsReward: {
      stepCountPerPoint: 1000,
      pointsPerTree: 50,
      goalPoints: 100,
    },
    meditationReward: {
      routines: [],
      routinesPerPoint: 3,
      pointsPerTree: 30,
      goalPoints: 100,
    },
    foodReward: {
      routines: [],
      routinesPerPoint: 3,
      pointsPerTree: 30,
      goalPoints: 100,
    },
  });
  const [error, setError] = useState({});

  const [selectedChallengeCat, setSelectChallengeCat] = useState({});

  const handleCategoryChange = (e) => {
    var value = challengeCategorys.find(
      (category) => category.name == e.target.value
    );
    setchallengeCategoryId(value._id);
    setchallengeType(value.name);
    setSelectChallengeCat(value);
  };
  const handleStepsRewardChange = (event) => {
    setChallengeData({
      ...challengeData,
      stepsReward: {
        ...challengeData.stepsReward,
        [event.target.name]: event.target.value,
      },
    });
  };
  const handleMeditationRewardChange = (event) => {
    setChallengeData({
      ...challengeData,
      meditationReward: {
        ...challengeData.meditationReward,
        [event.target.name]: event.target.value,
      },
    });
  };
  const handleFoodRewardChange = (event) => {
    setChallengeData({
      ...challengeData,
      foodReward: {
        ...challengeData.foodReward,
        [event.target.name]: event.target.value,
      },
    });
  };

  const handleMeditationRoutineChange = async (event, index) => {
    let { name, value } = event.target;
    let specialValue = "";
    if (name == "contentId") {
      value = event.clipboardData.getData("text/plain");
      if (value) {
        let res = await axios.get(
          API_BASE_URL + "search/content?title=" + value
        );

        let data = res?.data?.data;
        if (data?.length) specialValue = data[0]._id;
      }
    }
    const updatedRoutines = [...challengeData.meditationReward.routines];
    updatedRoutines[index] = {
      ...updatedRoutines[index],
      [name]: specialValue ? specialValue : value,
    };
    setChallengeData({
      ...challengeData,
      meditationReward: {
        ...challengeData.meditationReward,
        routines: updatedRoutines,
      },
    });
  };

  const removeMeditationRoutine = (index) => {
    const updatedRoutines = [...challengeData.meditationReward.routines];
    updatedRoutines.splice(index, 1);
    setChallengeData({
      ...challengeData,
      meditationReward: {
        ...challengeData.meditationReward,
        routines: updatedRoutines,
      },
    });
  };
  const addMeditationRoutine = () => {
    const newRoutine = {
      time: "09:00",
      contentId: "",
      routine: "Morning",
    };
    const updatedRoutines = [
      ...challengeData.meditationReward.routines,
      newRoutine,
    ];
    setChallengeData({
      ...challengeData,
      meditationReward: {
        ...challengeData.meditationReward,
        routines: updatedRoutines,
      },
    });
  };

  const handleFoodRoutineChange = (event, index) => {
    const { name, value } = event.target;
    console.log({ name, value });
    const updatedRoutines = [...challengeData.foodReward.routines];
    updatedRoutines[index] = {
      ...updatedRoutines[index],
      [name]: value,
    };
    setChallengeData({
      ...challengeData,
      foodReward: {
        ...challengeData.foodReward,
        routines: updatedRoutines,
      },
    });
  };
  const removeFoodRoutine = (index) => {
    const updatedRoutines = [...challengeData.foodReward.routines];
    updatedRoutines.splice(index, 1);
    setChallengeData({
      ...challengeData,
      foodReward: {
        ...challengeData.foodReward,
        routines: updatedRoutines,
      },
    });
  };
  const addFoodRoutine = () => {
    const newRoutine = {
      mealType: "Breakfast",
      startTime: "09:00",
    };
    const updatedRoutines = [...challengeData.foodReward.routines, newRoutine];
    setChallengeData({
      ...challengeData,
      foodReward: {
        ...challengeData.foodReward,
        routines: updatedRoutines,
      },
    });
  };

  async function imageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setmediaLink(data.data.location);
  }
  const ChallengeCategorys = createAsyncThunk(
    "expertise/get/expertise",
    async (thunkAPI) => {
      try {
        // console.log("consultation")
        const data = await ChallengeService.getChallengeCategories();
        return { user: data };
      } catch (error) {
        const sessionCount =
          (error.response &&
            error.response.data &&
            error.response.data.sessionCount) ||
          error.sessionCount ||
          error.toString();
        return thunkAPI.rejectWithValue({ sessionCount });
      }
    }
  );
  useEffect(() => {
    dispatch(ChallengeCategorys())
      .unwrap()
      .then((data) => {
        setchallengeCategorys(data.user.data);
      })
      .catch(({ sessionCount }) => {
        alert(sessionCount);
      });
  }, [dispatch]);

  const validationField = (field) => {
    let errorRequired = {};
    let returnField;
    console.log("vall", field);
    field?.forEach((obj) => {
      Object.entries(obj).some(([key, value]) => {
        if (value === undefined || value === null || value === "") {
          errorRequired = { ...errorRequired, [key]: true };
          returnField = true;
        }
      });
    });
    return { errorRequired: errorRequired, returnField: returnField };
  };

  async function addData(e) {
    e.preventDefault();
    try {
      const required = [
        { name: name },
        { shortDescription: shortDescription },
        { description: description },
        { challengeType: challengeType },
        { startDate: startDate },
        { endDate: endDate },
      ];
      const reuireData = validationField(required);
      console.log("required", reuireData);
      if (reuireData?.returnField) {
        console.log("ohk");
        setError(reuireData?.errorRequired);
        return;
      }
      let item = {
        name,
        shortDescription,
        description,
        challengeCategoryId,
        challengeType,

        mediaLink,
        termsConditions,

        startDate,
        endDate,

        tips,
        needToDo: needToDos,
        reward,
      };

      if (challengeType == "Steps challenge") {
        item.stepsReward = challengeData.stepsReward;
      } else if (challengeType == "Meditation challenge") {
        item.meditationReward = challengeData.meditationReward;
      } else if (challengeType == "Food challenge") {
        item.foodReward = challengeData.foodReward;
      }

      console.log({ item });
      dispatch(postChallenge(item))
        .unwrap()
        .then(() => {
          alert("Challenge added succesfully");
          navigate(-1);
        })
        .catch(({ message }) => {
          alert(message);
        });
    } catch (e) {
      alert(e.message);
    }
  }

  console.log({
    name,
    shortDescription,
    description,
    challengeCategoryId,
    challengeType,

    mediaLink,
    termsConditions,

    startDate,
    endDate,

    tips,
    needToDos,

    challengeData,
  });

  function setArr(field) {
    if (field == "tip" && tip) {
      let tipData = [...tips, tip];
      setTips(tipData);
      setTip("");
    } else if (needToDo) {
      let needToData = [...needToDos, needToDo];
      setNeedToDos(needToData);
      setNeedToDo("");
    }
  }

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">Add Challenge</h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <form>
                        <div className="row">
                          <div className="col-md-6 pt-3">
                            <span>Name</span>
                            <input
                              type="text"
                              placeholder="Enter Challenge Name"
                              className="form-control"
                              value={name}
                              required
                              onChange={(e) => {
                                setName(e.target.value);
                                setError({ ...error, ["name"]: false });
                              }}
                            />
                            {error?.name && (
                              <span style={{ color: "red" }}>
                                {errorMessage}
                              </span>
                            )}
                          </div>

                          <div className="col-md-6 pt-3">
                            <span>Challenge Category</span>
                            <select
                              className="form-control"
                              onChange={handleCategoryChange}
                              required
                            >
                              <option value="">Select</option>
                              {challengeCategorys.map((option) => (
                                <option value={option.expertiseId}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                            <p className="alert-message"></p>
                          </div>

                          <div className="col-md-12 pt-2">
                            <span>Description</span>
                            <textarea
                              placeholder="Enter description"
                              className="form-control"
                              value={description}
                              onChange={(e) => {
                                setDescription(e.target.value);
                                setError({ ...error, ["description"]: false });
                              }}
                            />
                            {error?.description && (
                              <span style={{ color: "red" }}>
                                {errorMessage}
                              </span>
                            )}
                          </div>

                          <div className="col-md-6 pt-2">
                            <span>Short Description</span>
                            <input
                              type="text"
                              placeholder="Enter short description"
                              className="form-control"
                              value={shortDescription}
                              required
                              onChange={(e) => {
                                setShortDescription(e.target.value);
                                setError({
                                  ...error,
                                  ["shortDescription"]: false,
                                });
                              }}
                            />
                            {error?.shortDescription && (
                              <span style={{ color: "red" }}>
                                {errorMessage}
                              </span>
                            )}
                          </div>

                          <div className="col-md-6 pt-2">
                            <span>Image</span>

                            <input
                              type="file"
                              className="form-control"
                              Placeholder="Price Tagline"
                              name="imageUrl"
                              onChange={(e) => imageUpload(e)}
                            />
                          </div>

                          <div className="col-md-6 pt-3">
                            <span>Start Date </span>
                            <input
                              type="date"
                              placeholder=""
                              className="form-control"
                              value={startDate}
                              required
                              onChange={(e) => {
                                setStartDate(e.target.value);
                                setError({ ...error, ["startDate"]: false });
                              }}
                            />
                            {error?.startDate && (
                              <span style={{ color: "red" }}>
                                {errorMessage}
                              </span>
                            )}
                          </div>

                          <div className="col-md-6 pt-3">
                            <span>End Date</span>
                            <input
                              type="date"
                              placeholder="Blog Title"
                              className="form-control"
                              value={endDate}
                              required
                              onChange={(e) => {
                                setEndDate(e.target.value);
                                setError({ ...error, ["endDate"]: false });
                              }}
                            />
                            {error?.endDate && (
                              <span style={{ color: "red" }}>
                                {errorMessage}
                              </span>
                            )}
                          </div>

                          <div className="col-md-6 pt-3">
                            <span>Tips</span>
                            <input
                              type="text"
                              placeholder="Enter Tips"
                              value={tip}
                              onChange={(e) => setTip(e.target.value)}
                              className="form-control"
                              required
                            />
                            <button
                              type="button"
                              onClick={(e) => setArr("tip")}
                            >
                              Add
                            </button>
                            <br />
                            <b>Added Tips :</b>{" "}
                            {tips.map((x) => (
                              <p>{x}</p>
                            ))}
                          </div>

                          <div className="col-md-6 pt-3">
                            <span>Need to Do</span>
                            <input
                              type="text"
                              className="form-control"
                              value={needToDo}
                              required
                              onChange={(e) => setNeedToDo(e.target.value)}
                            />
                            <button
                              type="button"
                              onClick={(e) => setArr("needTo")}
                            >
                              Add
                            </button>
                            <br />
                            <b>Added Need To Do:</b>{" "}
                            {needToDos.map((x) => (
                              <p>{x}</p>
                            ))}
                          </div>
                          <div className="col-md-12 col-sm-12 pt-2">
                            <div className="form-body">
                              <div className="form-group">
                                <label for="placeTextarea">
                                  Terms and Conditions
                                </label>
                                <textarea
                                  rows="5"
                                  className="form-control"
                                  Placeholder="Enter Terms and Conditions"
                                  value={termsConditions}
                                  onChange={(e) =>
                                    setTermsConditions(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6 pt-2">
                            <span>Reward (text)</span>
                            <input
                              type="text"
                              placeholder="Enter reward text"
                              className="form-control"
                              value={reward}
                              required
                              onChange={(e) => setReward(e.target.value)}
                            />
                          </div>

                          {challengeType === "Steps challenge" && (
                            <div className="col-md-12 pt-3">
                              <h4>Steps Reward:</h4>
                              <label htmlFor="stepCountPerPoint">
                                Step Count Per Point:
                              </label>
                              <input
                                type="number"
                                id="stepCountPerPoint"
                                className="form-control"
                                name="stepCountPerPoint"
                                value={
                                  challengeData.stepsReward.stepCountPerPoint
                                }
                                onChange={handleStepsRewardChange}
                              />

                              <label htmlFor="pointsPerTree">
                                Points Per Tree:
                              </label>
                              <input
                                type="number"
                                id="pointsPerTree"
                                className="form-control"
                                name="pointsPerTree"
                                value={challengeData.stepsReward.pointsPerTree}
                                onChange={handleStepsRewardChange}
                              />

                              <label htmlFor="goalPoints">Goal Points:</label>
                              <input
                                type="number"
                                id="goalPoints"
                                className="form-control"
                                name="goalPoints"
                                value={challengeData.stepsReward.goalPoints}
                                onChange={handleStepsRewardChange}
                              />
                            </div>
                          )}

                          {challengeType === "Meditation challenge" && (
                            <div className="col-md-12 pt-3">
                              <h4>
                                Meditation Reward:{" "}
                                <a
                                  target="_blank"
                                  href="/LiveWellCategory"
                                  className="btn btn-info"
                                >
                                  Find Videos
                                </a>
                              </h4>
                              {challengeData.meditationReward.routines.map(
                                (routine, index) => (
                                  <div className="row" key={index}>
                                    <div className="col-md-3 pt-3">
                                      <label htmlFor={`contentId-${index}`}>
                                        Content name:
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id={`contentId-${index}`}
                                        name={`contentId`}
                                        // value=value
                                        onPaste={(event) =>
                                          handleMeditationRoutineChange(
                                            event,
                                            index
                                          )
                                        }
                                        placeholder="paste video title here"
                                      />
                                      <p>contentId : {routine.contentId}</p>
                                      <br />
                                    </div>

                                    <div className="col-md-3 pt-3">
                                      <label htmlFor={`time-${index}`}>
                                        Time:
                                      </label>
                                      <input
                                        type="time"
                                        id={`time-${index}`}
                                        className="form-control"
                                        name={`time`}
                                        value={routine.time}
                                        onChange={(event) =>
                                          handleMeditationRoutineChange(
                                            event,
                                            index
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="col-md-3 pt-3">
                                      <label htmlFor={`routine-${index}`}>
                                        Routine:
                                      </label>
                                      <select
                                        className="form-control"
                                        id={`routine-${index}`}
                                        name={`routine`}
                                        value={routine.routine}
                                        onChange={(event) =>
                                          handleMeditationRoutineChange(
                                            event,
                                            index
                                          )
                                        }
                                      >
                                        <option value="Morning">Morning</option>
                                        <option value="Evening">Evening</option>
                                        <option value="Night">Night</option>
                                      </select>
                                    </div>
                                    <div className="col-md-3">
                                      <button
                                        style={{ "margin-top": "20%" }}
                                        className="btn btn-warning"
                                        type="button"
                                        onClick={() =>
                                          removeMeditationRoutine(index)
                                        }
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                )
                              )}

                              <button
                                type="button"
                                className="btn btn-info"
                                onClick={addMeditationRoutine}
                              >
                                Add Routine
                              </button>

                              <br />

                              <label htmlFor="routinesPerPoint">
                                Routines Per Point:
                              </label>
                              <input
                                className="form-control"
                                type="number"
                                id="routinesPerPoint"
                                name="routinesPerPoint"
                                value={
                                  challengeData.meditationReward
                                    .routinesPerPoint
                                }
                                onChange={handleMeditationRewardChange}
                              />

                              <label htmlFor="pointsPerTree">
                                Points Per Tree:
                              </label>
                              <input
                                className="form-control"
                                type="number"
                                id="pointsPerTree"
                                name="pointsPerTree"
                                value={
                                  challengeData.meditationReward.pointsPerTree
                                }
                                onChange={handleMeditationRewardChange}
                              />

                              <label htmlFor="goalPoints">Goal Points:</label>
                              <input
                                className="form-control"
                                type="number"
                                id="goalPoints"
                                name="goalPoints"
                                value={
                                  challengeData.meditationReward.goalPoints
                                }
                                onChange={handleMeditationRewardChange}
                              />
                            </div>
                          )}

                          {challengeType === "Food challenge" && (
                            <div className="col-md-12 pt-3">
                              <h4>Food Reward:</h4>
                              {challengeData.foodReward.routines.map(
                                (routine, index) => (
                                  <div className="row" key={index}>
                                    <div className="col-md-3 pt-3">
                                      <label htmlFor={`mealType-${index}`}>
                                        Meal Type:
                                      </label>
                                      <select
                                        className="form-control"
                                        id={`mealType-${index}`}
                                        name={`mealType`}
                                        value={routine.mealType}
                                        onChange={(event) =>
                                          handleFoodRoutineChange(event, index)
                                        }
                                      >
                                        <option value="Breakfast">
                                          Breakfast
                                        </option>
                                        <option value="Morning Snacks">
                                          Morning Snacks
                                        </option>
                                        <option value="Lunch">Lunch</option>
                                        <option value="Evening Snacks">
                                          Lunch
                                        </option>
                                        <option value="Dinner">Dinner</option>
                                      </select>
                                    </div>

                                    <div className="col-md-3 pt-3">
                                      <label htmlFor={`startTime-${index}`}>
                                        Time:
                                      </label>
                                      <input
                                        className="form-control"
                                        type="time"
                                        id={`startTime-${index}`}
                                        name={`startTime`}
                                        value={routine.time}
                                        onChange={(event) =>
                                          handleFoodRoutineChange(event, index)
                                        }
                                      />
                                    </div>

                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      onClick={() => removeFoodRoutine(index)}
                                    >
                                      Remove
                                    </button>
                                  </div>
                                )
                              )}

                              <button
                                type="button"
                                className="btn btn-info"
                                onClick={addFoodRoutine}
                              >
                                Add Routine
                              </button>
                              <br />

                              <label htmlFor="routinesPerPoint">
                                Routines Per Point:
                              </label>
                              <input
                                className="form-control"
                                type="number"
                                id="routinesPerPoint"
                                name="routinesPerPoint"
                                value={
                                  challengeData.foodReward.routinesPerPoint
                                }
                                onChange={handleFoodRewardChange}
                              />

                              <label htmlFor="pointsPerTree">
                                Points Per Tree:
                              </label>
                              <input
                                className="form-control"
                                type="number"
                                id="pointsPerTree"
                                name="pointsPerTree"
                                value={challengeData.foodReward.pointsPerTree}
                                onChange={handleFoodRewardChange}
                              />

                              <label htmlFor="goalPoints">Goal Points:</label>
                              <input
                                className="form-control"
                                type="number"
                                id="goalPoints"
                                name="goalPoints"
                                value={challengeData.foodReward.goalPoints}
                                onChange={handleFoodRewardChange}
                              />
                            </div>
                          )}

                          <div className="col-md-3" />
                          <div className="col-md-12" align="center">
                            <br />
                            <button
                              onClick={addData}
                              type="submit"
                              className="btn btn-primary"
                              style={{ width: "150px" }}
                            >
                              Save
                            </button>
                            &nbsp;
                            <button
                              type="reset"
                              onClick={goBack}
                              className="btn btn-danger"
                              style={{ width: "150px" }}
                            >
                              Back
                            </button>
                          </div>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
};

export default AddChallenges;
