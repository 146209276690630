import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import Navbarside from "./Navbarside";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./footer";
import { postonboarding } from "../store/slices/corporate";

const OnBoardCompany = () => {
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [employeeLimit, setEmployeeLimit] = useState("");
  const [address, setAddress] = useState("");
  const [whitelistDomain, setWhitelistDomain] = useState("");
  const [adminName, setAdminName] = useState("");

  const navigate = useNavigate("");
  const dispatch = useDispatch();

  const save = (e) => {
    // const regname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    // if (regname.test(name)) {
    //   setAlertName("");
    // } else if (!regname.test(name) && name === "") {
    //   setAlertName("Please enter the body part name");
    //   e.preventDefault();
    // } else {
    //   setAlertName("Single and double quotes are not-valid");
    //   e.preventDefault();
    // }
  };

  const cancel = () => {};

  async function addData(e) {
    e.preventDefault();
    let item = {
      email,
      companyName,
      mobileNo,
      employeeLimit,
      address,
      whitelistDomain,
      adminName,
    };
    dispatch(postonboarding(item))
      .unwrap()
      .then(() => {
        alert("Uploaded succesfully");
        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }

  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">Onboard Company</h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <form onSubmit={addData}>
                        <div className="row">
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Email
                            </p>
                            <input
                              type="text"
                              placeholder="Enter the email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Company Name
                            </p>
                            <input
                              type="text"
                              placeholder="Enter the company name"
                              value={companyName}
                              onChange={(e) => setCompanyName(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Mobile No
                            </p>
                            <input
                              type="text"
                              placeholder="Enter the mobile no."
                              value={mobileNo}
                              onChange={(e) => setMobileNo(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Employe Limit
                            </p>
                            <input
                              type="text"
                              placeholder="Enter the employe limit "
                              value={employeeLimit}
                              onChange={(e) => setEmployeeLimit(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Address
                            </p>
                            <input
                              type="text"
                              placeholder="Enter the address"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Whitelist Domain
                            </p>
                            <input
                              type="text"
                              placeholder="Enter the whitelist domain"
                              value={whitelistDomain}
                              onChange={(e) =>
                                setWhitelistDomain(e.target.value)
                              }
                              className="form-control"
                            />
                            <p className="alert-message">{}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Admin Name
                            </p>
                            <input
                              type="text"
                              placeholder="Enter the admin name"
                              value={adminName}
                              onChange={(e) => setAdminName(e.target.value)}
                              className="form-control"
                            />
                            <p className="alert-message">{}</p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center pt-3">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={save}
                          >
                            Submit
                          </button>
                          &nbsp; &nbsp; &nbsp;
                          <button
                            type="reset"
                            className="btn btn-warning"
                            onClick={cancel}
                          >
                            Reset
                          </button>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default OnBoardCompany;
