import React from 'react';

const VideoPlayer = (props) => {
  const videoUrl = extractVideoInfo(props.url);

  return (
    <div>
      {videoUrl ? (
        <iframe
          title="Video player"
          width="300"
          height="150"
          src={videoUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      ) : (
        <p>Invalid video URL</p>
      )}
    </div>
  );
};

function extractVideoInfo(url) {
  // Extract YouTube video ID
  let match = url?.match(/(?:youtu.be\/|youtube.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([^&]{11})/);
  if (match) return `https://youtube.com/embed/${match[1]}`;

  return url;
}

export default VideoPlayer;
