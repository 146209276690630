import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../services/auth.service";
import AnalysisService from './../../services/analysis.service';



export const SalesAnalysisDetails = createAsyncThunk(
  "analysis/get/salesAnalysis",
  async (thunkAPI) => {
    try {
      return await AnalysisService.salesAnalysis(thunkAPI);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const GSTReconDetails = createAsyncThunk(
  "analysis/get/GSTReconDetails",
  async (thunkAPI) => {
    try {
      console.log("GSTReconDetails",thunkAPI)
      return await AnalysisService.GSTRecon(thunkAPI);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const salesReportCall = createAsyncThunk(
  "analysis/get/salesReport",
  async (thunkAPI) => {
    try {
      return await AnalysisService.salesReport(thunkAPI);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const exportReconCall = createAsyncThunk(
  "analysis/get/exportRecon",
  async (thunkAPI) => {
    try {
      return await AnalysisService.exportRecon(thunkAPI);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const ConversionAnalysisDetails = createAsyncThunk(
  "analysis/get/salesAnalysis",
  async (thunkAPI) => {
    try {
      return await AnalysisService.ConversionAnalysis(thunkAPI);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const enquiryAnalysisDetails = createAsyncThunk(
  "analysis/get/enquiryAnalysis",
  async (thunkAPI) => {
    try {
      return await AnalysisService.enquiryAnalysis(thunkAPI);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const getSubscriptions = createAsyncThunk(
  "analysis/fetch/subscription",
  async (params, thunkAPI) => {
    try {
      const data = await AnalysisService.getSubscription(params);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const getSessions = createAsyncThunk(
  "analysis/fetch/sessions",
  async (params, thunkAPI) => {
    try {
      const data = await AnalysisService.getSessions(params);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


export const AppointmentAnalysisDetails = createAsyncThunk(
  "analysis/get/appointmentAnalysis",
  async (thunkAPI) => {
    try {
      const data = await AnalysisService.appointmentAnalysis(thunkAPI);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const SessionnalysisDetails = createAsyncThunk(
  "analysis/get/sessionAnalysis",
  async (thunkAPI) => {
    try {
      const data = await AnalysisService.sessionAnalysis(thunkAPI);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


export const GstanalysisDetails = createAsyncThunk(
  "analysis/get/gstAnalysis",
  async (thunkAPI) => {
    try {
      const data = await AnalysisService.gstAnalysis();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);




// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
  loading: false,
  error: "",
  user: AuthService.getUserDetails() || null,
  isLoggedIn: false,
};

const analysisSlice = createSlice({
  name: "analysis",
  initialState,
  extraReducers: {
    [SalesAnalysisDetails.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [SalesAnalysisDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [SalesAnalysisDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.enquiryData = null;
    },
    [ConversionAnalysisDetails.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [ConversionAnalysisDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [ConversionAnalysisDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.enquiryData = null;
    },
    
    [enquiryAnalysisDetails.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.enquiryData = null;
    },
    [enquiryAnalysisDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.enquiryData = action.payload.user;
    },
    [enquiryAnalysisDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },
    

    [SessionnalysisDetails.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [SessionnalysisDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [SessionnalysisDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },

    [GstanalysisDetails.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [GstanalysisDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [GstanalysisDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },


    [AppointmentAnalysisDetails.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [AppointmentAnalysisDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [AppointmentAnalysisDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },


    // [logout.fulfilled]: (state, action) => {
    //   state.isLoggedIn = false;
    //   state.user = null;
    // },
  },
});

const { reducer } = analysisSlice;
export default reducer;