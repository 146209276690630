import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import workoutService from "../../services/workoutplans.service";
import AuthService from "../../services/auth.service";




export const getworkoutplans = createAsyncThunk(
    "exercise/store/exercise",
    async( thunkAPI) => {
        try {
            const data = await workoutService.getworkplan();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) || 
                error.message ||  
                error.toString(); 
            return thunkAPI.rejectWithValue({ message });  
        }
    }
);
export const getExpertworkoutplans = createAsyncThunk(
    "exercise/store/exercise",
    async( thunkAPI) => {
        try {
            const data = await workoutService.getExpertworkplan();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) || 
                error.message ||  
                error.toString(); 
            return thunkAPI.rejectWithValue({ message });  
        }
    }
);


export const getworkoutplanDetails = createAsyncThunk(
    "exercise/store/exercise",
    async( thunkAPI) => {
        try {
            const data = await workoutService.getworkplanDetails();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) || 
                error.message ||  
                error.toString(); 
            return thunkAPI.rejectWithValue({ message });  
        }
    }
);

export const DeleteWorkoutplan = createAsyncThunk(
    "workout/delete/workoutplan",
    async(thunkAPI) => {
        try {
            const data = await workoutService.deleteWorkoutplan();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
    loading: false,
    error: "",
    user: AuthService.getUserDetails() || null,
    isLoggedIn: false,
};

const TravelSlice = createSlice({
    name: "addtravels",
    initialState,
    extraReducers: {
    
        [getworkoutplans.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getworkoutplans.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getworkoutplans.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },

    

        // [logout.fulfilled]: (state, action) => {
        //   state.isLoggedIn = false;
        //   state.user = null;
        // },
    },
});

const { reducer } = TravelSlice;
export default reducer;